import {
  DateRangeType,
  DateStringType,
  DollarsToCentsType,
  FileAttachmentType,
  mkDateStringType,
} from "@meterup/ui/src/zod/helpers";
import { z } from "zod";

export const InvoiceSchema = z.object({
  invoice: z.object({
    sid: z.string().optional(),
    ispContractSID: z.string(),
    dueDate: DateStringType,
    invoicedAt: mkDateStringType({ nullable: true }),
    paidAt: mkDateStringType({ nullable: true }),
    amountActualCurrency: z.string().default("USD3"),
    amountActual: DollarsToCentsType,
  }),
  status: z.object({
    value: z.string(),
    label: z.string(),
  }),
  billingCycleDates: DateRangeType,
  invoiceDocument: z.instanceof(File).nullish(),
  // fileAttachment: FileAttachmentType,
});

export interface InvoiceSchemaFormData extends z.TypeOf<typeof InvoiceSchema> {}
