import React, { FormEvent, useCallback } from "react";

import type { AxiosAPIError, CompanyLocationResponse } from "@meterup/api";
import { axios } from "@meterup/api";
import {
  Button,
  CloseDrawerButton,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  Text,
} from "@meterup/metric";
import { BaseModalProps } from "@meterup/ui/src/components/Modal";
import { logError } from "@meterup/ui/src/Log.utils";
import { Form } from "@meterup/ui/src/styles/Form";
import { Drawer, ModalContent, ModalWrapper } from "@meterup/ui/src/styles/Modal";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { log } from "../Log.utils";

type ConfirmDeletionOfISPsForCompanyLocation = BaseModalProps & {
  companyLocation: CompanyLocationResponse;
  companySID: string;
};

export default function ConfirmDeletionModal({
  close,
  companyLocation,
  companySID,
  setModalIsBlocked,
}: ConfirmDeletionOfISPsForCompanyLocation) {
  const onClose = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      close(e);
    },
    [close],
  );

  const navigate = useNavigate();
  const { location, name, sid } = companyLocation;
  const { address } = location || {};

  const deleteLocationMutation = useMutation<any, AxiosAPIError, any>(
    // eslint-disable-next-line no-underscore-dangle
    () => axios.delete_(`/v1/connect/admin/company-locations/${sid}`),
    {
      onSuccess(resp) {
        console.log("resp", resp);
        navigate(`/dashboard/companies/${companySID}/searches`);
      },
      onError(err) {
        logError(err);
      },
    },
  );

  const onSubmit = useCallback(
    (e: React.SyntheticEvent<FormEvent>) => {
      e.preventDefault();
      deleteLocationMutation.mutate();
    },
    [deleteLocationMutation],
  );

  return (
    <ModalWrapper>
      <ModalContent>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>Confirm location deletion</DrawerTitle>
            <DrawerControls>
              <CloseDrawerButton onClick={onClose} />
            </DrawerControls>
          </DrawerHeader>
          <Form onSubmit={onSubmit}>
            <DrawerContent>
              Confirm you want to delete:
              <Text weight="medium">
                {name} {address?.address1}
              </Text>
            </DrawerContent>
            <DrawerFooter>
              <Button variant="secondary" onClick={onClose} type="button">
                Cancel
              </Button>
              <Button variant="destructive" type="submit">
                Delete
              </Button>
            </DrawerFooter>
          </Form>
        </Drawer>
      </ModalContent>
    </ModalWrapper>
  );
}
