import React, { useMemo, useState } from "react";

import {
  axios,
  AxiosAPIError,
  ConnectAdminCompaniesResponse,
  ConnectAdminCompanyResponse,
} from "@meterup/api";
import { Badge, Button, CopyCapsule, Text } from "@meterup/metric";
import { Drawer, OnClickRowProps, OpenDrawerLink } from "@meterup/ui";
import { CentsCell, DateCell } from "@meterup/ui/src/components/Table/CommonCells";
import { EM_DASH } from "@meterup/ui/src/constants";
import Header from "@meterup/ui/src/DataView/components/Header";
import DataView from "@meterup/ui/src/DataView/DataView";
import { useSimpleCellSizing } from "@meterup/ui/src/DataView/hooks/useCellSizings";
import LoadingLayout from "@meterup/ui/src/Layouts/LoadingLayout";
import { useQuery } from "@tanstack/react-query";
import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useNavigate, useOutlet } from "react-router-dom";

import { H3 } from "../../styles/dashboard";

const colHelper = createColumnHelper<ConnectAdminCompanyResponse>();

const columns: ColumnDef<ConnectAdminCompanyResponse, any>[] = [
  colHelper.accessor((r) => r.company?.name, {
    id: "name",
    header: "Name",
    cell: (props: CellContext<ConnectAdminCompanyResponse, any>) => (
      <Button onClick={(e) => console.log("button clicked", e)} variant="tertiary">
        {props.row?.original?.company?.name || EM_DASH}
      </Button>
    ),
  }),
  colHelper.accessor((r) => r.company?.defaultSlug, {
    id: "defaultSlug",
    header: "URL Slug",
    cell: (props: CellContext<ConnectAdminCompanyResponse, any>) => {
      const { defaultSlug } = props.row?.original?.company || {};
      if (defaultSlug) {
        return (
          <CopyCapsule
            aria-label="Copy company slug"
            arrangement="trailing-icon"
            textValue={defaultSlug}>
            {defaultSlug}
          </CopyCapsule>
        );
      }

      return <Button variant="tertiary">{EM_DASH}</Button>;
    },
  }),
  colHelper.accessor((r) => r.numQuotes, {
    id: "numQuotes",
    header: "Searches",
    cell: (props: CellContext<ConnectAdminCompanyResponse, any>) => (
      <Badge variant="neutral">{props.row?.original?.numQuotes || EM_DASH}</Badge>
    ),
  }),
  colHelper.accessor((r) => r.numContracts, {
    id: "numContracts",
    header: "Contracts",
    cell: (props: CellContext<ConnectAdminCompanyResponse, any>) => (
      <Badge variant="neutral">{props.row?.original?.numContracts || EM_DASH}</Badge>
    ),
  }),
  colHelper.accessor((r) => r.numUsers, {
    id: "numUsers",
    header: "Users",
    cell: (props: CellContext<ConnectAdminCompanyResponse, any>) => (
      <Text>{props.row?.original?.numUsers || EM_DASH}</Text>
    ),
  }),
  colHelper.accessor((r) => r.numActiveLocations, {
    id: "numActiveLocations",
    header: "Active locations",
    cell: (props: CellContext<ConnectAdminCompanyResponse, any>) => (
      <Text>{props.row?.original?.numActiveLocations || EM_DASH}</Text>
    ),
  }),
  colHelper.accessor((r) => r.numTodoStatuses, {
    id: "numTodoStatuses",
    header: "To-do",
    cell: (props: CellContext<ConnectAdminCompanyResponse, any>) => (
      <Badge variant="negative">{props.row?.original?.numTodoStatuses || EM_DASH}</Badge>
    ),
  }),
  colHelper.accessor((r) => r.numUpcomingRenewals, {
    id: "numUpcomingRenewals",
    header: "Upcoming renewals",
    cell: (props: CellContext<ConnectAdminCompanyResponse, any>) => (
      <Badge variant="alternative">{props.row?.original?.numUpcomingRenewals || EM_DASH}</Badge>
    ),
  }),
  colHelper.accessor((r) => r.sumMonthlyCostCents, {
    id: "sumMonthlyCostCents",
    header: "Total bill (monthly)",
    cell: (props: CellContext<ConnectAdminCompanyResponse, any>) => (
      <Text>
        <CentsCell value={props.row?.original?.sumMonthlyCostCents} />
      </Text>
    ),
  }),
  colHelper.accessor((r) => r.company?.createdAt, {
    id: "companyCreatedAt",
    header: "Created at",
    cell: (props: CellContext<ConnectAdminCompanyResponse, any>) => (
      <Text>
        <DateCell value={props.row?.original?.company?.createdAt} />
      </Text>
    ),
  }),
];

export default function Companies() {
  const [paginationState, setPaginationState] = useState({
    limit: 500,
    offset: 0,
  });
  const { data, isLoading, error } = useQuery<
    unknown,
    AxiosAPIError,
    ConnectAdminCompaniesResponse
  >(["admin", "companies"], () =>
    axios.get(
      `v1/connect/admin/companies?limit=${paginationState.limit}&offset=${paginationState.offset}`,
    ),
  );
  const buttons = useMemo(
    () => (
      <OpenDrawerLink path="create">
        <Button icon="plusCircle" variant="tertiary" arrangement="leading-label" size="medium">
          Add company
        </Button>
      </OpenDrawerLink>
    ),
    [],
  );
  const navigate = useNavigate();
  const children = useOutlet();
  const nameCellSizingFn = useSimpleCellSizing("button", "div");
  if (isLoading) {
    return <LoadingLayout />;
  }

  return (
    <>
      <DataView
        buttons={buttons}
        header={
          <Header>
            <Text size={14} lineHeight={20} as={H3} color={{ light: "gray-800" }} weight="medium">
              Companies
            </Text>
          </Header>
        }
        // searchValue={searchValue}
        // setSearchValue={setSearchValue}
        cellSizing={{
          name: nameCellSizingFn,
        }}
        columns={columns}
        data={data?.companies || []}
        noWrapColumns={["name", "defaultSlug"]}
        onClickRow={({ record }: OnClickRowProps<ConnectAdminCompanyResponse>) => {
          navigate(`/dashboard/companies/${record.company?.defaultSlug}`);
        }}
        staticHeight
      />
      <Drawer>{children}</Drawer>
    </>
  );
}
