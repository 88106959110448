import React, { useCallback, useMemo } from "react";

import {
  httpDeleteWithData,
  LocationProviderAvailabilityDeleteRequest,
  post,
  Provider,
  ProviderAvailability,
} from "@meterup/api";
import { BasicSelect, BasicSelectItem, Button, Icon } from "@meterup/metric";

interface ProvidersAvailabilitySectionProps {
  providers: Provider[];
  providersAvailable: Provider[];
  availability: string;
  locationSid: string;
  reloadLocation: () => Promise<void>;
}

export default function ProvidersAvailabilitySection({
  providers,
  providersAvailable,
  availability,
  locationSid,
  reloadLocation,
}: ProvidersAvailabilitySectionProps) {
  const providersDropdown = useMemo(
    () =>
      providersAvailable.map((provider: Provider) => ({
        value: provider.sid,
        label: provider.name,
      })),
    [providersAvailable],
  );

  const selectOnChange = useCallback(
    (providerSID: string) => {
      post(`admin/location-provider-availabilities`, {
        locationSid,
        providerSid: providerSID,
        availability,
      }).then(() => {
        reloadLocation();
      });
    },
    [availability, locationSid, reloadLocation],
  );

  const removeOnClick = useCallback(
    (providerSid: string) => {
      httpDeleteWithData<ProviderAvailability, LocationProviderAvailabilityDeleteRequest>(
        `admin/location-provider-availabilities`,
        {
          locationSid,
          providerSid,
        },
      ).then(() => {
        reloadLocation();
      });
    },
    [locationSid, reloadLocation],
  );

  return (
    <section className="px-4 pt-4 pb-2 overflow-hidden">
      <BasicSelect onValueChange={selectOnChange} value="">
        <BasicSelectItem value="" key={`providers-availability-${availability}-empty`}>
          Select a provider
        </BasicSelectItem>
        {providersDropdown.map((provider) => (
          <BasicSelectItem
            value={provider.value}
            key={`providers-availability-${availability}-${provider.value}`}>
            {provider.label}
          </BasicSelectItem>
        ))}
      </BasicSelect>
      <ul className="pt-4">
        {providers.map((provider: Provider) => (
          <li className="inline-block pr-2 pb-2" key={provider.sid}>
            <Button variant="tertiary">
              <span style={{ whiteSpace: "break-spaces" }}>{provider.name}</span>
              <span
                className="text-xs -translate-y-nudge-2px transform inline-block ml-2"
                onClick={() => removeOnClick(provider.sid)}>
                <Icon icon="cross" />
              </span>
            </Button>
          </li>
        ))}
      </ul>
    </section>
  );
}
