/* eslint-disable */
import Long from "long";
import * as _m0 from "protobufjs/minimal";
import {
  Company,
  CompanyCreateRequest,
  InviteUsersRequest,
  CompanyResponse,
  InviteUsersResponse,
  ISPStatus,
  InternetServicePlan,
  CompanyLocation,
  Provider as Provider1,
  CompanyLocationResponse,
} from "./apiProtos";
import {
  Provider,
  ConnectionType,
  ConnectionStatus,
  QuotesCreateRequest,
  QuotesUpdateRequest,
  ISPContract,
  Invoice,
} from "./connectProtos";
import { Address, FileAttachment } from "./common";

export const protobufPackage = "connectadmin";

export enum ConnectAdminErrors {
  ignored = "ignored",
  overlapping_contracts = "overlapping_contracts",
}

export interface ConnectAdminCompanyResponse {
  company?: Company;
  num_quotes: number;
  num_contracts: number;
  num_users: number;
  num_active_locations: number;
  num_todo_statuses: number;
  num_upcoming_renewals: number;
  sum_monthly_cost_cents: number;
}

/** GET /v1/connect/admin/companies */
export interface ConnectAdminCompaniesResponse {
  companies: ConnectAdminCompanyResponse[];
  offset: number;
  limit: number;
}

/** POST /v1/connect/admin/companies */
export interface ConnectAdminCreateCompanyWithUsersRequest {
  company?: CompanyCreateRequest;
  users?: InviteUsersRequest;
  send_invite: boolean;
}

export interface ConnectAdminCreateCompanyWithUsersResponse {
  company?: CompanyResponse;
  users?: InviteUsersResponse;
}

export interface PartialISPUpdate {
  status: ISPStatus;
  location?: CompanyLocation | undefined;
  contract_url?: string | undefined;
  contact_phone_number?: string | undefined;
  primary_contact_email?: string | undefined;
  billing_provider_name?: string | undefined;
}

export interface PartialQuoteUpdate {
  provider?: Provider;
  connection_type?: ConnectionType;
  is_manual: boolean;
  monthly_cost_cents: number;
  install_fee_cents: number;
  installation_estimate_days: number;
  download_kbps: number;
  upload_kbps: number;
}

/** POST /v1/connect/admin/quote-requests/:quoteRequestSID/dashboard-quotes */
export interface ConnectAdminCreateQuoteForDashboard {
  connection_status?: ConnectionStatus;
  quotes_create_req?: QuotesCreateRequest;
  isp?: PartialISPUpdate;
  contract_pdf?: Uint8Array | undefined;
}

/** PUT /v1/connect/admin/quote-requests/:quoteRequestSID/dashboard-quotes/:quoteSID */
export interface ConnectAdminUpdateQuoteForDashboard {
  connection_status?: ConnectionStatus;
  quotes_update_req?: QuotesUpdateRequest;
  isp?: PartialISPUpdate;
  contract_pdf?: Uint8Array | undefined;
}

export interface ISPContractResponse {
  isp_contract?: ISPContract;
  internet_service_plan?: InternetServicePlan;
  company?: Company;
}

/** POST /v1/connect/admin/internet-service-plans/:ispSID/billings */
export interface CreateISPContractRequest {
  location?: CompanyLocation;
  isp?: InternetServicePlan;
  contract_url: string;
  contact_phone_number: string;
  primary_contact_email: string;
  billing_provider_name: string;
}

export interface InvoiceResponse {
  invoice?: Invoice;
  created_at?: string;
  updated_at?: string;
  internet_service_plan_sid: string;
  provider?: Provider1;
  company_location_name: string;
  address?: Address;
}

/** GET /v1/companies/:slug/connect/billings */
export interface InvoicesResponse {
  invoices: InvoiceResponse[];
}

/** GET /v1/companies/:slug/connect/contracts */
export interface ISPContractsResponse {
  isp_contracts: ISPContractResponse[];
}

/** GET /v1/companies/:slug/connect/contracts/:ispContractSID */
export interface ContractResponse {
  isp_contract?: ISPContract;
  provider?: Provider1;
  address?: Address;
  company_location?: CompanyLocationResponse | undefined;
}

/** GET /v1/connect/admin/companies/:slug/contracts */
export interface ContractsResponse {
  contracts: ContractResponse[];
}

/** POST /v1/connect/admin/internet-service-plans/:ispSID/contracts */
export interface ISPContractCreateRequest {
  isp_contract?: ISPContract;
  contract_document?: FileAttachment | undefined;
  contract_document_s3_key?: string;
  delete_document?: boolean | undefined;
}

export interface ISPContractCreateRequest_Error {
  type: ConnectAdminErrors;
  title: string;
  conflicting_contract?: ISPContract;
  detail: string;
}

/** POST /v1/connect/admin/internet-service-plans/:ispSID/contracts/:ispContractSID/invoices */
export interface InvoiceCreateRequest {
  invoice?: Invoice;
  document?: FileAttachment | undefined;
  document_s3_key?: string;
  delete_document?: boolean | undefined;
}

export interface InvoiceCreateRequest_Error {
  type: ConnectAdminErrors;
  title: string;
  conflicting_invoice?: Invoice;
  detail: string;
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
