import { useMemo } from "react";

import type {
  AxiosAPIError,
  ConnectionChangeStatusCategory,
  ConnectionStatus,
  ConnectionStatusesResponse,
} from "@meterup/api";
import { axios } from "@meterup/api";
import { useQuery } from "@tanstack/react-query";

export function useAllStatuses() {
  return useQuery<unknown, AxiosAPIError, ConnectionStatusesResponse>(["statuses"], () =>
    axios.get<ConnectionStatusesResponse>("v1/connect/admin/statuses"),
  );
}

type UseStatusesByCategoryResult = {
  byCategory: Record<ConnectionChangeStatusCategory, ConnectionStatus[]>;
  byPriority: ConnectionChangeStatusCategory[];
  isLoading: boolean;
};

export function useStatusesByCategory(): UseStatusesByCategoryResult {
  const { data, isLoading } = useAllStatuses();

  return useMemo(() => {
    if (!data) {
      return { isLoading } as UseStatusesByCategoryResult;
    }
    const byCategory = data.statuses.reduce((acc, status) => {
      const statuses = [...(acc[status.category] || []), status];
      return {
        ...acc,
        [status.category]: statuses,
      };
    }, {} as UseStatusesByCategoryResult["byCategory"]);
    const byPriority = new Array(Object.keys(byCategory).length);
    data.statuses.forEach((status) => {
      const { category, priority } = status;
      byPriority.splice(priority - 1, 1, category);
    });

    return {
      byCategory,
      byPriority,
      isLoading,
    };
  }, [data, isLoading]);
}
