import React, { useMemo } from "react";

import { useApiResources } from "@meterup/api";
import { Icon } from "@meterup/metric";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "../components/Layout";
import QuoteForm from "../components/QuoteForm";

export default function EditQuote() {
  const { quoteRequestSid, sid } = useParams<{
    quoteRequestSid: string;
    sid: string;
  }>();

  const [loading, quotes] = useApiResources("quotes", `quote-requests/${quoteRequestSid}/quotes`);

  const quote = useMemo(() => quotes.find((quote) => quote.sid === sid), [quotes, sid]);
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="flex flex-col mx-4">
        <p className="mt-8 mb-8">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}>
            <Icon icon="chevronLeft" className="mr-1 text-xs" /> Back
          </a>
        </p>

        <h2 className="mb-2 text-2xl font-semibold">Edit Quote</h2>
        {loading ? <Skeleton /> : <QuoteForm quoteRequestSid={quoteRequestSid} quote={quote} />}
      </div>
    </Layout>
  );
}
