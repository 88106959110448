import React from "react";

import { HStack } from "@meterup/metric";

import { ShowHeaderDetailContainer } from "./containers";

type ShowHeaderLargeProps = {
  children: Read.ReactNode;
};

export default function ShowHeaderDetail({ children }: ShowHeaderLargeProps) {
  if (React.Children.count(children) > 2) {
    console.error("ShowHeaderLarge can only have two children");
  }

  return (
    <HStack as={ShowHeaderDetailContainer} align="end" justify="between" width="full">
      {children}
    </HStack>
  );
}

/*
Invoice Date
Invoice Due Date
Amount
Status


Date
Provider
Location
 */
