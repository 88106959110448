import React, { useMemo } from "react";
import { CompanyContractResponse, CompanyContractsResponse } from "@meterup/api";
import { ContractForDisplayType, formatContractForDisplay } from "./useFormatContractsForTable";
import { calcContractSumValue } from "../utils/ContractValue";
import { StatusTabItems, StatusTabItemValues } from "./useActiveStatusTab";
import { leafMatches } from "../utils/filters";
import useFormatters from "./useFormatters";

export type UseProcessedContractsResultType = {
  allContracts: ContractForDisplayType[];
  counts: Record<StatusTabItemValues, number>;
  filteredContracts: CompanyContractsResponse[];

  rawRows: ContractForDisplayType[];
  totalBill?: string;
};

export type GroupedContractsType = {
  contract: CompanyContractsResponse;
};

type UseProcessedContractsProps = {
  activeFilterType: StatusTabItems;
  companySID?: string;
  contracts?: CompanyContractsResponse[];
  searchValue: string;
};

export default function useProcessedContracts({
  activeFilterType,
  companySID,
  contracts,
  searchValue,
}: UseProcessedContractsProps): UseProcessedContractsResultType {
  const formatters = useFormatters();
  return useMemo(() => {
    const grouped = {} as Record<string, CompanyContractsResponse>;
    const counts = Object.values(StatusTabItems).reduce(
      (acc, val) => ({ ...acc, [val]: 0 }),
      {} as Record<StatusTabItemValues, number>,
    );
    const groupedCounts = { ...counts };
    if (!contracts) {
      return {
        allContracts: [] as ContractForDisplayType[],
        counts,
        filteredContracts: [] as CompanyContractsResponse[],
        rawRows: [],
      };
    }
    const rawRows: ContractForDisplayType[] = [];
    const allContractsForDisplay: ContractForDisplayType[] = [];
    const allContracts: CompanyContractResponse[] = [];

    const filteredContracts: CompanyContractsResponse[] = contracts.flatMap(
      (companyContractsResponse) => {
        const { companyLocation, companyContracts } = companyContractsResponse;
        if (!companyLocation) {
          return [];
        }
        const { sid } = companyLocation;
        const sumCostCents = calcContractSumValue(companyContracts);
        const filteredCompanyContracts: CompanyContractResponse[] = [];
        allContracts.push(...companyContracts);
        companyContracts.forEach((companyContract: CompanyContractResponse) => {
          allContractsForDisplay.push({
            companyLocation,
            companySID,
            contract: companyContract,
            sumCostCents: 0,
          });
          const category = companyContract?.latestStatus?.connectionStatus?.category;
          if (!category) {
            return;
          }
          if (!companyContract) {
            return;
          }
          let contractMatchesAppliedFilters = activeFilterType === StatusTabItems.All;
          counts.all += 1;

          if (category === "in-progress") {
            counts.installs += 1;

            if (!contractMatchesAppliedFilters) {
              contractMatchesAppliedFilters = activeFilterType === StatusTabItems.Installs;
            }
          }
          if (category === "quotes") {
            counts.quotes += 1;
            if (!contractMatchesAppliedFilters) {
              contractMatchesAppliedFilters = activeFilterType === StatusTabItems.Quotes;
            }
          }
          if (category === "active") {
            counts.active += 1;
          }

          const formattedForTable = formatContractForDisplay(
            {
              companyLocation,
              companySID,
              contract: companyContract,
              sumCostCents,
            },
            formatters,
          );

          if (
            contractMatchesAppliedFilters &&
            (leafMatches(formattedForTable, searchValue) || searchValue === "") &&
            formattedForTable
          ) {
            rawRows.push({
              companyLocation,
              companySID,
              contract: companyContract,
              sumCostCents,
            });
            filteredCompanyContracts.push(companyContract);
            grouped[sid] = companyContractsResponse;
          }
        });

        if (filteredCompanyContracts.length > 0) {
          return [
            {
              companyLocation,
              companyContracts: filteredCompanyContracts,
            },
          ];
        }

        return [];
      },
    );

    return {
      allContracts: allContractsForDisplay,
      counts,
      filteredContracts,
      rawRows,
      totalBill: formatters.centsFormatter(calcContractSumValue(allContracts)),
    };
  }, [contracts, formatters, activeFilterType, searchValue]);
}
