import React from "react";

import { IconName } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "@meterup/metric";
import { Header } from "@tanstack/react-table";

type SortIconProps<TData, TValue> = {
  header: Header<TData, TValue>;
};

export default function SortIcon<TData, TValue = unknown>({
  header,
}: SortIconProps<TData, TValue>) {
  const isSorted = header.column.getIsSorted();
  if (!isSorted) {
    return null;
  }

  let iconName: IconName = "chevronDown";

  if (isSorted === "asc") {
    iconName = "chevronUp";
  }

  return (
    <span>
      <Icon icon={iconName} size={8} />
    </span>
  );
}
