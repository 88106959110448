import React from "react";

import { flexRender, Row as ReactRow } from "@tanstack/react-table";
import cx from "classnames";

interface RowProps {
  row: ReactRow;
  onClick?: () => void;
}

export default function Row({ row, onClick }: RowProps) {
  return (
    <tr key={row.id} onClick={onClick} className={cx({ "cursor-pointer group": onClick })}>
      {row.getVisibleCells().map((cell, i) => (
        // eslint-disable-next-line react/jsx-key
        <td
          key={cell.id}
          className={cx({
            "px-4 py-3 whitespace-nowrap text-gray": true,
            "border-teal-meter group-hover:pl-3 group-hover:border-l-8": onClick && i === 0,
          })}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
}
