import { useLocation } from "react-router-dom";

import { useEffectDebugger } from "../../utils/debugging";
import { useDrawer } from "./drawerContext";
import { DrawerProps } from "./DrawerProps";

export function Drawer({ autoOpen = true, children }: DrawerProps) {
  const { openDrawer, setContent } = useDrawer();
  const location = useLocation();

  useEffectDebugger(
    () => {
      setContent(children, autoOpen);

      // return () => setContent(null, true);
    },
    [children, setContent],
    ["children", "setContent"],
    false,
  );

  useEffectDebugger(
    async () => {
      if (children) {
        await openDrawer();
      }
    },
    [location, children],
    ["location", "children"],
  );

  return null;
}

Drawer.whyDidYouRender = true;
