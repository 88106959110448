import React, { MouseEvent, useCallback, useMemo } from "react";

import { useAuthenticatedUser } from "@meterup/api";
import { ConnectLogo, Icon, IconName } from "@meterup/metric";
import classNames from "classnames";
import md5 from "md5";
import { useLocation, useNavigate } from "react-router-dom";
import trim from "lodash/trim";

import { route } from "../routing";

interface SidebarProps { }

const links: { path: string; icon: IconName; title: string }[] = [
  {
    path: "quoteRequests",
    icon: "search",
    title: "Searches",
  },
  {
    path: "providers",
    icon: "home",
    title: "Providers",
  },
  {
    path: "settings",
    icon: "wrench",
    title: "Settings",
  },
  {
    path: "dashboard",
    icon: "company",
    title: "Dashboard",
  },
];

const linkBaseClasses = [
  "flex",
  "items-center",
  "px-2",
  "py-2",
  "text-base",
  "font-medium",
  "leading-6",
  "transition",
  "duration-150",
  "ease-in-out",
  "rounded-md",
  "group",
  "focus:outline-none",
].join(" ");

const isCurrentRoute = (current: string, path: string) => current === path;

export default function Sidebar() {
  const [, userCookie, onLogout] = useAuthenticatedUser();

  const avatarSrc = useMemo(
    () => `https://www.gravatar.com/avatar/${md5(trim(userCookie?.username).toLowerCase())}`,
    [userCookie],
  );

  const navigate = useNavigate();
  const location = useLocation();

  const onClick = useCallback(
    (routeName) => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      navigate(route(routeName));
    },
    [],
  );

  const onClickLogout = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      // onLogout();
      onClick("login")(e);
    },
    [onClick, onLogout],
  );

  return (
    <div className="flex flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col flex-1 h-0 bg-white border-r border-gray-100">
          <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <ConnectLogo />
            </div>
            <nav className="flex-1 px-2 mt-5 space-y-1 bg-white">
              {links.map((link, i) => (
                <a
                  key={i}
                  href="#"
                  onClick={onClick(link.path)}
                  className={classNames({
                    [linkBaseClasses]: true,
                    "text-gray-900 bg-gray-50 focus:bg-gray-50": isCurrentRoute(
                      location.pathname,
                      route(link.path as any),
                    ),
                    "hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50": !isCurrentRoute(
                      location.pathname,
                      route(link.path as any),
                    ),
                  })}>
                  <Icon className="mr-4" icon={link.icon} />
                  {link.title}
                </a>
              ))}
            </nav>
          </div>
          <div className="flex flex-shrink-0 p-4 border-t border-gray-100">
            <a href="#" className="flex-shrink-0 block w-full group" onClick={onClickLogout}>
              <div className="flex items-center">
                <div className="w-1/4">
                  <img className="inline-block rounded-full h-9 w-9" src={avatarSrc} alt="" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium leading-5 text-gray-700 group-hover:text-gray-900">
                    {userCookie?.username}
                  </p>
                  <p
                    onClick={onLogout}
                    className="text-xs font-medium leading-4 text-gray-200 transition duration-150 ease-in-out group-hover:text-gray-700">
                    Logout
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
