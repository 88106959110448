import React from "react";

import { Skeleton, VStack } from "@meterup/metric";

import { log } from "../../Log.utils";

type StackedSkeletonProps = {
  count?: number;
};

export default function StackedSkeleton({ count = 3 }: StackedSkeletonProps) {
  return (
    <VStack spacing={12}>
      {Array.from({ length: count }).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton key={`stacked-skell-${i}`} height="30px" width="100%" radius={6} />
      ))}
    </VStack>
  );
}
