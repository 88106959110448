import React, { useMemo } from "react";

import { InvoiceCreateRequest } from "@meterup/api";
import { useInvalidateInvoices } from "@meterup/api/src/billing/queryKeys";
import useCreateInvoice from "@meterup/api/src/billing/useCreateInvoice";
import readDocument from "@meterup/ui/src/files/readDocument";
import { logError } from "@meterup/ui/src/Log.utils";
import { SubmitHandler } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";

import InvoiceFormDrawer from "../../../components/invoices/InvoiceFormDrawer";
import { InvoiceSchemaFormData } from "../../../components/invoices/invoiceSchema";
import { InvoicesPageContextType } from "../../../contexts/InvoicesPageContext";

export default function CreateInvoice() {
  const ctx = useOutletContext<InvoicesPageContextType>();
  const { ispContractSID } = useParams();

  const mutation = useCreateInvoice(ctx.ispContract?.ispContract?.internetServicePlanSid);
  const invalidateInvoices = useInvalidateInvoices();
  const onSubmit = useMemo<SubmitHandler<InvoiceSchemaFormData>>(
    () => async (innerData) => {
      try {
        const { invoice: rawInvoice, invoiceDocument: file, billingCycleDates, status } = innerData;
        const { ispContractSID: ignored, ...invoice } = rawInvoice;
        const req: InvoiceCreateRequest = {
          invoice: {
            ...invoice,
            billingCycleStartDate: billingCycleDates.startDate,
            billingCycleEndDate: billingCycleDates.endDate,
            ispContractsSid: ispContractSID,
            status: status.value,
          },
        };

        if (file) {
          const documentContents = await readDocument({ file });
          if (documentContents) {
            req.document = documentContents;
          }
        }

        await mutation.mutateAsync(req);
        invalidateInvoices();
      } catch (e) {
        logError(e);
      }
    },
    [invalidateInvoices, ispContractSID, mutation],
  );

  return (
    <InvoiceFormDrawer
      error={mutation.isError && mutation.error?.response?.data}
      ispContractSID={ispContractSID}
      onSubmit={onSubmit}
      purpose="create"
      successData={mutation.isSuccess && mutation.data}
    />
  );
}
