import { DependencyList, EffectCallback, useCallback, useEffect, useRef } from "react";

import { log as logMessage, logError, LogLevel, shouldLog } from "../Log.utils";

export function usePrevious(value, initialValue) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useEffectDebugger(
  effectHook: EffectCallback,
  dependencies: DependencyList,
  dependencyNames: string[] = [],
  log: boolean = true,
) {
  const previousDeps = usePrevious(dependencies, []);
  if (import.meta.env.NODE_ENV !== "development") {
    logError("useEffectDebugger should only be used in development");
  }
  if (shouldLog(LogLevel.DEBUG)) {
    const changedDeps = dependencies.reduce((accum, dependency, index) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
          },
        };
      }

      return accum;
    }, {});

    if (Object.keys(changedDeps).length && log) {
      logMessage("[use-effect-debugger] ", changedDeps, "dependencyNames", dependencyNames);
    }
  }

  return useEffect(effectHook, dependencies);
}

export function useCallbackDebugger<T extends (...args: any[]) => any>(
  callbackHook: T,
  dependencies: DependencyList,
  dependencyNames: string[] = [],
  log: boolean = true,
): T {
  const previousDeps = usePrevious(dependencies, []);

  if (import.meta.env.NODE_ENV !== "development") {
    logError("useCallbackDebugger should only be used in development");
  }

  if (shouldLog(LogLevel.DEBUG)) {
    const changedDeps = dependencies.reduce((accum, dependency, index) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
          },
        };
      }

      return accum;
    }, {});

    if (Object.keys(changedDeps).length && log) {
      logMessage("[use-callback-debugger] ", changedDeps);
    }
  }

  return useCallback(callbackHook, dependencies);
}

export function printStackTrace(...prefix: any[]) {
  const err = new Error();
  log(...prefix, err.stack);
}
