import React from "react";
import cx from "classnames";
import { formatMoney } from "@meterup/metric";

import styles from "./FormattedMonthlyAmount.module.css";

export interface FormattedMonthlyAmountProps {
  amount: number;
}

const FormattedMonthlyAmount: React.FunctionComponent<FormattedMonthlyAmountProps> = ({
  amount,
}) => {
  const formattedAmount = formatMoney({ amount, hideCentsIfZero: true });
  const [roundedAmount, cents] = formattedAmount.toString().split(".");

  return (
    <div className="text-gray-800 inline-block flex items-center">
      <span className="inline-block ">{roundedAmount}</span>
      <sup
        className={cx("font-normal text-gray-700 ml-nudge-2px inline-block self-top", styles.sup)}>
        {cents}
      </sup>
      <span className={cx("font-normal text-gray-600 ml-1 inline-block", styles.monthlyAddon)}>
        /mo
      </span>
    </div>
  );
};

export default FormattedMonthlyAmount;
