/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useMemo, useState } from "react";

import { httpDelete, Provider, useApiResources } from "@meterup/api";
import { Button } from "@meterup/metric";
import { ProviderLogo, Table, useFlashMessage, useModal } from "@meterup/ui";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { route } from "../../../routing";
import { CellTypeBase } from "../../QuoteRequest/types";

type CellType = CellTypeBase<Provider>;

export default function ProvidersTable() {
  const [providersLoading, providers, reloadProviders] = useApiResources("providers", "providers");

  const [rowLoading, hookSetRowLoading] = useState<string>();
  const setRowLoading = useCallback(
    (sid: string) => (loading: boolean) => hookSetRowLoading(loading ? sid : undefined),
    [],
  );

  const flashMessage = useFlashMessage({
    type: "success",
    message: "Successfully deleted provider.",
  });

  const onConfirm = useCallback(async (sid: string) => {
    await httpDelete<Provider>(`providers/${sid}`, setRowLoading(sid));
    await reloadProviders();
    flashMessage();
    onHideModal();
  }, []);

  const { onShowModal, onHideModal } = useModal({
    type: "warning",
    title: "Are you sure?",
    message: "Are you sure you'd like to delete this provider? This action is not reversible!",
    confirmText: "Delete Provider",
    onConfirm,
    showCancel: true,
  });

  const providersColumns = useMemo(
    () => [
      {
        header: "Provider name",
        accessorKey: "name",
      },
      {
        header: "Logo path",
        id: "logo",
        disableSortBy: true,
        cell: ({
          row: {
            original: { path, name },
          },
        }: CellType) => (
          <div style={{ maxWidth: "4rem" }}>
            {path && <ProviderLogo name={name} path={path} />}
            {!path && <span className="text-gray-200 italic">Missing</span>}
          </div>
        ),
      },
      {
        header: "",
        id: "edit",
        disableSortBy: true,
        cell: ({
          row: {
            original: { sid },
          },
        }: CellType) => (
          <p className="text-right">
            <Link to={route("edit_provider", { sid })}>Edit</Link>
          </p>
        ),
      },
      {
        header: "",
        id: "delete",
        disableSortBy: true,
        cell: ({
          row: {
            original: { sid },
          },
        }: CellType) => (
          <p className="text-right">
            <Button
              variant="destructive"
              onClick={(e) => {
                onShowModal(sid);
              }}>
              Delete
            </Button>
          </p>
        ),
      },
    ],
    [],
  );

  if (providersLoading) {
    return <Skeleton count={10} />;
  }

  return <Table data={providers} loading={providersLoading} columns={providersColumns} />;
}
