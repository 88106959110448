import React, { useCallback, useState, useMemo } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { post, Provider, ProviderCreateRequest } from "@meterup/api";
import { TextInput, validation, ProviderLogo } from "@meterup/ui";
import { Button, Link } from "@meterup/metric";
import { useNavigate } from "react-router-dom";
import { route } from "../../../routing";

interface ProviderFormProps {
  provider?: Provider;
  initialValues?: ProviderCreateRequest;
}

const ProviderForm: React.FunctionComponent<ProviderFormProps> = ({
  provider,
  initialValues: propsInitialValues,
}) => {
  const navigate = useNavigate();
  const [logoPath, setLogoPath] = useState(provider?.path || propsInitialValues?.path || "");

  const handleSubmit = useCallback(
    async (values: any) => {
      const formattedValues = { ...values };
      if (provider) {
        await post(`providers/${provider.sid}`, formattedValues);
      } else {
        await post("providers", formattedValues);
      }

      navigate(route("providers"));
    },
    [provider],
  );

  const initialValues = useMemo(() => {
    let init: any = provider;

    if (!init) {
      init = propsInitialValues;
    }

    return init
      ? {
          name: init.name,
          path: init.path,
        }
      : {};
  }, [provider, propsInitialValues]);

  const onChange = useCallback(
    ({ values }) => {
      setLogoPath(values.path);
    },
    [propsInitialValues],
  );

  return (
    <Form<ProviderCreateRequest>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting, valid }) => (
        <form onSubmit={handleSubmit} className="mt-4">
          <FormSpy onChange={onChange} />
          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="name">
              Provider name
            </label>

            <Field<string>
              component={TextInput}
              size="x-large"
              name="name"
              id="name"
              validate={validation.required}
            />
          </div>
          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="path">
              Provider URL path (optional)
            </label>

            <Field<string> component={TextInput} size="x-large" name="path" id="path" />
            <p className="mt-4">
              {logoPath && <ProviderLogo name={initialValues.name} path={logoPath} />}
            </p>
          </div>
          <p className="mt-1 mb-8 w-1/3 text-sm text-gray-600">
            The path should be a path to a URL hosted at public.meter.com, for example{" "}
            <code>/providers/Sonic.png</code>. You can add logos to the{" "}
            <Link
              href="https://github.com/meterup/public"
              title="Meter public assets repo"
              target="_blank">
              public assets repo
            </Link>
            .
          </p>
          <div className="flex flex-col w-1/3 pt-8 mb-8 border-t-1 border-gray-100">
            <p className="w-1/3">
              <Button disabled={!valid || submitting} size="x-large" type="submit">
                {provider ? "Save" : "Create"}
              </Button>
            </p>
          </div>
        </form>
      )}
    />
  );
};

export default ProviderForm;
