import { parsePhoneNumberFromString } from "libphonenumber-js";

export const allOf = (...validators: ((value: any) => string | undefined)[]) => (value: any) =>
  validators.reduce<string | undefined>((error, validator) => error || validator(value), undefined);

export const required = (value: string) =>
  value || parseInt(value) === 0 ? undefined : "Required";

export const naturalNumber = (value: string) =>
  !isNaN(parseInt(value)) && parseInt(value) === parseFloat(value) && parseInt(value) > 0
    ? undefined
    : "Must be a positive integer";

export const validEmail = (value: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  )
    ? undefined
    : "Must be a valid email address";

export const validTelephone = (value: string) =>
  parsePhoneNumberFromString(value, "US")?.isValid()
    ? undefined
    : "Must be a valid US telephone number";

export const futureDate = (value: string) =>
  new Date(value) > new Date() ? undefined : "Must be a future date";
