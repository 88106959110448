import LabelRow from "@meterup/ui/src/components/LabelRow";
import { BaseDataViewWrapper } from "@meterup/ui/src/DataView/components/BaseDataViewWrapper";
import Header from "@meterup/ui/src/DataView/components/Header";
import React from "react";

import { styled } from "@meterup/ui";

import { Separator } from "../../styles/dashboard";

export const ShowHeaderLargeContainer = styled("div", {});

export const ShowHeaderDetailContainer = styled("div", {
  "& > div": {
    flex: 1,
  },
});

export const ShowHeaderContainer = styled(LabelRow, {
  padding: "$14 $20",

  [`& > ${ShowHeaderLargeContainer}, & > ${ShowHeaderDetailContainer}`]: {
    marginTop: 0,
  },

  [`& ${Separator}`]: {
    display: "none",
  },

  [`& ${ShowHeaderDetailContainer} + ${Separator}`]: {
    display: "unset",
  },
});

export const ShowContentContainer = styled("div", {
  [`& ${BaseDataViewWrapper} ${Header}`]: {
    marginTop: "$4",
  },
});
