import React, { useCallback, useMemo, useState } from "react";

import type { AxiosAPIError, QuoteRequest } from "@meterup/api";
import { axios, httpDelete, PortalOrigin, put } from "@meterup/api";
import { getConnectUrl } from "@meterup/api/src/config";
import { Button, Icon, VStack } from "@meterup/metric";
import {
  FormattedAddress,
  FormattedDate,
  FormattedMonthlyAmount,
  FormattedSpeed,
  useFlashMessage,
  useModal,
} from "@meterup/ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { route } from "../../routing";
import StatusChip from "../QuoteRequests/StatusChip";

export default function QuoteRequestInfo({ quoteRequest }: { quoteRequest: QuoteRequest }) {
  const navigate = useNavigate();
  const [closeLoading, setCloseLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const resetMutation = useMutation<unknown, AxiosAPIError, unknown>(() =>
    axios.put(`v1/connect/admin/quote-requests/${quoteRequest.sid}/reset`, {}),
  );
  const requoteMutation = useMutation<unknown, AxiosAPIError, unknown>(() =>
    axios.put(`v1/connect/admin/quote-requests/${quoteRequest.sid}/requote`, {}),
  );

  const flashMessage = useFlashMessage({
    type: "success",
    message: "Successfully deleted search.",
  });

  const toggleStatus = useCallback(
    (
        isManualResponsePendingChanged: boolean,
        isManualResponsePending: QuoteRequest["isManualResponsePending"],
      ) =>
      async () => {
        await put(
          `quote-requests/${quoteRequest.sid}`,
          { isManualResponsePendingChanged, isManualResponsePending },
          setCloseLoading,
        );
        window.location.reload();
      },
    [quoteRequest],
  );

  const onConfirm = useCallback(async () => {
    await httpDelete(`admin/quote-requests/${quoteRequest.sid}`, setDeleteLoading);
    flashMessage();
    navigate(route("quoteRequests"));
  }, [flashMessage, navigate, quoteRequest.sid]);

  const { onShowModal } = useModal({
    type: "warning",
    title: "Are you sure?",
    message: "Are you sure you'd like to delete this search? This action is not reversible!",
    confirmText: "Delete Search",
    onConfirm,
    showCancel: true,
  });

  const queryClient = useQueryClient();

  const onConfirmReset = useCallback(async () => {
    await resetMutation.mutateAsync();
    await queryClient.invalidateQueries(["admin", "quote-requests", quoteRequest.sid]);
    await queryClient.invalidateQueries(["quotes", quoteRequest.sid]);
    return true;
  }, [queryClient, quoteRequest.sid, resetMutation]);
  const { onShowModal: onShowModalReset } = useModal({
    type: "warning",
    title: "Are you sure?",
    message: "Are you sure you'd like to reset this search? This action is not reversible!",
    confirmText: "Reset Search",
    onConfirm: onConfirmReset,
    showCancel: true,
  });
  const onConfirmReQuote = useCallback(async () => {
    await requoteMutation.mutateAsync();
    await queryClient.invalidateQueries(["admin", "quote-requests", quoteRequest.sid]);
    await queryClient.invalidateQueries(["quotes", quoteRequest.sid]);
    return true;
  }, [queryClient, quoteRequest.sid, requoteMutation]);
  const { onShowModal: onShowModalRequote } = useModal({
    type: "warning",
    title: "Are you sure?",
    message:
      "Are you sure you'd like to requote this search? This will delete all existing quotes. This action is not reversible!",
    confirmText: "Re-quote Search",
    onConfirm: onConfirmReQuote,
    showCancel: true,
  });

  const liveUrl = useMemo(
    () =>
      getConnectUrl(
        window.location,
        PortalOrigin.Connect,
        `/quoteRequests/${quoteRequest.sid}/results`,
      ),
    [quoteRequest.sid],
  );

  return (
    <div className="flex w-full p-4">
      <div className="flex justify-between w-full">
        <div className="flex">
          <div className="w-full">
            <h2 className="text-2xl font-semibold">{quoteRequest.companyName}</h2>
            <h3 className="text-xl">{quoteRequest.contactName}</h3>
            <h3 className="mt-1 mb-2 font-mono break-words text-md">{quoteRequest.contactEmail}</h3>
            <h3 className="mt-1 mb-2 font-mono break-words text-md">
              +1{quoteRequest.contactTelephone}
            </h3>
            <StatusChip
              status={quoteRequest.status}
              isManualResponsePending={quoteRequest.isManualResponsePending}
            />
          </div>
        </div>

        {quoteRequest.location?.address && (
          <div className="flex mt-2 mr-2">
            <p className="w-10">
              <Icon icon="home" className="text-lg" />
            </p>
            <FormattedAddress address={quoteRequest.location?.address} showAddress2 />
          </div>
        )}

        <div className="flex mt-2 mr-2">
          <p className="w-10">
            <Icon icon="document" className="text-lg" />
          </p>
          <p>
            {quoteRequest.contractMinimumMonths}-month minimum
            <br /> Download: <FormattedSpeed mbps={quoteRequest.requestedDownloadKbps / 1024} />
            <br /> Upload: <FormattedSpeed mbps={quoteRequest.requestedUploadKbps / 1024} />
            <br /> Install by <FormattedDate value={quoteRequest.contractMaximumInstallDate} />
          </p>
        </div>

        {quoteRequest.existingProvider && (
          <div className="flex mt-2 mr-2">
            <p className="w-10">
              <Icon icon="company" className="text-lg" />
            </p>
            <ul>
              <li>
                <strong>Current provider: </strong>
                {quoteRequest.existingProvider.name}
              </li>
              {quoteRequest.existingMonthlyFeeCents && (
                <li>
                  <strong>Monthly fee: </strong>
                  <span className="inline-block">
                    <FormattedMonthlyAmount amount={quoteRequest.existingMonthlyFeeCents / 100} />
                  </span>
                </li>
              )}
              {quoteRequest.existingContractEndDate && (
                <li>
                  <strong>Contract expire on: </strong>
                  <FormattedDate value={quoteRequest.existingContractEndDate} />
                </li>
              )}
            </ul>
          </div>
        )}

        {quoteRequest.notes && (
          <div className="flex mt-2">
            <p className="w-10">
              <Icon icon="pencil" className="text-lg" />
            </p>
            <p className="whitespace-pre-line">{quoteRequest.notes.trim()}</p>
          </div>
        )}

        <VStack spacing={8}>
          <Button
            variant="tertiary"
            width="full"
            size="large"
            disabled={deleteLoading}
            onClick={() => window.open(liveUrl, "_blank")}>
            View Customer Search
          </Button>

          {quoteRequest.status === "ready_for_review" && quoteRequest.isManualResponsePending && (
            <Button
              width="full"
              size="large"
              loading={closeLoading}
              disabled={closeLoading}
              onClick={toggleStatus(true, false)}>
              Send Quotes for Review
            </Button>
          )}
          <Button
            variant="tertiary"
            width="full"
            size="large"
            disabled={deleteLoading}
            loading={deleteLoading}
            onClick={onShowModal}>
            Delete Request
          </Button>
          {quoteRequest.status === "contract_requested" ? (
            <Button
              variant="tertiary"
              width="full"
              size="large"
              disabled={resetMutation.isLoading}
              loading={resetMutation.isLoading}
              onClick={onShowModalReset}>
              Reset
            </Button>
          ) : null}
          <Button
            variant="tertiary"
            width="full"
            size="large"
            disabled={requoteMutation.isLoading}
            loading={requoteMutation.isLoading}
            onClick={onShowModalRequote}>
            Re-quote
          </Button>
        </VStack>
      </div>
    </div>
  );
}
