import { useCallback } from "react";

import { Optional } from "@meterup/api";
import camelCaseKeys from "camelcase-keys";

import usePlacesServices from "../components/hooks/usePlacesServices";
import {
  AutocompletePrediction,
  GeocoderAddressComponent,
  GeolocationMap,
  PredictionFormatterResult,
} from "../types/gmaps";

export default function usePredictionFormatter() {
  const { getServicesPromise } = usePlacesServices();
  return useCallback(
    async (prediction: AutocompletePrediction): Optional<PredictionFormatterResult> => {
      const services = await getServicesPromise();
      if (!services) {
        return undefined;
      }

      const { geocoderService } = services;
      const geocoded = await geocoderService.geocode({ address: prediction.description });
      const geocodedEntries = Array.from(geocoded.results.entries()).map((entry) => entry[1]);
      if (geocodedEntries.length < 1) {
        return undefined;
      }

      const [entry] = geocodedEntries;

      const mappedComponents = entry.address_components.reduce(
        (memo, component: GeocoderAddressComponent) => ({
          ...memo,
          [component.types[0]]: component,
        }),
        {} as GeolocationMap,
      );

      return {
        components: camelCaseKeys(mappedComponents as GeolocationMap, { deep: true }),
        entry,
      };
    },
    [getServicesPromise],
  );
}
