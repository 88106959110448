import React, { useMemo } from "react";

import { css } from "../styled";

export type NavItemPropsType = {
  children: React.ReactNode;
  className?: string;
};

export const styledNavItem = css({
  variants: {
    selected: {
      true: {
        "---iconColor": "$brand-700 !important",
        backgroundColor: "$brand-50 !important",
        color: "$brand-800 !important",

        "& > span": {
          color: "$brand-800 !important",
        },
      },
      false: {},
    },
  },
});

export function NavItem({ children, className, ...other }: NavItemPropsType) {
  const origClasses = useMemo(() => {
    if (!className) {
      return [];
    }
    return className.split(/\s+/);
  }, [className]);
  const isSelected = useMemo(
    () => origClasses.filter((c) => c.indexOf("isSelected") !== -1).length > 0,
    [origClasses],
  );
  const classes = useMemo(
    () =>
      // const withoutSelected = origClasses.filter((c) => c.indexOf("isSelected") === -1);
      // return withoutSelected.join(" ");
      origClasses.join(" "),
    [origClasses],
  );

  return (
    <span className={`${classes} ${styledNavItem({ selected: isSelected })}`} {...other}>
      {children}
    </span>
  );
}
