import * as React from "react";

import * as M from "@meterup/metric";
import * as CSSUtil from "@stitches/react/types/css-util";
import { RemoveIndex } from "@stitches/react/types/stitches";
import {
  IntrinsicElementsKeys,
  StyledComponent,
  TransformProps,
} from "@stitches/react/types/styled-component";
import * as Util from "@stitches/react/types/util";

const theme = M.theme;
// const { css: themeCSS, keyframes, styled: themeStyled } = theme;

// const d = themeStyled("div", {});
//
// type GetStyledDetails<T> = T extends StyledComponent<any, infer Props, infer Media, infer CSS>
//   ? {
//       CSS: CSS;
//       Media: Media;
//       Props: Props;
//     }
//   : never;
//
// type GetCSSDetails<T> = T extends CSSUtil.CSS<any, infer Theme, infer ThemeMap, infer Utils>
//   ? {
//       Theme: Theme;
//       ThemeMap: ThemeMap;
//       Utils: Utils;
//     }
//   : never;
//
// // type GetCSSTypes<T> = T extends CssComponent<>
//
// export type StyledConfig = GetStyledDetails<typeof d>;
// export type CSSConfig = StyledConfig["CSS"];
// export type Media = StyledConfig["Media"];
// export type ExtraCSSConfig = GetCSSDetails<CSSConfig>;
// export type Theme = ExtraCSSConfig["Theme"];
// export type ThemeMap = ExtraCSSConfig["ThemeMap"];
// export type Utils = ExtraCSSConfig["Utils"];
//
// export type Styled<
//   Type extends IntrinsicElementsKeys | React.ComponentType<any> = "span",
//   Props extends React.ForwardRefExoticComponent<
//     Util.Assign<
//       Type extends IntrinsicElementsKeys | React.ComponentType<any>
//         ? React.ComponentPropsWithRef<Type>
//         : never,
//       TransformProps<Props, Media> & { css?: CSSConfig }
//     >
//   > = {},
// > = StyledComponent<Type, Props, Media, CSSConfig>;
//
// export type StyledFunction = <
//   Type extends keyof JSX.IntrinsicElements | React.ComponentType<any> | Util.Function,
//   Composers extends (
//     | string
//     | React.ComponentType<any>
//     | Util.Function
//     | { [name: string]: unknown }
//   )[],
//   CSS = CSSUtil.CSS<Media, Theme, ThemeMap, Utils>,
// >(
//   type: Type,
//   ...composers: {
//     [K in keyof Composers]: string extends Composers[K] // Strings, React Components, and Functions can be skipped over
//       ? Composers[K]
//       : Composers[K] extends string | React.ComponentType<any> | Util.Function
//       ? Composers[K]
//       : RemoveIndex<CSS> & {
//           /** The **variants** property lets you set a subclass of styles based on a key-value pair.
//            *
//            * [Read Documentation](https://stitches.dev/docs/variants)
//            */
//           variants?: {
//             [Name in string]: {
//               [Pair in number | string]: CSS;
//             };
//           };
//           /** The **compoundVariants** property lets you set a subclass of styles based on a combination of active variants.
//            *
//            * [Read Documentation](https://stitches.dev/docs/variants#compound-variants)
//            */
//           compoundVariants?: (("variants" extends keyof Composers[K]
//             ? {
//                 [Name in keyof Composers[K]["variants"]]?:
//                   | Util.Widen<keyof Composers[K]["variants"][Name]>
//                   | Util.String;
//               }
//             : Util.WideObject) & {
//             css: CSS;
//           })[];
//           /** The **defaultVariants** property allows you to predefine the active key-value pairs of variants.
//            *
//            * [Read Documentation](https://stitches.dev/docs/variants#default-variants)
//            */
//           defaultVariants?: "variants" extends keyof Composers[K]
//             ? {
//                 [Name in keyof Composers[K]["variants"]]?:
//                   | Util.Widen<keyof Composers[K]["variants"][Name]>
//                   | Util.String;
//               }
//             : Util.WideObject;
//         } & CSS & {
//             [K2 in keyof Composers[K]]: K2 extends
//               | "compoundVariants"
//               | "defaultVariants"
//               | "variants"
//               ? unknown
//               : K2 extends keyof CSS
//               ? CSS[K2]
//               : unknown;
//           };
//   }
// ) => StyledComponent.StyledComponent<
//   Type,
//   StyledComponent.StyledComponentProps<Composers>,
//   Media,
//   CSSUtil.CSS<Media, Theme, ThemeMap, Utils>
// >;
//
// const styled: StyledFunction = themeStyled as StyledFunction;

const styled = theme.styled;
const css = theme.css;
const keyframes = theme.keyframes;

export { css, keyframes, styled };
