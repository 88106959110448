import { LocationsCreateRequest } from "@meterup/api";
import * as Sentry from "@sentry/react";

import type { GeolocationMap, PredictionFormatterResult } from "../types/gmaps";

function mapToAddressObject(geolocationMap: GeolocationMap) {
  const streetNumber = geolocationMap.streetNumber?.longName || "";
  const route = geolocationMap.route?.longName || "";
  const premise = geolocationMap.premise?.longName || "";
  const subpremise = geolocationMap.subpremise?.longName || "";
  const city = geolocationMap.locality?.longName || geolocationMap.political?.longName || "";
  const state = geolocationMap.administrativeAreaLevel1?.shortName || "";
  const postalCode = geolocationMap.postalCode?.longName || "";
  const establishment = geolocationMap.establishment?.longName || "";

  let address1 = "";
  let address2 = "";

  if (streetNumber || route) {
    address1 = `${streetNumber} ${route}`.trim();
  } else if (premise) {
    address1 = premise;
  } else if (establishment) {
    address1 = establishment;
  } else {
    Sentry.captureException(new Error("No address1 found"), {
      extra: {
        ...geolocationMap,
      },
    });
  }

  if (subpremise) {
    address2 = subpremise;
  }

  return {
    address1,
    address2,
    city,
    state,
    postalCode,
  };
}

export default function formatLocationParts(
  result: PredictionFormatterResult,
): LocationsCreateRequest {
  const { components, entry } = result;

  return {
    ...mapToAddressObject(components),
    latitude: entry.geometry.location.lat(),
    longitude: entry.geometry.location.lng(),
  };
}
