import React from "react";

interface FormattedSpeedProps {
  mbps: number;
}

const FormattedSpeed: React.FunctionComponent<FormattedSpeedProps> = ({ mbps }) => (
  <>
    {mbps > 1024 ? Math.round(Math.round((mbps / 1024) * 100) / 100) : Math.round(mbps)}{" "}
    {mbps > 1024 ? "Gbps" : "Mbps"}
  </>
);

export default FormattedSpeed;
