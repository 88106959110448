import React, { useCallback } from "react";

import { Button, PrimaryField, SecondaryField, Text, VStack } from "@meterup/metric";
import { Control, Controller } from "react-hook-form";

type UploadDocumentProps<TFieldValues, TContext, TName> = {
  control: Control<TFieldValues, TContext>;
  fieldType: typeof PrimaryField | typeof SecondaryField;
  isSubmitting: boolean;
  name: TName;
  setValue: (name: TName, f: File | null) => void;
};

export default function UploadDocument<TFieldValues, TContext, TName>({
  control,
  fieldType: FieldType,
  isSubmitting,
  name,
  setValue,
}: UploadDocumentProps<TFieldValues, TContext, TName>) {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        const [f] = event.target.files;
        setValue(name, f);
      }
    },
    [name, setValue],
  );
  return (
    <Controller<TFieldValues, TName>
      render={({ field, fieldState }) => (
        <FieldType
          element={
            <div>
              <VStack spacing={4} align="end">
                {field.value ? (
                  <VStack spacing={4} align="end">
                    <Text size={12} lineHeight={16} color={{ light: "gray-600" }}>
                      <Text weight="medium">Selected:</Text> {field.value.name}
                    </Text>
                    <Button
                      disabled={isSubmitting}
                      variant="tertiary"
                      icon="cross"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isSubmitting) {
                          setValue(name, null);
                        }
                      }}
                      arrangement="leading-icon">
                      Clear
                    </Button>
                  </VStack>
                ) : null}
                <Button
                  disabled={isSubmitting}
                  variant="tertiary"
                  icon="upload"
                  type="button"
                  arrangement="leading-icon"
                  as="label"
                  onClick={(e) => {
                    if (isSubmitting) {
                      e.preventDefault();
                    }
                  }}
                  loading={isSubmitting}
                  css={{
                    ...(field.value ? { display: "none" } : {}),
                  }}>
                  <input type="file" onChange={onChange} hidden accept=".pdf" />
                  Upload contract
                </Button>
              </VStack>
            </div>
          }
          label="Contract"
          errorMessage={fieldState.error?.message}
        />
      )}
      name={name}
      control={control}
    />
  );
}
