import { useCallback, useEffect, useMemo, useRef } from "react";

import { Location, NavigateOptions, useLocation, useNavigate } from "react-router-dom";

import { useDrawer } from "../components/Drawer/drawerContext";
import { handleOnClickFactory, isSpecialClick, MouseEventLike } from "./useRouter";

type HandleOnClickClose = (e: MouseEventLike) => Promise<void>;

export default function useDrawerRouter(opts?: NavigateOptions) {
  const navigate = useNavigate();
  const location = useLocation();
  const drawer = useDrawer();
  const locationChangeCb = useRef<(loc: Location) => void>();

  const handleOnClickBase = useMemo(() => handleOnClickFactory(navigate)(opts), [navigate, opts]);
  const handleOnClickCallback = useCallback((e: MouseEventLike) => handleOnClickBase(e), [
    handleOnClickBase,
  ]);
  const handleOnClickClose: HandleOnClickClose = useMemo(
    () => (e: MouseEventLike) => {
      const { altKey, button, ctrlKey, currentTarget, preventDefault, metaKey, shiftKey } = e;
      if (!isSpecialClick(e)) {
        e.preventDefault();
        const closeDrawerPromise = drawer.closeDrawer();
        return closeDrawerPromise.finally(() => {
          handleOnClickCallback({
            altKey,
            button,
            ctrlKey,
            currentTarget,
            preventDefault,
            metaKey,
            shiftKey,
          });
        });
      }
      return Promise.resolve();
    },
    [drawer, handleOnClickCallback],
  );
  const handleOnClickOpen: HandleOnClickClose = useMemo(
    () => (e: MouseEventLike) => {
      const { altKey, button, ctrlKey, currentTarget, preventDefault, metaKey, shiftKey } = e;
      if (!isSpecialClick(e)) {
        e.preventDefault();
        const href = currentTarget.getAttribute("href") as string;
        locationChangeCb.current = (loc: Location) => {
          if (loc.pathname === href) {
            drawer.openDrawer();
          }
        };
        handleOnClickCallback({
          altKey,
          button,
          ctrlKey,
          currentTarget,
          preventDefault,
          metaKey,
          shiftKey,
        });
      }
      return Promise.resolve();
    },
    [drawer, handleOnClickCallback],
  );
  useEffect(() => {
    if (locationChangeCb.current) {
      locationChangeCb.current(location);
      locationChangeCb.current = undefined;
    }
  }, [location]);

  return {
    handleOnClickClose,
    handleOnClickOpen,
  };
}
