import React from "react";
import cx from "classnames";
import { Direction } from "../old-modal-types";

const ModalBackground = ({
  visible,
  direction,
  onClose,
}: {
  visible: boolean;
  direction: Direction;
  onClose: () => void;
}) => (
  <div
    className={cx("fixed inset-0 transition-opacity", {
      "opacity-0": !visible,
      "opacity-100": visible,
      "ease-out duration-300": direction === "entering",
      "ease-in duration-200": direction === "leaving",
    })}
    onClick={onClose}
    aria-hidden="true">
    <div className="absolute inset-0 bg-gray-50 opacity-75"></div>
  </div>
);

export default ModalBackground;
