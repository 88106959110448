// type ErrorType = api.Error | ISPContractCreateRequestError;
import React from "react";

import { ConnectAdminErrors } from "@meterup/api/src/connectadminProtos";
import { APIErrorType } from "@meterup/api/src/errors";
import { Text } from "@meterup/metric";
import { api } from "@meterup/proto";
import { DateRange, dtToString, getOverlappingRange, numDaysInRange } from "@meterup/ui/src/utils/dates";
import has from "lodash/has";

function isAPIErrorType<T, K>(err: APIErrorType<T, K>, k: K): err is APIErrorType<T, K> {
  const { type } = err;

  return has(ConnectAdminErrors, type) && has(err, k);
}

function isAPIError<T, K>(err: APIErrorType<T, K>, k: K): err is api.Error {
  return !isAPIErrorType(err, k);
}

type DateStartEnd = {
  startDate: Date;
  endDate: Date;
};
type OverlappingDatesErrorCopyProps<T, K extends keyof T, E extends APIErrorType<T, K>> = {
  dates: DateStartEnd;
  err: E;
  getConflictingDates: (err: E) => DateRange;
  name: K;
  label: string;
};

export default function OverlappingDatesErrorCopy<T, K, E extends APIErrorType<T, K>>({
  dates,
  err,
  getConflictingDates,
  name,
  label,
}: OverlappingDatesErrorCopyProps<T, K, E>) {
  if (isAPIError(err, name)) {
    return err.detail || null;
  }
  if (isAPIErrorType(err, name)) {
    const range1 = getConflictingDates(err);
    const range2 = [new Date(dates.startDate), new Date(dates.endDate)];
    const overlappingRange = getOverlappingRange(range1, range2);

    if (!overlappingRange) {
      return null;
    }
    const dateStrings = overlappingRange.map((d) => dtToString(d));
    const numDays = numDaysInRange(overlappingRange);
    const { sid } = err[name];
    if (numDays === 1) {
      return (
        <div>
          Existing {label} with SID={sid} overlaps on <Text weight="medium">{dateStrings[0]}</Text>
        </div>
      );
    }
    return (
      <div>
        Existing {label} with SID={sid} overlaps from <Text weight="medium">{dateStrings[0]}</Text>{" "}
        to <Text weight="medium">{dateStrings[1]}</Text> for a total of {numDays} days.
      </div>
    );
  }

  return null;
}
