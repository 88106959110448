import React, { useCallback, useRef } from "react";

import { TextInput, TextInputProps } from "@meterup/metric";

type TextInputWrapperProps = Omit<TextInputProps, "onChange"> & {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function TextInputWrapper(props: TextInputWrapperProps) {
  const { onChange, ...rest } = props;
  const ref = useRef<HTMLInputElement>();
  const onChangeInner = useCallback(
    (value) => {
      onChange({
        target: {
          value,
          focus: () => {
            const { current } = ref;
            if (current) {
              current.focus();
            }
          },
        },
      });
    },
    [onChange],
  );

  return <TextInput onChange={onChangeInner} {...rest} ref={ref} prefix="$" />;
}
