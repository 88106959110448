import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { Icon, TextInput } from "@meterup/metric";
import { useEvent } from "@meterup/react-aria";
import { CSSTransition } from "react-transition-group";

import { styled } from "../styled";
import { SearchForm } from "../styles/SearchForm";
import ClearInputButton from "./ClearInputButton";

function preventDefault(e: React.FormEvent) {
  e.preventDefault();
}

const ClearIconWrapper = styled("span", {
  cursor: "pointer",
});

type SearchButtonInputProps = {
  setValue: (e: string) => void;
  value: string;
};

export default function SearchButtonInput({ setValue, value }: SearchButtonInputProps) {
  const [isFocused, setIsFocused] = React.useState(false);
  const isExpanded = useMemo(() => isFocused || value.length > 0, [isFocused, value.length]);
  const windowRef = useRef<Window>(window as Window);
  const timeoutRef = useRef<number>();
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchFormRef = useRef<HTMLLabelElement>(null);
  const handleBlurOrFocus = useCallback(() => {
    if (timeoutRef.current) {
      windowRef.current.clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);
  useEvent(windowRef, "blur", handleBlurOrFocus);
  const handleChange = useCallback(
    (val: string) => {
      setValue(val);
    },
    [setValue],
  );
  const onBlur = useCallback(() => {
    if (value.length === 0) {
      if (!timeoutRef.current) {
        timeoutRef.current = windowRef.current.setTimeout(() => {
          setIsFocused(false);
          timeoutRef.current = undefined;
        }, 50);
      }
    }
  }, [value.length]);
  const onEntered = useCallback(() => {
    if (isFocused && searchFormRef.current) {
      searchFormRef.current.focus();
    }
  }, [isFocused]);
  useEffect(() => {
    if (formRef.current) {
      inputRef.current = formRef.current.querySelector("input");
    }
  }, []);
  const onClickClearInput = useCallback(() => {
    setValue("");
    if (searchFormRef.current) {
      searchFormRef.current.blur();
    }
    setIsFocused(false);
  }, [setValue]);
  const onClickForm = useCallback(() => {
    if (!isFocused) {
      setIsFocused(true);
    }
  }, [isFocused]);
  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      const { code } = e;
      const { current: inputEl } = inputRef;
      if (code === "Escape" && isFocused && inputEl) {
        setValue("");
        setTimeout(() => {
          inputEl.blur();
        }, 0);
      }
    },
    [isFocused, setValue],
  );

  return (
    <CSSTransition timeout={300} in={isExpanded} onEntered={onEntered} nodeRef={searchFormRef}>
      {(state) => (
        <SearchForm
          onSubmit={preventDefault}
          onBlur={onBlur}
          state={state}
          onClick={onClickForm}
          onKeyUp={onKeyUp}
          ref={formRef}>
          <TextInput
            icon="searchScoped"
            placeholder={isExpanded ? "" : "..."}
            value={value}
            ref={searchFormRef}
            onChange={handleChange}
            aria-label="Filter locations"
            suffix={<ClearInputButton onClick={onClickClearInput} visible={!isExpanded} />}
          />
        </SearchForm>
      )}
    </CSSTransition>
  );
}

SearchButtonInput.whyDidYouRender = true;
