import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Text } from "@meterup/metric";
import { route, RouteNames } from "./routing";

type RedirectParams = {
  routeName: RouteNames;
  params?: { [k: string]: any };
};

export function Redirect({ routeName, params }: RedirectParams) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(route(routeName, params));
  }, [params, navigate, routeName]);

  return <Text>Redirecting...</Text>;
}
