import React from "react";

import { components, SingleValueProps } from "react-select";

export default function SingleValue<T>(props: SingleValueProps<T, false>) {
  const values = props.getValue();
  let label: string | undefined;
  if (values.length > 0) {
    const [value] = values;
    if (Object.hasOwn(value, "label")) {
      label = value.label;
    }
  }
  const title = label
    ? {
        title: label,
        "aria-label": label,
        "data-tooltip": label,
      }
    : {};

  return (
    <components.SingleValue {...props} innerProps={{ ...props.innerProps, ...title }}>
      {props.children}
    </components.SingleValue>
  );
}
