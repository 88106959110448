import React from "react";
import { FieldRenderProps } from "react-final-form";
import { Textarea, TextareaProps } from "@meterup/metric";

// Mixed interface between React Final Form and our pure TextareaForm
interface TextareaFieldProps extends FieldRenderProps<string>, TextareaProps {}

// TODO: Can we move this to the fornt-end component library?
const TextareaField: React.FunctionComponent<TextareaFieldProps> = ({
  // React Final Form
  input,
  meta,
  placeholder,

  // Frontend components
  size,
  disabled,
}) => {
  // TODO: The error mark up should not be here
  const displayError = meta.error && meta.touched;
  return (
    <div>
      <Textarea
        name={input.name}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        value={input.value}
        disabled={disabled}
        placeholder={placeholder}
        size={size}
        hasError={displayError}
      />
      {displayError && <span className="mt-2 ml-2 text-xs text-red-600">{meta.error}</span>}
    </div>
  );
};

// RRF = React Final Form
export default TextareaField;
