import React, { useEffect, useMemo, useRef } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Invoice } from "@meterup/api";
import { UseCreateInvoiceErrorType } from "@meterup/api/src/billing/useCreateInvoice";
import { InvoiceStatus } from "@meterup/api/src/connectProtos";
import {
  Alert,
  Button,
  CloseDrawerButton,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  HStack,
  PrimaryField,
  PrimaryFieldComposite,
  SecondaryField,
  TextInput,
  VStack,
} from "@meterup/metric";
import { CloseDrawerLink } from "@meterup/ui";
import { TextInputWrapper } from "@meterup/ui/src/components/forms/TextInputWrapper";
import { ClearIndicator } from "@meterup/ui/src/components/Select/ClearIndicator";
import { DropdownIndicator } from "@meterup/ui/src/components/Select/DropdownIndicator";
import { getLabel, getValue, StringsOption } from "@meterup/ui/src/components/Select/hooks/Options";
import { useCustomStyles } from "@meterup/ui/src/components/Select/hooks/useCustomStyles";
import { addDays, dtToString } from "@meterup/ui/src/utils/dates";
import { snakeToTitleCase } from "@meterup/ui/src/utils/snakeToTitleCase";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import Select from "react-select";

import { Separator } from "../../styles/dashboard";
import { FormPurpose, StringsSelectType } from "../../types";
import OverlappingDatesErrorCopy from "../errors/OverlappingDatesErrorCopy";
import UploadDocument from "../files/UploadDocument";
import { InvoiceSchema, InvoiceSchemaFormData } from "./invoiceSchema";

const statusOptions: StringsOption[] = Object.entries(InvoiceStatus).map(([key, value]) => ({
  label: snakeToTitleCase(key),
  value,
}));

type InvoiceFormProps = {
  defaultValues?: InvoiceSchemaFormData;
  error?: UseCreateInvoiceErrorType;
  ispContractSID: string;
  onSubmit: SubmitHandler<InvoiceSchemaFormData>;
  purpose?: FormPurpose;
  successData?: Invoice;
  values?: InvoiceSchemaFormData;
};

export default function InvoiceFormDrawer({
  defaultValues: suppliedDefaultValues,
  error,
  ispContractSID,
  onSubmit,
  purpose = "create",
  successData,
  values,
}: InvoiceFormProps) {
  const defaultValues = useMemo<InvoiceSchemaFormData>(() => {
    if (suppliedDefaultValues) {
      return suppliedDefaultValues;
    }
    const d = new Date();
    return {
      billingCycleDates: {
        startDate: dtToString(d),
        endDate: dtToString(addDays(d, 1)),
      },
      invoice: {
        dueDate: dtToString(d),
        amountActual: "10",
        invoicedAt: "",
        paidAt: "",
        ispContractSID,
      },
      invoiceDocument: null,
      status: {
        value: InvoiceStatus.unpaid,
        label: snakeToTitleCase(InvoiceStatus.unpaid),
      },
    };
  }, [ispContractSID, suppliedDefaultValues]);
  const { control, handleSubmit, reset, watch, getValues, setValue, register, formState } =
    useForm<InvoiceSchemaFormData>({
      defaultValues,
      values: values || defaultValues,
      resolver: zodResolver(InvoiceSchema),
    });
  const { isSubmitting, errors } = formState;
  useEffect(() => {
    const subscription = watch((values, update) => {
      if (update.name === "billingCycleDates.startDate") {
        const { billingCycleDates, invoice } = values;
        const { startDate: prevStartDate, endDate: prevEndDate } = billingCycleDates;
        const { dueDate: prevDueDate } = invoice;
        const startDate = new Date(prevStartDate);
        const endDate = new Date(prevEndDate);
        const dueDate = new Date(prevDueDate);

        if (startDate >= endDate) {
          const newEndDate = addDays(startDate, 2);
          setValue("billingCycleDates.endDate", dtToString(newEndDate));
        }

        if (dueDate < startDate) {
          setValue("invoice.dueDate", prevStartDate);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);
  const customStylesProvider = useCustomStyles<StringsOption, false>("medium", true, false);
  const statusSelectRef = useRef<StringsSelectType | null>(null);
  const innerOnSubmit = useMemo<SubmitHandler<InvoiceSchemaFormData>>(
    () => async (data) => {
      const retVal = await onSubmit(data);
      if (retVal) {
        reset(defaultValues);
      }
    },
    [defaultValues, onSubmit, reset],
  );

  return (
    <form onSubmit={handleSubmit(innerOnSubmit)}>
      <DrawerHeader>
        <DrawerTitle>{purpose === "create" ? "Create" : "Update"} Invoice</DrawerTitle>
        <DrawerControls>
          <CloseDrawerLink>
            <CloseDrawerButton />
          </CloseDrawerLink>
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        {error ? (
          <Alert
            variant="negative"
            icon="warning"
            heading={error.title || "Something went wrong"}
            copy={
              <OverlappingDatesErrorCopy
                dates={getValues("billingCycleDates")}
                err={error}
                getConflictingDates={(err) => [
                  err.conflicting_invoice.billing_cycle_start_date,
                  err.conflicting_invoice.billing_cycle_end_date,
                ]}
                name="billingCycleDates"
                label="invoice"
              />
            }
          />
        ) : null}
        {successData ? (
          <Alert
            variant="positive"
            icon="checkmarkCircle"
            heading="Successfully created invoice"
            copy={`Successfully ${purpose === "create" ? "created" : "updated"} invoice with SID ${
              successData?.sid
            }`}
          />
        ) : null}
        <FieldContainer>
          <input {...register("invoice.ispContractSID")} type="hidden" />
          <PrimaryFieldComposite
            label="Dates"
            hasError={!!errors.billingCycleDates}
            errorMessage={errors.billingCycleDates?.message}
            fields={
              <div>
                <VStack>
                  <Controller
                    render={({ field, fieldState }) => (
                      <PrimaryField
                        element={
                          <TextInput
                            type="date"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            hasError={!!fieldState.error}
                          />
                        }
                        label="Invoice Start Date"
                        errorMessage={fieldState.error?.message}
                      />
                    )}
                    name="billingCycleDates.startDate"
                    control={control}
                  />
                  <Controller
                    render={({ field, fieldState }) => (
                      <PrimaryField
                        element={
                          <TextInput
                            type="date"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            hasError={!!fieldState.error?.message}
                          />
                        }
                        label="Invoice End Date"
                        errorMessage={fieldState.error?.message}
                      />
                    )}
                    name="billingCycleDates.endDate"
                    control={control}
                  />
                  <Controller
                    render={({ field, fieldState }) => (
                      <PrimaryField
                        element={
                          <TextInput
                            type="date"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            hasError={!!fieldState.error?.message}
                          />
                        }
                        label="Invoice Due Date"
                        errorMessage={fieldState.error?.message}
                      />
                    )}
                    name="invoice.dueDate"
                    control={control}
                  />
                  <Controller
                    render={({ field, fieldState }) => (
                      <SecondaryField
                        element={
                          <TextInput
                            type="date"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            hasError={!!fieldState.error?.message}
                          />
                        }
                        label="Invoiced On Date"
                        errorMessage={fieldState.error?.message}
                      />
                    )}
                    name="invoice.invoicedAt"
                    control={control}
                  />
                  <Controller
                    render={({ field, fieldState }) => (
                      <SecondaryField
                        element={
                          <TextInput
                            type="date"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            hasError={!!fieldState.error?.message}
                          />
                        }
                        label="Paid On Date"
                        errorMessage={fieldState.error?.message}
                      />
                    )}
                    name="invoice.paidAt"
                    control={control}
                  />
                </VStack>
              </div>
            }
          />

          <Controller
            render={({ field, fieldState }) => (
              <PrimaryField
                element={
                  <Select
                    options={statusOptions}
                    value={field.value}
                    isLoading={false}
                    onChange={field.onChange}
                    hasError={!!fieldState.error?.message}
                    styles={customStylesProvider}
                    ref={statusSelectRef}
                    getOptionLabel={getLabel}
                    getOptionValue={getValue}
                    menuPortalTarget={document.body}
                    menuPlacement="bottom"
                    menuPosition="absolute"
                    noOptionsMessage={() => null}
                    placeholder="Select status"
                    isClearable
                    components={{ DropdownIndicator, ClearIndicator }}
                  />
                }
                label="Status"
                errorMessage={fieldState.error?.message}
              />
            )}
            name="status"
            control={control}
          />

          <Controller
            render={({ field, fieldState }) => (
              <PrimaryField
                element={
                  <div>
                    <NumericFormat
                      hasError={!!fieldState.error?.message}
                      value={field.value}
                      decimalScale={2}
                      name={field.name}
                      onChange={field.onChange}
                      allowLeadingZeros={false}
                      onBlur={field.onBlur}
                      // fixedDecimalScale
                      thousandSeparator
                      customInput={TextInputWrapper}
                    />
                  </div>
                }
                label="Amount due"
                errorMessage={fieldState.error?.message}
              />
            )}
            name="invoice.amountActual"
            control={control}
          />

          <UploadDocument
            control={control}
            fieldType={SecondaryField}
            isSubmitting={isSubmitting}
            name="invoiceDocument"
            setValue={setValue}
          />
        </FieldContainer>
        <Separator />
        <HStack align="end" display="flex" spacing={8} justify="end">
          <CloseDrawerLink onClose={reset}>
            <Button variant="secondary" type="button" loading={isSubmitting}>
              Cancel
            </Button>
          </CloseDrawerLink>
          <Button variant="primary" type="submit" loading={isSubmitting}>
            Save
          </Button>
        </HStack>
      </DrawerContent>
    </form>
  );
}
