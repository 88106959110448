import { useCallback } from "react";
import useLocalStorage from "../../../frontend/src/hooks/useLocalStorage";
import { FlashMessage } from "../FlashMessages";

/**
 * Return a function which queues up a flash message.
 *
 * @todo support more than one flash message
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useFlashMessage(flashMessage: FlashMessage) {
  const [, setMessages] = useLocalStorage<FlashMessage[]>("flashMessages", []);

  return useCallback(() => {
    setMessages([flashMessage]);
  }, [flashMessage]);
}
