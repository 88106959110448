import { api } from "@meterup/proto";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { get } from "../axios";
import { ContractResponse } from "../protos";
import { ContractQueryParams, useContractQueryKey, useContractQueryParams } from "./queryKeys";

export default function useISPContract(queryParams?: ContractQueryParams) {
  const params = useContractQueryParams(queryParams);
  const contractsQueryKey = useContractQueryKey(params);
  const { companySID, ispContractSID } = params;

  return useQuery<unknown, AxiosError<api.Error>, ContractResponse>({
    queryKey: contractsQueryKey,
    queryFn: () =>
      get<ContractResponse>(`/v1/companies/${companySID}/connect/contracts/${ispContractSID}`),
  });
}
