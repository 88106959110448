import React, { useMemo } from "react";

import { UserResponse } from "@meterup/api";
import { Badge, Button, Text } from "@meterup/metric";
import { Drawer, OpenDrawerLink } from "@meterup/ui";
import UserTabBar from "@meterup/ui/src/components/Tabs/UserTabBar";
import DataView from "@meterup/ui/src/DataView/DataView";
import useActiveUserTab, {
  UserTabItems,
  UserTabItemValues,
} from "@meterup/ui/src/hooks/useActiveUserTypeTab";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useOutlet, useParams } from "react-router-dom";

import { useCompanyOutletContext } from "../../../contexts/CompanyContext";

const colHelper = createColumnHelper<UserResponse>();

const columns: ColumnDef<UserResponse, any>[] = [
  colHelper.accessor((user: UserResponse) => user.email, {
    id: "email",
    header: "Email",
    cell: (props) => props.getValue(),
  }),
  colHelper.accessor((user: UserResponse) => user.companyRole, {
    id: "role",
    header: "Role",
    cell: (props) => (
      <Badge>
        <Text transform="capitalize">{props.getValue()}</Text>
      </Badge>
    ),
  }),
  colHelper.accessor(
    (user: UserResponse) => [user.firstName, user.lastName].filter(Boolean).join(" "),
    {
      id: "name",
      header: "Name",
      cell: (props) => {
        const value = props.getValue();
        if (!value || value.length < 1) {
          return <>&nbsp;</>;
        }
        return value;
      },
    },
  ),
];

export default function Users() {
  const activeTab = useActiveUserTab();
  const { companySID } = useParams();
  const ctx = useCompanyOutletContext();
  const { users: usersResp } = ctx;
  const users = useMemo(() => usersResp?.users || [], [usersResp]);
  const { counts, visibleUsers } = useMemo(() => {
    const innerCounts = Object.values(UserTabItems).reduce(
      (acc, val) => ({ ...acc, [val]: 0 }),
      {} as Record<UserTabItemValues, number>,
    );
    const innerVisibleUsers = [] as UserResponse[];
    users.forEach((user) => {
      innerCounts.all += 1;
      const { companyRole } = user;
      if (activeTab === UserTabItems.All) {
        innerVisibleUsers.push(user);
      }
      if (companyRole === "admin") {
        innerCounts.admin += 1;
        if (activeTab === UserTabItems.Admin) {
          innerVisibleUsers.push(user);
        }
      }
      if (companyRole === "member") {
        innerCounts.member += 1;
        if (activeTab === UserTabItems.Member) {
          innerVisibleUsers.push(user);
        }
      }
    });
    return { counts: innerCounts, visibleUsers: innerVisibleUsers };
  }, [activeTab, users]);
  const children = useOutlet(ctx);

  return (
    <>
      <DataView
        buttons={
          <OpenDrawerLink path="create">
            <Button icon="plusCircle" variant="tertiary" type="button" arrangement="leading-icon">
              Add user
            </Button>
          </OpenDrawerLink>
        }
        header={
          <UserTabBar
            basePath={`/dashboard/companies/${companySID}/users`}
            tabCount={Object.values(UserTabItems).reduce(
              (acc, key) => ({ ...acc, [key]: counts[key] }),
              {} as Record<UserTabItemValues, number>,
            )}
          />
        }
        // searchValue={searchValue}
        // setSearchValue={setSearchValue}
        columns={columns}
        data={visibleUsers}
        // drawerContent={drawerContent}
      />
      <Drawer key="create-users">{children}</Drawer>
    </>
  );
}
