import { useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export function useContractsQueryKey() {
  const { companySID } = useParams();
  return ["contracts", companySID];
}

export function useInvalidateContracts() {
  const queryKey = useContractsQueryKey();
  const queryClient = useQueryClient();
  return useMemo(
    () => () => {
      queryClient.invalidateQueries(queryKey);
    },
    [queryClient, queryKey],
  );
}

export type ContractQueryParams = {
  companySID: string;
  ispContractSID: string;
};

export function useContractQueryParams(queryParams?: ContractQueryParams) {
  const params = useParams<ContractQueryParams>();
  return queryParams || params;
}

export function useContractQueryKey(queryParams?: ContractQueryParams) {
  const { companySID, ispContractSID } = useContractQueryParams(queryParams);

  return ["contracts", companySID, ispContractSID];
}

export function useInvalidateContract(queryParams?: ContractQueryParams) {
  const queryKey = useContractQueryKey(queryParams);
  const queryClient = useQueryClient();
  return useMemo(
    () => () => {
      queryClient.invalidateQueries(queryKey);
    },
    [queryClient, queryKey],
  );
}

export function useInvoicesQueryKey(queryParams?: ContractQueryParams) {
  const { companySID, ispContractSID } = useContractQueryParams(queryParams);

  return ["invoices", companySID, ispContractSID];
}

export function useInvalidateInvoices(queryParams?: ContractQueryParams) {
  const queryKey = useInvoicesQueryKey(queryParams);
  const queryClient = useQueryClient();
  return useMemo(
    () => () => {
      queryClient.invalidateQueries(queryKey);
    },
    [queryClient, queryKey],
  );
}
