import fuzzy from "fuzzy";

export function isString(val?: any) {
  return typeof val === "string" || val instanceof String;
}

export function leafMatches(val: any, pattern: string, columnId?: string): boolean {
  let value = val;

  if (typeof value === "boolean") {
    value = `${value}`;
  }
  if (typeof value === "number" || value instanceof Number) {
    value = `${value}`;
  }
  if (isString(value)) {
    return fuzzy.test(pattern, value.toString());
  }

  if (!value) {
    return false;
  }
  if (Array.isArray(val)) {
    return !!val.find((v) => leafMatches(v, pattern));
  }
  if (value instanceof Date) {
    return leafMatches(val.toString(), pattern);
  }
  return !!Object.keys(val).find((k) => leafMatches(val[k], pattern, columnId));
}
