import React from "react";

import { VStack } from "@meterup/metric";

import { ShowHeaderContainer } from "./containers";

type ShowHeaderProps = {
  children: React.ReactNode;
};

export default function ShowHeader({ children }: ShowHeaderProps) {
  return (
    <VStack spacing={12} as={ShowHeaderContainer}>
      {children}
    </VStack>
  );
}
