import React from "react";
import cx from "classnames";
import { Direction } from "../old-modal-types";

const ModalWrapper: React.FC<{
  visible: boolean;
  direction: Direction;
}> = ({ visible, direction, children }) => (
  <div
    className={cx(
      "inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6",
      {
        "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95": !visible,
        "opacity-100 translate-y-0 sm:scale-100": visible,
        "ease-out duration-300": direction === "entering",
        "ease-in duration-200": direction === "leaving",
      },
    )}
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-headline">
    {children}
  </div>
);

export default ModalWrapper;
