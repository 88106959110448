import { styled } from "@meterup/ui";

export const H3 = styled("h3", {
  flex: 1,
});

export const Separator = styled("div", {
  height: "1px",
  backgroundColor: "$gray-100",
  width: "100%",
});

export const TopSeparator = styled("div", {
  borderTop: "2px solid $gray-100",
});
