// import "@meterup/ui/src/wdyr";
import "@meterup/metric/dist/index.css";
import "@meterup/ui/src/css/tailwind.css";

import React from "react";

import ReactDOM from "react-dom";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
