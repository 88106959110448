import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { get } from "../axios";
import { APIError, CompanyResponse } from "../protos";

export function queryKey(companySID?: string) {
  return ["companies", companySID];
}

export function useCompany(companySID?: string): UseQueryResult<CompanyResponse, APIError> {
  return useQuery<unknown, APIError, CompanyResponse>(
    queryKey(companySID),
    () => get(`v1/companies/${companySID}`),
    {
      enabled: !!companySID,
    },
  );
}
