import React from "react";

import { Quote } from "@meterup/api";
import { Icon } from "@meterup/metric";
import { Link } from "react-router-dom";

import { route } from "../../routing";
import { CopyToNewQuoteRequestLocationState } from "../../types";

type CopyToNewProps = {
  disabled?: boolean;
  quote: Quote;
  quoteRequestSid: string;
  title?: string;
};

function CopyQuoteButton({ disabled, quote, quoteRequestSid, title }: CopyToNewProps) {
  const state: CopyToNewQuoteRequestLocationState = {
    copyToNewQuoteRequest: {
      ...quote,
      provider: quote.provider?.sid || "",
      connectionType: quote.connectionType?.sid || "",
    },
  };
  return (
    <Link
      style={{ opacity: disabled ? 0.5 : 1 }}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
      }}
      to={{
        pathname: route("newQuote", {
          sid: quoteRequestSid,
        }),
      }}
      state={state}
      title={disabled ? "Select an address to copy to" : title || "Copy"}>
      <Icon icon="copy" />
    </Link>
  );
}

export default CopyQuoteButton;
