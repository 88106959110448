import React, { useCallback, useMemo } from "react";

import type {
  AxiosAPIError,
  Quote,
  QuotesUpdateRequest,
  QuoteWithRequestAndISP,
} from "@meterup/api";
import { axios, QuoteStatus } from "@meterup/api";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  HStack,
  Icon,
  ToggleInput,
} from "@meterup/metric";
import { styled } from "@meterup/ui";
import { OpenDrawerLink } from "@meterup/ui/src/components/Drawer/DrawerLinks";
import * as HoverCard from "@radix-ui/react-hover-card";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const Popup = styled("div", {
  // borderRadius: "$8",
  // boxShadow: "$modalLight",
  padding: "$20",
  marginBottom: "-1px",
  zIndex: 90,
  backgroundColor: "$white",
});

const HoverCardArrow = styled(HoverCard.Arrow, {
  fill: "white",
});

const HoverCardContent = styled(HoverCard.Content, {
  boxShadow: "$modalLight",
  borderRadius: "$8",
  zIndex: 90,
  overflow: "hidden",
});

/**
 * Valid transitions
 * var validQuoteTransitions = map[db.QuoteStatus][]db.QuoteStatus{
 * 	db.QuoteStatusProposed:  {db.QuoteStatusRequested},
 * 	db.QuoteStatusRequested: {db.QuoteStatusEnabled, db.QuoteStatusDisabled},
 * 	db.QuoteStatusEnabled:   {db.QuoteStatusDisabled},
 * 	db.QuoteStatusDisabled:  {db.QuoteStatusEnabled},
 * }
 */

const TOGGLEABLE_QUOTE_STATUSES = [
  QuoteStatus.enabled,
  QuoteStatus.disabled,
  QuoteStatus.requested,
];
export type RowActionsProps = {
  quoteWithOther?: QuoteWithRequestAndISP;
};

export default function RowActions({ quoteWithOther }: RowActionsProps) {
  const { companyLocationSID, quoteRequestSid, sid, status } = quoteWithOther || {};
  const queryClient = useQueryClient();
  const changeStatusMutation = useMutation<Quote, AxiosAPIError, QuotesUpdateRequest>(
    (vars) => axios.put(`/v1/connect/admin/quote-requests/${quoteRequestSid}/quotes/${sid}`, vars),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quotes"]);
      },
    },
  );
  const onChangeSelected = useCallback(
    (value) => {
      console.log("onChangeSelected", value, "quoteWithOther", quoteWithOther);
      changeStatusMutation.mutate({ status: value ? "enabled" : "disabled" });
    },
    [changeStatusMutation, quoteWithOther],
  );
  const canToggle = status && TOGGLEABLE_QUOTE_STATUSES.includes(status);
  const toggleEl = useMemo(
    () => (
      <ToggleInput
        aria-label="Enable or disable the quote for the end user"
        selected={status === "enabled"}
        onChange={onChangeSelected}
        disabled={changeStatusMutation.isLoading || !canToggle}
      />
    ),
    [canToggle, changeStatusMutation.isLoading, onChangeSelected, status],
  );

  return (
    <HStack
      spacing={6}
      align="center"
      onClick={(e) => console.log("onClick", e, "quoteWithOther", quoteWithOther, "sid", sid)}>
      {canToggle ? (
        toggleEl
      ) : (
        <HoverCard.Root openDelay={0}>
          <HoverCard.Trigger>{toggleEl}</HoverCard.Trigger>
          {/* <HoverCard.Portal> */}
          <HoverCardContent side="top">
            <HoverCardArrow />
            <Popup>
              Can't transition from {status} to {status === "enabled" ? "disabled" : "enabled"}
            </Popup>
            {/* <Drawer> */}
            {/*  <DrawerHeader> */}
            {/*    <DrawerTitle>Rename location</DrawerTitle> */}
            {/*  </DrawerHeader> */}
            {/*  <LoadingWrapper> */}
            {/*    <Skeleton height="30px" width="205px" radius={6} /> */}
            {/*  </LoadingWrapper> */}
            {/* </Drawer> */}
          </HoverCardContent>
          {/* </HoverCard.Portal> */}
        </HoverCard.Root>
      )}

      <OpenDrawerLink
        path={`quotes/${sid}`}
        aria-label={`Edit quote ${sid} for ${companyLocationSID}.`}
        title="Edit quote">
        <Button variant="tertiary" disabled={changeStatusMutation.isLoading}>
          <Icon icon="pencil" size={12} />
        </Button>
      </OpenDrawerLink>
      <Button variant="tertiary" disabled={changeStatusMutation.isLoading}>
        <Icon icon="copy" size={12} />
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild disabled={changeStatusMutation.isLoading}>
          <Button icon="chevronDown" variant="tertiary">
            <Icon icon="location" color={{ light: "gray-500", dark: "gray-300" }} size={16} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={0} side="bottom" align="start">
          ???
        </DropdownMenuContent>
      </DropdownMenu>
    </HStack>
  );
}

RowActions.whyDidYouRender = true;
