import { DateRangeType, DateStringType, DollarsToCentsType } from "@meterup/ui/src/zod/helpers";
import { z } from "zod";

export const ISPContractSchema = z.object({
  ispContract: z.object({
    sid: z.string().optional(),
    internetServicePlanSid: z.object({
      value: z.string(),
      label: z.string(),
    }),
    // startDate: z.date(),
    // endDate: z.date(),
    amountDueCurrency: z.string().default("USD3"),
    amountDue: DollarsToCentsType,
    contractDocumentS3Key: z.string().optional(),
    firstInvoiceDueDate: DateStringType,
  }),
  contractDates: DateRangeType,
  contractDocument: z.instanceof(File).nullish(),
});

export interface ISPContractFormData extends z.TypeOf<typeof ISPContractSchema> {}
