import React, { useCallback } from "react";

import { Icon } from "@meterup/metric";

import { styled } from "../styled";

const ClearIconWrapper = styled("span", {
  cursor: "pointer",
});

type ClearInputButtonProps = {
  onClick: (e: React.SyntheticEvent) => void;
  visible?: boolean;
};

export default function ClearInputButton({ onClick: onClickProp, visible }: ClearInputButtonProps) {
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onClickProp(e);
    },
    [onClickProp],
  );
  if (!visible) {
    return null;
  }

  return (
    <ClearIconWrapper onClick={onClick}>
      <Icon icon="crossCircle" />
    </ClearIconWrapper>
  );
}
