import React from "react";

import { SimpleListItem } from "../styles/SimpleList";
import { EmailWithName } from "../utils/parseEmails";

type UserListItemProps = {
  user: EmailWithName;
};

export function UserListItem({ user }: UserListItemProps) {
  let name = "";
  const { firstName, lastName } = user;
  if (firstName) {
    name = ` (${firstName})`;
  }
  if (lastName) {
    name = `${name.substring(0, name.length - 1)} ${lastName})`;
  }
  return (
    <SimpleListItem key={user.email}>
      {user.email}
      {name}
    </SimpleListItem>
  );
}
