import { generatePath } from "react-router-dom";

const routes = {
  editQuote: {
    path: "/quoteRequests/:quoteRequestSid/quotes/:sid/edit",
  },
  newQuote: {
    path: "/quoteRequests/:sid/quotes/new",
  },
  quoteRequest: {
    path: "/quoteRequests/:sid",
  },
  quoteRequests: {
    path: "/quoteRequests",
  },
  providers: {
    path: "/providers",
  },
  new_provider: {
    path: "/providers/new",
  },
  edit_provider: {
    path: "/providers/:sid/edit",
  },
  settings: {
    path: "/settings",
  },
  login: {
    path: "/login",
  },
  adminRoot: {
    path: "/",
  },
  dashboard: {
    path: "/dashboard",
  },
};

export type RouteNames = keyof typeof routes;

export function route(name: RouteNames, params?: Record<string, any>) {
  return generatePath(routes[name].path, params);
}
