import React from "react";

import { QuoteRequest as QuoteRequestApi, useApiResource } from "@meterup/api";
import { Icon } from "@meterup/metric";
import { Link, useLocation, useParams } from "react-router-dom";

import Layout from "../components/Layout";
import QuoteForm from "../components/QuoteForm";
import { route } from "../routing";
import { CopyToNewQuoteRequestLocationState } from "../types";

interface NewQuoteProps {}

const NewQuote: React.FunctionComponent<NewQuoteProps> = () => {
  const { sid } = useParams<{
    sid: string;
  }>();
  const location = useLocation<CopyToNewQuoteRequestLocationState>();
  const [, quoteRequest] = useApiResource<QuoteRequestApi>(
    `admin/quote-requests/${sid}`,
    undefined,
    true,
  );

  // if we call useLocalStorage twice, we can write to one without nuking the
  // value of the other on rerender
  const labelText = [
    quoteRequest?.companyName,
    quoteRequest?.location?.address?.address1,
    quoteRequest?.location?.address?.city,
  ]
    .filter((x) => !!x)
    .map((x) => x!.substring(0, 10))
    .join(" - ");

  return (
    <Layout>
      <div className="flex flex-col mx-4">
        <p className="mb-8 mt-8 text-sm">
          <Link to={route("quoteRequest", { sid })}>
            <Icon icon="chevronLeft" /> Back
          </Link>
        </p>

        <h2 className="mb-2 text-2xl font-semibold">New Quote for {labelText}</h2>
        <QuoteForm
          initialValues={location.state?.copyToNewQuoteRequest || undefined}
          quoteRequestSid={sid}
        />
      </div>
    </Layout>
  );
};

export default NewQuote;
