import React, { useMemo } from "react";

import { HStack, Icon } from "@meterup/metric";
import { Cell, flexRender, Row } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import { ExpandFlex } from "../components/ExpandFlex";
import { log } from "../Log.utils";
import { DetailsArrow } from "../styles/DetailsArrow";
import { InnerTd } from "../styles/Table/InnerTd";
import { RightShadow, StickyTd, Tr } from "../styles/Table/table-styles";
import { TdContentWrapper } from "../styles/Table/TdContentWrapper";
import { stopPropagation } from "../utils/eventHandlers";
import { CellWrapper, InnerCellWrapper } from "./components/CellWrapper";
import { DataViewTheme } from "./types";

type CellRows<RecordType> = {
  row: Row<RecordType>;
  cells: {
    leading: Cell<RecordType, unknown>[];
    trailing: Cell<RecordType, unknown>[];
  };
};

function partitionCells<RecordType>(
  cells: Cell<RecordType, unknown>[],
  numLeading = 0,
): CellRows<RecordType>["cells"] {
  if (numLeading === 0) {
    return {
      leading: [],
      trailing: cells,
    };
  }

  const leading = cells.slice(0, numLeading);
  const trailing = cells.slice(numLeading, cells.length);

  return {
    leading,
    trailing,
  };
}

function renderCell<RecordType>(cell: Cell<RecordType, unknown>) {
  const cellContext = cell.getContext();
  const isAgg = cell.getIsAggregated();

  return flexRender(
    isAgg ? cell.column.columnDef.aggregatedCell : cell.column.columnDef.cell,
    cellContext,
  );
}

function cssProps<RecordType>(cell: Cell<RecordType, unknown>, numRows = 1) {
  if (cell.id === "address") {
    log(
      "cssProps for address",
      "size",
      cell.column.columnDef.size,
      "cell",
      cell,
      "numRows",
      numRows,
    );
  }
  return {
    style: {
      // height: Math.min(80, 40 * numRows),
      ...(numRows > 1 ? { height: `${40 * numRows}px` } : {}),
      width: `${cell.column.getSize()}px`,
      maxWidth: `${cell.column.getSize()}px`,
    },
  };
}

function dataProps<RecordType>(cell: Cell<RecordType, unknown>) {
  return {
    "data-column-id": cell.column.id,
  };
}

type DataViewRowProps<RecordType, As extends React.ComponentType<any>> = {
  as?: As;
  chevronLink?: (r: RecordType) => string;
  lastColumnIsSticky?: boolean;
  numLeadingStickyColumns?: number;
  onClickRecord: (record: RecordType) => (e: React.SyntheticEvent) => void;
  row: Row<RecordType>;
  theme?: DataViewTheme;
  trailingChevron?: boolean;
};

export type PartitionCellRows<RecordType> = {
  cells: CellRows<RecordType>["cells"];
  row: Row<RecordType>;
}[];

export function partitionCellRows<RecordType>(
  row: Row<RecordType>,
  numLeadingStickyColumns = 0,
): PartitionCellRows<RecordType> {
  const { subRows } = row;
  const topLevelCells = row.getVisibleCells();
  if (subRows.length === 0) {
    return [{ row, cells: partitionCells(topLevelCells, numLeadingStickyColumns) }];
  }
  const aggregated = topLevelCells.map((cell) => {
    if (cell.getIsAggregated()) {
      return cell;
    }
    return null;
  });

  return subRows.map((subRow) => {
    const cells = subRow.getVisibleCells();
    const mappedCells = aggregated.map((aggCell, i) => {
      if (aggCell) {
        return aggCell;
      }
      return cells[i];
    });
    return { row: subRow, cells: partitionCells(mappedCells, numLeadingStickyColumns) };
  });
}

export default function DataViewRow<RecordType, As extends React.ComponentType<any>>({
  as = Tr,
  chevronLink,
  lastColumnIsSticky = true,
  numLeadingStickyColumns = 0,
  onClickRecord,
  row,
  theme = "default",
  trailingChevron = false,
}: DataViewRowProps<RecordType, As>) {
  const cellRows = useMemo<CellRows<RecordType>[]>(
    () => partitionCellRows(row, numLeadingStickyColumns),
    [numLeadingStickyColumns, row],
  );
  return (
    <>
      {cellRows.map((cellRow, cellRowIdx) => {
        const isFirst = cellRowIdx === 0;
        const { row: innerRow, cells } = cellRow;
        const { original } = innerRow;
        const { leading, trailing } = cells;
        const numSubRows = cellRows.length;
        return (
          <Tr
            as={as}
            key={innerRow.id}
            onClick={onClickRecord(original)}
            onDoubleClick={stopPropagation}
            positionInGroup={isFirst ? "first" : "rest"}>
            {isFirst
              ? leading.map((cell, leadingIdx) => (
                  <StickyTd
                    positionInRow={leadingIdx === 0 ? "first" : "secondSticky"}
                    key={cell.id}
                    {...dataProps(cell)}
                    rowSpan={numSubRows}>
                    {/* <InnerTd> */}
                    <TdContentWrapper {...cssProps(cell, numSubRows)}>
                      {renderCell(cell)}
                    </TdContentWrapper>
                    <RightShadow>&nbsp;</RightShadow>
                    {/* </InnerTd> */}
                  </StickyTd>
                ))
              : null}
            {trailing.map((cell, trailingIdx) => {
              // const normalizedIdx = trailingIdx + numLeadingStickyColumns;
              const isAggregated = cell.getIsAggregated();
              const isLast = trailing.length - 1 === trailingIdx;

              if (isFirst && (isAggregated || (isLast && lastColumnIsSticky))) {
                return (
                  <StickyTd
                    positionInRow="last"
                    key={cell.id}
                    rowSpan={numSubRows}
                    {...cssProps(cell, numSubRows)}
                    {...dataProps(cell)}>
                    <CellWrapper align="center" variant="lastTdSticky-NoArrow">
                      <TdContentWrapper position="last">
                        <div>{renderCell(cell)}</div>
                      </TdContentWrapper>
                      <DetailsArrow>
                        <Icon icon="chevronRight" size={12} />
                      </DetailsArrow>
                    </CellWrapper>
                  </StickyTd>
                );
              }
              if (isAggregated && !isFirst) {
                return null;
              }

              return (
                <StickyTd key={cell.id} {...dataProps(cell)}>
                  <HStack spacing={4} {...cssProps(cell)}>
                    <InnerCellWrapper>
                      <div>{renderCell(cell)}</div>
                    </InnerCellWrapper>
                  </HStack>
                </StickyTd>
              );
            })}
            {trailingChevron ? (
              <StickyTd positionInRow="last" key={`stickyTdLastCell-${innerRow.id}`}>
                <InnerTd type="lastEmpty">
                  <ExpandFlex />
                  <div>
                    {chevronLink ? (
                      <Link to={chevronLink(original)}>
                        <Icon size={8} icon="chevronRight" />
                      </Link>
                    ) : (
                      <Icon size={8} icon="chevronRight" />
                    )}
                  </div>
                </InnerTd>
              </StickyTd>
            ) : null}
          </Tr>
        );
      })}
    </>
  );
}
