import React, { useMemo } from "react";

import { ISPStatus } from "@meterup/api";
import { GenericObject, TypedRecord } from "@meterup/api/src/helpers";
import set from "lodash/set";
import { useOutletContext } from "react-router-dom";

import {
  QuoteFormData,
  QuoteSchemaType,
} from "../../../components/Dashboard/Companies/quoteFormSchema";
import { QuoteForm } from "../../../components/Dashboard/Search/QuoteForm";
import { SearchContext } from "./SearchContext";
import { roundKbps } from "@meterup/ui/src/components/Table/formatters";

const baseFormDataValues: Readonly<QuoteSchemaType> = Object.freeze({
  availability: "unknown",
  contract_url: "",
  install_fee: "",
  monthly_fee: "",
  details: {
    contract_status: undefined,
    type: ISPStatus.IS_UNKNOWN,
  },
  support_contacts: {
    contactEmail: "",
    contactPhoneNumber: "",
  },
});

function deriveFormDataValues2<T extends GenericObject>(
  values: Partial<TypedRecord<T>>,
  base: T | Readonly<T>,
) {
  const newValues: T = { ...base };
  Object.keys(values).forEach((key) => {
    const val = values[key as keyof TypedRecord<T>];
    if (val !== undefined) {
      set(newValues, key, val);
    }
  });
  return newValues;
}

function deriveFormDataValues(vals: Partial<TypedRecord<QuoteSchemaType>>) {
  return deriveFormDataValues2(vals, baseFormDataValues);
}

export default function QuoteFormPage() {
  const context = useOutletContext<SearchContext>();
  const { isp, latestStatus, nextSID, prevSID, quote, quoteRequest } = context;
  const values = useMemo<QuoteFormData | undefined>(() => {
    if (!quote || !quoteRequest) {
      return baseFormDataValues;
    }

    const { status } = isp || {};
    const { connectionType } = quote;
    /* eslint-disable @typescript-eslint/naming-convention */
    let connection_type = connectionType;
    if (connection_type?.name === "") {
      connection_type = undefined;
    }
    /* eslint-enable @typescript-eslint/naming-convention */

    const derivedValues = deriveFormDataValues({
      availability: quote.availability,
      provider: quote.provider,
      connection_type,
      monthly_fee: quote.monthlyFeeCents / 100,
      install_fee: quote.installFeeCents / 100,
      download_kbps: roundKbps(quote.downloadKbps / 1_000),
      upload_kbps: roundKbps(quote.uploadKbps / 1_000),
      installation_estimates: quote.installationEstimateDays,
      contract_length: quote.contractLengthMonths,
      // contract_status: latestStatus?.connectionStatus || "",
      contract_url: isp?.contractUrl ?? null,
      "details.type": status,
      "details.contract_status": latestStatus?.connectionStatus ?? null,
      "support_contacts.primaryContactEmail": isp?.primaryContactEmail ?? null,
      "support_contacts.contactPhoneNumber": isp?.contactPhoneNumber ?? null,
    });

    console.log(
      "QuoteFormPage",
      derivedValues,
      "latestStatus",
      latestStatus,
      "status",
      status,
      "quote",
      quote,
      "quoteRequest",
      quoteRequest,
      { derivedValues, quote, quoteRequest, status }
    );

    return derivedValues;
  }, [latestStatus, isp, quote, quoteRequest]);
  return (
    <QuoteForm
      isp={isp}
      nextSID={nextSID}
      prevSID={prevSID}
      quote={quote}
      quoteRequest={quoteRequest}
      values={values}
    />
  );
}

QuoteFormPage.whyDidYouRender = true;
