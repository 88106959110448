import React from "react";

import { styled } from "../../styled";
import { InnerTd } from "../../styles/Table/InnerTd";
import { RightShadow, StickyTd, StickyTh } from "../../styles/Table/table-styles";
import { TdContentWrapper } from "../../styles/Table/TdContentWrapper";

const FirstTdContentWrapper = styled(TdContentWrapper, {
  padding: "$6 $8",
});

interface FirstCellProps {
  children: React.ReactNode;
}

interface LastCellTdProps {
  children: React.ReactNode;
  isSticky?: boolean;
  numRows: number;
}

export function FirstCell({ children }: FirstCellProps) {
  return (
    <InnerTd>
      <FirstTdContentWrapper>{children}</FirstTdContentWrapper>
      <RightShadow>&nbsp;</RightShadow>
    </InnerTd>
  );
}

export function FirstCellTd({ children, isSticky = true, numRows }: LastCellTdProps) {
  return (
    <StickyTd
      className={isSticky ? "first" : "first2"}
      rowSpan={numRows}
      style={{ height: Math.min(80, 40 * numRows) }}>
      <FirstCell>{children}</FirstCell>
    </StickyTd>
  );
}

export function FirstCellTh({ children, style }: FirstCellProps) {
  return (
    <StickyTh className="first">
      <InnerTd style={style} type="header">
        <TdContentWrapper>{children}</TdContentWrapper>
        <RightShadow>&nbsp;</RightShadow>
      </InnerTd>
    </StickyTh>
  );
}
