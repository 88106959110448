export type Option<Label, Value> = {
  label: Label;
  value: Value;
};

export type StringsOption = Option<string, string>;

export function getLabel<Label, Value>(option: Option<Label, Value>): Label {
  return option.label;
}

export function getValue<Label, Value>(option: Option<Label, Value>): Value {
  return option.value;
}
