import React from "react";
import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";

const NotFound: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="mt-12 ml-12 text-center sm:text-left">
        <h1 className="text-4xl">404</h1>
        <p className="font-light">
          <span className="block sm:my-4 sm:inline">Sorry, we couldn't find this page.</span>

          <span className="block sm:inline sm:ml-1">
            Try <button onClick={() => navigate(-1)}>going back</button>.
          </span>
        </p>
      </div>
    </Layout>
  );
};

export default NotFound;
