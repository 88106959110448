import React, { useCallback, useEffect, useRef, useState } from "react";

import type {
  APIError,
  AxiosAPIError,
  LocationsCreateRequest,
  Optional,
  QuoteRequest,
  QuoteRequestCreateRequest,
} from "@meterup/api";
import { post } from "@meterup/api/src/axios";
import useConnectionsQueryKey from "@meterup/api/src/hooks/useConnectionsQueryKey";
import { Button, DrawerContent, DrawerFooter } from "@meterup/metric";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import lodashIdentity from "lodash/identity";
import wrap from "lodash/wrap";
import Select, { GroupBase } from "react-select";
import { useAsyncList } from "react-stately";

import useFetchLocationPredictions from "../hooks/useFetchLocationPredictions";
import { useIdentity } from "../hooks/useIdentity";
import usePredictionFormatter from "../hooks/usePredictionFormatter";
import { logError } from "../Log.utils";
import { FieldDescription, Form } from "../styles/Form";
import { AutocompletePrediction } from "../types/gmaps";
import { DefaultPreventable } from "../utils/eventHandlers";
import formatLocationParts from "../utils/formatLocationParts";
import { ClearIndicator } from "./Select/ClearIndicator";
import { useCustomStyles } from "./Select/hooks/useCustomStyles";
import { IconValueContainer } from "./Select/IconValueContainer";
import withIconValueContainer from "./Select/withIconValueContainer";

const FILTER_FN = wrap(true, lodashIdentity);

export type AddLocationButtonsProps = {
  disabled: boolean;
  loading: boolean;
  onSubmit: (e: DefaultPreventable) => void;
};

type AddLocationProps = {
  buttons?: (props: AddLocationButtonsProps) => React.ReactNode;
  buttonText?: string;
  companySID?: string;
  dropdownPlaceholder?: string;
  emptyFieldDescription?: React.ReactNode;
  isAdmin?: boolean;
  onError?: (error: APIError) => void;
  onLoading?: (loading: boolean) => void;
  onSelectionChange?: (selection: Optional<AutocompletePrediction>) => void;
  onSuccess?: (quoteRequest: QuoteRequest) => void;
};

const LocationIconValueContainer = withIconValueContainer("location", IconValueContainer);

export default function AddLocation({
  buttons,
  buttonText,
  companySID,
  dropdownPlaceholder = "Search places to see available Internet providers",
  emptyFieldDescription,
  isAdmin,
  onError,
  onLoading,
  onSelectionChange: onSelectionChangeCallback,
  onSuccess,
}: AddLocationProps) {
  const queryClient = useQueryClient();
  const identity = useIdentity();
  const [locationsCreateRequest, setLocationsCreateRequest] = useState<LocationsCreateRequest>();
  const textInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const queryKey = useConnectionsQueryKey();
  const createQuoteMutation = useMutation<QuoteRequest, AxiosAPIError, QuoteRequestCreateRequest>(
    (vars) => post(`v1/companies/${companySID}/connect/quote-requests?via-admin=${isAdmin}`, vars),
    {
      onSuccess: (result) => {
        if (onSuccess) {
          onSuccess(result);
        }
        if (onLoading) {
          onLoading(false);
        }
        return queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        logError(error);
        if (onLoading) {
          onLoading(false);
        }
        if (onError) {
          onError(error);
        }
      },
      onMutate: () => {
        if (onLoading) {
          onLoading(true);
        }
      },
    },
  );
  useEffect(() => {
    textInputRef.current?.focus();
  }, []);
  const fetchPredictions = useFetchLocationPredictions();
  const list = useAsyncList<AutocompletePrediction>({
    async load({ filterText }) {
      const items = await fetchPredictions(filterText);
      return { items };
    },
  });
  const formatPrediction = usePredictionFormatter();
  const [selectedOption, setSelectedOption] = useState<AutocompletePrediction>();
  const onSelectionChange = useCallback(
    async (selected: AutocompletePrediction) => {
      if (onSelectionChangeCallback) {
        onSelectionChangeCallback(selected);
      }
      if (!selected) {
        setSelectedOption(undefined);
        setLocationsCreateRequest(undefined);
        return selected;
      }
      setSelectedOption(selected);
      const formatted = await formatPrediction(selected);
      if (!formatted) {
        return undefined;
      }

      setLocationsCreateRequest(formatLocationParts(formatted));
      if (formRef.current) {
        formRef.current.focus();
      }
    },
    [formatPrediction, onSelectionChangeCallback],
  );

  const submitLocation = useCallback(
    async (e: DefaultPreventable) => {
      e.preventDefault();
      try {
        const created = await createQuoteMutation.mutateAsync({
          companyName: companySID,
          contactName: "",
          contactEmail: identity?.username || "",
          contactTelephone: "",
          requestedDownloadKbps: 0,
          requestedUploadKbps: 0,
          contractMinimumMonths: 0,
          notes: "",
          existingMonthlyFeeCents: 0,
          existingProviderSid: "",
          location: locationsCreateRequest,
        });
      } catch (err) {
        logError(err);
      }
    },
    [companySID, createQuoteMutation, identity?.username, locationsCreateRequest],
  );
  const customStylesProvider = useCustomStyles<AutocompletePrediction, false>({
    size: "medium",
    showIndicator: true,
    controlPositioning: false,
    singleLine: true,
    capsuleStyle: false,
  });
  const getField = useCallback(
    (field: "label" | "value") => (item: AutocompletePrediction) => item[field],
    [],
  );
  const multiSelectRef = useRef<Select<
    AutocompletePrediction,
    false,
    GroupBase<AutocompletePrediction>
  > | null>(null);

  return (
    <Form ref={formRef} onSubmit={submitLocation}>
      <DrawerContent>
        <Select
          options={list.items}
          value={selectedOption}
          isLoading={list.isLoading}
          onInputChange={list.setFilterText}
          onChange={onSelectionChange}
          openMenuOnFocus
          styles={customStylesProvider}
          getOptionLabel={getField("description")}
          getOptionValue={getField("place_id")}
          menuPortalTarget={document.body}
          menuPlacement="bottom"
          menuPosition="absolute"
          noOptionsMessage={() => null}
          placeholder={dropdownPlaceholder}
          isClearable
          ref={multiSelectRef}
          filterOption={FILTER_FN}
          components={{
            ClearIndicator,
            ValueContainer: LocationIconValueContainer,
            DropdownIndicator: null,
            LoadingIndicator: null,
          }}
        />
        <FieldDescription>
          {locationsCreateRequest ? (
            <>
              Address: {locationsCreateRequest.address1}
              {locationsCreateRequest.address2 && ` ${locationsCreateRequest.address2}`},{" "}
              {locationsCreateRequest.city}, {locationsCreateRequest.state}
            </>
          ) : (
            emptyFieldDescription
          )}
        </FieldDescription>
      </DrawerContent>
      <DrawerFooter>
        {buttons ? (
          buttons({
            disabled: !locationsCreateRequest,
            loading: createQuoteMutation.isLoading,
            onSubmit: submitLocation,
          })
        ) : (
          <Button
            variant="primary"
            loading={createQuoteMutation.isLoading}
            disabled={!locationsCreateRequest}
            type="submit">
            {buttonText || "Save"}
          </Button>
        )}
      </DrawerFooter>
    </Form>
  );
}

AddLocation.whyDidYouRender = true;
