export type DateRange = [Date, Date];

export function getOverlappingRange(range1: DateRange, range2: DateRange): DateRange | null {
  const start1 = range1[0].getTime();
  const end1 = range1[1].getTime();
  const start2 = range2[0].getTime();
  const end2 = range2[1].getTime();

  const overlapStart = new Date(Math.max(start1, start2));
  const overlapEnd = new Date(Math.min(end1, end2));

  if (overlapStart.getTime() <= overlapEnd.getTime()) {
    return [overlapStart, overlapEnd];
  }

  return null;
}

export function dateInRange(date: Date, range: DateRange): boolean {
  return date.getTime() >= range[0].getTime() && date.getTime() <= range[1].getTime();
}

export function compareDateToRange(date: Date, range: DateRange): -1 | 0 | 1 {
  if (dateInRange(date, range)) {
    return 0;
  }
  if (date < range[0]) {
    return -1;
  }
  return 1;
}

const MS_IN_DAY = 1000 * 60 * 60 * 24;
export function numDaysInRange(range: DateRange): number {
  return Math.ceil((range[1].getTime() - range[0].getTime()) / MS_IN_DAY);
}

// const TypedField = createTypedField<ISPContractFormData>();
const dtFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  timeZone: "UTC",
});

export function dtToString(dt: Date) {
  const parts = dtFormatter.formatToParts(dt);
  const grouped: Record<Intl.DateTimeFormatPartTypes, string> = {};
  parts.forEach((part) => {
    grouped[part.type] = part.value;
  });

  return `${grouped.year}-${grouped.month}-${grouped.day}`;
}

export function addDays(date: Date, days: number): Date {
  const msInDays = days * MS_IN_DAY;
  return new Date(date.getTime() + msInDays);
}

export function formatDateStringFromAPI(date: string | undefined): string | null {
  let d = new Date();
  if (date) {
    d = new Date(date);
  }
  if (Number.isNaN(d.getTime())) {
    return null;
  }
  return dtToString(d);
}

export function formatDateForAPI(date: Date | null): string {
  if (date === null) {
    return undefined;
  }

  return date.toISOString();
}
