import { ToastProps } from "@meterup/metric";
import createToast from "react-hot-toast";

export function notify(
  heading: string,
  {
    variant = "neutral",
    duration = 2000,
    icon,
  }: Omit<ToastProps, "heading"> & { duration?: number } = {},
) {
  createToast(heading, {
    icon,
    // KLUDGE: react-hot-toast doesn't provide a way to pass custom props, so we're overriding the
    // className prop to pass the variant.
    className: variant,
    duration,
  });
}
