import { MouseEvent, useCallback } from "react";

import { NavigateFunction, NavigateOptions, useNavigate } from "react-router-dom";

import { log } from "../Log.utils";

export type MouseEventLike = {
  currentTarget: EventTarget & HTMLAnchorElement;
} & Pick<MouseEvent, "button" | "metaKey" | "ctrlKey" | "shiftKey" | "altKey" | "preventDefault">;

export type NavigateOptionsExtended = NavigateOptions & Partial<Pick<HTMLAnchorElement, "target">>;

/** Following two functions borrowed from react-router-dom v6 * */
export function isSpecialClick(event: MouseEventLike) {
  return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
}

export function shouldProcessLinkClick(event: MouseEventLike, target?: string) {
  return (
    event.button === 0 && // Ignore everything but left clicks
    (!target || target === "_self") && // Let browser handle "target=_blank" etc.
    !isSpecialClick(event) // Ignore clicks with modifier keys
  );
}

export function handleOnClickFactory(
  navigate: NavigateFunction,
): (opts?: NavigateOptionsExtended) => (event: MouseEventLike) => void {
  return function handleOnClickCustom(
    opts?: NavigateOptionsExtended,
  ): (event: MouseEventLike) => void {
    return function handleOnClick(event: MouseEventLike) {
      if (shouldProcessLinkClick(event, opts?.target) && event.currentTarget) {
        event.preventDefault();

        // TS error otherwise
        const hrefMaster = event.currentTarget.getAttribute("href") as string;
        navigate(hrefMaster, opts);
      }
    };
  };
}

// react-router v6 friendly version
export default function useRouter() {
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    (opts?: NavigateOptionsExtended) => handleOnClickFactory(navigate)(opts),
    [navigate],
  );

  return {
    handleOnClick: handleOnClick(),
    handleOnClickReplace: handleOnClick({ replace: true }),
  };
}
