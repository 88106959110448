import React, { useCallback, useEffect, useState } from "react";

import type { AxiosAPIError, IdentityResponse } from "@meterup/api";
import { axios, PortalOrigin, redirectToPortal } from "@meterup/api";
import { Skeleton } from "@meterup/metric";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import { IdentifiedContext, IdentifiedContextType } from "../contexts/IdentifiedContext";
import { logError } from "../Log.utils";
import { LoadingWrapper } from "../styles/LoadingWrapper";

type AuthenticatedLayoutProps = {
  authenticationRequired?: boolean;
  children: React.ReactNode;
  portalOrigin?: PortalOrigin;
};

const THIRTY_MINUTES_IN_MS = 30 * 60 * 1_000;

export default function AuthenticatedLayout({
  authenticationRequired = true,
  children,
  portalOrigin = PortalOrigin.Connect,
}: AuthenticatedLayoutProps) {
  const location = useLocation();
  const [identity, setIdentity] = useState<IdentifiedContextType>();
  const doRedirect = useCallback(
    (failureCount: number, err?: any) => {
      if (authenticationRequired && !identity && failureCount > 3) {
        if (err) {
          logError(err);
        }
        redirectToPortal(portalOrigin, location.pathname);
      }
    },
    [authenticationRequired, identity, location.pathname, portalOrigin],
  );
  const identityQuery = useQuery<unknown, AxiosAPIError, IdentityResponse>(
    ["identity"],
    () => axios.get("v1/identity").catch(() => null),
    {
      onSuccess(resp) {
        setIdentity(resp);
      },
      onError(error) {
        doRedirect(identityQuery.failureCount, error);
      },
      refetchOnWindowFocus: false,
      refetchInterval: THIRTY_MINUTES_IN_MS,
    },
  );
  useEffect(() => {
    doRedirect(identityQuery.failureCount);
  }, [doRedirect, identityQuery.failureCount, location, portalOrigin]);

  if (identityQuery.isLoading && authenticationRequired) {
    return (
      <LoadingWrapper>
        <Skeleton height="30px" width="205px" radius={6} />
        <div style={{ height: "15px" }} />
        <Skeleton height="245px" width="100%" radius={6} />
      </LoadingWrapper>
    );
  }

  return <IdentifiedContext.Provider value={identity}>{children}</IdentifiedContext.Provider>;
}
