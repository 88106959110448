import React, { useContext } from "react";

export type DrawerContextProps = {
  openDrawer: () => Promise<void>;
  closeDrawer: () => Promise<void>;
  drawerIsOpen: boolean;
  isOpening: boolean;
  isClosing: boolean;
  setContent: (content: React.ReactNode, autoOpenClose?: boolean) => void;
  // subscribe: (callback: () => void) => void;
  // unsubscribe: (callback: () => void) => void;
};

export const DrawerContext = React.createContext<DrawerContextProps>({
  openDrawer: () => Promise.resolve(),
  closeDrawer: () => Promise.resolve(),
  drawerIsOpen: false,
  setContent: () => {},
  isOpening: false,
  isClosing: false,
  // subscribe: () => {},
  // unsubscribe: () => {},
});

export function useDrawer() {
  return useContext<DrawerContextProps>(DrawerContext);
}
