import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import SuccessModal from "./ModalProvider/SuccessModal";
import WarningModal from "./ModalProvider/WarningModal";
import ActionModal from "./ModalProvider/ActionModal";
import {
  ActionModal as ActionModalType,
  Direction,
  ModalType,
  StandardModal,
} from "./old-modal-types";

type OnShowModalCallback = (modal: Modal) => void;

export const ModalContext = createContext<{
  onShowModal: OnShowModalCallback;
  onHideModal: () => void;
}>({
  onShowModal: () => {
    throw new Error(
      "Whoops! You managed to call the onShowModal callback without the correct context set. Something's gone terribly wrong!",
    );
  },
  onHideModal: () => {
    throw new Error(
      "Whoops! You managed to call the onHideModal callback without the correct context set. Something's gone terribly wrong!",
    );
  },
});

export type Modal = StandardModal | ActionModalType;

const modalViews: {
  [k in ModalType | "action"]: any;
} = {
  warning: WarningModal,
  success: SuccessModal,
  action: ActionModal,
};

const ModalProvider: React.FunctionComponent = ({ children }) => {
  const [modal, setModal] = useState<Modal>();
  const [visible, setVisible] = useState(false);
  const [direction, setDirection] = useState<Direction>("entering");

  const onShowModal = useCallback((modal: Modal) => {
    setModal(modal);
    setDirection("entering");
    setVisible(true);
    setTimeout(() => setDirection("leaving"), 300);
  }, []);

  const onHideModal = useCallback(() => {
    setDirection("leaving");
    setVisible(false);
    setTimeout(() => {
      setDirection("entering");
      setModal(undefined);
    }, 200);
  }, []);

  const ModalView = useMemo(() => modal && modalViews[modal.type], [
    modal,
    onHideModal,
    visible,
    direction,
  ]);

  const location = useLocation();

  useEffect(() => {
    document.addEventListener("keyup", (e) => e.key === "Escape" && onHideModal());
  }, [onHideModal]);

  useEffect(() => {
    onHideModal();
  }, [location.pathname]);

  return (
    <ModalContext.Provider value={{ onShowModal, onHideModal }}>
      {children}
      {ModalView && modal && (
        <ModalView modal={modal} onClose={onHideModal} visible={visible} direction={direction} />
      )}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
