import React from "react";

import { PortalOrigin } from "@meterup/api";
import AuthenticatedLayout from "@meterup/ui/src/Layouts/AuthenticatedLayout";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import AdminLayout from "./components/AdminLayout";
// import authenticate from "./components/Authenticate";
import Authenticate from "./components/Authenticate";
import Companies from "./pages/Dashboard/Companies";
import Company from "./pages/Dashboard/Company";
import Contracts from "./pages/Dashboard/Company/Contracts";
import CreateContract from "./pages/Dashboard/Company/CreateContract";
import CreateInvoice from "./pages/Dashboard/Company/CreateInvoice";
import CreateUser from "./pages/Dashboard/Company/CreateUser";
import EditContract from "./pages/Dashboard/Company/EditContract";
import EditInvoice from "./pages/Dashboard/Company/EditInvoice";
import Invoices from "./pages/Dashboard/Company/Invoices";
import Searches from "./pages/Dashboard/Company/Searches";
import Users from "./pages/Dashboard/Company/Users";
import Root from "./pages/Dashboard/Root";
import Search from "./pages/Dashboard/Search";
import CreateCompany from "./pages/Dashboard/Search/Company/CreateCompany";
import EditQuoteRequestPage from "./pages/Dashboard/Search/EditQuoteRequestPage";
import QuoteFormPage from "./pages/Dashboard/Search/QuoteFormPage";
import routes from "./routes";

const browserRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} path="/">
      <Route
        path="/dashboard"
        element={
          <AuthenticatedLayout portalOrigin={PortalOrigin.ConnectAdmin}>
            <AdminLayout />
          </AuthenticatedLayout>
        }>
        <Route path="searches" element={<div>Searches</div>} />
        <Route path="companies" element={<Companies />}>
          <Route path="create" element={<CreateCompany />} />
        </Route>
        <Route path="companies/:companySID/searches/:companyLocationSID" element={<Search />}>
          <Route path="quotes/:quoteSID" element={<QuoteFormPage />} />
          <Route path="edit" element={<EditQuoteRequestPage />} />
        </Route>
        <Route path="companies/:companySID/billing/:ispContractSID/invoices" element={<Invoices />}>
          <Route path="create" element={<CreateInvoice />} />
          <Route path="edit/:invoiceSID" element={<EditInvoice />} />
        </Route>
        <Route path="companies/:companySID" element={<Company />}>
          <Route path="searches" element={<Searches />} />
          <Route path="users" element={<Users />}>
            <Route path="create" element={<CreateUser />} />
          </Route>
          <Route path="billing" element={<Contracts />}>
            <Route path="create" element={<CreateContract />} />
            <Route path="edit/:ispContractSID" element={<EditContract />} />
          </Route>
        </Route>
      </Route>
      {Object.values(routes).map(
        ({ path, component: Component, exact = false, authenticate = true }) => {
          let AuthWrapper: React.FC = React.Fragment;
          if (authenticate) {
            AuthWrapper = Authenticate;
          }

          return <Route key={path} path={path} exact={exact} element={<Component />} />;
        },
      )}
    </Route>,
  ),
);
if (import.meta.hot) {
  import.meta.hot.dispose(() => browserRouter.dispose());
}
function Fallback() {
  return <p>Performing initial data "load"</p>;
}

export default function App() {
  return <RouterProvider router={browserRouter} fallbackElement={<Fallback />} />;
}
