export type DefaultPreventable = {
  preventDefault: () => void;
};

export function preventDefault<T extends DefaultPreventable>(event: T): T {
  event.preventDefault();
  return event;
}

export type StopPropagationAble = {
  stopPropagation: () => void;
};

export function stopPropagation<T extends StopPropagationAble>(event: T): T {
  event.stopPropagation();
  return event;
}
