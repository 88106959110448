import React, { useCallback } from "react";

import { FileAttachmentInfo } from "@meterup/api/src/common";
import { Button } from "@meterup/metric";
import useDownloadFile from "@meterup/ui/src/useDownloadFile";
import { useParams } from "react-router-dom";

import { ContractPageParams } from "../routerParams";

type DownloadDocumentCellProps = {
  companySID?: string;
  fileAttachmentInfo?: FileAttachmentInfo;
  invoiceSID?: string;
  ispContractSID?: string;
};

export default function DownloadInvoiceCell({
  companySID: companySIDParam,
  fileAttachmentInfo,
  invoiceSID,
  ispContractSID: ispContractSIDParam,
}: DownloadDocumentCellProps) {
  const urlParams = useParams<ContractPageParams>();
  const companySID = companySIDParam ?? urlParams.companySID;
  const ispContractSID = ispContractSIDParam ?? urlParams.ispContractSID;
  const url = `v1/companies/${companySID}/connect/contracts/${ispContractSID}/invoices/${invoiceSID}/documents/${fileAttachmentInfo?.key}`;
  const downloadFile = useDownloadFile({
    disabled: !fileAttachmentInfo,
    fileAttachment: fileAttachmentInfo,
    queryKey: ["invoice", "download", invoiceSID, fileAttachmentInfo?.key],
    url,
  });
  const onClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      downloadFile.download();
    },
    [downloadFile],
  );

  return (
    <Button
      onClick={onClick}
      icon="download"
      variant="tertiary"
      disabled={downloadFile.downloadState.disabled}
      loading={downloadFile.downloadState.loading}>
      {fileAttachmentInfo?.name ? "Download invoice" : "No invoice"}
    </Button>
  );
}
