import "regenerator-runtime/runtime.js";

import React from "react";

import { TextInput } from "@meterup/metric";
import debounce from "lodash/debounce";

interface SearchInputProps {
  totalRowCount: number;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

export default function SearchInput({
  totalRowCount,
  globalFilter,
  setGlobalFilter,
}: SearchInputProps) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = debounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  const placeholder = `${totalRowCount} record${totalRowCount === 1 ? "" : "s"}...`;
  const handleOnChange = (value: string) => {
    setValue(value);
    onChange(value);
  };
  return (
    <TextInput
      name="search"
      value={value || ""}
      onChange={handleOnChange}
      placeholder={placeholder}
      size="x-large"
      icon="search"
    />
  );
}
