import React, { useEffect, useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import type {
  AxiosAPIError,
  CompanyLocationPutRequest,
  CompanyLocationResponse,
  QuoteRequest,
  QuoteRequestUpdateRequest,
} from "@meterup/api";
import { axios } from "@meterup/api";
import {
  Alert,
  Button,
  CloseDrawerButton,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  HStack,
  PrimaryField,
  SecondaryField,
  TextInput,
  VStack,
} from "@meterup/metric";
import { CloseDrawerLink } from "@meterup/ui/src/components/Drawer/DrawerLinks";
import StackedSkeleton from "@meterup/ui/src/components/Loading/StackedSkeleton";
import { CapitalizeFirstLetter } from "@meterup/ui/src/components/Table/CommonCells";
import { EmDash } from "@meterup/ui/src/constants";
import { logError } from "@meterup/ui/src/Log.utils";
import { zodBlankable } from "@meterup/ui/src/zod/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import { z } from "zod";

import { Separator } from "../../../styles/dashboard";
import { SearchContext } from "./SearchContext";

const EditQuoteRequestSchema = z.object({
  name: zodBlankable(
    z.string().min(3, "Name must contain at least 3 non-whitespace characters.").optional(),
  ),
  userContact: z.object({
    contactName: z.literal("").or(z.string().min(3).optional()),
    contactEmail: z.literal("").or(z.string().email().optional()),
    contactTelephone: z.literal("").or(z.string().optional()),
  }),
});

interface EditQuoteRequestFormData extends z.TypeOf<typeof EditQuoteRequestSchema> {}
type EditSchemaType = z.infer<typeof EditQuoteRequestSchema>;

export default function EditQuoteRequestPage() {
  const { companySID, companyLocationSID: sid } = useParams();
  const context = useOutletContext<SearchContext>();
  const { companyLocation } = context;
  const address = companyLocation?.location?.address;
  const addressRendered = useMemo(() => {
    if (!address) {
      return <EmDash />;
    }
    const { address1, city, postalCode, state } = address;
    return (
      <VStack spacing={8}>
        <div>Default value:</div>
        <div>
          {address1}, {city}, {state} {postalCode}
        </div>
      </VStack>
    );
  }, [address]);

  const queryClient = useQueryClient();
  const editNameMutation = useMutation<
    CompanyLocationResponse,
    AxiosAPIError,
    CompanyLocationPutRequest
  >((vars) => axios.put(`v1/companies/${companySID}/connect/locations/${sid}`, vars), {
    async onSuccess() {
      await queryClient.invalidateQueries(["contracts", { sid }]);
    },
    onError(err) {
      logError(err);
    },
  });
  const editContactInfoMutation = useMutation<
    QuoteRequest,
    AxiosAPIError,
    Partial<QuoteRequestUpdateRequest>
  >((data) => axios.put(`v1/connect/quote-requests/${context.quoteRequest?.sid}`, data), {
    async onSuccess(data) {
      await queryClient.invalidateQueries(["quoteRequests", { sid }]);
    },
    onError: (error) => {
      logError(error);
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, isSubmitting, dirtyFields },
  } = useForm<EditQuoteRequestFormData>({
    defaultValues: useMemo(
      () => ({
        name: companyLocation?.name,
        userContact: {
          contactName: context.quoteRequest?.contactName,
          contactEmail: context.quoteRequest?.contactEmail,
          contactTelephone: context.quoteRequest?.contactTelephone,
        },
      }),
      [
        companyLocation?.name,
        context.quoteRequest?.contactEmail,
        context.quoteRequest?.contactName,
        context.quoteRequest?.contactTelephone,
      ],
    ),
    resolver: zodResolver(EditQuoteRequestSchema),
  });

  useEffect(() => {
    const { unsubscribe } = watch(() => {
      if (editNameMutation.isError || editNameMutation.isSuccess) {
        editNameMutation.reset();
      }
      if (editContactInfoMutation.isError || editContactInfoMutation.isSuccess) {
        editContactInfoMutation.reset();
      }
    });

    return unsubscribe;
  }, [editContactInfoMutation, editNameMutation, watch]);

  const onSubmit = useMemo<SubmitHandler<EditQuoteRequestFormData>>(
    () => async (innerData) => {
      const { name, userContact } = innerData;

      if (!isDirty) {
        return;
      }

      const p = Promise.all([
        dirtyFields.name ? editNameMutation.mutateAsync({ name }) : Promise.resolve(),
        dirtyFields.userContact
          ? editContactInfoMutation.mutateAsync({ ...userContact })
          : Promise.resolve(),
      ]);
      let hadError = false;
      try {
        await p;
      } catch (err) {
        logError(err);
        hadError = true;
      }

      reset(
        {
          ...getValues(),
        },
        {
          keepErrors: hadError,
        },
      );
    },
    [dirtyFields, editContactInfoMutation, editNameMutation, getValues, isDirty, reset],
  );
  const successMessage = useMemo(() => {
    if (!editContactInfoMutation.isSuccess && !editNameMutation.isSuccess) {
      return null;
    }
    const fields = [
      editContactInfoMutation.isSuccess ? "user contact info" : null,
      editNameMutation.isSuccess ? "location name" : null,
    ].filter(Boolean);

    return `Successfully updated ${fields.join(" and ")}.`;
  }, [editContactInfoMutation.isSuccess, editNameMutation.isSuccess]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DrawerHeader>
        <DrawerTitle>Edit quote request</DrawerTitle>
        <DrawerControls>
          <CloseDrawerLink>
            <CloseDrawerButton />
          </CloseDrawerLink>
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        {companyLocation ? (
          <VStack spacing={16}>
            {successMessage ? (
              <CapitalizeFirstLetter>
                <Alert variant="positive" copy={successMessage} />
              </CapitalizeFirstLetter>
            ) : null}
            <FieldContainer>
              <Controller
                render={({ field, fieldState }) => (
                  <PrimaryField
                    element={
                      <TextInput
                        {...field}
                        disabled={isSubmitting || editNameMutation.isLoading}
                        autoComplete="off"
                        hasError={!!fieldState.error?.message}
                      />
                    }
                    label="Location display name"
                    description={addressRendered}
                    errorMessage={
                      fieldState.error?.message ||
                      (editNameMutation.isError && "Error updating name")
                    }
                  />
                )}
                name="name"
                control={control}
              />
            </FieldContainer>
            <FieldContainer>
              <Controller
                render={({ field, fieldState }) => (
                  <PrimaryField
                    element={
                      <TextInput
                        {...field}
                        disabled={isSubmitting || editContactInfoMutation.isLoading}
                        hasError={!!fieldState.error?.message}
                        placeholder="Enter email"
                        icon="email"
                        autoComplete="off"
                      />
                    }
                    label="User contact"
                    errorMessage={fieldState.error?.message}
                  />
                )}
                name="userContact.contactEmail"
                control={control}
              />
              <Controller
                render={({ field, fieldState }) => (
                  <SecondaryField
                    element={
                      <TextInput
                        {...field}
                        disabled={isSubmitting || editContactInfoMutation.isLoading}
                        hasError={!!fieldState.error?.message}
                        autoComplete="off"
                      />
                    }
                    label="User name"
                    errorMessage={fieldState.error?.message}
                  />
                )}
                name="userContact.contactName"
                control={control}
              />
              <Controller
                render={({ field, fieldState }) => (
                  <SecondaryField
                    element={
                      <TextInput
                        {...field}
                        disabled={isSubmitting || editContactInfoMutation.isLoading}
                        hasError={!!fieldState.error?.message}
                        autoComplete="off"
                      />
                    }
                    label="User phone number"
                    errorMessage={
                      fieldState.error?.message ||
                      (editContactInfoMutation.isError && "Error updating contact info")
                    }
                  />
                )}
                name="userContact.contactTelephone"
                control={control}
              />
            </FieldContainer>
            <Separator />
            <HStack align="end" display="flex" spacing={8} justify="end">
              <CloseDrawerLink onClose={reset}>
                <Button variant="secondary" type="button">
                  Cancel
                </Button>
              </CloseDrawerLink>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                loading={editNameMutation.isLoading}>
                Save
              </Button>
            </HStack>
          </VStack>
        ) : (
          <StackedSkeleton />
        )}
      </DrawerContent>
    </form>
  );
}
