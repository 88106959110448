import React from "react";
import Layout from "../../components/Layout";
import ProviderForm from "../../components/Providers/ProviderForm/ProviderForm";
import { Link } from "@meterup/metric";
import { useRouter } from "@meterup/ui";
import { route } from "../../routing";

interface NewProviderProps {}

const NewProvider: React.FunctionComponent<NewProviderProps> = () => {
  const { handleOnClick } = useRouter();
  const newProvider = { name: "", path: "" };
  return (
    <Layout>
      <div className="mt-8">
        <div className="flex px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <h1 className="mb-8 text-2xl font-semibold text-gray-900">
            <Link href={route("providers")} onClick={handleOnClick}>
              Providers
            </Link>
            <span className="text-gray-600"> / </span>
            New
          </h1>
        </div>
        <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <ProviderForm initialValues={newProvider} />
        </div>
      </div>
    </Layout>
  );
};

export default NewProvider;
