import { useCallback, useMemo, useRef } from "react";

import { Optional } from "@meterup/api";
import { atom } from "jotai";
import { useAtom } from "jotai/react";
import { useAtomCallback } from "jotai/utils";

import { log } from "../../Log.utils";
import { AutocompleteService, GeocoderService } from "../../types/gmaps";

type Resolve = (value: PromiseLike<AutocompleteService> | AutocompleteService) => void;

type PlacesServices = {
  autocompleteService: AutocompleteService;
  geocoderService: GeocoderService;
};

type ReturnValue = {
  services: Optional<AutocompleteService>;
  getServicesPromise: GetServicesPromise;
};

function createPlacesServices(): PlacesServices {
  return {
    autocompleteService: new window.google.maps.places.AutocompleteService(),
    geocoderService: new window.google.maps.Geocoder(),
  };
}

function loadMapsAPICallback(...rest: any[]) {
  log("loadMapsAPICallback", ...rest);
}

window.loadMapsAPICallback = loadMapsAPICallback;

const placesServicesAtom = atom<Optional<PlacesServices>>(undefined);
const autocompleteServiceHolderIsLoading = atom(false);
const placesServicesSetter = atom<PlacesServices>(null, async (get, set) => {
  if (get(placesServicesAtom) || get(autocompleteServiceHolderIsLoading)) {
    return Promise.resolve(placesServicesAtom);
  }

  await set(autocompleteServiceHolderIsLoading, true);

  if (window.google?.maps?.places?.AutocompleteService) {
    return set(placesServicesAtom, createPlacesServices());
  }

  return new Promise((resolve: Resolve) => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDQ34LlABV3gXpbIwa78ael-vnfaGZd3s8&region=US&libraries=places&callback=loadMapsAPICallback`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      const placesServices = createPlacesServices();
      set(placesServicesAtom, placesServices);
      resolve(placesServices);
    };
    document.body.appendChild(script);
  });
});

type GetServicesPromise = (arg?: unknown) => Promise<Optional<PlacesServices>>;

export default function usePlacesServices(): ReturnValue {
  const [holder] = useAtom(placesServicesAtom);
  const prevSetter = useRef<GetServicesPromise>();
  const setterCallback = useAtomCallback(
    useCallback(async (get, set) => {
      await set(placesServicesSetter, null);
      return get(placesServicesAtom);
    }, []),
  );
  // printStackTrace();
  // useEffect(() => {
  //   if (prevSetter.current !== setterCallback) {
  //     console.log(
  //       "[CHANGE] setting prevSetter",
  //       "prevSetter",
  //       prevSetter.current,
  //       "setterCallback",
  //       setterCallback,
  //     );
  //   }
  //   prevSetter.current = setterCallback;
  // }, [setterCallback]);

  return useMemo(
    () => ({
      services: holder,
      getServicesPromise: setterCallback,
    }),
    [holder, setterCallback],
  );
}
