import React from "react";
import { FieldRenderProps } from "react-final-form";
import { TextInput, TextInputProps } from "@meterup/metric";

// Mixed interface between React Final Form and our pure TextInputForm
export interface TextInputFieldProps extends FieldRenderProps<string>, TextInputProps { }

// TODO: Can we move this to the fornt-end component library?
const TextInputField: React.FunctionComponent<TextInputFieldProps> = ({
  // React Final Form
  input,
  meta,
  placeholder,

  // Frontend components
  controlSize,
  size,
  type,
  icon,
  prefix,
  suffix,
  autocomplete,
  disabled,
  ariaLabel,
  ...props
}) => {
  // TODO: The error mark up should not be here
  const displayError = meta.error && meta.touched;
  const inputType = input.type as TextInputProps["type"];
  const internalOnChange = (value: string) => {
    return input.onChange({ target: { value } });
  };

  return (
    <div>
      <TextInput
        controlSize={controlSize}
        name={input.name}
        onChange={internalOnChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        value={input.value}
        type={inputType}
        placeholder={placeholder}
        autoComplete={autocomplete}
        size={size}
        icon={icon}
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
        hasError={displayError}
        aria-label={ariaLabel}
      />
      {displayError && <span className="mt-2 ml-2 text-xs text-red-600">{meta.error}</span>}
    </div>
  );
};

// RRF = React Final Form
export default TextInputField;

