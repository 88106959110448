import React from "react";

import { VStack } from "@meterup/metric";
import { styled } from "@meterup/ui";

const TableContainer = styled("div", {
  overflowY: "hidden",
});

type ShowProps = {
  children: React.ReactNode;
};

export default function Show({ children }: ShowProps) {
  return <VStack as={TableContainer}>{children}</VStack>;
}
