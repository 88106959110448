import React, { useMemo, useRef, useState } from "react";

import { HStack } from "@meterup/metric";

import { isFunction } from "../utils";
import { ExpandFlex } from "../components/ExpandFlex";
import { styled } from "../styled";
import { BaseDataViewWrapper } from "./components/BaseDataViewWrapper";
import { BaseDataViewProps } from "./types";

const Buttons = styled(HStack, {
  // marginTop: "0 !important",
});

// const TopWrapper = styled(HStack, LabelRow, {
//   paddingBottom: 0,
// });

// Current variation works as the plain Companies view
//
// To make companies view work, we need to:
// - Add drawer (as Context-able?)
//
// Need to add the variation that involves nested tabs.
//   I think to do that we make this either take a Heading or Tabs. Then we just pick one or the other
//   Then to display nested tabs, we just nest this thing inside a parent component with tabs. Can probably re-use similar Box as VStack then HStack as LabelRow stuff
export default function BaseDataView({
  buttons,
  header,
  children,

  drawerContent,
}: BaseDataViewProps) {
  // If we only support the use case of plumbing search through flat tables, I think it's
  // fine to just use the local state and not bubble it up?
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [drawerElement, setDrawerElement] = useState<React.ReactElement | null>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const derivedDrawerContent = useMemo(() => {
    if (drawerContent) {
      return drawerContent({
        closeDrawer: () => setDrawerIsOpen(false),
      });
    }
    return null;
  }, [drawerContent]);
  const derivedDrawerIsOpen = useMemo(
    () => drawerIsOpen && (drawerElement !== null || derivedDrawerContent !== null),
    [derivedDrawerContent, drawerElement, drawerIsOpen],
  );
  const derivedButtons = useMemo(() => {
    if (isFunction(buttons)) {
      return buttons({
        openDrawer: () => setDrawerIsOpen(true),
      });
    }
    return buttons;
  }, [buttons]);

  return (
    <div>
      <BaseDataViewWrapper align="center" display="flex">
        {header}
        <ExpandFlex />
        <Buttons spacing={8} align="center">
          {derivedButtons}
        </Buttons>
      </BaseDataViewWrapper>
      {children}
    </div>
  );
}

BaseDataView.whyDidYouRender = true;
