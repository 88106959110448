/* eslint-disable react/jsx-key */
import React, { useCallback, useMemo, useState } from "react";

import { HStack, Icon, StackProps } from "@meterup/metric";
import { styled } from "@meterup/ui";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Header,
  HeaderGroup,
  Row,
  SortingState,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";

import { useEffectDebugger } from "../utils/debugging";
import SearchInput from "./SearchInput";
import TableBody from "./TableBody";

const TableWrapper = styled("div", {
  overflowY: "auto",
  marginY: "$8",
  marginX: "$16",
});

type RenderSortIconProps = {
  header: Header<any, unknown>;
  setSortBy: (sortBy: SortingState) => void;
};

function RenderSortIcon({ header, setSortBy }: RenderSortIconProps) {
  const isSorted = header.column.getIsSorted();
  if (!isSorted) {
    return null;
  }

  if (isSorted === "desc") {
    return (
      <span>
        <Icon icon="chevronDown" />
      </span>
    );
  }

  return (
    <span>
      <Icon icon="chevronUp" />
    </span>
  );
}

type InnerHeaderProps = {
  header: Header<any, unknown>;
};

function InnerHeader({ header }: InnerHeaderProps) {
  const { column, getContext, getSize, id } = header;
  const { columnDef, getIsSorted, setSortBy, getToggleSortingHandler } = column;
  const stackProps: StackProps<"div"> = {
    style: {
      width: getSize(),
    },
  };

  if (getIsSorted()) {
    stackProps.onClick = getToggleSortingHandler();
    stackProps.style!.cursor = "pointer";
  }

  return (
    <HStack spacing="6" key={`col-hdr-${id}-inner`} {...stackProps}>
      <span>{flexRender(columnDef.header, getContext())}</span>

      <RenderSortIcon header={header} setSortBy={setSortBy} />
    </HStack>
  );
}

interface TableProps<RecordType> {
  columns: ColumnDef<RecordType, any>[];
  data: RecordType[];
  loading: boolean;
  showSearch?: boolean;
  defaultSortBy?: SortingState;
  renderFilters?: () => React.ReactNode;
  onClickRow?: (row: Row<RecordType>) => void;
  meta?: any;
}

export default function Table<RecordType>({
  columns,
  data,
  showSearch = false,
  loading = true,
  renderFilters,
  onClickRow,
  defaultSortBy,
  meta,
}: TableProps) {
  const innerOnClickRow = useCallback(
    (row: Row<RecordType>) => {
      if (onClickRow) {
        onClickRow(row);
      }
    },
    [onClickRow],
  );
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [sortBy, setSortBy] = useState<SortingState>(defaultSortBy || []);

  const tableOptions = useMemo<TableOptions<RecordType>>(
    () => ({
      columns,
      data,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      state: {
        sorting: sortBy,
        globalFilter,
      },
      enableSortingRemoval: false,
      onGlobalFilterChange: setGlobalFilter,
      onSortingChange: setSortBy,
      meta,
    }),
    [columns, data, sortBy, globalFilter, setSortBy, meta],
  );

  const table = useReactTable<RecordType>(tableOptions);

  const maxColumns = useMemo<number>(
    () =>
      table
        .getHeaderGroups()
        .map((g: any) => g.headers.length)
        .reduce((c: number, i: number) => (i > c ? i : c), 1),
    [table],
  );

  const headerGroups = useMemo(() => table.getHeaderGroups(), [table]);

  const rowModel = table.getRowModel();
  const rows = useMemo(() => rowModel.rows, [rowModel]);

  return (
    <div className="flex flex-col">
      <TableWrapper>
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {(showSearch || renderFilters) && (
              <div className="flex flex-col my-2 mb-4">
                {renderFilters && (
                  <h5 className="text-xs font-semibold tracking-wide uppercase text-gray mb-2">
                    Filters
                  </h5>
                )}
                <div className="flex items-center">
                  {renderFilters && renderFilters()}
                  {showSearch && (
                    <SearchInput
                      totalRowCount={data.length}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="border-b border-gray-light shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-50">
                <thead>
                  {headerGroups.map((headerGroup: HeaderGroup<any>) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header: Header<any, unknown>) => (
                        <th
                          key={header.id}
                          className="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-400 uppercase bg-gray-50">
                          <InnerHeader header={header} />
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <TableBody
                  loading={loading}
                  maxColumns={maxColumns}
                  onClickRow={innerOnClickRow}
                  rows={rows}
                />
              </table>
            </div>
          </div>
        </div>
      </TableWrapper>
    </div>
  );
}

Table.whyDidYouRender = true;
