import React, { useCallback } from "react";

export default function useMetaSubmit() {
  return useCallback(
    (fn: () => any) =>
      function onMetaSubmit(e: React.KeyboardEvent<HTMLFormElement>) {
        if ((e.metaKey || e.ctrlKey) && e.code === "Enter") {
          fn();
        }
      },
    [],
  );
}
