import { useMemo } from "react";

import { api } from "@meterup/proto";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { get } from "../axios";
import { InvoicesResponse } from "../protos";
import { ContractQueryParams, useContractQueryParams, useInvoicesQueryKey } from "./queryKeys";

export default function useInvoices(queryParams?: ContractQueryParams) {
  const params = useContractQueryParams(queryParams);
  const queryKey = useInvoicesQueryKey(params);
  const { companySID, ispContractSID } = params;
  const searchParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("status", "all");
    urlSearchParams.append("start_date", "1980-01-01");
    return urlSearchParams;
  }, []);

  return useQuery<unknown, AxiosError<api.Error>, InvoicesResponse>({
    queryKey,
    queryFn: () =>
      get<InvoicesResponse>(
        `/v1/companies/${companySID}/connect/contracts/${ispContractSID}/invoices?${searchParams.toString()}`,
      ),
  });
}
