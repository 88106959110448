import React, { useCallback, useMemo } from "react";

import { axios } from "@meterup/api";
import { Button, ButtonProps } from "@meterup/metric";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const buttonProps = ["variant", "size", "disabled", "icon", "type"] as const;
type SupportedButtonProps = typeof buttonProps[number];

type ViewContractButtonProps = {
  children: React.ReactNode;
  companySID?: string;
  contractPath?: string;
  ispSID?: string;
} & Pick<ButtonProps, SupportedButtonProps>;

export default function ViewContractButton({
  children,
  companySID,
  contractPath,
  ispSID,
  ...rest
}: ViewContractButtonProps) {
  const queryKey = useMemo(() => ["contract", "download", ispSID], [ispSID]);
  const queryClient = useQueryClient();
  const dataQuery = useQuery(
    queryKey,
    () =>
      axios.plainGet(
        `/v1/companies/${companySID}/internet-service-plans/${ispSID}/contracts/${contractPath}`,
        {
          responseType: "blob",
        },
      ),
    {
      enabled: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  const onClickDownload = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      if ((dataQuery.isLoading && dataQuery.isFetching) || dataQuery.data || rest.disabled) {
        return;
      }
      dataQuery.refetch().then((res) => {
        const { data, status } = res;
        if (status === "error") {
          return;
        }
        const url = window.URL.createObjectURL(data.data);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = contractPath;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        queryClient.removeQueries({ queryKey, exact: true });
      });
    },
    [contractPath, dataQuery, queryClient, queryKey, rest.disabled],
  );

  if (!contractPath || !companySID || !ispSID) {
    return null;
  }

  return (
    <Button
      {...rest}
      onClick={onClickDownload}
      loading={dataQuery.isLoading && dataQuery.isFetching}>
      {children}
    </Button>
  );
}
