import React from "react";
import { ConnectLogo, ServiceIcon, ServiceIconService } from "@meterup/metric";
import { useNavigate } from "react-router-dom";
import { route } from "../routing";

const Login: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center min-h-screen py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <ConnectLogo />
        <h2 className="text-sm font-extrabold leading-9 text-gray-900 uppercase">connect</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white shadow sm:rounded-lg sm:px-10">
          <a
            onClick={() => navigate(route("adminRoot"))}
            className="inline-flex w-full px-4 py-8 rounded-md shadow-sm cursor-pointer hover:opacity-75">
            <div className="mr-4 text-lg">
              <ServiceIcon service={ServiceIconService.Google} />
            </div>
            Login with Google
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
