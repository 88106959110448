import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { put } from "../axios";
import { ISPContractCreateRequest_Error } from "../connectadminProtos";
import { APIErrorType } from "../errors";
import { Invoice, InvoiceCreateRequest } from "../protos";

export type UseCreateInvoiceErrorType = APIErrorType<
  ISPContractCreateRequest_Error,
  "conflicting_contract"
>;

export default function useEditInvoice(ispSID: string, invoiceSID: string) {
  return useMutation<Invoice, AxiosError<UseCreateInvoiceErrorType>, InvoiceCreateRequest>((req) =>
    put(
      `/v1/connect/admin/internet-service-plans/${ispSID}/contracts/${req.invoice.ispContractsSid}/invoices/${invoiceSID}`,
      req,
    ),
  );
}
