import React from "react";

import { ModalProvider } from "@meterup/react-aria";
import { ModalProvider as OldModalProvider } from "@meterup/ui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet, ScrollRestoration } from "react-router-dom";

import ErrorBoundary from "../../components/ErrorBoundary";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
      // useErrorBoundary: true,
    },
  },
});

export default function Root() {
  return (
    <ErrorBoundary>
      <ScrollRestoration />
      <QueryClientProvider client={queryClient}>
        <ModalProvider>
          <OldModalProvider>
            <Outlet />
          </OldModalProvider>
        </ModalProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
