import React, { useCallback, useRef, useState } from "react";

import { CompanyContractsResponse } from "@meterup/api";
import { Button, HStack } from "@meterup/metric";
import AddLocationModal from "@meterup/ui/src/components/AddLocationModal";
import Modal from "@meterup/ui/src/components/Modal";
import { DateCell, StatusCell } from "@meterup/ui/src/components/Table/CommonCells";
import StatusTabBar from "@meterup/ui/src/components/Tabs/StatusTabBar";
import { EM_DASH, EmDash } from "@meterup/ui/src/constants";
import DataView from "@meterup/ui/src/DataView/DataView";
import useActiveStatusTab, {
  StatusTabItems,
  StatusTabItemValues,
} from "@meterup/ui/src/hooks/useActiveStatusTab";
import useProcessedContracts, {
  UseProcessedContractsResultType,
} from "@meterup/ui/src/hooks/useProcessedContracts";
import { CellContext, ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { CompanyContextType } from "../../../contexts/CompanyContext";

const colHelper = createColumnHelper<CompanyContractsResponse>();
const columns: ColumnDef<CompanyContractsResponse, any>[] = [
  colHelper.accessor(
    (row: CompanyContractsResponse) => {
      const address = row.companyLocation?.location?.address;
      if (!address) {
        return EM_DASH;
      }

      return address.address1;
    },
    {
      id: "address",
      header: "Address",
      size: 200,
    },
  ),
  colHelper.accessor(
    (row: CompanyContractsResponse) => {
      const address = row.companyLocation?.location?.address;
      if (!address) {
        return EM_DASH;
      }
      const { city, state } = address;

      return [city, state].filter(Boolean).join(", ");
    },
    {
      id: "region",
      header: "Region",
    },
  ),
  colHelper.accessor(
    (row: CompanyContractsResponse) => {
      const { companyContracts } = row;
      if (!companyContracts || companyContracts.length === 0) {
        return EM_DASH;
      }
      const primaryContactEmail = companyContracts[0].internetServicePlan?.primaryContactEmail;
      if (!primaryContactEmail) {
        return EM_DASH;
      }

      return primaryContactEmail;
    },
    {
      id: "contact",
      header: "Contact",
    },
  ),
  colHelper.accessor(
    (row: CompanyContractsResponse) => {
      const { companyContracts } = row;
      if (!companyContracts || companyContracts.length === 0) {
        return EM_DASH;
      }

      return companyContracts.map((contract) => contract.internetServicePlan?.sid).join("___");
    },
    {
      id: "status",
      header: "Status",
      size: 288,
      cell: (props: CellContext<CompanyContractsResponse, any>) => {
        const { companyContracts } = props.row.original;
        if (!companyContracts || companyContracts.length === 0) {
          return <EmDash />;
        }

        return (
          <HStack spacing={4}>
            {companyContracts.map((contract) => {
              const { latestStatus } = contract;
              if (!latestStatus) {
                return null;
              }

              return (
                <StatusCell
                  key={`status-cell-${latestStatus.sid}`}
                  connectionStatusChange={latestStatus}
                />
              );
            })}
          </HStack>
        );
      },
    },
  ),
  colHelper.accessor(
    (row: CompanyContractsResponse) => {
      const { companyContracts } = row;
      if (!companyContracts || companyContracts.length === 0) {
        return EM_DASH;
      }
      const createdAt = companyContracts[0].internetServicePlan?.createdAt;
      if (!createdAt) {
        return EM_DASH;
      }

      return createdAt;
    },
    {
      id: "createdAt",
      header: "Created at",
      cell: (props: CellContext<CompanyContractsResponse, any>) => {
        const { companyContracts } = props.row.original;
        if (!companyContracts || companyContracts.length === 0) {
          return <EmDash />;
        }
        const [contract] = companyContracts;
        return <DateCell value={contract.internetServicePlan?.createdAt} />;
      },
    },
  ),
  colHelper.accessor(
    (row: CompanyContractsResponse) => {
      const { companyContracts } = row;
      if (!companyContracts || companyContracts.length === 0) {
        return EM_DASH;
      }
      const updatedAt = companyContracts[0].internetServicePlan?.updatedAt;
      if (!updatedAt) {
        return EM_DASH;
      }

      return updatedAt;
    },
    {
      id: "updatedAt",
      header: "Last updated",
      cell: (props: CellContext<CompanyContractsResponse, any>) => {
        const { companyContracts } = props.row.original;
        if (!companyContracts || companyContracts.length === 0) {
          return <EmDash />;
        }
        const [contract] = companyContracts;
        return <DateCell value={contract.internetServicePlan?.updatedAt} />;
      },
    },
  ),
];

const colNames = columns.map((col) => col.id).filter(Boolean) as string[];

export default function Searches() {
  const { companySID } = useParams();
  const activeTab = useActiveStatusTab();
  const { allContracts: allContractsResponse } = useOutletContext<CompanyContextType>();
  const { contracts } = allContractsResponse || {};
  const navigate = useNavigate();

  const {
    counts,
    rawRows,
    filteredContracts,
  }: UseProcessedContractsResultType = useProcessedContracts({
    activeFilterType: activeTab,
    companySID: companySID || "",
    contracts,
    searchValue: "",
  });
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef();
  const toggleModal = useCallback(
    (e) => {
      e.preventDefault();
      setShowModal(!showModal);
    },
    [showModal],
  );

  return (
    <div>
      <Modal onWantsToClose={toggleModal} in={showModal} forwardedRef={modalRef}>
        {({ close, setModalIsBlocked }) => (
          <AddLocationModal close={close} setModalIsBlocked={setModalIsBlocked} />
        )}
      </Modal>
      <DataView
        buttons={
          <Button
            icon="plusCircle"
            variant="tertiary"
            arrangement="leading-icon"
            onClick={toggleModal}>
            New quote
          </Button>
        }
        formatColumnForSearch={{
          status: (row: Row<CompanyContractsResponse>) => {
            return row.original.companyContracts.map((contract) => contract.latestStatus);
          }
        }}
        header={
          <StatusTabBar
            basePath={`/dashboard/companies/${companySID}/searches`}
            tabCount={Object.values(StatusTabItems).reduce(
              (acc, key) => ({ ...acc, [key]: counts[key] }),
              {} as Record<StatusTabItemValues, number>,
            )}
          />
        }
        noWrapColumns={colNames}
        columns={columns}
        data={filteredContracts}
        staticHeight
        onClickRow={({ record }) => {
          const { companyLocation } = record;
          navigate(`/dashboard/companies/${companySID}/searches/${companyLocation?.sid}`);
        }}
        numLeadingStickyColumns={1}
      />
    </div>
  );
}
