import { useQuery } from "@tanstack/react-query";

import { get } from "../axios";
import type { AxiosAPIError, ContractsResponse } from "../protos";
import type { ContractQueryParams } from "./queryKeys";
import { useContractQueryParams, useContractsQueryKey } from "./queryKeys";

export default function useISPContracts(queryParams?: ContractQueryParams) {
  const params = useContractQueryParams(queryParams);
  const contractsQueryKey = useContractsQueryKey();
  const { companySID } = params;

  return useQuery<unknown, AxiosAPIError, ContractsResponse>({
    queryKey: contractsQueryKey,
    queryFn: () => get<ContractsResponse>(`/v1/connect/admin/companies/${companySID}/contracts`),
  });
}
