import React from "react";
import { useApiResource, Provider } from "@meterup/api";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import ProviderForm from "../../components/Providers/ProviderForm/ProviderForm";
import { Link } from "@meterup/metric";
import { useRouter } from "@meterup/ui";
import { route } from "../../routing";

interface EditProviderProps {}

const EditProvider: React.FunctionComponent<EditProviderProps> = () => {
  const { handleOnClick } = useRouter();

  const { sid } = useParams<{
    sid: string;
  }>();

  const [loading, provider] = useApiResource<Provider>(`providers/${sid}`);

  return (
    <Layout>
      <div className="mt-8">
        <div className="flex px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <h1 className="mb-8 text-2xl font-semibold text-gray-900">
            <Link href={route("providers")} onClick={handleOnClick}>
              Providers
            </Link>
            <span className="text-gray-600"> / </span>
            Edit {provider?.name}
          </h1>
        </div>
        <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          {!loading && <ProviderForm provider={provider} />}
        </div>
      </div>
    </Layout>
  );
};

export default EditProvider;
