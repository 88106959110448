/* eslint-disable */
import Long from "long";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "common";

export enum Currency {
  USD = "USD",
  USD3 = "USD3",
  USD5 = "USD5",
}

export interface Point {
  x: number;
  y: number;
}

export interface GeoPoint {
  latitude: number;
  longitude: number;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
}

export interface Location {
  location_sid: string;
  address?: Address;
  point?: GeoPoint;
  created_at?: string;
  updated_at?: string;
  company_slug: string;
  sublocation_sid: string;
}

export interface FileAttachment {
  name: string;
  /** content is a base64 encoded string containing the file's contents */
  content: string;
}

export interface FileAttachmentInfo {
  name: string;
  content_type: string;
  size: number;
  key: string;
}

export interface TimeRange {
  start?: string;
  end?: string;
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
