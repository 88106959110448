import React, { useCallback, useMemo } from "react";

import { axios } from "@meterup/api";
import { FileAttachmentInfo } from "@meterup/api/src/common";
import { QueryKey } from "@tanstack/query-core";
import { useQuery, useQueryClient } from "@tanstack/react-query";

type UseDownloadFileResult = {
  loading: boolean;
  success: boolean;
};

type UseDownloadFileStates = {
  disabled: boolean;
  error?: boolean;
  loading: boolean;
  success: boolean;
};

type UseDownloadFile<TQueryKey extends QueryKey> = {
  disabled?: boolean;
  fileAttachment?: FileAttachmentInfo;
  queryKey: TQueryKey;
  url: string;
};

export default function useDownloadFile<TQueryKey extends QueryKey = QueryKey>({
  disabled: disabledProp,
  fileAttachment,
  queryKey,
  url,
}: UseDownloadFile<TQueryKey>) {
  const queryClient = useQueryClient();
  const dataQuery = useQuery(
    queryKey,
    () =>
      axios.plainGet(url, {
        responseType: "blob",
      }),
    {
      enabled: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );
  const [downloadState, setDownloadState] = React.useState<UseDownloadFileStates>({
    disabled: disabledProp || !fileAttachment,
    error: false,
    loading: false,
    success: false,
  });
  const download = useMemo(
    () => async () => {
      if (
        (dataQuery.isLoading && dataQuery.isFetching) ||
        dataQuery.data ||
        downloadState.disabled
      ) {
        return;
      }
      setDownloadState((state) => ({ ...state, loading: true }));
      const result = await dataQuery.refetch();
      const { data, status } = result;
      if (status === "error") {
        setDownloadState((state) => ({ ...state, error: true, loading: false, success: false }));
        return;
      }
      const objectURL = window.URL.createObjectURL(data.data);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = objectURL;
      a.download = fileAttachment.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectURL);
      document.body.removeChild(a);
      queryClient.removeQueries({ queryKey, exact: true });
      setDownloadState((state) => ({ ...state, error: false, loading: false, success: true }));
    },
    [dataQuery, downloadState, fileAttachment?.name, queryClient, queryKey],
  );
  return {
    download,
    downloadState,
  };
}
