import React, { useCallback, useMemo } from "react";

import { InvoiceResponse } from "@meterup/api";
import useInvoices from "@meterup/api/src/billing/useInvoices";
import useISPContract from "@meterup/api/src/billing/useISPContract";
import { InvoiceStatus } from "@meterup/api/src/connectProtos";
import { Badge, Button, HStack, Text, VStack } from "@meterup/metric";
import { Drawer, OpenDrawerLink } from "@meterup/ui";
import { EmDash } from "@meterup/ui/src/constants";
import Header from "@meterup/ui/src/DataView/components/Header";
import DataView from "@meterup/ui/src/DataView/DataView";
import useDownloadFile from "@meterup/ui/src/useDownloadFile";
import { formatDateStringFromAPI } from "@meterup/ui/src/utils/dates";
import { formatCurrency } from "@meterup/ui/src/utils/formatCurrency";
import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import isEmpty from "lodash/isEmpty";
import { Link, useNavigate, useOutlet, useParams } from "react-router-dom";

import AddressHeading from "../../../components/Dashboard/Search/AddressHeading";
import DownloadInvoiceCell from "../../../components/DownloadInvoiceCell";
import Show from "../../../components/show/Show";
import ShowContent from "../../../components/show/ShowContent";
import ShowHeader from "../../../components/show/ShowHeader";
import ShowHeaderDetail from "../../../components/show/ShowHeaderDetail";
import ShowHeaderLarge from "../../../components/show/ShowHeaderLarge";
import { ContractPageParams } from "../../../routerParams";
import { H3 } from "../../../styles/dashboard";

function blankCell<TData, TValue>(r: (props: CellContext<TData, TValue>) => any) {
  const val = r.getValue();
  if (isEmpty(val) && val !== 0) {
    return <EmDash />;
  }
  return val;
}

const columnHelper = createColumnHelper<InvoiceResponse>();
const columns: ColumnDef<InvoiceResponse>[] = [
  columnHelper.accessor((r) => r.invoice?.sid, {
    id: "sid",
    header: "SID",
    cell: blankCell,
    size: 150,
  }),
  columnHelper.accessor(
    (r) => {
      const invoicedAt = r.invoice?.invoicedAt;
      if (!invoicedAt) {
        return "";
      }
      return formatDateStringFromAPI(invoicedAt);
    },
    {
      id: "invoiced-at",
      header: "Invoiced On",
      cell: blankCell,
      size: 50,
    },
  ),
  columnHelper.accessor(
    (r) => {
      const billingCycleStartDate = r.invoice?.billingCycleStartDate;
      if (!billingCycleStartDate) {
        return "";
      }
      return formatDateStringFromAPI(billingCycleStartDate);
    },
    {
      id: "cycle-start",
      header: "Cycle Start",
      cell: blankCell,
      size: 50,
    },
  ),
  columnHelper.accessor(
    (r) => {
      const billingCycleEndDate = r.invoice?.billingCycleEndDate;
      if (!billingCycleEndDate) {
        return "";
      }
      return formatDateStringFromAPI(billingCycleEndDate);
    },
    {
      id: "cycle-end",
      header: "Cycle End",
      cell: blankCell,
      size: 50,
    },
  ),
  columnHelper.accessor(
    (r) => {
      const dueDate = r.invoice?.dueDate;
      if (!dueDate) {
        return "";
      }
      return formatDateStringFromAPI(dueDate);
    },
    {
      id: "due-date",
      header: "Due Date",
      cell: blankCell,
      size: 75,
    },
  ),
  columnHelper.accessor(
    (r) => {
      const { invoice } = r;
      if (!invoice) {
        return "";
      }

      return formatCurrency({ amount: invoice.amountActual, unit: invoice.amountActualCurrency });
    },
    {
      id: "amount-due",
      header: "Amount due",
      cell: blankCell,
      size: 75,
    },
  ),
  columnHelper.accessor(
    (r) => {
      const { invoice } = r;
      if (!invoice) {
        return "Unknown status";
      }
      return invoice.status;
    },
    {
      id: "status",
      header: "Status",
      cell: (r) => {
        const { invoice } = r.row.original;

        const status: InvoiceStatus | undefined = invoice?.status;
        if (status === InvoiceStatus.unpaid) {
          return <Badge variant="negative">Unpaid</Badge>;
        }
        if (status === InvoiceStatus.paid) {
          return <Badge variant="positive">Paid</Badge>;
        }
        if (status === InvoiceStatus.past_due) {
          return <Badge variant="negative">Past due</Badge>;
        }
        if (status === InvoiceStatus.cancelled) {
          return <Badge variant="alternative">Cancelled</Badge>;
        }
        if (status === InvoiceStatus.upcoming) {
          return <Badge variant="neutral">Upcoming</Badge>;
        }
        if (status === InvoiceStatus.refunded) {
          return <Badge variant="positive">Refunded</Badge>;
        }

        return <Badge variant="negative">Unknown {status ? <Text>{status}</Text> : null}</Badge>;
      },
      size: 75,
    },
  ),
  columnHelper.accessor(
    (r) => {
      const document = r.invoice?.document;
      if (!document) {
        return "";
      }

      return document.key;
    },
    {
      id: "invoice-document",
      header: "PDF",
      cell: (r) => {
        const document = r.row.original.invoice?.document;
        const invoiceSID = r.row.original.invoice?.sid;
        console.log("Invoice document", "invoiceSID", invoiceSID, "document", document, "r", r);
        return <DownloadInvoiceCell invoiceSID={invoiceSID} fileAttachmentInfo={document} />;
      },
    },
  ),
];

export default function Invoices() {
  const { companySID, ispContractSID } = useParams<ContractPageParams>();
  const { data: ispContract } = useISPContract();
  const { data: invoices } = useInvoices();

  const data = useMemo(() => invoices?.invoices || [], [invoices?.invoices]);
  const children = useOutlet({
    invoices: data,
    ispContract,
  });

  const navigate = useNavigate();
  const fileAttachmentInfo = ispContract?.ispContract?.document;
  const url = `v1/companies/${companySID}/connect/contracts/${ispContractSID}/documents/${fileAttachmentInfo?.key}`;
  const downloadFile = useDownloadFile({
    disabled: !fileAttachmentInfo,
    fileAttachment: fileAttachmentInfo,
    queryKey: ["contract", "download", ispContract?.ispContract?.sid, fileAttachmentInfo?.key],
    url,
  });
  const onClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      downloadFile.download();
    },
    [downloadFile],
  );

  return (
    <Show>
      <ShowContent>
        <ShowHeader>
          <ShowHeaderDetail>
            <HStack spacing={6} align="center">
              <Link to={`/dashboard/companies/${companySID}/billing`}>
                <Button
                  variant="tertiary"
                  size="medium"
                  type="button"
                  icon="arrowLeft"
                  arrangement="leading-icon">
                  Contracts
                </Button>
              </Link>
              <Text>Contract</Text>
              {ispContract?.provider ? (
                <Badge variant="neutral">{ispContract.provider.name}</Badge>
              ) : null}
            </HStack>
          </ShowHeaderDetail>
          <ShowHeaderLarge>
            <AddressHeading companyLocation={ispContract?.companyLocation} />
            <VStack spacing={6} align="center">
              <Button
                icon="download"
                variant="tertiary"
                arrangement="leading-label"
                size="medium"
                onClick={onClick}>
                Download contract
              </Button>
            </VStack>
          </ShowHeaderLarge>
        </ShowHeader>
        <VStack spacing={8}>
          <DataView
            buttons={
              <OpenDrawerLink path="create">
                <Button
                  icon="plusCircle"
                  variant="tertiary"
                  arrangement="leading-label"
                  size="medium">
                  Add invoice
                </Button>
              </OpenDrawerLink>
            }
            columns={columns}
            data={data}
            noWrapColumns={columns.map((c) => c.id)}
            header={
              <Header>
                <Text
                  size={14}
                  lineHeight={20}
                  as={H3}
                  color={{ light: "gray-800" }}
                  weight="medium">
                  Invoices
                </Text>
              </Header>
            }
            onClickRow={({ record }) => {
              const {
                invoice: { sid },
              } = record;
              navigate(`./edit/${sid}`);
            }}
            lastColumnVariant="lastTdSticky-NoArrow"
            theme="light"
          />
        </VStack>
      </ShowContent>
      <Drawer>{children}</Drawer>
    </Show>
  );
}
