import React from "react";

import {
  Availability,
  ConnectionStatusChange,
  ConnectionType,
  InternetServicePlan,
  ISPStatus,
  Quote,
} from "@meterup/api";
import {
  Badge,
  BadgeProps,
  BadgeVariant,
  HStack,
  Icon,
  IconName,
  Text,
  TextProps,
  VStack,
} from "@meterup/metric";

import { EmDash } from "../../constants";
import useFormatters from "../../hooks/useFormatters";
import { styled } from "../../styled";
import ViewContractButton from "../ViewContractButton";
import { quoteToTerm } from "./formatters";
import { WidthMarker } from "./styles";
import { BandwidthProps } from "./types";

type OptionalValue<T> = {
  value?: T;
};

export function CentsCell({ value }: OptionalValue<number>) {
  const { centsFormatter } = useFormatters();
  if (!value) {
    return <EmDash />;
  }

  return <>{centsFormatter(value)}</>;
}

export function DateCell({ value }: OptionalValue<string>) {
  const { pbDateFormatter } = useFormatters();
  if (!value) {
    return <EmDash />;
  }
  return <>{pbDateFormatter(value)}</>;
}

export type StatusCellProps = {
  connectionStatusChange?: ConnectionStatusChange;
};

export function StatusCell({ connectionStatusChange }: StatusCellProps) {
  if (!connectionStatusChange) {
    return <EmDash />;
  }
  let variant: BadgeVariant = "neutral";
  let icon: IconName | undefined;

  const { relatedTs, connectionStatus } = connectionStatusChange;

  if (!connectionStatus) {
    return <EmDash />;
  }

  const { category, display, priority, status } = connectionStatus;
  // console.log("status", { category, display, priority, status });

  switch (category) {
    case "active":
      variant = "positive";
      break;
    case "in-progress":
      icon = "clock";
      break;
    case "needs-attention":
      icon = "attention";
      variant = "negative";
      break;
    case "quotes":
      variant = "brand";
      icon = "internet";
      switch (status) {
        case "confirm-quote":
          icon = "arrowRight";
          break;
        default:
      }
      break;
    default:
  }
  return (
    <WidthMarker>
      <Badge
        variant={variant}
        size="small"
        arrangement="leading-icon"
        ends="pill"
        {...(icon ? { icon } : {})}>
        {display}
      </Badge>
    </WidthMarker>
  );
}

export const CapitalizeFirstLetter = styled("div", {
  "&:first-letter": {
    textTransform: "uppercase",
  },
});

export type AvailabilityProps = {
  availability: Availability;
  recommended: boolean;
};

const ACBadgeProps: BadgeProps = {
  arrangement: "leading-icon",
  size: "small",
  ends: "pill",
};

export function AvailabilityCell({ availability, recommended }: AvailabilityProps) {
  let variant: BadgeVariant = "neutral";
  let label = availability.replace("location_", "").replace("_", "-");

  switch (availability) {
    case Availability.location_near_net:
      variant = "alternative";
      break;
    case Availability.location_on_net:
      variant = "positive";
      break;
    case Availability.location_off_net:
      variant = "negative";
      break;
    default:
      label = "Unknown";
  }

  return (
    <div>
      <Badge variant={variant} {...ACBadgeProps}>
        <CapitalizeFirstLetter>{label}</CapitalizeFirstLetter>
        {recommended ? (
          <Badge variant="brand" {...ACBadgeProps}>
            Recommended
          </Badge>
        ) : null}
      </Badge>
    </div>
  );
}

export type ProductCellProps = {
  connectionType?: ConnectionType;
};

export function ProductCell({ connectionType }: ProductCellProps) {
  const name = connectionType?.name;
  if (!connectionType || !name) {
    return <EmDash />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{name}</>;
}

const Bandwidth = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  gap: "$8",
});

export function BandwidthCell({ downloadKbps, uploadKbps }: BandwidthProps) {
  if (!downloadKbps && !uploadKbps) {
    return (
      <Bandwidth>
        <EmDash />
      </Bandwidth>
    );
  }
  const textOptions: TextProps<"span"> = {
    size: 11,
    lineHeight: 16,
    color: { light: "gray-700" },
    family: "monospace",
  };

  return (
    <HStack spacing="4" align="center">
      <HStack spacing="2" align="center">
        <Icon icon="arrowDown" size={12} />
        <Text {...textOptions}>{downloadKbps || "—"}</Text>
      </HStack>
      <HStack spacing="2" align="center">
        <Icon icon="arrowUp" size={12} />
        <Text {...textOptions}>{uploadKbps || "—"}</Text>
      </HStack>
    </HStack>
  );
}

type ContractTermCellProps = {
  quote?: Quote;
};

export function ContractTermCell({ quote }: ContractTermCellProps) {
  const term = quoteToTerm(quote);
  if (!term) {
    return <EmDash />;
  }

  return <>{term}</>;
}

type ContractCellProps = {
  internetServicePlan?: InternetServicePlan;
  companySID?: string;
};

export function ContractCell({ internetServicePlan, companySID }: ContractCellProps) {
  if (!internetServicePlan) {
    return <EmDash />;
  }

  const { contractUrl, status } = internetServicePlan;

  if (!contractUrl && !status && status !== ISPStatus.IS_UNKNOWN) {
    return <EmDash />;
  }

  return (
    <VStack spacing="4" align="start">
      <ViewContractButton
        contractPath={contractUrl}
        companySID={companySID}
        variant="tertiary"
        size="small"
        icon="document"
        ispSID={internetServicePlan?.sid}>
        View Contract
      </ViewContractButton>
      {status ? (
        <Badge variant="alternative" ends="pill" size="small">
          {status === ISPStatus.IS_PRIMARY ? "Primary" : "Secondary"}
        </Badge>
      ) : null}
    </VStack>
  );
}
