import { useEffect, useMemo, useState } from "react";
import { useEffectDebugger } from "@meterup/ui/src/utils/debugging";

export function useIdCarousel(ids: string[], id?: string) {
  useEffectDebugger(() => console.log("useIdCarousel"), [id, ids], ["id", "ids"]);
  return useMemo(() => {
    if (ids.length === 0) {
      return { nextId: undefined, prevId: undefined };
    }
    const idx = id === "new" ? 0 : ids.findIndex((i) => i === id);
    console.log("useIdCarousel useMemo", "idx", idx);
    return {
      prev: ids[idx - 1] || ids[ids.length - 1],
      next: ids[idx + 1] || ids[0],
      current: id,
    };
  }, [id, ids]);
}

// React hook called useIdCarousel2 that takes a list of strings and a current string which is an element in that list.
// The hook returns a list of three strings: the previous element in the list, the current element, and the next element in the list.
// If the current element is the first element in the list, the previous element is the last element in the list.
// If the current element is the last element in the list, the next element is the first element in the list.
// If the current element is not in the list, the previous and next elements are undefined.
// If the current element is "new", the previous element is the last element in the list.
// If the current element is "new" and the list is empty, the previous element is undefined.
// If the current element is "new" and the list is not empty, the next element is the first element in the list.
// If the current element is "new" and the list is empty, the next element is undefined.
function useIdCarousel2(ids: string[], id?: string) {}

type NextPrevType = {
  prev?: string;
  next?: string;
};

export function useNextPrev(list: string[], current: string) {
  const [position, setPosition] = useState<NextPrevType>({ next: undefined, prev: undefined });

  useEffect(() => {
    console.log("useIdCarousel useEffect", "current", current, "list", { list }, "position", position);
    if (list.length === 0) {
      setPosition({ next: undefined, prev: undefined });
      return;
    }

    let currentIndex = list.indexOf(current);
    console.log("useIdCarousel useEffect", "currentIndex", currentIndex);
    if (current === "new" || currentIndex === -1) {
      currentIndex = 0;
    }

    setPosition({
      next: list[(currentIndex + 1) % list.length],
      prev: list[(currentIndex - 1 + list.length) % list.length],
    });
  }, [list, current, position]);

  return position;
}
