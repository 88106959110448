import { Body2 } from "@meterup/metric";

import { styled } from "../styled";

export const SimpleList = styled("ul", {
  listStyle: "none",
  margin: 0,
  padding: 0,
});

export const SimpleListItem = styled("li", Body2, {
  listStyleType: "disc",
  fontWeight: "$medium",
  marginLeft: "$20",
  paddingLeft: "$4",
});
