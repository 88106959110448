import React from "react";

import { CompanyLocationResponse } from "@meterup/api";
import { Text, TextProps, VStack } from "@meterup/metric";
import { EmDash } from "@meterup/ui/src/constants";
import size from "lodash/size";

import { H3 } from "../../../styles/dashboard";

const topLineText: TextProps<typeof H3> = {
  size: 20,
  lineHeight: 28,
  as: H3,
  color: { light: "gray-800" },
  weight: "medium",
};

const restLineText: TextProps<"span"> = {
  size: 14,
  lineHeight: 20,
  color: { light: "gray-700" },
};

type AddressHeadingProps = {
  companyLocation?: CompanyLocationResponse;
};

export default function AddressHeading({ companyLocation }: AddressHeadingProps) {
  if (!companyLocation) {
    return (
      <Text {...topLineText}>
        <EmDash />
      </Text>
    );
  }
  const { location, name, sid } = companyLocation;
  const { address } = location || {};

  const parts = [
    name,
    address?.address1,
    [address?.address2, address?.city, address?.state, address?.postalCode]
      .filter(Boolean)
      .join(", "),
  ].filter(Boolean);

  if (size(parts) === 0) {
    return (
      <Text {...topLineText}>
        <EmDash />
      </Text>
    );
  }

  const [firstLine, ...rest] = parts;

  return (
    <VStack spacing={8} key={`address-heading-cl-${sid}`}>
      <Text {...topLineText}>{firstLine}</Text>
      {size(rest) > 0 && (
        <VStack spacing={2}>
          {rest.map((line, i) => (
            <Text key={`address-heading-cl-${sid}-row-${i}`} {...restLineText}>
              {line}
            </Text>
          ))}
        </VStack>
      )}
    </VStack>
  );
}
