import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";

import { PortalOrigin } from "@meterup/api";
import { getConnectUrl } from "@meterup/api/src/config";
import {
  Button,
  CloseDrawerButton,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  HStack,
  Text,
  VStack,
} from "@meterup/metric";
import { useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { log } from "../Log.utils";
import { styled } from "../styled";
import { Drawer, EditorMessage, ModalContent, ModalWrapper } from "../styles/Modal";
import AddLocation from "./AddLocation";
import { BaseModalProps } from "./Modal";

const EditorMessageContainer = styled("div", {
  paddingBottom: "$2",
});

enum ActionType {
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
  SET_SUCCESS = "SET_SUCCESS",
}

interface State {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

interface Action {
  type: ActionType;
  payload?: boolean;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ActionType.SET_ERROR:
      return { isError: action.payload ?? true, isLoading: false, isSuccess: false };
    case ActionType.SET_LOADING:
      return { isError: false, isLoading: action.payload ?? true, isSuccess: false };
    case ActionType.SET_SUCCESS:
      return { isError: false, isLoading: false, isSuccess: action.payload ?? true };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

type AddLocationProps = BaseModalProps & {
  isAdmin?: boolean;
};

export default function AddLocationModal({ close, isAdmin, setModalIsBlocked }: AddLocationProps) {
  const [editorMessage, setEditorMessage] = useState<React.ReactNode>();
  const { companySID } = useParams();
  const [state, dispatch] = useReducer(reducer, {
    isError: false,
    isLoading: false,
    isSuccess: false,
  });

  const onClose = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      close(e);
    },
    [close],
  );

  const instructionsRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (state.isLoading || state.isError) {
      setModalIsBlocked(true);
      log("[AddLocationModal] Setting to blocked", "state", { ...state });
    } else {
      setModalIsBlocked(false);
      log("[AddLocationModal] Setting to NOT blocked", "state", { ...state });
    }
  }, [setModalIsBlocked, state]);

  return (
    <ModalWrapper>
      <ModalContent>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>Create a new location</DrawerTitle>
            <DrawerControls>
              <CloseDrawerButton onClick={onClose} />
            </DrawerControls>
          </DrawerHeader>
          <AddLocation
            companySID={companySID}
            isAdmin={isAdmin}
            onLoading={(isLoading) => isLoading && dispatch({ type: ActionType.SET_LOADING })}
            onError={() => {
              dispatch({ type: ActionType.SET_ERROR });
              setEditorMessage(
                <div>
                  <VStack spacing={8}>
                    We’re having trouble creating a quote for this location. If this issue persists
                    please contact support.
                    <a href="mailto:support@meter.com" target="_blank" rel="noreferrer">
                      <Button variant="primary">Contact support</Button>
                    </a>
                  </VStack>
                </div>,
              );
            }}
            onSuccess={(result) => {
              const path = getConnectUrl(
                window.location,
                PortalOrigin.Connect,
                `/quoteRequests/${result.sid}/results`,
              );
              dispatch({ type: ActionType.SET_SUCCESS });
              setEditorMessage(
                <div>
                  <VStack spacing={8}>
                    <div>
                      Quote request generated successfully.{" "}
                      <a href={path} target="_blank" rel="noreferrer">
                        Click here
                      </a>{" "}
                      to select quotes for this location.
                    </div>
                    <HStack spacing={8}>
                      <a href={path} target="_blank" rel="noreferrer">
                        <Button variant="primary">View location</Button>
                      </a>
                      <Button
                        variant="secondary"
                        onClick={(e: React.SyntheticEvent) => {
                          e.preventDefault();
                          close(e);
                        }}>
                        Close
                      </Button>
                    </HStack>
                  </VStack>
                </div>,
              );
            }}
          />
        </Drawer>
      </ModalContent>
      <CSSTransition
        nodeRef={instructionsRef}
        in={state.isError || state.isSuccess}
        unmountOnExit
        timeout={250}>
        {(variant) => (
          <EditorMessage
            state={variant}
            ref={instructionsRef}
            type={state.isSuccess ? "success" : state.isError && "error"}>
            <Text
              size={14}
              lineHeight={20}
              as={EditorMessageContainer}
              display="block"
              css={{ paddingBottom: "$4" }}>
              {editorMessage}
            </Text>
          </EditorMessage>
        )}
      </CSSTransition>
    </ModalWrapper>
  );
}
