import { useCallback, useContext } from "react";

import { Modal, ModalContext } from "../ModalProvider";

/**
 * When called within a component which is a descendent of ModalProvider,
 * return a function which shows a modal, animate its entry and manage its state.
 *
 * This will take the modal of the
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useModal(modal: Modal) {
  const { onShowModal, onHideModal } = useContext(ModalContext);
  const onConfirm = useCallback(
    (args: any[] | any) => () => {
      const result = modal.type !== "action" ? modal.onConfirm(...args) : null;
      if (result) {
        onHideModal();
      }
    },
    [modal],
  );

  return {
    onShowModal: useCallback(
      (...args) => onShowModal({ ...modal, onConfirm: onConfirm(args) } as any),
      [modal],
    ),
    onHideModal,
  };
}
