import React from "react";

import { HStack } from "@meterup/metric";

import { ShowHeaderLargeContainer } from "./containers";

type ShowHeaderLargeProps = {
  children: React.ReactNode;
};

export default function ShowHeaderLarge({ children }: ShowHeaderLargeProps) {
  if (React.Children.count(children) > 2) {
    console.error("ShowHeaderLarge can only have two children");
  }

  return (
    <HStack spacing={12} as={ShowHeaderLargeContainer} width="full" justify="between" align="center">
      {children}
    </HStack>
  );
}
