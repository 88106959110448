import React, { useCallback, useMemo, useRef, useState } from "react";

import type {
  AxiosAPIError,
  CompanyContractResponse,
  CompanyContractsResponse,
  ConnectionStatusChange,
  InternetServicePlan,
  QuoteRequest,
  QuoteRequestsRequest,
  QuotesRequest,
  QuoteWithRequestAndISP,
} from "@meterup/api";
import { ISPStatus, PortalOrigin, QuoteStatus } from "@meterup/api";
import { get } from "@meterup/api/src/axios";
import { getConnectUrl } from "@meterup/api/src/config";
import { useCompany } from "@meterup/api/src/hooks/useCompany";
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  HStack,
  Icon,
  MinList,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
  Skeleton,
  Text,
  VStack,
} from "@meterup/metric";
import { Drawer, OpenDrawerLink, styled } from "@meterup/ui";
import EditStatus from "@meterup/ui/src/components/Dashboard/Search/EditStatus";
import { ExpandFlex } from "@meterup/ui/src/components/ExpandFlex";
import LabelRow from "@meterup/ui/src/components/LabelRow";
import Modal from "@meterup/ui/src/components/Modal";
import {
  AvailabilityCell,
  BandwidthCell,
  CentsCell,
  ContractCell,
  ContractTermCell,
  ProductCell,
  StatusCell,
} from "@meterup/ui/src/components/Table/CommonCells";
import { formatBandwidth } from "@meterup/ui/src/components/Table/formatters";
import { EmDash } from "@meterup/ui/src/constants";
import Header from "@meterup/ui/src/DataView/components/Header";
import DataView from "@meterup/ui/src/DataView/DataView";
import useRouter from "@meterup/ui/src/hooks/useRouter";
import { HorizontalPanelRow } from "@meterup/ui/src/styles/Panels";
import { TableContainer } from "@meterup/ui/src/styles/Table/table-styles";
import { useQuery } from "@tanstack/react-query";
import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Link, useOutlet, useParams, useResolvedPath } from "react-router-dom";

import ConfirmDeletionModal from "../../components/ConfirmDeletionModal";
import AddressHeading from "../../components/Dashboard/Search/AddressHeading";
import ContactForm from "../../components/Dashboard/Search/ContactForm";
import { useIdCarousel } from "../../hooks/useIdCarousel";
import RowActions from "../../src/components/Dashboard/Search/RowActions";
import { H3, Separator } from "../../styles/dashboard";
import { SearchContext } from "./Search/SearchContext";

const StatusBar = styled("div", {
  margin: "$12 $20 $4 $20",
});

const MiddleBar = styled("div", HorizontalPanelRow, {
  gap: "$20",
  width: "100%",
});

const LoneIconWrapper = styled("div", {
  padding: "$2",
});

const LabelRowTop = styled(LabelRow, {
  "& > div": {
    marginTop: "0px !important",
  },
});

const colHelper = createColumnHelper<QuoteWithRequestAndISP>();
const columns: ColumnDef<QuoteWithRequestAndISP, any>[] = [
  colHelper.accessor((q) => q.provider?.name, {
    id: "provider",
    header: "Provider",
  }),
  colHelper.accessor((q) => `${q.sid}-${q.internetServicePlan?.sid}`, {
    id: "contract",
    header: "Contract",
    cell: (props: CellContext<QuoteWithRequestAndISP, any>) => (
      <ContractCell
        internetServicePlan={props.row?.original?.internetServicePlan}
        companySID={props.row?.original?.companySID}
      />
    ),
  }),
  colHelper.accessor((q) => q.availability, {
    id: "availability",
    header: "Availability",
    cell: (props: CellContext<QuoteWithRequestAndISP, any>) => (
      <AvailabilityCell
        availability={props.row.original.availability}
        recommended={props.row.original.recommended}
      />
    ),
  }),
  colHelper.accessor((q) => q.status, {
    id: "manual",
    header: "Manual",
    cell: (props: CellContext<QuoteWithRequestAndISP, any>) => {
      const status = props.row.original?.status;
      const isManual = props.row.original?.isManual;
      if (!isManual || !status) {
        return null;
      }

      if (status === "requested") {
        return (
          <Badge
            variant="neutral"
            ends="card"
            size="small"
            icon="checkmarkCircle"
            arrangement="leading-icon">
            Requested
          </Badge>
        );
      }

      return (
        <Badge variant="neutral" ends="card" size="small" arrangement="leading-icon">
          <LoneIconWrapper>
            <Icon icon="checkmarkCircle" size={12} />
          </LoneIconWrapper>
        </Badge>
      );
    },
    size: 100,
  }),
  colHelper.accessor((q) => q.connectionType, {
    id: "product",
    header: "Product",
    cell: (props: CellContext<QuoteWithRequestAndISP, any>) => {
      const connectionType = props.row?.original?.connectionType;

      return <ProductCell connectionType={connectionType} />;
    },
  }),
  colHelper.accessor((q) => `${q.downloadKbps}-${q.uploadKbps}`, {
    id: "speed",
    header: "Speed",
    cell: (props: CellContext<QuoteWithRequestAndISP, any>) => {
      const formatted = formatBandwidth({ value: props.row?.original });

      return <BandwidthCell {...formatted} />;
    },
    size: 200,
  }),
  colHelper.accessor((q) => q.monthlyFeeCents, {
    id: "price",
    header: "Price",
    cell: (props: CellContext<QuoteWithRequestAndISP, any>) => {
      const monthlyFeeCents = props.row?.original?.monthlyFeeCents;
      return <CentsCell value={monthlyFeeCents} />;
    },
    size: 80,
  }),
  colHelper.accessor((q) => q.contractLengthMonths, {
    id: "contractTerm",
    header: "Contract term",
    cell: (props: CellContext<QuoteWithRequestAndISP, any>) => (
      <ContractTermCell quote={props.row?.original} />
    ),
    size: 80,
  }),
  colHelper.accessor((q) => q.contractLengthMonths, {
    id: "latestStatus",
    header: "Status",
    cell: (props: CellContext<QuoteWithRequestAndISP, any>) => (
      <StatusCell connectionStatusChange={props.row?.original?.latestStatus} />
    ),
    size: 150,
  }),
  colHelper.accessor((q) => `${q.sid}-actions`, {
    id: "actions",
    header: "Actions",
    cell: (props: CellContext<QuoteWithRequestAndISP, any>) => (
      <RowActions quoteWithOther={props.row?.original} />
    ),
    size: 163,
  }),
];

const ALL_QUOTE_STATUSES = Object.values(QuoteStatus);

type ContractStatusType = "primary" | "secondary";
type ISPByQuoteSIDType = Record<string, InternetServicePlan>;
type ContractsByStatusType = Record<ContractStatusType, CompanyContractResponse | undefined>;
type QuotesMappedType = {
  contractsByStatus: ContractsByStatusType;
  ispByQuoteSID: ISPByQuoteSIDType;
  latestStatus?: ConnectionStatusChange;
  latestStatusByQuoteSID: Record<string, ConnectionStatusChange>;
};

export default function Search() {
  const { handleOnClick } = useRouter();
  const { companySID, companyLocationSID: sid, quoteSID } = useParams();
  const {
    data: contract,
    isError: failed,
    isLoading: loading,
  } = useQuery<unknown, AxiosAPIError, CompanyContractsResponse>(["contracts", { sid }], () =>
    get<CompanyContractsResponse>(`v1/companies/${companySID}/connect/connections/${sid}`),
  );
  const { data: quotesRequest } = useQuery<unknown, AxiosAPIError, QuotesRequest>(
    ["quotes", { sid }],
    () =>
      get<QuotesRequest>(`v1/companies/${companySID}/connect/locations/${sid}/quotes`, {
        statuses: ALL_QUOTE_STATUSES,
      }),
  );
  const { data: quoteRequests } = useQuery<
    QuoteRequestsRequest,
    AxiosAPIError,
    QuoteRequestsRequest
  >(["quoteRequests", { sid }], () =>
    get<QuoteRequestsRequest>(
      `v1/connect/admin/companies/${companySID}/company-locations/${sid}/quote-requests`,
    ),
  );

  const { contractsByStatus, ispByQuoteSID, latestStatusByQuoteSID } = useMemo<QuotesMappedType>(
    () =>
      (contract?.companyContracts || []).reduce(
        (acc, cc) => {
          const {
            ispByQuoteSID: ispByQuoteSIDOut,
            latestStatusByQuoteSID: latestStatusByQuoteSIDOut,
          } = acc;
          const contractsByStatusOut = { ...acc.contractsByStatus };
          if (cc.internetServicePlan?.status === ISPStatus.IS_BACKUP) {
            contractsByStatusOut.secondary = cc;
          }
          if (cc.internetServicePlan?.status === ISPStatus.IS_PRIMARY) {
            contractsByStatusOut.primary = cc;
          }
          const quote = cc.internetServicePlan?.quote;

          if (!quote || !cc.internetServicePlan || !cc.latestStatus) {
            return {
              contractsByStatus: contractsByStatusOut,
              ispByQuoteSID: ispByQuoteSIDOut,
              latestStatusByQuoteSID: latestStatusByQuoteSIDOut,
            };
          }

          return {
            contractsByStatus: contractsByStatusOut,
            ispByQuoteSID: {
              ...ispByQuoteSIDOut,
              [quote.sid]: cc.internetServicePlan,
            },
            latestStatusByQuoteSID: {
              ...latestStatusByQuoteSIDOut,
              [quote.sid]: cc.latestStatus,
            },
          };
        },
        {
          contractsByStatus: {
            primary: undefined,
            secondary: undefined,
          },
          ispByQuoteSID: {},
          latestStatusByQuoteSID: {},
        } as QuotesMappedType,
      ),
    [contract],
  );

  const {
    quoteWithRequests,
    quotesBySID,
    quoteSIDs,
  }: {
    quoteWithRequests: QuoteWithRequestAndISP[];
    quotesBySID: Record<string, QuoteWithRequestAndISP>;
    quoteSIDs: string[];
  } = useMemo(() => {
    if (!quotesRequest) {
      return {
        quoteWithRequests: [] as QuoteWithRequestAndISP[],
        quotesBySID: {} as Record<string, QuoteWithRequestAndISP>,
        quoteSIDs: [] as string[],
      };
    }
    const quoteRequestBySIDMap = (quoteRequests?.quoteRequests || []).reduce(
      (acc, quoteRequest) => ({
        ...acc,
        [quoteRequest.sid]: quoteRequest,
      }),
      {} as Record<string, QuoteRequest>,
    );

    const results: QuoteWithRequestAndISP[] = [];
    const quoteBySIDMap: Record<string, QuoteWithRequestAndISP> = {};

    quotesRequest.quotes.forEach((quote) => {
      const quoteWithRequest: QuoteWithRequestAndISP = {
        ...quote,
        companyLocationSID: sid,
        internetServicePlan: ispByQuoteSID[quote.sid],
        quoteRequest: quoteRequestBySIDMap[quote.quoteRequestSid],
        latestStatus: latestStatusByQuoteSID[quote.sid],
        companySID,
      };
      quoteBySIDMap[quote.sid] = quoteWithRequest;
      const status = ispByQuoteSID[quote.sid]?.status;
      const firstEl = results.length > 0 ? results[0] : null;
      if (status) {
        if (status === ISPStatus.IS_PRIMARY) {
          return results.unshift(quoteWithRequest);
        }
        if (
          status === ISPStatus.IS_BACKUP &&
          firstEl?.internetServicePlan?.status === ISPStatus.IS_PRIMARY
        ) {
          return results.splice(1, 0, quoteWithRequest);
        }
        if (firstEl && firstEl.internetServicePlan?.status === status) {
          return results.unshift(quoteWithRequest);
        }
        // return results.push(quoteWithRequest);
      }
      return results.push(quoteWithRequest);
    });
    return {
      quoteWithRequests: results,
      quotesBySID: quoteBySIDMap,
      quoteSIDs: Object.keys(quoteBySIDMap),
    };
  }, [
    companySID,
    ispByQuoteSID,
    latestStatusByQuoteSID,
    quoteRequests?.quoteRequests,
    quotesRequest,
    sid,
  ]);
  const { prev: previousSID, next: nextSID } = useIdCarousel(quoteSIDs, quoteSID);

  const childContext = useMemo<SearchContext>(() => {
    const base: SearchContext = {};

    if (contract?.companyLocation) {
      base.companyLocation = contract.companyLocation;
    }

    if (!quoteRequests?.quoteRequests?.length) {
      return base;
    }
    const isp = quoteSID ? ispByQuoteSID[quoteSID] : undefined;
    const latestStatus = quoteSID ? latestStatusByQuoteSID[quoteSID] : undefined;

    return {
      ...base,
      quote: quoteSID ? quotesBySID[quoteSID] : undefined,
      quoteRequest: quoteRequests.quoteRequests[0],
      latestStatus,
      isp,
      nextSID,
      prevSID: previousSID,
    };
  }, [
    contract,
    quoteRequests,
    quoteSID,
    ispByQuoteSID,
    latestStatusByQuoteSID,
    quotesBySID,
    nextSID,
    previousSID,
  ]);

  const { data: company } = useCompany(companySID);

  const children = useOutlet(childContext);
  const currentPath = useResolvedPath(".");
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef();
  const toggleModal = useCallback(
    (e) => {
      e.preventDefault();
      setShowModal(!showModal);
    },
    [showModal],
  );
  const onClickDelete = useCallback((e: React.SyntheticEvent<HTMLAnchorElement>) => {
    setShowModal(true);
  }, []);

  if (loading) {
    return (
      <div>
        <Skeleton height="30px" width="100%" radius={6} />
        <div style={{ height: "15px" }} />
        <Skeleton height="245px" width="100%" radius={6} />
      </div>
    );
  }
  if (!contract) {
    return <>Error no contract</>;
  }
  const { companyLocation, companyContracts } = contract;
  const companyContract =
    (companyContracts && companyContracts.length > 0 && companyContracts[0]) || undefined;

  return (
    <VStack as={TableContainer}>
      <VStack spacing={8}>
        <HStack as={StatusBar} align="end">
          <HStack spacing={8}>
            <Button variant="tertiary" size="small">
              <a href={`/dashboard/companies/${companySID}/searches`} onClick={handleOnClick}>
                {company?.name}
              </a>
            </Button>
            {contract.companyContracts.map((companyContractInner, i) => {
              const { latestStatus } = companyContractInner;
              if (!latestStatus) {
                return <EmDash />;
              }
              return (
                <StatusCell
                  connectionStatusChange={latestStatus}
                  key={`status-${latestStatus.sid || i}`}
                />
              );
            })}
          </HStack>
          <ExpandFlex />
          <HStack spacing={8} align="center">
            <Button variant="tertiary" size="small">
              <a href={`/dashboard/companies/${companySID}/searches`} onClick={handleOnClick}>
                <Icon icon="arrowLeft" size={16} />
              </a>
            </Button>
            <Button variant="tertiary" size="small">
              <Icon icon="arrowRight" size={16} />
            </Button>
          </HStack>
        </HStack>
        <VStack as={LabelRowTop} spacing={12}>
          <Separator />
          <HStack width="full">
            <AddressHeading companyLocation={companyLocation} />
            <ExpandFlex />
            <HStack spacing={8} align="center" as="div">
              {companyContract && companyContract.internetServicePlan && (
                <Button variant="tertiary">
                  <a
                    href={getConnectUrl(
                      window.location,
                      PortalOrigin.Connect,
                      `/quoteRequests/${companyContract.internetServicePlan?.quoteRequest?.sid}/results`,
                    )}
                    target="_blank"
                    rel="noreferrer">
                    View quote
                  </a>
                </Button>
              )}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button icon="overflowVertical" variant="tertiary" arrangement="leading-icon">
                    <Icon
                      icon="chevronDown"
                      color={{ light: "gray-500", dark: "gray-300" }}
                      size={8}
                    />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent sideOffset={0} side="bottom" align="start">
                  <OpenDrawerLink path="edit">
                    <DropdownMenuItem icon="pencil">Edit</DropdownMenuItem>
                  </OpenDrawerLink>
                  <Link to={currentPath} onClick={onClickDelete}>
                    <DropdownMenuItem icon="delete">Delete</DropdownMenuItem>
                  </Link>
                </DropdownMenuContent>
              </DropdownMenu>
            </HStack>
          </HStack>
        </VStack>
        <VStack>
          <HStack as={LabelRow}>
            <MiddleBar numChildren="2">
              <ContactForm
                quoteRequest={
                  quoteWithRequests.length > 0 ? quoteWithRequests[0].quoteRequest : undefined
                }
              />
              <MinList>
                <MinListItemHeader icon="document">
                  <MinListTitle>Contract Status</MinListTitle>
                </MinListItemHeader>
                {contractsByStatus.primary && (
                  <MinListItemPair>
                    <MinListItemLabel>Primary</MinListItemLabel>
                    <MinListItemValue>
                      <EditStatus companyContract={contractsByStatus.primary} />
                    </MinListItemValue>
                  </MinListItemPair>
                )}
                {contractsByStatus.secondary && (
                  <MinListItemPair>
                    <MinListItemLabel>Secondary</MinListItemLabel>
                    <MinListItemValue>
                      <EditStatus companyContract={contractsByStatus.secondary} />
                    </MinListItemValue>
                  </MinListItemPair>
                )}
              </MinList>
            </MiddleBar>
          </HStack>
          <DataView
            buttons={
              <OpenDrawerLink path="quotes/new">
                <Button
                  icon="plusCircle"
                  variant="tertiary"
                  arrangement="leading-label"
                  size="medium">
                  New Quote
                </Button>
              </OpenDrawerLink>
            }
            columns={columns}
            data={quoteWithRequests}
            noWrapColumns={columns.map((c) => c.id)}
            ignoreRowClick
            header={
              <Header>
                <Text
                  size={14}
                  lineHeight={20}
                  as={H3}
                  color={{ light: "gray-800" }}
                  weight="medium">
                  Quotes list
                </Text>
              </Header>
            }
            lastColumnVariant="lastTdSticky-NoArrow"
            theme="light"
          />
        </VStack>
      </VStack>
      <Drawer key={`drawer-${quoteSID}`}>{children}</Drawer>
      <Modal onWantsToClose={toggleModal} in={showModal} forwardedRef={modalRef}>
        {({ close, setModalIsBlocked }) => (
          <ConfirmDeletionModal
            close={close}
            companySID={companySID}
            setModalIsBlocked={setModalIsBlocked}
            companyLocation={companyLocation}
          />
        )}
      </Modal>
    </VStack>
  );
}

Search.whyDidYouRender = true;
