/* eslint-disable react/display-name */
import React from "react";
import Login from "./pages/Login";
import NewQuote from "./pages/NewQuote";
import EditQuote from "./pages/EditQuote";
import Providers from "./pages/Providers";
import NewProvider from "./pages/Providers/NewProvider";
import EditProvider from "./pages/Providers/EditProvider";
import QuoteRequest from "./pages/QuoteRequest";
import QuoteRequests from "./pages/QuoteRequests";
import Settings from "./pages/Settings";
import { Redirect } from "./Redirect";

const routes = {
  editQuote: {
    path: "/quoteRequests/:quoteRequestSid/quotes/:sid/edit",
    component: EditQuote,
    exact: true,
    authenticate: true,
  },
  newQuote: {
    path: "/quoteRequests/:sid/quotes/new",
    component: NewQuote,
    exact: true,
    authenticate: true,
  },
  quoteRequest: {
    path: "/quoteRequests/:sid",
    component: QuoteRequest,
    exact: true,
    authenticate: true,
  },
  quoteRequests: {
    path: "/quoteRequests",
    component: QuoteRequests,
    exact: true,
    authenticate: true,
  },
  providers: {
    path: "/providers",
    component: Providers,
    exact: true,
    authenticate: true,
  },
  new_provider: {
    path: "/providers/new",
    component: NewProvider,
    exact: true,
    authenticate: true,
  },
  edit_provider: {
    path: "/providers/:sid/edit",
    component: EditProvider,
    exact: true,
    authenticate: true,
  },
  settings: {
    path: "/settings",
    component: Settings,
    exact: true,
    authenticate: true,
  },
  login: {
    path: "/login",
    component: Login,
    exact: true,
    authenticate: false,
  },
  adminRoot: {
    path: "/",
    component: () => <Redirect routeName="quoteRequests" />,
    exact: true,
    authenticate: true,
  },
};

export default routes;
