import React, { useState } from "react";

import { Quote, QuoteRequest } from "@meterup/api";
import { Option } from "@meterup/metric/dist/components/Select/Select";
import { ClearIndicator } from "@meterup/ui/src/components/Select/ClearIndicator";
import { DropdownIndicator } from "@meterup/ui/src/components/Select/DropdownIndicator";
import { useCustomStyles } from "@meterup/ui/src/components/Select/hooks/useCustomStyles";
import SingleValue from "@meterup/ui/src/components/Select/SingleValue";
import Select from "react-select";

import CopyQuoteButton from "./CopyQuoteButton";

type CopyQuoteToOtherQuoteRequestProps = {
  addressOptions: Option[];
  quoteRequest: QuoteRequest;
  quote: Quote;
};

export default function CopyQuoteToOtherQuoteRequest({
  addressOptions,
  quote,
  quoteRequest,
}: CopyQuoteToOtherQuoteRequestProps) {
  const [quoteRequestToCopyTo, setQuoteRequestToCopyTo] = useState<Option>(null);
  const onSelectQuoteRequestToCopyTo = (value: Option) => {
    setQuoteRequestToCopyTo(value);
  };
  const customStyles = useCustomStyles<Option, false>({
    size: "small",
    showIndicator: true,
    controlPositioning: false,
  });

  return (
    <div style={{ minWidth: "220px" }}>
      <Select
        options={addressOptions}
        value={quoteRequestToCopyTo}
        isLoading={addressOptions.length === 0}
        onChange={onSelectQuoteRequestToCopyTo}
        menuPlacement="auto"
        menuPosition="fixed"
        placeholder="Select address"
        menuPortalTarget={document.body}
        isClearable
        components={{ DropdownIndicator, ClearIndicator, SingleValue }}
        styles={customStyles}
      />
      <CopyQuoteButton
        disabled={!quoteRequestToCopyTo}
        quote={quote}
        quoteRequestSid={quoteRequestToCopyTo?.value || quoteRequest.sid}
        title="Copy to Different Address"
      />
    </div>
  );
}
