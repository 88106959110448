import { Box } from "@meterup/metric";

import { TabsBarContainer } from "../../components/Tabs/TabsBarContainer";
import { styled } from "../../styled";
import Header from "./Header";

export const BaseDataViewWrapper = styled(Box, {
  boxShadow: "$fenceBottomLight",
  padding: "0 $20",

  [`& ${TabsBarContainer}`]: {
    marginTop: "$14",

    "& + div + div": {
      marginTop: 0,
    },
  },

  [`& ${Header}`]: {
    margin: "$14 0",
  },
});
