import React from "react";

export default function withIconValueContainer<T>(
  icon: IconType,
  Component: React.ComponentType<T>,
): React.ComponentType<T> {
  function WithIconValueContainer(props: T) {
    return <Component icon={icon} {...props} />;
  }

  WithIconValueContainer.displayName = `WithIconValueContainer(${
    Component.displayName || Component.name
  })`;

  return WithIconValueContainer;
}
