import React, { SyntheticEvent, useCallback, useMemo, useState } from "react";

import { useModal } from "@meterup/react-aria";
import { CSSTransition } from "react-transition-group";

import { styled } from "../styled";
import { DefaultPreventable } from "../utils/eventHandlers";
import { Portal } from "./Portal";

const ModalContainer = styled("div", {
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  inset: 0,
  zIndex: 2,
  background:
    "radial-gradient(50% 50% at 50% 50%, rgba(9, 11, 20, 0.1) 0%, rgba(9, 11, 20, 0.25) 100%)",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  display: "flex",

  variants: {
    state: {
      entering: {
        transition: "opacity 300ms",
      },
      entered: {
        opacity: 1,
        transition: "opacity 300ms",
      },
      exiting: {
        opacity: 0,
        transition: "opacity 300ms",
      },
      exited: {
        opacity: 0,
        transition: "opacity 300ms",
      },
    },
  },
});

type ModalChildProps = {
  close: () => void;
  setModalIsBlocked: (isBlocked: boolean) => void;
};

type ModalProps = {
  children: (props: ModalChildProps) => React.ReactNode;
  in?: boolean;
  onWantsToClose: (e: DefaultPreventable) => void;
  forwardedRef?: React.Ref<undefined | HTMLElement>;
};

export type BaseModalProps = {
  close: (e: SyntheticEvent) => void;
  setModalIsBlocked: (isBlocked: boolean) => void;
};

export default function Modal({
  children: childrenFn,
  in: inVar,
  onWantsToClose,
  forwardedRef,
}: ModalProps) {
  const [modalIsBlocked, setModalIsBlocked] = useState(false);
  const { modalProps } = useModal();
  const wantsToClose = useCallback(
    (e?: DefaultPreventable) => {
      console.log("wants to close", e, "modalIslocked", modalIsBlocked);
      if (!modalIsBlocked) {
        return onWantsToClose(e || { preventDefault: () => null });
      }
      return undefined;
    },
    [modalIsBlocked, onWantsToClose],
  );
  const clickOutsideModal = useCallback(
    (e: SyntheticEvent<MouseEvent>) => {
      const { target, currentTarget } = e;
      if (target === currentTarget) {
        wantsToClose(e);
        // closeModal(e);
      }
    },
    [wantsToClose],
  );
  const children = useMemo(
    () =>
      childrenFn({
        close: wantsToClose,
        setModalIsBlocked,
      }),
    [childrenFn, wantsToClose],
  );

  return (
    <Portal>
      <CSSTransition nodeRef={forwardedRef} in={inVar} timeout={300} unmountOnExit>
        {(transitionState) => (
          <ModalContainer
            state={transitionState}
            ref={forwardedRef}
            {...modalProps}
            onClick={clickOutsideModal}>
            {children}
          </ModalContainer>
        )}
      </CSSTransition>
    </Portal>
  );
}
