import { useMemo } from "react";

import { useSearchParams } from "react-router-dom";

export enum UserTabItems {
  All = "all",
  Admin = "admin",
  Member = "member",
}

export type UserTabItemsType = typeof UserTabItems;
export type UserTabItemKeys = keyof UserTabItemsType;
export type UserTabItemValues = typeof UserTabItems[keyof typeof UserTabItems];
export type UserTabItemsByValType = { [key in UserTabItemValues]: UserTabItemKeys };

export const UserTabItemsByVal: UserTabItemsByValType = Object.keys(UserTabItems).reduce(
  (acc, key) => {
    acc[UserTabItems[key as UserTabItemKeys] as UserTabItemValues] = key as UserTabItemKeys;
    return acc;
  },
  {} as UserTabItemsByValType,
);

export const QueryParam = "role";

export default function useActiveUserTab() {
  const [searchParams] = useSearchParams();
  return useMemo(() => {
    const filterValue = searchParams.get(QueryParam);
    let activeTabValue = UserTabItems.All;
    if (filterValue && activeTabValue in UserTabItemsByVal) {
      activeTabValue = filterValue as UserTabItemValues;
    }

    return activeTabValue;
  }, [searchParams]);
}

export const VisibleTabs = [UserTabItems.All, UserTabItems.Admin, UserTabItems.Member] as const;
