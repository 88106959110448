import React, { useCallback, useMemo, useReducer, useRef, useState } from "react";

import { Alert, Box, Drawer as DrawerMetric, ListItem, VStack } from "@meterup/metric";
import delay from "lodash/delay";
import { CSSTransition } from "react-transition-group";

import { styled } from "../../styled";
import { DrawerContext, DrawerContextProps } from "./drawerContext";
import { DrawerProps } from "./DrawerProps";

const DataViewWithDrawer = styled("div", {
  flexDirection: "row",
  variants: {
    // @awhitty: is there a better way to do this grow animation?
    state: {
      entering: {
        "& > :first-child": {
          flex: 3,
        },
        "& > :last-child": {
          flex: 1,
          width: 1,
        },
      },
      entered: {
        "& > :first-child": {
          flex: 3,
        },
        "& > :last-child": {
          flex: 1,
          width: "unset",
          // minWidth: "300px",
          minWidth: "360px",
        },
      },
      exiting: {
        "& > :first-child": {
          flex: 1,
        },
        "& > :last-child": {
          flex: 0.001,
        },
      },
      exited: {
        "& > :first-child": {
          flex: 1,
        },
        "& > :last-child": {
          width: 0,
        },
      },
    },
  },
  "& > :first-child": {
    transition: "flex 0.5s ease-in-out",
  },
  "& > :last-child": {
    // display: "none",
    flexBasis: 0,
    flexGrow: 0,
    transition:
      "display 0.5s ease-in-out, flex 0.5s ease-in-out, flex-grow 0.5s ease-in-out, flex-basis 0.5s ease-in-out",
    // width: "unset",
    maxWidth: "unset",
    width: 0,
    overflow: "hidden",

    [`& ${ListItem}`]: {
      [`&:last-child:not(${Alert})`]: {
        paddingBottom: "$12",
      },
    },
  },
});

enum ActionType {
  SET_CLOSED = "SET_CLOSED",
  SET_CLOSING = "SET_CLOSING",
  SET_OPEN = "SET_OPEN",
  SET_OPENING = "SET_OPENING",

  ON_ENTERED = "ON_ENTERED",
  ON_ENTERING = "ON_ENTERING",
  ON_EXITED = "ON_EXITED",
}

type ResolveReject = {
  resolve: () => void;
  reject: () => void;
};

interface State {
  hasMounted: boolean;
  isOpen: boolean;
  isClosing: boolean;
  isOpening: boolean;
  resolveRejectClose?: ResolveReject;
  resolveRejectOpen?: ResolveReject;
}

type ActionTypeWrapper<T extends ActionType> = { type: T };
type ActionWrapper<T extends ActionType, V> = ActionTypeWrapper<T> & {
  type: T;
  payload: V;
};

type ResolveRejectPayload = {
  resolveReject?: ResolveReject;
};

type IsAppearingPayload = {
  isAppearing: boolean;
};

type ForceOpenPayload = {
  force: boolean;
  forceOnFirstMount?: boolean;
} & Partial<IsAppearingPayload>;

type Action =
  | ActionWrapper<ActionType.SET_CLOSED, ForceOpenPayload>
  | ActionWrapper<ActionType.SET_OPEN, ForceOpenPayload>
  | ActionWrapper<ActionType.SET_CLOSING, ResolveRejectPayload>
  | ActionWrapper<ActionType.SET_OPENING, ResolveRejectPayload>
  | ActionWrapper<ActionType.ON_ENTERED, IsAppearingPayload>
  | ActionWrapper<ActionType.ON_ENTERING, IsAppearingPayload>
  | ActionTypeWrapper<ActionType.ON_EXITED>;

function doResolve(resolveReject?: ResolveReject) {
  return delay(
    (r?: ResolveReject) => {
      if (r) {
        r.resolve();
      }
    },
    0,
    resolveReject,
  );
}
function reducer(state: State, action: Action): State {
  const { resolveRejectClose, resolveRejectOpen, ...restState } = state;
  const { hasMounted, isOpen, isClosing, isOpening } = restState;
  switch (action.type) {
    case ActionType.SET_CLOSED:
      if (!isOpen && !isClosing && !resolveRejectClose && !action.payload.force) {
        return state;
      }
      doResolve(resolveRejectClose);
      return {
        ...restState,
        hasMounted: true,
        isOpen: false,
        isClosing: false,
        isOpening: false,
      };
    case ActionType.SET_CLOSING:
      if (isClosing && !isOpen && !isOpening) {
        return state;
      }
      return {
        ...restState,
        isOpen: false,
        isClosing: true,
        isOpening: false,
        resolveRejectClose: action.payload.resolveReject,
      };
    case ActionType.SET_OPEN:
      // console.log("SET_OPEN", action, state)
      if (
        !action.payload.force &&
        // !(action.payload.forceOnFirstMount && !hasMounted && action.payload.isAppearing) &&
        !(action.payload.forceOnFirstMount && !hasMounted && !action.payload.isAppearing)
      ) {
        if (isOpening || isClosing) {
          // console.log("SET_OPEN short circuit", action, state);
          return state;
        }
      }
      doResolve(resolveRejectOpen);
      // }
      return {
        ...restState,
        isOpen: true,
        isClosing: false,
        isOpening: false,
        hasMounted:
          action.payload.isAppearing ||
          hasMounted ||
          !!(!hasMounted && action.payload.forceOnFirstMount),
      };
    case ActionType.SET_OPENING:
      if (!isClosing && isOpen && isOpening) {
        // console.log("SET_OPENING short circuiting 1", action, state);
        return state;
      }
      if (isOpen && hasMounted) {
        // console.log("SET_OPENING short circuiting 2", action, state);
        doResolve(action.payload.resolveReject);
        return {
          ...restState,
          isOpen: true,
          isClosing: false,
          isOpening: false,
        };
      }
      return {
        ...restState,
        isOpen: true,
        isClosing: false,
        isOpening: true,
        resolveRejectOpen: action.payload.resolveReject,
      };
    case ActionType.ON_ENTERING:
      if (!isClosing && isOpen && isOpening && hasMounted) {
        return state;
      }
      return {
        ...restState,
        isOpen: true,
        isOpening: true,
        isClosing: false,
        hasMounted: true,
      };
    case ActionType.ON_ENTERED:
      if (!isClosing && isOpen && !isOpening && hasMounted) {
        return state;
      }
      return {
        ...restState,
        isOpen: true,
        isOpening: false,
        isClosing: false,
        hasMounted: true,
      };
    default:
      console.error("Unhandled action", action);
      throw new Error(`Unhandled action: ${action}`);
  }
}

export function DrawerProvider({ children, isOpen = false }: DrawerProps) {
  const [state, dispatch] = useReducer(reducer, {
    isOpen,
    isClosing: false,
    hasMounted: false,
    isOpening: false,
  });
  const parentRef = useRef<HTMLDivElement>(null);
  const [content, setContent] = useState<React.ReactNode>(null);
  const drawerProps = useMemo<DrawerContextProps>(
    () => ({
      openDrawer() {
        return new Promise((resolve, reject) => {
          dispatch({
            type: ActionType.SET_OPENING,
            payload: { resolveReject: { resolve, reject } },
          });
        });
      },
      closeDrawer() {
        return new Promise((resolve, reject) => {
          dispatch({
            type: ActionType.SET_CLOSING,
            payload: { resolveReject: { resolve, reject } },
          });
        });
      },
      drawerIsOpen: state.isOpen,
      isOpening: state.isOpening,
      isClosing: state.isClosing,
      setContent(newContent: React.ReactNode, autoOpenClose?: boolean) {
        setContent(newContent);
        console.log(
          "[DrawerProvider] setContent::autoOpenClose",
          autoOpenClose,
          "newContent",
          newContent,
        );
        if (autoOpenClose) {
          if (newContent) {
            dispatch({
              type: ActionType.SET_OPEN,
              payload: { force: false, forceOnFirstMount: true },
            });
          } else {
            dispatch({ type: ActionType.SET_CLOSING, payload: {} });
          }
        }
      },
    }),
    [state.isOpen, state.isOpening, state.isClosing],
  );
  const onEntered = useCallback((isAppearing: boolean) => {
    dispatch({ type: ActionType.SET_OPEN, payload: { force: false, isAppearing: !isAppearing } });
  }, []);
  const onExited = useCallback(() => {
    dispatch({ type: ActionType.SET_CLOSED, payload: { force: true } });
  }, []);
  const onEntering = useCallback((isAppearing: boolean) => {
    dispatch({ type: ActionType.SET_OPEN, payload: { force: false, isAppearing: !isAppearing } });
  }, []);

  return (
    <DrawerContext.Provider value={drawerProps}>
      <Box display="flex" height="full" width="full" overflow={{ all: "auto" }}>
        <CSSTransition
          in={state.isOpen}
          timeout={510}
          nodeRef={parentRef}
          onEntered={onEntered}
          onEntering={onEntering}
          unmountOnExit={false}
          onExited={onExited}>
          {(transitionState) => (
            <Box
              as={DataViewWithDrawer}
              ref={parentRef}
              state={transitionState}
              height="full"
              width="full"
              display="flex">
              <Box as={VStack} display="flex" height="full" width="full" overflow={{ all: "auto" }}>
                {children}
              </Box>
              <DrawerMetric>
                <Box overflow={{ y: "auto" }}>{content}</Box>
              </DrawerMetric>
            </Box>
          )}
        </CSSTransition>
      </Box>
    </DrawerContext.Provider>
  );
}

DrawerProvider.whyDidYouRender = true;
