import React, { useMemo } from "react";

import { Provider, useApiResource, useApiResources } from "@meterup/api";
import { Badge } from "@meterup/metric";

import ProvidersAvailabilitySection from "./ProvidersAvailabilitySection";

interface ProvidersAvailabilityProps {
  locationSid: string;
}

export default function ProvidersAvailability({ locationSid }: ProvidersAvailabilityProps) {
  const [locationLoading, location, reloadLocation] = useApiResource(
    `admin/location-provider-availabilities/locations/${locationSid}`,
  );

  const [providersLoading, providers, reloadProviders] = useApiResources("providers", "providers");

  const getProvidersBySids = (
    location: any,
    availability: "location_on_net" | "location_near_net" | "location_off_net",
    providers: Provider[],
  ) => {
    const providerSids = location.providerAvailabilities.reduce(
      (sids: string[], providerAvailability: any) => {
        if (providerAvailability.availability == availability) {
          sids.push(providerAvailability.providerSid);
        }
        return sids;
      },
      [],
    );

    return providers.filter((provider) => providerSids.includes(provider.sid));
  };

  const getAllAvailableProviders = (location: any, providers: Provider[]) => {
    const providerSids = location.providerAvailabilities.map(
      (providerAvailability: any) => providerAvailability.providerSid,
    );

    return providers.filter((provider) => !providerSids.includes(provider.sid));
  };

  // Providers filtered by availability
  const providersOnNet = useMemo(() => {
    if (providersLoading || locationLoading || !providers || !location) {
      return [];
    }
    return getProvidersBySids(location, "location_on_net", providers);
  }, [providersLoading, providers, locationLoading, location]);

  const providersNearNet = useMemo(() => {
    if (providersLoading || locationLoading || !providers || !location) {
      return [];
    }
    return getProvidersBySids(location, "location_near_net", providers);
  }, [providersLoading, providers, locationLoading, location]);

  const providersOffNet = useMemo(() => {
    if (providersLoading || locationLoading || !providers || !location) {
      return [];
    }
    return getProvidersBySids(location, "location_off_net", providers);
  }, [providersLoading, providers, locationLoading, location]);

  // Providers without a specific set availability
  const providersAvailable = useMemo(() => {
    if (providersLoading || locationLoading || !providers || !location) {
      return [];
    }

    return getAllAvailableProviders(location, providers);
  }, [providersLoading, providers, locationLoading, location]);

  if (!reloadLocation || providersLoading || locationLoading || !providers || !location) {
    return null;
  }
  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="bg-gray-50 border border-gray-100 rounded-lg">
        <header className="p-4 border-b border-gray-100">
          <Badge variant="positive" size="medium">
            On-net
          </Badge>
        </header>
        <ProvidersAvailabilitySection
          locationSid={locationSid}
          reloadLocation={reloadLocation}
          providers={providersOnNet}
          providersAvailable={providersAvailable}
          availability="location_on_net"
        />
      </div>
      <div className="bg-gray-50 border border-gray-100 rounded-lg">
        <header className="p-4 border-b border-gray-100">
          <Badge variant="attention" size="medium">
            Near-net
          </Badge>
        </header>
        <ProvidersAvailabilitySection
          locationSid={locationSid}
          reloadLocation={reloadLocation}
          providers={providersNearNet}
          providersAvailable={providersAvailable}
          availability="location_near_net"
        />
      </div>
      <div className="bg-gray-50 border border-gray-100 rounded-lg">
        <header className="p-4 border-b border-gray-100">
          <Badge variant="negative" size="medium">
            Off-net
          </Badge>
        </header>
        <ProvidersAvailabilitySection
          locationSid={locationSid}
          reloadLocation={reloadLocation}
          providers={providersOffNet}
          providersAvailable={providersAvailable}
          availability="location_off_net"
        />
      </div>
    </div>
  );
}
