import { css, styled } from "../styled";

const numChildrenVariants = [...Array(8).keys()].reduce((acc, i) => {
  const nextVariant = {
    [`${i}`]: {
      gridTemplateColumns: `repeat(${i}, 1fr)`,
    },
  };

  return { ...acc, ...nextVariant };
}, {});

export const HorizontalPanelRow = styled("div", {
  display: "grid",
  gap: "1px",

  variants: {
    numChildren: numChildrenVariants,
  },

  defaultVariants: {
    numChildren: "1",
  },
});
