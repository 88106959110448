import React, { useMemo } from "react";
import dayjs from "dayjs";

interface FormattedDateProps {
  value: string | Date | dayjs.Dayjs;
  format?: string;
  withTime?: boolean;
  withHumanFormatting?: boolean;
}

const FormattedDate: React.FunctionComponent<FormattedDateProps> = ({
  value,
  format,
  withTime = false,
  withHumanFormatting = false,
}) => {
  const momentValue = useMemo(() => dayjs(value), [value]);

  let formattingString;
  if (withHumanFormatting && momentValue.isSame(dayjs(), "day")) {
    formattingString = format || "HH:MM:ss";
  } else if (withHumanFormatting && momentValue.isSame(dayjs().subtract(1, "day"), "day")) {
    formattingString = "[yesterday], HH:MM:ss";
  } else {
    formattingString = format || (withTime ? "MMM D, YYYY. HH:MM:ss" : "MMM D, YYYY");
  }

  return <>{momentValue.format(formattingString)}</>;
};

export default FormattedDate;
