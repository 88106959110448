import React, { useMemo } from "react";

import { ColumnSizingState } from "@tanstack/react-table";

type DataViewContentProps = {
  children?: React.ReactNode;
  columnSizingState?: ColumnSizingState;
  renderContent?: (columnSizingState: ColumnSizingState) => React.ReactNode;
};

export default function DataViewContent({
  children: childrenProp,
  columnSizingState,
  renderContent,
}: DataViewContentProps) {
  console.log("I'm a DataViewContent component!");
  const children = useMemo(() => {
    if (renderContent) {
      return renderContent(columnSizingState);
    }
    return childrenProp;
  }, [childrenProp, columnSizingState, renderContent]);
  return <>{children}</>;
}
