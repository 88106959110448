import React from "react";

import { HStack, Icon, IconName, LoadingIcon } from "@meterup/metric";
import { styled } from "@meterup/ui";
import { components, ValueContainerProps } from "react-select";

const ValueContainerWrapper = styled("div", {
  paddingLeft: "$8",
  "& > div": {
    paddingLeft: 0,
  },

  "& input, & #react-select-3-placeholder": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  "& input": {
    // 30px is 16px for icon + 6px spacing + 8px padding
    // maxWidth: "calc(100% - 30px)",
  },

  "& #react-select-3-placeholder": {
    maxWidth: "100%",
  },
});

type IconValueContainerProps<T, Q> = ValueContainerProps<T, Q> & {
  icon: IconName;
};

export function IconValueContainer<T>(props: IconValueContainerProps<T, false>) {
  const { children, icon, ...rest } = props;
  return (
    <HStack as={ValueContainerWrapper} spacing={6} align="center">
      {rest.selectProps.isLoading ? <LoadingIcon size={16} /> : <Icon icon={icon} size={16} />}
      <components.ValueContainer {...rest}>{children}</components.ValueContainer>
    </HStack>
  );
}
