import React, { useCallback } from "react";

import { BasicSelect, BasicSelectItem, SelectProps } from "@meterup/metric";
import first from "lodash/first";
import { FieldRenderProps } from "react-final-form";

// Mixed interface between React Final Form and our pure SelectForm
interface SelectFieldProps extends FieldRenderProps<string>, SelectProps {}

// TODO: Can we move this to the fornt-end component library?
export default function SelectField({
  // React Final Form
  input,
  meta,
  placeholder,

  // Frontend components
  size,
  options,
  disabled,
}: SelectFieldProps) {
  // TODO: The error mark up should not be here
  const displayError = meta.error && meta.touched;
  const onChange = useCallback(
    (val: string) => {
      input.onChange(val);
    },
    [input],
  );

  return (
    <div>
      <BasicSelect
        name={input.name}
        // onChange={input.onChange}
        onValueChange={onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        value={input.value || first(options)?.value || ""}
        disabled={disabled}
        placeholder={placeholder}
        size={size}
        width="full"
        hasError={displayError}>
        {options.map(({ label, value }) => (
          <BasicSelectItem value={value} key={value}>
            {label}
          </BasicSelectItem>
        ))}
      </BasicSelect>
      {displayError && <span className="mt-2 ml-2 text-xs text-red-600">{meta.error}</span>}
    </div>
  );
}
