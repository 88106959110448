import lodashGet from "lodash/get";
import lodashIsFunction from "lodash/isFunction";

export function get<T, K extends keyof T>(object: T | undefined, key: K): T[K] | undefined {
  return lodashGet(object, key);
}

export function isFunction<T extends (...args: any[]) => any>(value: any): value is T {
  return lodashIsFunction(value);
}
