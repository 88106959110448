import React from "react";

import { Skeleton } from "@meterup/metric";
import { LoadingWrapper } from "../styles/LoadingWrapper";

export default function LoadingLayout() {
  return (
    <LoadingWrapper>
      <Skeleton height="30px" width="205px" radius={6} />
      <div style={{ height: "15px" }} />
      <Skeleton height="245px" width="100%" radius={6} />
    </LoadingWrapper>
  );
}
