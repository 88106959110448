import { useCallback, useState } from "react";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const dateFormatter = new Intl.DateTimeFormat("en", {
  month: "short",
  day: "numeric",
  year: "numeric",
});

function pbDateFormatter(fmt: Intl.DateTimeFormat, pbDate?: string) {
  if (!pbDate) {
    return pbDate;
  }
  const parsed = new Date(Date.parse(pbDate));

  if (parsed.getFullYear() < 1900) {
    return undefined;
  }

  return fmt.format(new Date(Date.parse(pbDate)));
}

function centsFormatter(cents: number) {
  return currencyFormatter.format(cents / 100);
}

export type UseFormattersType = {
  pbDateFormatter: (pbDate?: string) => string | undefined;
  currencyFormatter: Intl.NumberFormat;
  centsFormatter: (cents: number) => string;
  dateFormatter: Intl.DateTimeFormat;
};

// TODO: In the future we can allow localization here.
export default function useFormatters(): UseFormattersType {
  const [pbDateFormatterState] = useState(() => pbDateFormatter);
  const localPbDateFormatter = useCallback(
    (fmt: Intl.DateTimeFormat) => (pbDate?: string) => pbDateFormatterState(fmt, pbDate),
    [pbDateFormatterState],
  );

  return {
    centsFormatter,
    currencyFormatter,
    dateFormatter,
    pbDateFormatter: localPbDateFormatter(dateFormatter),
  };
}
