import { styled } from "../../styled";

export const Wrapper = styled("div", {
  height: "100vh",
  width: "100%",
  display: "grid",
  position: "relative",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "min-content minmax(0, 1fr)",
  gridTemplateAreas: '"header" "content"',
});

export const ContentWrapper = styled("div", {
  gridArea: "content",
  // overflow: "auto",
  zIndex: 1,
  height: "100%",
  width: "100%",
  backgroundColor: "#fff",
  overflow: "hidden",
  display: "flex",
});

export const NewWrapper = styled("div", {
  display: "grid",
  gridTemplateColumns: "260px 1fr",
  gridTemplateRows: "100vh",
  backgroundColor: "#fff",
  flex: 1,
  // gridGap: "10px",
});

export const NewContentWrapper = styled("div", {
  // gridArea: "content",
  zIndex: 1,
  height: "100%",
  width: "100%",
  backgroundColor: "#fff",
  overflow: "hidden",
  display: "flex",
  // height: "100vh",
  // width: "100%",
  position: "relative",
});

export const ChildrenWrapper = styled("div", {
  flex: 1,
  width: "100%",
  // overflow: "auto",
  overflowX: "hidden",
  overflowY: "auto",
});

export const HeaderWrapper = styled("div", {
  gridArea: "header",
  zIndex: 2,
});
