import snakeCaseKeys from "snakecase-keys";
import { getAPIBaseUrl } from "./config";
import { PortalOrigin } from "./enums";
import verifyAuth from "./verifyAuth";
import { headers } from "./headers";

export const BASE_URL = getAPIBaseUrl(window.location);

export class NotFoundError extends Error {
  public code;

  constructor() {
    super();
    this.code = 404;
  }
}

export class ErrorWithMessage extends Error {
  public message;

  public id;

  constructor(message: string, id: string) {
    super();
    this.message = message;
    this.id = id;
  }
}

/*
type CommonRequestProps = {
  path: string;
  setLoading?: (loading: boolean) => void;
  options?: any;
  via?: PortalOrigin;
}

type RequestProps = {
  method: "GET" | "POST" | "PUT" | "DELETE";
} & CommonRequestProps;

type DataRequestProps<R> = {
  data: R;
} & CommonRequestProps;

export const request = async <T>(
  props: RequestProps,
): Promise<T | undefined> => {
  const { method, options, path, setLoading } = props;
  let { via } = props;
  if (!via) {
    via = PortalOrigin.ConnectAdmin;
  }
 */

export const request = async <T>(
  path: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  setLoading?: (loading: boolean) => void,
  options?: any,
  via: PortalOrigin = PortalOrigin.ConnectAdmin,
): Promise<T | undefined> => {
  if (setLoading) {
    setLoading(true);
  }

  const response = await fetch(`${BASE_URL}/${path}`, {
    method,
    headers,
    ...options,
    credentials: "include",
  });

  if (setLoading) {
    setLoading(false);
  }

  if (response.status === 204) {
    return;
  }

  if (response.status === 403) {
    await verifyAuth(via);
  }

  const json = await response.json();

  if (!response.ok && json.id && json.title) {
    throw new ErrorWithMessage(json.title, json.id);
  } else if (method === "GET" && response.status === 404) {
    throw new NotFoundError();
  } else if (!response.ok && !response.bodyUsed) {
    throw new Error(await response.text());
  } else if (!response.ok) {
    throw new Error(JSON.stringify(response));
  }

  return json;
};

export const get = async <T>(
  path: string,
  setLoading?: (loading: boolean) => void,
): Promise<T | undefined> => request<T>(path, "GET", setLoading);

export const post = async <T, R>(
  path: string,
  data: R,
  setLoading?: (loading: boolean) => void,
  via?: PortalOrigin,
): Promise<T | undefined> => {
  let jsonData: string;
  try {
    jsonData = JSON.stringify(snakeCaseKeys(data));
  } catch (e) {
    return Promise.reject(e);
  }
  return request<T>(path, "POST", setLoading, {
    body: jsonData,
  }, via);
};

export const put = async <T, R>(
  path: string,
  data: Partial<T>,
  setLoading?: (loading: boolean) => void,
): Promise<R | undefined> => {
  let jsonData: string;
  try {
    jsonData = JSON.stringify(snakeCaseKeys(data));
  } catch (e) {
    return Promise.reject(e);
  }
  return request<R>(path, "PUT", setLoading, {
    body: jsonData,
  });
};

export const httpDelete = async <T>(
  path: string,
  setLoading?: (loading: boolean) => void,
): Promise<T | undefined> => request<T>(path, "DELETE", setLoading);

export const httpDeleteWithData = async <T, R>(
  path: string,
  data: R,
  setLoading?: (loading: boolean) => void,
): Promise<T | undefined> => {
  let jsonData: string;
  try {
    jsonData = JSON.stringify(snakeCaseKeys(data));
  } catch (e) {
    return Promise.reject(e);
  }
  return request<T>(path, "DELETE", setLoading, {
    body: jsonData,
  });
};
