import React from "react";

import useActiveUserTab, {
  QueryParam,
  UserTabItems,
  UserTabItemsByVal,
  VisibleTabs,
} from "../../hooks/useActiveUserTypeTab";
import { TabItem } from "./Tabs";
import { TabsBar } from "./TabsBar";

type UserTabBarProps = {
  basePath: string;
  tabCount: Record<UserTabItems, number>;
};
export default function UserTabBar({ basePath, tabCount }: UserTabBarProps) {
  const activeTabValue = useActiveUserTab();

  return (
    <TabsBar>
      {VisibleTabs.map((tab) => {
        const pathParams = new URLSearchParams({
          ...(tab === UserTabItems.All ? {} : { [QueryParam]: tab }),
        }).toString();
        const path = `${basePath}${pathParams.length > 0 ? `?${pathParams}` : ""}`;
        const isActive = tab === activeTabValue;
        return (
          <TabItem path={path} count={tabCount[tab]} isActive={isActive} key={`user-tab-${tab}`}>
            {UserTabItemsByVal[tab]}
          </TabItem>
        );
      })}
    </TabsBar>
  );
}
