import { createContext, useContext } from "react";

import {
  AllContractsResponse,
  CompanyResponse,
  ContractsResponse,
  UsersResponse,
} from "@meterup/api";
import { useOutletContext } from "react-router-dom";

export type CompanyContextType = {
  allContracts?: AllContractsResponse;
  company?: CompanyResponse;
  contracts?: ContractsResponse;
  users?: UsersResponse;
};

export const CompanyContext = createContext<CompanyContextType>({});

export function useCompanyContext() {
  return useContext<CompanyContextType>(CompanyContext);
}

export function useCompanyOutletContext() {
  return useOutletContext<CompanyContextType>();
}
