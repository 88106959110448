import React from "react";

import { Icon } from "@meterup/metric";

import statusToFields, { StatusChipProps } from "./statusToFields";

export default function StatusChip({ status, isManualResponsePending }: StatusChipProps) {
  const fields = statusToFields({ status, isManualResponsePending });
  if (fields.text && fields.bg) {
    return (
      <span
        className={`inline-flex px-2 text-xs font-semibold leading-5 ${fields.text} ${fields.bg} rounded-full`}>
        {status === "contract_requested" && <Icon icon="checkmarkCircle" className="mr-1" />}
        {fields.label}
      </span>
    );
  }

  return null;
}
