import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { CompanyContractsResponse, ISPContract } from "@meterup/api";
import { useInvalidateContracts } from "@meterup/api/src/billing/queryKeys";
import { UseCreateContractErrorType } from "@meterup/api/src/billing/useCreateContract";
import {
  Alert,
  Button,
  CloseDrawerButton,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  HStack,
  PrimaryField,
  PrimaryFieldComposite,
  SecondaryField,
  Text,
  TextInput,
  VStack,
} from "@meterup/metric";
import { CloseDrawerLink } from "@meterup/ui";
import { TextInputWrapper } from "@meterup/ui/src/components/forms/TextInputWrapper";
import { ClearIndicator } from "@meterup/ui/src/components/Select/ClearIndicator";
import { DropdownIndicator } from "@meterup/ui/src/components/Select/DropdownIndicator";
import { getLabel, getValue, StringsOption } from "@meterup/ui/src/components/Select/hooks/Options";
import { useCustomStyles } from "@meterup/ui/src/components/Select/hooks/useCustomStyles";
import { addDays, dtToString } from "@meterup/ui/src/utils/dates";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import Select from "react-select";

import { Separator } from "../../styles/dashboard";
import { FormPurpose, StringsSelectType } from "../../types";
import OverlappingDatesErrorCopy from "../errors/OverlappingDatesErrorCopy";
import UploadDocument from "../files/UploadDocument";
import { InvoiceSchemaFormData } from "../invoices/invoiceSchema";
import { ISPContractFormData, ISPContractSchema } from "./ContractSchema";
import { companyContractsToISPOptions } from "./utils";

type ContractFormProps = {
  contracts?: CompanyContractsResponse[];
  defaultValues?: ISPContractFormData;
  error?: UseCreateContractErrorType;
  ispContractSID?: string;
  onSubmit: SubmitHandler<ISPContractFormData>;
  purpose?: FormPurpose;
  successData?: ISPContract;
  values?: ISPContractFormData;
};

export default function ContractFormDrawer({
  contracts: locationContracts,
  defaultValues: suppliedDefaultValues,
  error,
  ispContractSID,
  onSubmit,
  purpose = "create",
  successData,
  values,
}: ContractFormProps) {
  const ispOptions = useMemo(
    () => companyContractsToISPOptions(locationContracts),
    [locationContracts],
  );
  const defaultValues = useMemo<ISPContractFormData>(() => {
    if (suppliedDefaultValues) {
      return suppliedDefaultValues;
    }
    const d = new Date();
    return {
      contractDates: {
        startDate: dtToString(d),
        endDate: dtToString(addDays(d, 1)),
      },
      ispContract: {
        amountDue: "5",
        internetServicePlanSid: null,
        firstInvoiceDueDate: dtToString(d),
        sid: ispContractSID,
      },
      contractDocument: null,
    };
  }, [ispContractSID, suppliedDefaultValues]);
  const innerValues = useMemo<ISPContractFormData>(() => {
    if (values) {
      return values;
    }
    return defaultValues;
  }, [defaultValues, values]);
  console.log("ContractFormDrawer", { defaultValues, values, innerValues });
  const { control, handleSubmit, reset, watch, getValues, setValue, register, formState } =
    useForm<ISPContractFormData>({
      defaultValues,
      values: innerValues,
      resolver: zodResolver(ISPContractSchema),
    });
  const { isSubmitting, errors } = formState;
  const onDateChange = useCallback(
    (fn: (...event: any[]) => void) => (val: string) => {
      fn(val);
    },
    [],
  );
  useEffect(() => {
    const subscription = watch((v, update) => {
      if (update.name === "contractDates.startDate") {
        const { startDate: prevStartDate, endDate: prevEndDate } = v.contractDates;
        const startDate = new Date(prevStartDate);
        const endDate = new Date(prevEndDate);

        if (startDate >= endDate) {
          const newEndDate = addDays(startDate, 2);
          setValue("contractDates.endDate", dtToString(newEndDate));
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);
  const customStylesProvider = useCustomStyles<StringsOption, false>("medium", true, false);
  const ispSelectRef = useRef<StringsSelectType | null>(null);
  const invalidateContracts = useInvalidateContracts();
  const innerOnSubmit = useMemo<SubmitHandler<ISPContractFormData>>(
    () => async (data) => {
      const retVal = await onSubmit(data);
      if (retVal) {
        invalidateContracts();
        reset(innerValues);
      }
    },
    [innerValues, invalidateContracts, onSubmit, reset],
  );

  return (
    <form onSubmit={handleSubmit(innerOnSubmit)}>
      <DrawerHeader>
        <DrawerTitle>{purpose === "create" ? "Create" : "Update"} Contract</DrawerTitle>
        <DrawerControls>
          <CloseDrawerLink>
            <CloseDrawerButton />
          </CloseDrawerLink>
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        {error ? (
          <Alert
            variant="negative"
            icon="warning"
            heading={error.title || "Something went wrong"}
            copy={
              error.conflicting_contract ? (
                <OverlappingDatesErrorCopy
                  dates={getValues("contractDates")}
                  err={error}
                  getConflictingDates={(err) => [
                    err.conflicting_contract.contract_start_date,
                    err.conflicting_contract.contract_end_date,
                  ]}
                  name="conflicting_contract"
                  label="contract"
                />
              ) : (
                <Text>{error.message}</Text>
              )
            }
          />
        ) : null}
        {successData ? (
          <Alert
            variant="positive"
            icon="checkmarkCircle"
            heading={`Successfully ${purpose === "create" ? "created" : "updated"} contract`}
            copy={`Successfully ${purpose === "create" ? "created" : "updated"} contract with SID ${
              successData?.sid
            }`}
          />
        ) : null}
        <FieldContainer>
          <Controller
            render={({ field, fieldState }) => (
              <PrimaryField
                element={
                  <Select
                    options={ispOptions}
                    value={field.value}
                    isLoading={false}
                    // onBlur={onBlur(form, "provider")}
                    onChange={field.onChange}
                    hasError={!!fieldState.error?.message}
                    styles={customStylesProvider}
                    ref={ispSelectRef}
                    getOptionLabel={getLabel}
                    getOptionValue={getValue}
                    menuPortalTarget={document.body}
                    menuPlacement="bottom"
                    menuPosition="absolute"
                    noOptionsMessage={() => null}
                    placeholder="Select ISP"
                    isClearable
                    components={{ DropdownIndicator, ClearIndicator }}
                  />
                }
                label="ISP to create contract for"
                errorMessage={fieldState.error?.message}
              />
            )}
            name="ispContract.internetServicePlanSid"
            control={control}
          />
          <PrimaryFieldComposite
            label="Dates"
            hasError={!!errors.contractDates}
            errorMessage={errors.contractDates?.message}
            fields={
              <div>
                <VStack>
                  <Controller
                    render={({ field, fieldState }) => (
                      <PrimaryField
                        element={
                          <TextInput
                            type="date"
                            value={field.value}
                            name={field.name}
                            onChange={onDateChange(field.onChange)}
                            hasError={!!fieldState.error}
                          />
                        }
                        label="Contract Start Date"
                        errorMessage={fieldState.error?.message}
                      />
                    )}
                    name="contractDates.startDate"
                    control={control}
                  />
                  <Controller
                    render={({ field, fieldState }) => (
                      <PrimaryField
                        element={
                          <TextInput
                            type="date"
                            value={field.value}
                            name={field.name}
                            onChange={onDateChange(field.onChange)}
                            hasError={!!fieldState.error?.message}
                          />
                        }
                        label="Contract End Date"
                        errorMessage={fieldState.error?.message}
                      />
                    )}
                    name="contractDates.endDate"
                    control={control}
                  />
                  <Controller
                    render={({ field, fieldState }) => (
                      <PrimaryField
                        element={
                          <TextInput
                            type="date"
                            value={field.value}
                            name={field.name}
                            onChange={onDateChange(field.onChange)}
                            hasError={!!fieldState.error?.message}
                          />
                        }
                        label="First Invoice Due Date"
                        errorMessage={fieldState.error?.message}
                      />
                    )}
                    name="ispContract.firstInvoiceDueDate"
                    control={control}
                  />
                </VStack>
              </div>
            }
          />

          <Controller
            render={({ field, fieldState }) => (
              <PrimaryField
                element={
                  <div>
                    <NumericFormat
                      hasError={!!fieldState.error?.message}
                      value={field.value}
                      decimalScale={2}
                      name={field.name}
                      onChange={field.onChange}
                      allowLeadingZeros={false}
                      onBlur={field.onBlur}
                      // fixedDecimalScale
                      thousandSeparator
                      customInput={TextInputWrapper}
                    />
                  </div>
                }
                label="Amount due"
                errorMessage={fieldState.error?.message}
              />
            )}
            name="ispContract.amountDue"
            control={control}
          />

          <UploadDocument
            control={control}
            fieldType={SecondaryField}
            isSubmitting={isSubmitting}
            name="contractDocument"
            setValue={setValue}
          />
        </FieldContainer>
        <Separator />
        <HStack align="end" display="flex" spacing={8} justify="end">
          <CloseDrawerLink onClose={reset}>
            <Button variant="secondary" type="button" loading={isSubmitting}>
              Cancel
            </Button>
          </CloseDrawerLink>
          <Button variant="primary" type="submit" loading={isSubmitting}>
            Save
          </Button>
        </HStack>
      </DrawerContent>
    </form>
  );
}
