import React from "react";

import { Badge, Text, VStack } from "@meterup/metric";
import { Link } from "react-router-dom";

import { ExpandFlex } from "../ExpandFlex";
import { ActiveTabMarker } from "./ActiveTabMarker";
import { TabLabel } from "./TabLabel";

export type TabItemProps = {
  count: number;
  children: React.ReactNode;
  isActive?: boolean;
  path: string;
};

export function TabItem({ count = 0, isActive = false, children, path }: TabItemProps) {
  return (
    <Link to={path}>
      <VStack spacing={10}>
        <ExpandFlex>
          <TabLabel spacing={4} align="center">
            <Text
              size={14}
              lineHeight={20}
              weight="medium"
              color={{ light: isActive ? "brand-700" : "gray-700" }}>
              {children}
            </Text>
            <Badge variant={isActive ? "brand" : "neutral"} size="small">{count}</Badge>
          </TabLabel>
        </ExpandFlex>
        {isActive ? <ActiveTabMarker /> : null}
      </VStack>
    </Link>
  );
}
