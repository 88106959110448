import { useEffect, useState } from "react";

export default function useLocalStorage<P>(
  key: string,
  initialValue?: P,
  poll = false,
): [P | undefined, (value: P) => void] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: P) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {}
  };

  useEffect(() => {
    if (!poll) {
      return;
    }

    const pollingFn = () => {
      const item = window.localStorage.getItem(key);
      const parsedItem = item ? JSON.parse(item) : null;

      if (parsedItem === null) {
        return;
      }

      if (JSON.stringify(storedValue) !== JSON.stringify(parsedItem)) {
        setStoredValue(parsedItem);
      }

      setTimeout(pollingFn, 250);
    };

    setTimeout(pollingFn, 250);
  }, [poll, storedValue, key]);

  return [storedValue, setValue];
}
