/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useCallback, useMemo } from "react";

import type { AxiosAPIError, Quote, QuoteRequest } from "@meterup/api";
import { axios, httpDelete } from "@meterup/api";
import { Badge, Button, Icon } from "@meterup/metric";
import { Option } from "@meterup/metric/dist/components/Select/Select";
import { QuotesRequest } from "@meterup/proto/js/connect_pb";
import { FormattedSpeed, Table, useFlashMessage, useModal } from "@meterup/ui";
import { useQuery } from "@tanstack/react-query";
import { Column } from "@tanstack/react-table";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { route } from "../../routing";
import CopyQuoteButton from "./CopyQuoteButton";
import CopyQuoteToOtherQuoteRequest from "./CopyQuoteToOtherQuoteRequest";
import QuoteOptions from "./QuoteOptions";
import { CellTypeWithMeta } from "./types";

interface QuotesTableProps {
  quoteRequest: QuoteRequest;
}

interface SelectedQuoteBadgeProps {
  backup?: boolean;
}

function SelectedQuoteBadge({ backup = false }: SelectedQuoteBadgeProps) {
  return (
    <span className="px-3 py-1 bg-blue-light text-blue-dark rounded-full">
      {backup ? "Backup" : "Primary"}
    </span>
  );
}

const getSelectedBadge = (selectedQuotes: string[], sid: string) => {
  if (selectedQuotes[0] === sid) {
    return <SelectedQuoteBadge />;
  }
  if (selectedQuotes[1] === sid) {
    return <SelectedQuoteBadge backup />;
  }

  return null;
};

type TableMetaType = {
  addressOptions: Option[];
  quoteRequest: QuoteRequest;
  reloadQuotes: (skipSetLoading?: boolean) => Promise<void>;
};

type CellType = CellTypeWithMeta<Quote, TableMetaType>;

const quotesTableColumns = [
  {
    header: "Provider",
    id: "provider",
    cell: ({
      row: {
        original: { provider },
      },
    }: CellType) => provider.name,
  },
  {
    header: "Availability",
    id: "availability",
    cell: ({
      row: {
        original: { availability },
      },
    }: CellType) => {
      if (!availability) {
        return (
          <Badge variant="neutral" size="medium">
            Unknown
          </Badge>
        );
      }
      if (availability === "location_on_net") {
        return (
          <Badge variant="positive" size="medium">
            On-net
          </Badge>
        );
      }
      if (availability === "location_near_net") {
        return (
          <Badge variant="attention" size="medium">
            Near-net
          </Badge>
        );
      }
      if (availability === "location_off_net") {
        return (
          <Badge variant="negative" size="medium">
            Off-net
          </Badge>
        );
      }
      return (
        <Badge variant="neutral" size="medium">
          Unknown
        </Badge>
      );
    },
  },
  {
    header: "Connection Type",
    id: "connectionType",
    accessorFn: (row: Quote) => row.connectionType?.name,
  },
  {
    header: "Price ($)",
    accessorKey: "monthlyFeeCents",
    cell: ({ getValue }: CellType) => `$${Number(getValue() / 100).toFixed(2)}`,
  },
  {
    header: "Download",
    accessorKey: "downloadKbps",
    cell: ({ getValue }: CellType) => <FormattedSpeed mbps={getValue() / 1024} />,
  },
  {
    header: "Upload",
    accessorKey: "uploadKbps",
    cell: ({ getValue }: CellType) => <FormattedSpeed mbps={getValue() / 1024} />,
  },
  {
    header: "Contract Length (months)",
    accessorKey: "contractLengthMonths",
  },
  {
    header: "Est. Install Date",
    id: "estInstallDate",
    cell: ({ row: { original } }: CellType) =>
      original.installationEstimateDays
        ? dayjs().add(original.installationEstimateDays, "day").format("YYYY-MM-DD")
        : "-",
  },
  {
    header: "Options",
    enableSorting: false,
    id: "options",
    // eslint-disable-next-line react/display-name
    cell: ({
      table: {
        options: { meta },
      },
      row: {
        original: { sid, recommended, routerIncluded, status },
      },
    }: CellType) => (
      <QuoteOptions
        quoteRequestSid={meta.quoteRequest.sid}
        recommended={recommended}
        reloadQuotes={meta.reloadQuotes}
        routerIncluded={routerIncluded}
        sid={sid}
        status={status}
      />
    ),
  },
  {
    header: "Requested",
    enableSorting: false,
    cell: ({
      row: {
        original: { status },
      },
    }: CellType) =>
      status === "requested" ? (
        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-yellow-900 bg-yellow-100 rounded-full">
          Requested
        </span>
      ) : null,
  },
  {
    header: "Manual?",
    enableSorting: false,
    accessorKey: "isManual",
    cell: ({
      row: {
        original: { isManual },
      },
    }: CellType) =>
      isManual ? (
        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-yellow-900 bg-yellow-100 rounded-full">
          Manual
        </span>
      ) : (
        <span className="inline-flex px-2 text-xs font-semibold leading-5 text-blue-900 bg-blue-100 rounded-full">
          Automated
        </span>
      ),
  },
  {
    header: "Selected?",
    id: "selected",
    enableSorting: false,
    cell: ({
      row: {
        original: { sid },
      },
      table: {
        options: { meta },
      },
    }: CellType) => getSelectedBadge(meta.quoteRequest.selectedQuotes, sid),
  },
  {
    header: "",
    id: "copyToNew",
    enableSorting: false,
    cell: ({
      row: { original },
      table: {
        options: { meta },
      },
    }: CellType) => <CopyQuoteButton quote={original} quoteRequestSid={meta.quoteRequest.sid} />,
  },
  {
    header: "",
    id: "copyToNewDifferentAddress",
    enableSorting: false,
    cell: ({
      row: { original },
      table: {
        options: { meta },
      },
    }: CellType) => (
      <CopyQuoteToOtherQuoteRequest
        addressOptions={meta.addressOptions || []}
        quoteRequest={meta.quoteRequest}
        quote={original}
      />
    ),
  },
  {
    header: "",
    id: "edit",
    enableSorting: false,
    cell: ({
      row: {
        original: { sid },
      },
      table: {
        options: { meta },
      },
    }: CellType) => (
      <Link
        muted
        to={route("editQuote", {
          quoteRequestSid: meta.quoteRequest.sid,
          sid,
        })}>
        <Icon icon="pencil" />
      </Link>
    ),
  },
  {
    header: "",
    id: "delete",
    enableSorting: false,
    cell: ({
      row: {
        original: { sid },
      },
      table: {
        options: { meta },
      },
    }: CellType) => (
      <Button
        icon="crossCircle"
        arrangement="hidden-label"
        variant="destructive"
        onClick={(e) => {
          meta.onShowModal(sid);
        }}>
        Delete
      </Button>
    ),
  },
];

export default function QuotesTable({ quoteRequest }: QuotesTableProps) {
  const { data: quoteRequests, dataUpdatedAt: quoteRequestsUpdatedAt } = useQuery<
    unknown,
    AxiosAPIError,
    QuoteRequest[]
  >(["quoteRequests"], () =>
    axios.get("v1/connect/admin/quote-requests").then((d) => d.quoteRequests),
  );
  const {
    data: quotes,
    isLoading: loading,
    refetch: reloadQuotes,
    dataUpdatedAt,
  } = useQuery<unknown, AxiosAPIError, QuotesRequest[]>(
    ["quotes", quoteRequest.sid],
    () => axios.get(`v1/connect/quote-requests/${quoteRequest.sid}/quotes`).then((d) => d.quotes),
    {
      refetchInterval: 30_000,
    },
  );
  const addressOptions: Option[] = useMemo<Option[]>(() => {
    if (!quoteRequests) {
      return [];
    }
    return quoteRequests
      .flatMap((_quoteRequest) => {
        const statePart = [
          _quoteRequest.location?.address?.state,
          _quoteRequest.location?.address?.postalCode,
        ]
          .filter(Boolean)
          .join(" ");
        const label = [
          _quoteRequest.companyName,
          _quoteRequest.location?.address?.address1,
          _quoteRequest.location?.address?.address2,
          _quoteRequest.location?.address?.city,
          statePart,
        ]
          .filter(Boolean)
          .join(", ");
        if (!label) {
          return [];
        }
        return [
          {
            value: _quoteRequest.sid,
            label,
          },
        ];
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [quoteRequestsUpdatedAt]);

  const filteredQuotes = useMemo<Quote[]>(
    () =>
      (quotes || []).flatMap((quote: Quote) =>
        quote.status === "proposed"
          ? []
          : [
              {
                ...quote,
              },
            ],
      ),
    [quotes],
  );

  const flashMessage = useFlashMessage({
    type: "success",
    message: "Successfully deleted quote.",
  });

  const onConfirm = useCallback(
    (sid: string) => {
      httpDelete<Quote>(`admin/quote-requests/${quoteRequest.sid}/quotes/${sid}`).then(() => {
        reloadQuotes().then(() => {
          flashMessage();
          onHideModal();
        });
      });
    },
    [flashMessage, quoteRequest.sid, reloadQuotes],
  );

  const modalProps = useMemo(
    () => ({
      type: "warning",
      title: "Are you sure?",
      message: "Are you sure you'd like to delete this quote? This action is not reversible!",
      confirmText: "Delete Quote",
      onConfirm,
      showCancel: true,
    }),
    [onConfirm],
  );
  const { onShowModal, onHideModal } = useModal(modalProps);

  const meta: TableMetaType = useMemo(
    () => ({
      addressOptions,
      onShowModal,
      quoteRequest,
      reloadQuotes,
    }),
    [addressOptions, onShowModal, quoteRequest, reloadQuotes],
  );

  return (
    <Table
      loading={loading}
      columns={quotesTableColumns as Column<any>[]}
      data={filteredQuotes}
      meta={meta}
    />
  );
}

QuotesTable.whyDidYouRender = true;
