import { css, styled } from "../../styled";
import { InnerTd } from "./InnerTd";
import { TdContentWrapper } from "./TdContentWrapper";

// export const Table = styled("table", {
//   width: "100%",
//   display: "block",
//   overflowX: "hidden",
//   whiteSpace: "nowrap",
//   boxShadow: "$fenceBottomLight",
//   position: "relative",
//
//   "&:hover": {
//     overflowX: "auto",
//
//     "&::-webkit-scrollbar": {
//       "-webkit-appearance": "none",
//     },
//     "&::-webkit-scrollbar:horizontal": {
//       height: "12px",
//     },
//     "&::-webkit-scrollbar:vertical": {
//       width: "12px",
//     },
//     "&::-webkit-scrollbar-track": {
//       backgroundColor: "transparent",
//       borderRadius: "8px",
//     },
//     "&::-webkit-scrollbar-thumb": {
//       borderRadius: "8px",
//       backgroundColor: "rgba(0, 0, 0, .5)",
//       border: "2px solid white",
//     },
//     "&::before": {
//       content: '""',
//       position: "absolute",
//       zIndex: 1,
//       top: 0,
//       right: 0,
//       bottom: 0,
//       width: "12px",
//       pointerEvents: "none",
//       background: "linear-gradient(to right, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0))",
//     },
//   },
// });

export const THead = styled("thead", {
  width: "100%",
});

export const StickyTableHeader = styled("thead", {
  position: "sticky",
  top: 0,
  zIndex: 5,
  background: "$white",
});

export const TBody = styled("tbody", {});

export const Th = css({
  padding: "$6 $8",
  color: "$gray-600",
  textAlign: "left",
  fontWeight: 500,
  fontSize: "$12",
  lineHeight: "8px",
  height: "28px",
  width: "100%",
  boxShadow: "$fenceBottomLight",
  "&:first-child": {
    backgroundColor: "$white",
    width: "247px",
    padding: 0,
    position: "sticky",
    left: 0,
    boxShadow: "$fenceRightLight, $fenceLeftLight, $fenceBottomLight",
    lineHeight: "$16",
  },
  "&:nth-child(2)": {
    boxShadow: "$fenceRightLight, $fenceLeftLight, $fenceBottomLight",
    // boxShadow: "$fenceBottomLight, $fenceRightLight",
  },
  "&:nth-child(3)": {
    padding: "10px 8px 10px 13px",
  },
  "&:last-child": {
    lineHeight: "$16",
    backgroundColor: "$white",
    padding: 0,
  },
});

export const StickyTh = styled("th", Th, {
  "&:last-child": {
    position: "sticky",
    right: 0,
    zIndex: 2,
  },
});

const TdVariants = {
  positionInRow: {
    first: {
      color: "$gray-700",
      position: "sticky",
      zIndex: 2,
      left: 0,
      padding: 0,
      // boxShadow: "$fenceLeftLight, $fenceTopLight",
      boxShadow: "$fenceRightLight, $fenceBottomLight, $fenceLeftLight",

      [`& > ${TdContentWrapper}`]: {
        padding: "$10 $8",
      },
    },
    secondSticky: {
      padding: 0,
      position: "relative",

      [`& > ${TdContentWrapper}`]: {
        padding: "$10 $8",
      },

      "& > div > div:first-child": {
        // padding: "$10 0 $10 $10",
        marginRight: "$20",
      },
    },
    last: {
      height: "100%",
      padding: 0,
    },
  },
};

export const Td = css({
  padding: "8px",
  color: "$gray-600",
  backgroundColor: "$white",

  variants: TdVariants,
  "&.first2": {},
  "&.first": {},
  "&.second": {
    padding: "10px 8px 10px 13px",
  },

  "&.last": {},
});

export const StickyTd = styled("td", Td, {
  // boxShadow: "$fenceTopLight",
  boxShadow: "$fenceBottomLight",
  fontSize: "$12",
  lineHeight: "$16",
  color: "$gray-700",
  "&.first": {
    // maxWidth: "247px",
    // width: "247px",
  },
  variants: {
    positionInRow: {
      ...TdVariants.positionInRow,
      last: {
        ...TdVariants.positionInRow.last,
        position: "sticky",
        right: 0,
        zIndex: 2,
        height: "100%",
        padding: 0,
        // paddingRight: "$8",

        "& > a": {
          color: "$gray-700",
        },
      },
    },
  },
});

export const Tr = styled("tr", {
  cursor: "pointer",
  boxShadow: "$fenceBottomLight",

  [`& ${StickyTh} ${InnerTd} ${TdContentWrapper}`]: {
    // paddingLeft: "$20",
  },

  "& > th": {
    "&:first-child": {
      // width: "40%",
    },
    // "&:last-child": {
    //   width: "35%",
    // },
  },

  variants: {
    positionInGroup: {
      first: {
        [`& ${StickyTd}`]: {
          "&:nth-child(2)": {
            boxShadow: "$fenceBottomLight, $fenceRightLight",
          },
          "&:nth-child(3)": {
            // padding: "$10 $20",
          },
        },
      },
      rest: {},
    },
    theme: {
      light: {
        [`& ${StickyTh}:nth-child(2)`]: {
          boxShadow: "unset",
        },
      },
      default: {
        [`& ${StickyTh}:nth-child(2)`]: {
          boxShadow: "$fenceRightLight",
        },
      },
    },
  },

  compoundVariants: [
    {
      positionInGroup: "first",
      theme: "light",
      css: {
        [`& ${StickyTd}:nth-child(2)`]: {
          boxShadow: "$fenceBottomLight",
        },
      },
    },
  ],

  defaultVariants: {
    positionInGroup: "rest",
    theme: "default",
  },
});

export const FormRowInputCell = styled("form", {
  "& > label": {
    width: "100%",
    flex: 1,
    "& > input": {
      width: "100%",
      flex: 1,
    },
  },
  "& label, & input": {
    cursor: "text !important",
  },
});

export const RightShadow = styled("div", {
  // boxShadow: "$fenceLeftLight",
  padding: 0,
  alignSelf: "center",
  height: "100%",
  position: "absolute",
  top: 0,
  bottom: 0,
  right: "-10px",
  width: "10px",
});

export const StickyFinalRow = styled(Tr, {
  position: "sticky",
  bottom: 0,
  boxShadow: "$fenceTopLight",
  zIndex: 199,
  backgroundColor: "$white",
  cursor: "unset !important",

  "& > td": {
    boxShadow: "unset !important",

    "& > div": {
      boxShadow: "unset !important",
      display: "flex",
      // width: "100% !important",
      // flex: 1,

      "& > div": {
        flex: 1,
      },
    },
  },

  [`& ${StickyTd}`]: {
    "&:first-child": {
      [`& > ${TdContentWrapper}`]: {
        paddingTop: "$8",
        margin: "0 auto",
      },
      [`& > ${RightShadow}}`]: {
        display: "none",
      },
    },
  },
});

export const TableContainer = styled("div", {
  overflowY: "hidden",
});

export const Table = styled("table", {
  // width: "100%",
  display: "block",
  overflowX: "hidden",
  whiteSpace: "nowrap",
  // boxShadow: "$fenceBottomLight",

  "&:hover": {
    overflowX: "auto",

    "&::-webkit-scrollbar": {
      // width: "10px",
      "-webkit-appearance": "none",
    },
    "&::-webkit-scrollbar:horizontal": {
      height: "12px",
    },
    "&::-webkit-scrollbar:vertical": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      backgroundColor: "rgba(0, 0, 0, .5)",
      border: "2px solid white",
      // boxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
    },
  },
  //
  // [`& ${StickyTd}`]: {
  //   paddingRight: 12,
  // },
  // [`& ${StickyTh}`]: {
  //   paddingRight: 12,
  // },

  variants: {
    hover: {
      true: {
        [`& ${StickyTd}`]: {
          paddingRight: 0,
        },
        [`& ${StickyTh}`]: {
          paddingRight: 0,
        },
      },
      false: {},
    },
  },
});
