import React from "react";
import ModalBackground from "./ModalBackground";
import ModalWrapper from "./ModalWrapper";
import { ActionModal, ModalViewProps } from "../old-modal-types";

const WarningModal: React.FunctionComponent<ModalViewProps<ActionModal>> = ({
  modal,
  visible,
  direction,
  onClose,
}) => (
  <div className="fixed inset-0 z-10 overflow-y-auto">
    <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
      <ModalBackground onClose={onClose} visible={visible} direction={direction} />

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>

      <ModalWrapper visible={visible} direction={direction}>
        {modal.content(onClose)}
      </ModalWrapper>
    </div>
  </div>
);

export default WarningModal;
