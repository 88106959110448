import type { QueryOptions } from "@tanstack/query-core/src/types";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { get } from "../axios";
import type { AllContractsResponse, AxiosAPIError } from "../protos";
import useConnectionsQueryKey from "./useConnectionsQueryKey";

export default function useGetCompanyConnections(
  options: QueryOptions<unknown, AxiosAPIError, AllContractsResponse> = {},
) {
  const { companySID } = useParams();
  const { onSuccess: onSuccessOption, onError: onErrorOption, ...restOptions } = options;
  const queryKey = useConnectionsQueryKey();

  return useQuery<unknown, AxiosAPIError, AllContractsResponse>({
    queryKey,
    queryFn: () => get(`v1/companies/${companySID}/connect/connections`),
    refetchInterval: 15_000,
    ...restOptions,
  });
}
