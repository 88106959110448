import React from "react";

import { Icon } from "@meterup/metric";
import { components, DropdownIndicatorProps } from "react-select";

export function DropdownIndicator<T>(props: DropdownIndicatorProps<T, false>) {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon="chevronsVertical" size={12} />
    </components.DropdownIndicator>
  );
}
