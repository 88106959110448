import React from "react";
import Layout from "../components/Layout";
import QuoteRequestsTable from "../components/QuoteRequests/QuoteRequestsTable";

const QuoteRequests: React.FunctionComponent = () => (
  <Layout>
    <div className="mt-8">
      <div className="flex px-4 sm:px-6 md:px-8">
        <h1 className="mb-8 text-2xl font-semibold text-gray-900">Searches</h1>
      </div>
      <div className="px-4 sm:px-6 md:px-8">
        <QuoteRequestsTable />
      </div>
    </div>
  </Layout>
);

export default QuoteRequests;
