import { styled } from "../../styled";
import { TdContentWrapper } from "./TdContentWrapper";

export const InnerTd = styled("div", {
  alignItems: "stretch",
  display: "flex",
  height: "100%",
  position: "relative",
  variants: {
    type: {
      header: {
        height: "auto",
        // boxShadow: "$fenceBottomLight, $fenceTopLight",
        // boxShadow: "$fenceBottomLight",

        [`& ${TdContentWrapper}`]: {
          padding: "$6 $8",
        },
      },
      last: {
        boxShadow: "$fenceLeftLight",
        padding: "$10 $8",
      },
      lastEmpty: {
        boxShadow: "$fenceLeftLight",
        padding: "$16 $8",
      },
      lastHeader: {
        boxShadow: "$fenceLeftLight, $fenceBottomLight",
        padding: "$6 $8",
        height: "auto",
      },
    },
  },
});
