import React, { useEffect, useMemo } from "react";
import useLocalStorage from "../../frontend/src/hooks/useLocalStorage";

export type FlashMessage = {
  type: "success";
  message: string;
};

const FlashMessageTemplate: React.FunctionComponent<{
  message: FlashMessage;
}> = ({ message }) => {
  const color = useMemo(() => {
    switch (message.type) {
      case "success":
        return "green";
      default:
        break;
    }
  }, [message]);

  const icon = useMemo(() => {
    if (message.type === "success") {
      return (
        <svg
          className="w-5 h-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          />
        </svg>
      );
    }
    // else if (message.type === "warning") {
    //   return (
    //     <svg
    //       className="w-5 h-5 text-yellow-400"
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 20 20"
    //       fill="currentColor"
    //       aria-hidden="true">
    //       <path
    //         fillRule="evenodd"
    //         d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   );
    // }
  }, [message]);

  return (
    <div className={`p-4 bg-${color}-100 border-l-4 border-${color}-400`}>
      <div className="flex">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3">
          <p className={`text-sm text-${color}-700`}>{message.message}</p>
        </div>
      </div>
    </div>
  );
};

const FlashMessages: React.FunctionComponent = () => {
  const [messages, setMessages] = useLocalStorage<FlashMessage[]>("flashMessages", [], true);

  useEffect(() => {
    const timer = setTimeout(() => setMessages([]), 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return messages && messages.length > 0 ? (
    <div className="m-8">
      {messages.map((m, i) => (
        <FlashMessageTemplate key={i} message={m} />
      ))}
    </div>
  ) : null;
};

export default FlashMessages;
