import React, { useMemo } from "react";

import { ContractResponse, ISPContract, ISPContractCreateRequest } from "@meterup/api";
import useEditContract from "@meterup/api/src/billing/useEditContract";
import readDocument from "@meterup/ui/src/files/readDocument";
import { logError } from "@meterup/ui/src/Log.utils";
import { formatDateForAPI, formatDateStringFromAPI } from "@meterup/ui/src/utils/dates";
import { formatCurrencyToNum } from "@meterup/ui/src/utils/formatCurrency";
import { SubmitHandler } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";

import ContractFormDrawer from "../../../components/contracts/ContractFormDrawer";
import { ISPContractFormData } from "../../../components/contracts/ContractSchema";
import { companyContractsToISPOptions } from "../../../components/contracts/utils";
import { CompanyContextType } from "../../../contexts/CompanyContext";
import { ContractPageParams } from "../../../routerParams";

export default function EditContract() {
  const { allContracts: locations, contracts } = useOutletContext<CompanyContextType>();
  const { ispContractSID } = useParams<ContractPageParams>();
  const contract = useMemo<ContractResponse | undefined>(
    () => contracts?.contracts.find((c) => c.ispContract?.sid === ispContractSID),
    [contracts, ispContractSID],
  );

  const mutation = useEditContract(contract?.ispContract?.internetServicePlanSid);
  const values = useMemo(() => {
    const ispContract: ISPContract | undefined = contract?.ispContract;
    if (!ispContract) {
      return null;
    }
    const option = companyContractsToISPOptions(locations?.contracts).find(
      (o) => o.value === ispContract.internetServicePlanSid,
    );

    return {
      contractDates: {
        startDate: formatDateStringFromAPI(ispContract.contractStartDate),
        endDate: formatDateStringFromAPI(ispContract.contractEndDate),
      },
      ispContract: {
        amountDue: `${formatCurrencyToNum({ amount: ispContract.amountDue })}`,
        amountDueCurrency: ispContract.amountDueCurrency,
        internetServicePlanSid: option || null,
        firstInvoiceDueDate: formatDateStringFromAPI(ispContract.firstInvoiceDueDate),
        sid: ispContractSID,
      },
      contractDocument: null,
    };
  }, [contract?.ispContract, ispContractSID, locations?.contracts]);
  const onSubmit = useMemo<SubmitHandler<ISPContractFormData>>(
    () => async (innerData) => {
      try {
        const { contractDates, contractDocument: file, ispContract: ispContractRaw } = innerData;
        const { internetServicePlanSid, ...ispContract } = ispContractRaw;
        const req: ISPContractCreateRequest = {
          ispContract: {
            ...(contract?.ispContract || {}),
            ...ispContract,
            internetServicePlanSid: internetServicePlanSid.value,
            contractStartDate: formatDateForAPI(contractDates.startDate),
            contractEndDate: formatDateForAPI(contractDates.endDate),
          },
        };
        if (file) {
          const documentContents = await readDocument({ file });
          if (documentContents) {
            req.contractDocument = documentContents;
          }
        }

        await mutation.mutateAsync(req);
        return true;
      } catch (e) {
        logError(e);
        return false;
      }
    },
    [contract, mutation],
  );
  console.log("EditContract", "values", values, "contract", contract);

  return (
    <ContractFormDrawer
      contracts={locations?.contracts}
      defaultValues={values}
      error={mutation.isError && mutation.error?.response?.data}
      onSubmit={onSubmit}
      purpose="edit"
      successData={(mutation.isSuccess && mutation.data) || undefined}
      values={values}
    />
  );
}
