import React, { useCallback, useState } from "react";

import { put, QuotesUpdateRequest } from "@meterup/api";
import { CheckboxInputWithLabel } from "@meterup/metric";

type QuoteOptionsProps = {
  quoteRequestSid: string;
  recommended: boolean;
  reloadQuotes: (skipSetLoading?: boolean) => Promise<void>;
  routerIncluded: boolean;
  sid: string;
  status: string;
};

type QuoteSnakeCase = {
  recommended: boolean;
  router_included: boolean;
  status: string;
};

const QuoteOptions: React.FunctionComponent<QuoteOptionsProps> = ({
  quoteRequestSid,
  recommended,
  reloadQuotes,
  routerIncluded,
  sid,
  status,
}) => {
  const [rowLoading, setRowLoading] = useState(false);
  const [recommendedInternal, setRecommendedInternal] = useState(recommended);
  const [routerIncludedInternal, setRouterIncludedInternal] = useState(routerIncluded);
  const [statusInternal, setStatusInternal] = useState(status);

  const toggleEnabledCallback = useCallback(
    (status) => async (e: any) => {
      const result = await put<QuotesUpdateRequest, QuoteSnakeCase>(
        `admin/quote-requests/${quoteRequestSid}/quotes/${sid}`,
        {
          status,
        },
        setRowLoading,
      );
      if (result) {
        setStatusInternal(result.status);
      }
      await reloadQuotes(true);
    },
    [quoteRequestSid],
  );

  const toggleRecommendedCallback = useCallback(
    (recommended) => async (e: any) => {
      const result = await put<QuotesUpdateRequest, QuoteSnakeCase>(
        `admin/quote-requests/${quoteRequestSid}/quotes/${sid}`,
        {
          recommended,
          recommendedChanged: true,
        },
        setRowLoading,
      );
      if (result) {
        setRecommendedInternal(result.recommended);
      }
      await reloadQuotes(true);
    },
    [quoteRequestSid],
  );

  const toggleRouterIncludedCallback = useCallback(
    (routerIncluded) => async (e: any) => {
      const result = await put<QuotesUpdateRequest, QuoteSnakeCase>(
        `admin/quote-requests/${quoteRequestSid}/quotes/${sid}`,
        {
          routerIncluded,
          routerIncludedChanged: true,
        },
        setRowLoading,
      );
      if (result) {
        setRouterIncludedInternal(result.router_included);
      }
      await reloadQuotes(true);
    },
    [quoteRequestSid],
  );
  return (
    <ul className="flex flex-col">
      <li>
        <CheckboxInputWithLabel
          checked={routerIncludedInternal}
          disabled={rowLoading}
          name={`${sid}-routerIncluded`}
          onChange={toggleRouterIncludedCallback(!routerIncludedInternal)}>
          Router Included?
        </CheckboxInputWithLabel>
      </li>
      <li>
        <CheckboxInputWithLabel
          name={`${sid}-recommended`}
          checked={recommendedInternal}
          disabled={rowLoading}
          onChange={toggleRecommendedCallback(!recommendedInternal)}>
          Pinned?
        </CheckboxInputWithLabel>
      </li>
      {(status === "enabled" || status === "disabled") && (
        <li>
          <CheckboxInputWithLabel
            name={`${sid}-enabled`}
            checked={statusInternal === "enabled"}
            disabled={rowLoading}
            onChange={toggleEnabledCallback(statusInternal === "enabled" ? "disabled" : "enabled")}>
            Enabled?
          </CheckboxInputWithLabel>
        </li>
      )}
    </ul>
  );
};

export default QuoteOptions;
