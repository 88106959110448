import { PortalOrigin } from "./enums";

export enum Ports {
  ConnectBackend = 9913,
  ConnectFrontend = 9912,
  Portal = 6894,
}

export const getPortalTld = (location: Location) =>
  location.hostname.split(".")[location.hostname.split(".").length - 1];

export function getTldWithPort(location: Location, port: Ports) {
  const tld = getPortalTld(location);
  if (tld === "website") {
    return `${tld}:${port}`;
  }

  return tld;
}

export const getPortalUrl = (location: Location): string =>
  `https://portal.meter.${getTldWithPort(location, Ports.Portal)}`;

function safeURL(url: string, path?: string) {
  return `${url}/${path?.startsWith("/") ? path.slice(1) : path}`;
}

export const getProxyUrl = () => `${getPortalUrl(window.location)}/api-proxy`;
export const getProxyUrlWith = (path?: string) => safeURL(getProxyUrl(), path);

export const getAPIBaseUrl = (location: Location) =>
  `${getPortalUrl(location)}/api-proxy/v1/connect`;

export function getConnectUrl(location: Location, origin: PortalOrigin, path?: string) {
  let url = "";
  if (origin === PortalOrigin.Connect) {
    url = `https://connect.meter.${getTldWithPort(location, Ports.ConnectFrontend)}`;
  } else {
    url = `https://connect-admin.meter.${getTldWithPort(location, Ports.ConnectBackend)}`;
  }

  return `${url}/${path?.startsWith("/") ? path.slice(1) : path}`;
}
