import React, { useMemo } from "react";

import { ISPContractCreateRequest } from "@meterup/api";
import useCreateContract from "@meterup/api/src/billing/useCreateContract";
import readDocument from "@meterup/ui/src/files/readDocument";
import { logError } from "@meterup/ui/src/Log.utils";
import { SubmitHandler } from "react-hook-form";
import { useOutletContext } from "react-router-dom";

import ContractFormDrawer from "../../../components/contracts/ContractFormDrawer";
import { ISPContractFormData } from "../../../components/contracts/ContractSchema";
import { CompanyContextType } from "../../../contexts/CompanyContext";

export default function CreateContract() {
  const { allContracts: locations } = useOutletContext<CompanyContextType>();

  const mutation = useCreateContract();
  const onSubmit = useMemo<SubmitHandler<ISPContractFormData>>(
    () => async (innerData) => {
      try {
        const { contractDates, contractDocument: file, ispContract: ispContractRaw } = innerData;
        const { internetServicePlanSid, ...ispContract } = ispContractRaw;
        const req: ISPContractCreateRequest = {
          ispContract: {
            ...ispContract,
            internetServicePlanSid: internetServicePlanSid.value,
            contractStartDate: contractDates.startDate,
            contractEndDate: contractDates.endDate,
          },
        };
        if (file) {
          const documentContents = await readDocument({ file });
          if (documentContents) {
            req.contractDocument = documentContents;
          }
        }

        await mutation.mutateAsync(req);
        return true;
      } catch (e) {
        logError(e);
        return false;
      }
    },
    [mutation],
  );

  return (
    <ContractFormDrawer
      contracts={locations?.contracts}
      error={mutation.isError && mutation.error?.response?.data}
      onSubmit={onSubmit}
      purpose="create"
      successData={mutation.isSuccess && mutation.data}
    />
  );
}
