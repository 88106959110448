// There are a number of important differences between the typings built by the
// TS plugin we're using, and the underlying JSON:
//
// 1. The JSON representation is in snake_case, the typings are in camelCase
//
// 2. In the TS protos, repeated fields (i.e. array fields) get appended with
//    'List'. Hence:
//
//      'repeated Location locations' becomes 'locationsList: Location[]'
//
// 3. The values of the enums in the TS protos are the underlying integer,
//    whereas they get sent in JSON as *lowercased* strings of the keys.
//
// 4. The typings require most fields. PUT /entities/{id} requests over don't,
//    since we've enabled omitempty.
//
// We want the typings to remain in camelCase, since this is more idiomatic. We
// can do so by converting to camelCase when reading, in the relevant resource
// hooks (useApiResource/s), and to snake_case when writing, in the post/put
// helper methods in ./index.ts.
//
// The other three require changes to the typings. So this file modifies and
// re-exports the typings with the differences in 2/3/4 reversed.
//
// Some we can do automatically, some we have to do manually. In the case of the
// GET /entities request types (e.g. 'QuoteRequestsRequest'), we need to rewrite
// manually so our overridden entity type is used.
//
// Bless this mess.

import type { AxiosError } from "axios";

import type {
  CompanyContractResponse as CompanyContractResponseProto,
  // CompanyContractsResponse as CompanyContractsResponseProto,
  CompanyISPStatusChangeRequest as CompanyISPStatusChangeRequestProto,
  CompanyISPStatusChangeResponse as CompanyISPStatusChangeResponseProto,
  CompanyLocationResponse as CompanyLocationResponseProto,
  CompanyLocationsResponse as CompanyLocationsResponseProto,
  CompanyResponse as CompanyResponseProto,
  CompanyUpdateBillingRequest as CompanyUpdateBillingRequestProto,
  ConnectionStatusChange as ConnectionStatusChangeProto,
  Error as ErrorProto,
  IdentityResponse as IdentityResponseProto,
  InternetServicePlan as InternetServicePlanProto,
  InviteUserResponse as InviteUserResponseProto,
  InviteUsersRequest as InviteUsersRequestProto,
  InviteUsersResponse as InviteUsersResponseProto,
  Provider as ProviderProto,
  ProviderCreateRequest as ProviderCreateRequestProto,
  ProvidersResponse as ProvidersResponseProto,
  UsersResponse as UsersResponseProto,
} from "./apiProtos";
import {
  CompanyMembershipRole as CompanyMembershipRoleProto,
  ISPProduct as ISPProductProto,
  ISPStatus as ISPStatusProto,
} from "./apiProtos";
import type {
  Address as AddressProto,
  GeoPoint as GeoPointProto,
  Location as LocationProto,
  Point as PointProto,
} from "./common";
import type {
  ConnectAdminCompaniesResponse as ConnectAdminCompaniesResponseProto,
  ConnectAdminCompanyResponse as ConnectAdminCompanyResponseProto,
  ConnectAdminCreateCompanyWithUsersRequest as ConnectAdminCreateCompanyWithUsersRequestProto,
  ConnectAdminCreateCompanyWithUsersResponse as ConnectAdminCreateCompanyWithUsersResponseProto,
  ConnectAdminCreateQuoteForDashboard as ConnectAdminCreateQuoteForDashboardProto,
  ConnectAdminUpdateQuoteForDashboard as ConnectAdminUpdateQuoteForDashboardProto,
  ContractResponse as ContractResponseProto,
  InvoiceCreateRequest as InvoiceCreateRequestProto,
  InvoiceCreateRequest_Error as InvoiceCreateRequest_ErrorProto,
  InvoiceResponse as InvoiceResponseProto,
  ISPContractCreateRequest as ISPContractCreateRequestProto,
  ISPContractCreateRequest_Error as ISPContractCreateRequest_ErrorProto,
} from "./connectadminProtos";
// import { ConnectAdminErrors as ConnectAdminErrorsProto } from "./connectadminProtos";
import type {
  CompanyLocationPutRequest as CompanyLocationPutRequestProto,
  ConnectionStatus as ConnectionStatusProto,
  ConnectionType as ConnectionTypeProto,
  Invoice as InvoiceProto,
  InvoiceStatus as InvoiceStatusProto,
  ISPContract as ISPContractProto,
  LocationProviderAvailabilityDeleteRequest as LocationProviderAvailabilityDeleteRequestProto,
  LocationProviderAvailabilityUpsertRequest as LocationProviderAvailabilityUpsertRequestProto,
  LocationsCreateRequest as LocationsCreateRequestProto,
  Message as MessageProto,
  MessagesCreateRequest as MessagesCreateRequestProto,
  ProviderAvailabilitiesResponse as ProviderAvailabilitiesResponseProto,
  ProviderAvailability as ProviderAvailabilityProto,
  Quote as QuoteProto,
  QuoteRequest as QuoteRequestProto,
  QuoteRequestCreateRequest as QuoteRequestCreateRequestProto,
  QuoteRequestExport as QuoteRequestExportProto,
  QuoteRequestUpdateRequest as QuoteRequestUpdateRequestProto,
  QuotesCreateRequest as QuotesCreateRequestProto,
  QuotesUpdateRequest as QuotesUpdateRequestProto,
  UpdateInternetServicePlanNotesRequest as UpdateInternetServicePlanNotesRequestProto,
  ZayoBuilding as ZayoBuildingProto,
  ZayoLocation as ZayoLocationProto,
} from "./connectProtos";
import {
  Availability as AvailabilityProto,
  Quote_Status as QuoteStatusProto,
  QuoteRequest_Status,
} from "./connectProtos";

// Entity Types
// -----------------------------------------------------------------------------
// with the following typescript types I get an error that `TS2344: Type 'ISPContractCreateRequest' does not satisfy the constraint 'Record<string, unknown>'.
// Index signature for type 'string' is missing in type 'ISPContractCreateRequest'.`. how do I fix it?
// export interface ISPContractCreateRequest {
//   isp_contract?: ISPContract;
//   contract_document?: FileAttachment | undefined;
//   contract_document_s3_key?: string;
//   delete_document?: boolean | undefined;
// }
// export type ISPContractCreateRequest = KeysToCamelCase<ISPContractCreateRequestProto>;

// type Prev = [
//   never,
//   0,
//   1,
//   2,
//   3,
//   4,
//   5,
//   6,
//   7,
//   8,
//   9,
//   10,
//   11,
//   12,
//   13,
//   14,
//   15,
//   16,
//   17,
//   18,
//   19,
//   20,
//   ...0[],
// ];

// export type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
//   ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
//   : Lowercase<S>;
//
// export type IFaceWrapper<T> = { [K in keyof T]: T[K] };
//
// export type KeysToCamelCase<T extends Record<string, unknown> | undefined, D extends number = 3> = D extends 0
//   ? never
//   : {
//       [K in keyof T as CamelCase<string & K>]: T[K] extends Array<infer Q>
//         ? IFaceWrapper<Q> extends Record<string, unknown> ? KeysToCamelCase<IFaceWrapper<Q>, Prev[D]>[] : Q
//         : IFaceWrapper<T[K]> extends Record<string, unknown>
//         ? KeysToCamelCase<IFaceWrapper<T[K]>, Prev[D]>
//         : IFaceWrapper<T[K]> extends Optional<Record<string, unknown>>
//         ? KeysToCamelCase<IFaceWrapper<T[K]>, Prev[D]> | undefined
//         : T[K];
//     };
export type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>;

export type IsPrimitive<T> = T extends string | number | boolean | Date | undefined | null
  ? T
  : never;

export type KeysToCamelCase<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends IsPrimitive<T[K]>
    ? T[K]
    : T[K] extends Array<infer Q>
    ? Array<KeysToCamelCase<Q>>
    : T[K] extends {}
    ? KeysToCamelCase<T[K]>
    : T[K] extends Optional<{}>
    ? KeysToCamelCase<T[K]>
    : T[K] extends Optional<Array<infer P>>
    ? Optional<Array<KeysToCamelCase<P>>>
    : T[K];
};

export type Prettify<T> = T extends infer U ? { [K in keyof U]: U[K] } : never;
export type Address = Prettify<KeysToCamelCase<AddressProto>>;
export type AllContractsResponse = {
  contracts: CompanyContractsResponse[];
};
export type CompanyContractResponse = KeysToCamelCase<CompanyContractResponseProto>;
// export type CompanyContractResponse = Omit<
//   StripListSuffix<CompanyContractResponseProto.AsObject>,
//   "latestStatus" | "internetServicePlan"
// > & {
//   latestStatus?: ConnectionStatusChange;
//   internetServicePlan?: InternetServicePlan;
// };

export type Invoice = KeysToCamelCase<InvoiceProto>;
export type InvoiceStatus = InvoiceStatusProto;

// export type CompanyContractsResponse = KeysToCamelCase<CompanyContractsResponseProto>;
export type CompanyContractsResponse = {
  companyLocation?: CompanyLocationResponse;
  companyContracts: CompanyContractResponse[];
};
// export type CompanyContractsResponse = Omit<
//   StripListSuffix<CompanyContractsResponseProto.AsObject>,
//   "companyContracts" | "companyLocation"
// > & {
//   companyContracts: CompanyContractResponse[];
//   companyLocation: CompanyLocationResponse;
// };
export type CompanyLocationResponse = KeysToCamelCase<CompanyLocationResponseProto>;
export type CompanyLocationsResponse = KeysToCamelCase<CompanyLocationsResponseProto>;
export type CompanyResponse = KeysToCamelCase<CompanyResponseProto>;
export type CompanyUpdateBillingRequest = KeysToCamelCase<CompanyUpdateBillingRequestProto>;
export type ConnectAdminCompaniesResponse = KeysToCamelCase<ConnectAdminCompaniesResponseProto>;
export type ConnectAdminCompanyResponse = KeysToCamelCase<ConnectAdminCompanyResponseProto>;

export type ConnectAdminCreateCompanyWithUsersRequest =
  KeysToCamelCase<ConnectAdminCreateCompanyWithUsersRequestProto>;
export type ConnectAdminCreateCompanyWithUsersResponse =
  KeysToCamelCase<ConnectAdminCreateCompanyWithUsersResponseProto>;

export type Optional<T> = T | undefined;
// export type OptionalKeys<T extends object> = {
//   [K in keyof T]?: T[K];
// };
export type OptionalFields<T extends object, Fields extends keyof T> = Omit<T, Fields> & {
  [K in Fields]?: T[K];
};

export type IdentityResponse = KeysToCamelCase<IdentityResponseProto>;

// type CleanupPb<T extends object> = {
//   [K in keyof T]: T[K] extends google_protobuf_timestamp_pb.Timestamp.AsObject
//     ? string
//     : T[K] extends Optional<google_protobuf_timestamp_pb.Timestamp.AsObject>
//     ? Optional<string>
//     : T[K] extends google_protobuf_wrappers_pb.StringValue.AsObject
//     ? string
//     : T[K] extends Optional<google_protobuf_wrappers_pb.StringValue.AsObject>
//     ? Optional<string>
//     : T[K];
// };

export type CompanyLocationPutRequest = Prettify<KeysToCamelCase<CompanyLocationPutRequestProto>>;
export type UpdateInternetServicePlanNotesRequest = Prettify<
  KeysToCamelCase<UpdateInternetServicePlanNotesRequestProto>
>;

export type InternetServicePlan = KeysToCamelCase<InternetServicePlanProto>;
// export type InternetServicePlan = Omit<
//   NamedTypeToString<
//     InternetServicePlanProto.AsObject,
//     google_protobuf_timestamp_pb.Timestamp.AsObject
//   >,
//   "product" | "status"
// > & {
//   product?: ISPProductProto;
//   status: ISPStatus;
// };

export type Location = Prettify<KeysToCamelCase<LocationProto>>;
export type Point = Prettify<KeysToCamelCase<PointProto>>;
export type GeoPoint = Prettify<KeysToCamelCase<GeoPointProto>>;

export type Provider = KeysToCamelCase<ProviderProto>;
export type ISPContract = KeysToCamelCase<ISPContractProto>;
export type ProvidersResponse = KeysToCamelCase<ProvidersResponseProto>;

export type ConnectionType = KeysToCamelCase<ConnectionTypeProto>;

export type Message = KeysToCamelCase<MessageProto>;

export type ZayoBuilding = KeysToCamelCase<ZayoBuildingProto>;

export type ZayoLocation = KeysToCamelCase<ZayoLocationProto>;

export type QuoteRequestExport = KeysToCamelCase<QuoteRequestExportProto>;

export type QuoteRequest = KeysToCamelCase<QuoteRequestProto>;

// export interface QuoteRequest
//   extends Omit<StripListSuffix<QuoteRequestProto.AsObject>, "status" | "exports"> {
//   status: LowercasedKeys<QuoteRequestProto.StatusMap>;
//   exports: QuoteRequestExport[];
// }

// export type Quote = Omit<QuoteProto.AsObject, "status" | "riserResponsibility" | "availability"> & {
//   status: QuoteStatus;
//   riserResponsibility: LowercasedKeys<QuoteProto.RiserResponsibilityMap>;
//   availability: Availability;
// };

export type Quote = KeysToCamelCase<QuoteProto>;

export type QuoteWithRequestAndISP = Quote & {
  companyLocationSID?: string;
  internetServicePlan?: InternetServicePlan;
  quoteRequest?: QuoteRequest;
  latestStatus?: ConnectionStatusChange;
  companySID?: string;
};

const Availability = AvailabilityProto;
const CompanyMembershipRole = CompanyMembershipRoleProto;
const ISPProduct = ISPProductProto;
const ISPStatus = ISPStatusProto;
const QuoteStatus = QuoteStatusProto;
export { Availability, CompanyMembershipRole, ISPStatus, ISPProduct, QuoteStatus };

export type ConnectionChangeStatusCategory =
  | "active"
  | "in-progress"
  | "needs-attention"
  | "quotes";

export type ConnectionStatus = Omit<KeysToCamelCase<ConnectionStatusProto>, "category"> & {
  category: ConnectionChangeStatusCategory;
};

export type CompanyISPStatusChangeRequest = KeysToCamelCase<CompanyISPStatusChangeRequestProto>;

export type CompanyISPStatusChangeResponse = KeysToCamelCase<CompanyISPStatusChangeResponseProto>;

export type ConnectionStatusesResponse = {
  statuses: ConnectionStatus[];
};

export type ConnectionStatusChange = KeysToCamelCase<ConnectionStatusChangeProto>;

export type InviteUsersRequest = Prettify<KeysToCamelCase<InviteUsersRequestProto>>;
export type InviteUserResponse = Prettify<KeysToCamelCase<InviteUserResponseProto>>;
export type InviteUsersResponse = Prettify<KeysToCamelCase<InviteUsersResponseProto>>;
export type UsersResponse = Prettify<KeysToCamelCase<UsersResponseProto>>;

export type ArrayType<T> = T extends Array<infer Q> ? Q : never;
export type UserResponse = ArrayType<UsersResponse["users"]>;

export type Entity =
  | Address
  | AllContractsResponse
  | CompanyContractResponse
  | CompanyContractsResponse
  | CompanyLocationResponse
  | ConnectionStatus
  | ConnectionStatusChange
  | InternetServicePlan
  | Location
  | QuoteRequest
  | QuoteRequestExport
  | Provider
  | ConnectionType
  | Quote
  | Message
  | ZayoBuilding
  | ZayoLocation;

// GET /entities Request Types
// -----------------------------------------------------------------------------

export type QuoteRequestsRequest = {
  quoteRequests: QuoteRequest[];
};

export type QuotesRequest = {
  quotes: Quote[];
};

export type MessagesRequest = {
  messages: Message[];
};

export type ProvidersRequest = {
  providers: Provider[];
};

export type ConnectionTypesRequest = {
  connectionTypes: ConnectionType[];
};

export type ZayoGetBuildingsRequest = {
  buildings: ZayoBuilding[];
};

export type ZayoGetLocationsRequest = {
  locations: ZayoLocation[];
};

// These are both necessary to power the typing in useApiResources.
//
// The former is a map from entity plural name to request type.
//
// The latter, which should be coextensive, is the set of keys of each request type.

export type EntityToGetRequestMap = {
  quoteRequests: QuoteRequestsRequest;
  quotes: QuotesRequest;
  providers: ProvidersRequest;
  connectionTypes: ConnectionTypesRequest;
  messages: MessagesRequest;
  buildings: ZayoGetBuildingsRequest;
  locations: ZayoGetLocationsRequest;
};

export type GetRequestKey =
  | keyof QuoteRequestsRequest
  | keyof QuotesRequest
  | keyof MessagesRequest
  | keyof ProvidersRequest
  | keyof ConnectionTypesRequest
  | keyof ZayoGetBuildingsRequest
  | keyof ZayoGetLocationsRequest;

// POST /entities Request Types
// -----------------------------------------------------------------------------

export type QuoteRequestCreateRequest = KeysToCamelCase<QuoteRequestCreateRequestProto>;
// export type QuoteRequestCreateRequest = CleanupPb<
//   StripListSuffix<QuoteRequestCreateRequestProto>>
// >;

export type LocationsCreateRequest = KeysToCamelCase<LocationsCreateRequestProto>;

export type MessagesCreateRequest = KeysToCamelCase<MessagesCreateRequestProto>;

export type ProviderCreateRequest = KeysToCamelCase<ProviderCreateRequestProto>;

// export type LocationProviderAvailabilityUpsertRequest = LocationProviderAvailabilityUpsertRequestProto.AsObject;
export type LocationProviderAvailabilityUpsertRequest =
  KeysToCamelCase<LocationProviderAvailabilityUpsertRequestProto>;
// export type LocationProviderAvailabilityDeleteRequest =
//   LocationProviderAvailabilityDeleteRequestProto.AsObject;
export type LocationProviderAvailabilityDeleteRequest =
  KeysToCamelCase<LocationProviderAvailabilityDeleteRequestProto>;

// export type ProviderAvailabilitiesResponse = ProviderAvailabilitiesResponseProto.AsObject;
export type ProviderAvailabilitiesResponse = KeysToCamelCase<ProviderAvailabilitiesResponseProto>;

export type ProviderAvailability = KeysToCamelCase<ProviderAvailabilityProto>;

export type QuotesCreateRequest = KeysToCamelCase<QuotesCreateRequestProto>;

// PUT /entities/{id} Request Types
// -----------------------------------------------------------------------------

export type QuoteRequestUpdateRequest = KeysToCamelCase<QuoteRequestUpdateRequestProto>;
// export type QuoteRequestUpdateRequest = Omit<
//   Partial<StripListSuffix<QuoteRequestUpdateRequestProto.AsObject>>,
//   "status"
// > & {
//   status: QuoteRequest["status"];
// };

export type QuotesUpdateRequest = KeysToCamelCase<QuotesUpdateRequestProto>;
export type ConnectAdminCreateQuoteForDashboard =
  KeysToCamelCase<ConnectAdminCreateQuoteForDashboardProto>;
export type InvoiceResponse = KeysToCamelCase<InvoiceResponseProto>;
export type InvoicesResponse = {
  invoices: InvoiceResponse[];
};
// export type InvoicesResponse = Omit<KeysToCamelCase<InvoicesResponseProto>, "invoices"> & {
//   invoices: InvoiceResponse[];
// };
export type ContractResponse = KeysToCamelCase<ContractResponseProto>;
export type ContractsResponse = {
  contracts: ContractResponse[];
};

export type ISPContractCreateRequest = KeysToCamelCase<ISPContractCreateRequestProto>;
export type ISPContractCreateRequestError = ISPContractCreateRequest_ErrorProto;

export type InvoiceCreateRequest = KeysToCamelCase<InvoiceCreateRequestProto>;
export type InvoiceCreateRequestError = InvoiceCreateRequest_ErrorProto;

/* eslint-disable @typescript-eslint/naming-convention */
export type ConnectAdminCreateQuoteForDashboard_Req = Omit<
  ConnectAdminCreateQuoteForDashboardProto,
  "connection_status" | "quotes_create_req"
> & {
  connection_status?: ConnectionStatusProto;
  quotes_create_req: QuotesCreateRequestProto;
};

export type ConnectAdminUpdateQuoteForDashboard_Req = Omit<
  ConnectAdminUpdateQuoteForDashboardProto,
  "connection_status" | "quotes_update_req"
> & {
  connection_status?: OptionalFields<
    ConnectionStatusProto,
    "sid" | "status_category" | "status_type" | "priority"
  >;
  quotes_update_req: QuotesUpdateRequestProto;
};
// Maps
// -----------------------------------------------------------------------------

// export const QuoteRequestStatusMap = QuoteRequestProto.Status;
export const QuoteRequestStatusMap = QuoteRequest_Status;
// Utility Types
// -----------------------------------------------------------------------------

export type EntityKey = keyof EntityToGetRequestMap & keyof EntityMap & GetRequestKey;

export type EntityMap = {
  addresses: Address;
  quoteRequests: QuoteRequest;
  quotes: Quote;
  messages: Message;
  connectionTypes: ConnectionType;
  providers: Provider;
  buildings: ZayoBuilding;
  locations: ZayoLocation;
};

type WithoutListSuffix<S extends string> = S extends `${infer P1}List` ? `${P1}` : S;

type MaybeWithoutListSuffix<S extends string | number | symbol> = S extends string
  ? WithoutListSuffix<S>
  : S;

export type StripListSuffix<T extends Record<string, any>> = {
  [K in keyof T as MaybeWithoutListSuffix<K>]: T[K];
};

export type APIError = ErrorProto;
export type AxiosAPIError = AxiosError<APIError>;
