import React, { useCallback, useMemo, useRef, useState } from "react";

import type {
  AxiosAPIError,
  ConnectAdminCreateQuoteForDashboard_Req,
  ConnectAdminUpdateQuoteForDashboard_Req,
  ConnectionChangeStatusCategory,
  ConnectionStatus,
  ConnectionType,
  ConnectionTypesRequest,
  InternetServicePlan,
  Provider,
  ProvidersResponse,
  Quote,
  QuoteRequest,
} from "@meterup/api";
import { axios, ISPStatus } from "@meterup/api";
import { AllPaths, GetFromPath } from "@meterup/api/src/helpers";
import {
  Alert,
  BasicSelect,
  BasicSelectItem,
  Button,
  CloseDrawerButton,
  CompositeField,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  HStack,
  Icon,
  PrimaryField,
  PrimaryFieldComposite,
  SecondaryField,
  Text,
  TextInput,
  VStack,
} from "@meterup/metric";
import { styled } from "@meterup/ui";
import { CloseDrawerLink } from "@meterup/ui/src/components/Drawer/DrawerLinks";
import { ExpandFlex } from "@meterup/ui/src/components/ExpandFlex";
import { ClearIndicator } from "@meterup/ui/src/components/Select/ClearIndicator";
import { DropdownIndicator } from "@meterup/ui/src/components/Select/DropdownIndicator";
import { useCustomStyles } from "@meterup/ui/src/components/Select/hooks/useCustomStyles";
import { notify } from "@meterup/ui/src/components/stolenFromFrontends/notify";
import { AvailabilityCell } from "@meterup/ui/src/components/Table/CommonCells";
import { useStatusesByCategory } from "@meterup/ui/src/hooks/useAllStatuses";
import useRouter from "@meterup/ui/src/hooks/useRouter";
import { logError } from "@meterup/ui/src/Log.utils";
import { MutateOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  FieldInputProps,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikTouched,
} from "formik";
import get from "lodash/get";
import isObject from "lodash/isObject";
import { useParams } from "react-router-dom";
import Select from "react-select";
import SelectBaseType from "react-select/base";
import { GroupBase } from "react-select/dist/declarations/src/types";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { Separator } from "../../../styles/dashboard";
import {
  getBlankQuoteFormData,
  QuoteFormData,
  QuoteZodSchema,
  RefinedQuoteZodSchema,
  TypedField,
} from "../Companies/quoteFormSchema";

const Wrapper = styled("div", {
  '& button[role="combobox"]': {
    "& > div": {
      // This will break if the button has a loading style applied.
      "&:first-child, &:last-child": {
        display: "none",
      },
    },
    '&[data-loading="true"]': {
      "& > div": {
        // This will break if the button has a loading style applied.
        "&:first-child": {
          display: "flex",
        },
        "&:nth-child(2)": {
          display: "none",
        },
      },
    },
  },
});

type ConnectionStatusSelectType = SelectBaseType<
  ConnectionStatus,
  false,
  GroupBase<ConnectionStatus>
>;
type ProviderSelectType = SelectBaseType<Provider, false, GroupBase<Provider>>;
type ConnectionTypeSelectType = SelectBaseType<ConnectionType, false, GroupBase<ConnectionType>>;

function formatCategoryLabel(data: ConnectionChangeStatusCategory, priority?: number) {
  const [firstLetter, ...rest] = data.replaceAll("-", " ").split("");
  return `${firstLetter.toUpperCase()}${rest.join("")}${priority ? `, Priority: ${priority}` : ""}`;
}

function statusOptionLabel(status: ConnectionStatus) {
  return `${status.display} (${formatCategoryLabel(status.category)})`;
}

function statusOptionValue(status: ConnectionStatus) {
  const { status: statusVal, category } = status;
  return `${statusVal}-${category}`;
}

function getSID(value: { sid: string }) {
  return value.sid;
}

function getName(value: { name: string }) {
  return value.name;
}

window.errorMessages = [];
function errorMessage<
  Name extends AllPaths<FormikTouched<QuoteFormData>> & AllPaths<FormikErrors<QuoteFormData>>,
>(
  errors: FormikErrors<QuoteFormData>,
  touched: FormikTouched<QuoteFormData>,
  name: Name,
  // field?: FieldInputProps<any>,
  hasSubmitted?: boolean,
) {
  const e = get(errors, name);
  const t = get(errors, name);
  const retVal = ((t || hasSubmitted) && e) || undefined;
  if (isObject(retVal)) {
    return Object.values(retVal)[0];
  }
  return retVal;
}

type FileFieldProps = {
  field: FieldInputProps<File>;
  form: FormikProps<QuoteFormData>;
  isLoading?: boolean;
  onBlur: (form: FormikProps<QuoteFormData>, fieldName: string) => () => void;
  onUpload: (file: File) => void;
};

const FileField = React.forwardRef(
  (
    { field, form, isLoading, onBlur, onUpload }: FileFieldProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const meta = form.getFieldMeta<string | undefined>("contract_url");
    const val = meta.value;
    const onClickConfirm = useCallback(
      (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onUpload(field.value);
      },
      [field.value, onUpload],
    );
    return (
      <VStack spacing={4} align="end">
        {field.value ? (
          <Text size={12} lineHeight={16} color={{ light: "gray-600" }}>
            <Text weight="medium">Selected:</Text> {field.value.name}
          </Text>
        ) : null}
        {val && !field.value ? (
          <Text size={12} lineHeight={16} color={{ light: "gray-600" }}>
            <Text weight="medium">Uploaded:</Text> {val}
          </Text>
        ) : null}
        <HStack spacing={6}>
          {field.value ? (
            <Button
              disabled={isLoading}
              variant="tertiary"
              icon="cross"
              onClick={() => form.setFieldValue("pdf", undefined)}
              arrangement="leading-icon">
              Clear
            </Button>
          ) : null}
          <Button
            variant="tertiary"
            icon="upload"
            arrangement="leading-icon"
            as="label"
            css={{
              ...(field.value ? { display: "none" } : {}),
            }}>
            <input
              ref={ref}
              type="file"
              // onBlur={onBlur(form, "pdf")}
              onChange={(event) => {
                if (event.target.files && event.target.files.length > 0) {
                  form.setFieldValue("pdf", event.target.files[0]);
                }
              }}
              hidden
              accept=".pdf"
            />
            Upload contract
          </Button>
          {field.value ? (
            <Button
              variant="primary"
              icon="upload"
              arrangement="leading-icon"
              loading={isLoading}
              onClick={onClickConfirm}>
              Confirm upload
            </Button>
          ) : null}
        </HStack>
      </VStack>
    );
  },
);

export type QuoteFormProps = {
  isp?: InternetServicePlan;
  nextSID?: string;
  prevSID?: string;
  quote?: Quote;
  quoteRequest?: QuoteRequest;
  values?: QuoteFormData;
};

export function QuoteForm({ isp, nextSID, prevSID, quote, quoteRequest, values }: QuoteFormProps) {
  const { handleOnClick } = useRouter();
  const { data: providersData, isLoading: providersAreLoading } = useQuery<
    unknown,
    AxiosAPIError,
    ProvidersResponse
  >(["providers"], () => axios.get("v1/connect/providers"));
  const { data: connectionTypesData, isLoading: connectionTypesAreLoading } = useQuery<
    unknown,
    AxiosAPIError,
    ConnectionTypesRequest
  >(["connection-types"], () => axios.get("v1/connect/admin/connection-types"));
  const updateQuoteMutation = useMutation<
    Quote,
    AxiosAPIError,
    ConnectAdminUpdateQuoteForDashboard_Req
  >(
    (params) =>
      axios.put(
        `v1/connect/admin/quote-requests/${quoteRequest?.sid}/dashboard-quotes/${quote?.sid}`,
        params,
      ),
    {
      onError(error) {
        logError(error);
      },
    },
  );
  const createQuoteMutation = useMutation<
    Quote,
    AxiosAPIError,
    ConnectAdminCreateQuoteForDashboard_Req
  >(
    (params) =>
      axios.post(`v1/connect/admin/quote-requests/${quoteRequest?.sid}/dashboard-quotes`, params),
    {
      onError(error) {
        logError(error);
      },
    },
  );
  const uploadContractMutation = useMutation<unknown, AxiosAPIError, FormData>((params) =>
    axios.plainPost(`/v1/connect/admin/internet-service-plans/${isp?.sid}/contracts`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  );

  const { byCategory, byPriority, isLoading: statusesAreLoading } = useStatusesByCategory();
  const customStylesStatus = useCustomStyles<ConnectionStatus, false>({
    size: "medium",
    showIndicator: true,
    controlPositioning: false,
  });
  const customStylesProvider = useCustomStyles<Provider, false>({
    size: "medium",
    showIndicator: true,
    controlPositioning: false,
  });
  const customStylesConnectionType = useCustomStyles<ConnectionType, false>({
    size: "small",
    showIndicator: true,
    controlPositioning: false,
  });
  const { companySID, companyLocationSID: sid } = useParams();
  const connectionTypeSelectRef = useRef<ConnectionTypeSelectType | null>(null);
  const providerSelectRef = useRef<ProviderSelectType | null>(null);
  const statusSelectRef = useRef<ConnectionStatusSelectType | null>(null);
  const queryClient = useQueryClient();
  const statusSelectGroup = useMemo(() => {
    if (!byCategory || !byPriority) {
      return [];
    }
    return byPriority.map((category, i) => ({
      label: formatCategoryLabel(category as ConnectionChangeStatusCategory, i + 1),
      options: byCategory[category as ConnectionChangeStatusCategory],
    }));
  }, [byCategory, byPriority]);
  const initialValues = useMemo(() => values || getBlankQuoteFormData(), [values]);
  const providersSorted = useMemo(() => {
    if (!providersData) {
      return [];
    }
    return providersData.providers.sort((a, b) => a.name.localeCompare(b.name));
  }, [providersData]);
  const onBlur = useCallback(
    (form: FormikProps<QuoteFormData>, fieldName: string) => () => {
      form.setFieldTouched(fieldName, true);
    },
    [],
  );
  const fieldFieldRef = useRef<HTMLInputElement>();
  const queryKeys = useMemo(
    () => [
      ["contracts", { sid }],
      ["quotes", { sid }],
    ],
    [sid],
  );
  const onSubmit = useCallback(
    (onSubmitValues: QuoteFormData, helpers: FormikHelpers<QuoteFormData>) => {
      const parsed = QuoteZodSchema.parse(onSubmitValues);
      /* eslint-disable @typescript-eslint/naming-convention */
      const {
        availability,
        connection_type,
        contract_length,
        download_kbps,
        installation_estimates,
        install_fee,
        monthly_fee,
        provider,
        // type,
        upload_kbps,
        // contract_status,
        details: { contract_status, type },
        contract_url,
        support_contacts,
      } = parsed;
      /* eslint-enable @typescript-eslint/naming-convention */
      const quoteFields = {
        connection_type: connection_type.sid,
        contract_length_months: contract_length,
        download_kbps,
        install_fee_cents: Math.floor((install_fee || 0) * 100),
        installation_estimate_days: installation_estimates || 0,
        provider: provider.sid,
        monthly_fee_cents: Math.floor(monthly_fee * 100),
        upload_kbps,
      };

      const options: MutateOptions<Quote, AxiosAPIError, unknown> = {
        onSuccess: () => {
          const verb = quote ? "updated" : "created";
          helpers.setSubmitting(false);
          queryKeys.forEach((key) => queryClient.invalidateQueries(key));
          notify(`Quote ${verb} successfully`, {
            variant: "positive",
            icon: "checkmarkCircle",
          });
        },
        onError: (error) => {
          const verb = quote ? "updating" : "creating";
          logError(error);
          helpers.setSubmitting(false);
          notify(`Error ${verb} quote`, {
            variant: "negative",
            icon: "warning",
          });
        },
        onSettled: () => {
          helpers.setSubmitting(false);
        },
      };

      const baseFields: Omit<ConnectAdminUpdateQuoteForDashboard_Req, "quotes_update_req"> = {
        isp: {
          status: type,
          ...(contract_url ? { contract_url } : {}),
          ...(support_contacts.contactPhoneNumber
            ? { contact_phone_number: support_contacts.contactPhoneNumber }
            : {}),
          ...(support_contacts.primaryContactEmail
            ? { primary_contact_email: support_contacts.primaryContactEmail }
            : {}),
        },
      };

      if (contract_status) {
        baseFields.connection_status = {
          status: contract_status.status,
          display: contract_status.display,
          category: contract_status.category,
        };
      }

      if (quote) {
        updateQuoteMutation.mutate(
          {
            ...baseFields,
            quotes_update_req: quoteFields,
          },
          options,
        );
      } else {
        createQuoteMutation.mutate(
          {
            ...baseFields,
            quotes_create_req: quoteFields,
          },
          options,
        );
      }
    },
    [createQuoteMutation, queryClient, queryKeys, quote, updateQuoteMutation],
  );
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const onSubmitted = useCallback(
    (form: FormikProps<QuoteFormData>) => (e: React.FormEvent<HTMLFormElement>) => {
      if (!hasSubmitted) {
        setHasSubmitted(true);
      }
      form.handleSubmit(e);
    },
    [hasSubmitted],
  );
  const onReset = useCallback(() => {
    setHasSubmitted(false);
  }, []);
  const onFileUpload = useCallback(
    (f: File) => {
      const formData = new FormData();
      formData.append("file", f, f.name);
      uploadContractMutation.mutate(formData, {
        onSuccess: () => {
          queryKeys.forEach((key) => queryClient.invalidateQueries(key));
        },
      });
    },
    [queryClient, queryKeys, uploadContractMutation],
  );

  return (
    <Wrapper>
      <Formik<QuoteFormData>
        initialValues={initialValues}
        enableReinitialize
        validationSchema={(...args) => {
          console.log("validationSchema", {
            args,
            toFormikValidationSchema,
            RefinedQuoteZodSchema,
          });
          return toFormikValidationSchema(RefinedQuoteZodSchema);
        }}
        onReset={onReset}
        onSubmit={onSubmit}>
        {(form) => {
          const { errors, isSubmitting, touched } = form;
          return (
            // <form onSubmit={form.handleSubmit}>
            <form onSubmit={onSubmitted(form)}>
              <DrawerHeader>
                <DrawerTitle>Edit quote</DrawerTitle>
                <DrawerControls>
                  <CloseDrawerLink>
                    <CloseDrawerButton />
                  </CloseDrawerLink>
                </DrawerControls>
              </DrawerHeader>
              <DrawerContent>
                <FieldContainer>
                  <TypedField name="provider">
                    {({ field }) => (
                      <PrimaryField
                        controls={
                          <TypedField name="availability">
                            {({ field: availabilityField }) => (
                              <AvailabilityCell
                                availability={availabilityField.value}
                                recommended={false}
                              />
                            )}
                          </TypedField>
                        }
                        label="Provider"
                        errorMessage={errorMessage(errors, touched, "provider", hasSubmitted)}
                        element={
                          <Select
                            options={providersSorted}
                            value={field.value}
                            isLoading={providersAreLoading}
                            onBlur={onBlur(form, "provider")}
                            onChange={(option: Provider) => {
                              form.setFieldValue("provider", option || undefined);
                            }}
                            hasError={!!errorMessage(errors, touched, "provider", hasSubmitted)}
                            styles={customStylesProvider}
                            ref={providerSelectRef}
                            getOptionLabel={getName}
                            getOptionValue={getSID}
                            menuPortalTarget={document.body}
                            menuPlacement="bottom"
                            menuPosition="absolute"
                            noOptionsMessage={() => null}
                            placeholder="Select provider"
                            isClearable
                            components={{ DropdownIndicator, ClearIndicator }}
                          />
                        }
                      />
                    )}
                  </TypedField>
                  <TypedField name="connection_type">
                    {({ field }) => (
                      <SecondaryField
                        label="Connection Type"
                        errorMessage={errorMessage(
                          errors,
                          touched,
                          "connection_type",
                          hasSubmitted,
                        )}
                        element={
                          <Select
                            options={connectionTypesData?.connectionTypes || []}
                            value={field.value || undefined}
                            isLoading={connectionTypesAreLoading}
                            onBlur={onBlur(form, "connection_type")}
                            onChange={(option: ConnectionType, ...rest) => {
                              form.setFieldValue("connection_type", option || undefined);
                            }}
                            hasError={
                              !!errorMessage(errors, touched, "connection_type", hasSubmitted)
                            }
                            styles={customStylesConnectionType}
                            ref={connectionTypeSelectRef}
                            getOptionLabel={getName}
                            getOptionValue={getSID}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            menuPosition="absolute"
                            noOptionsMessage={() => null}
                            placeholder="Select connection type"
                            isClearable
                            components={{ DropdownIndicator, ClearIndicator }}
                          />
                        }
                      />
                    )}
                  </TypedField>
                </FieldContainer>
                <FieldContainer>
                  <TypedField name="monthly_fee">
                    {({ field }) => (
                      <PrimaryField
                        label="Monthly Fee"
                        errorMessage={errorMessage(errors, touched, "monthly_fee", hasSubmitted)}
                        element={
                          <TextInput
                            value={field.value ?? ""}
                            autoComplete="off"
                            placeholder="Enter monthly fee"
                            prefix="$"
                            id={field.name}
                            name={field.name}
                            onBlur={onBlur(form, "monthly_fee")}
                            onChange={(val) =>
                              form.setFieldValue(field.name, val)
                            }
                            hasError={!!errorMessage(errors, touched, "monthly_fee", hasSubmitted)}
                          />
                        }
                      />
                    )}
                  </TypedField>
                  <TypedField name="install_fee">
                    {({ field }) => (
                      <SecondaryField
                        label="Install Fee"
                        errorMessage={errorMessage(errors, touched, "install_fee", hasSubmitted)}
                        element={
                          <TextInput
                            value={field.value ?? ""}
                            autoComplete="off"
                            onBlur={onBlur(form, "install_fee")}
                            placeholder="Enter value"
                            prefix="$"
                            id={field.name}
                            name={field.name}
                            onChange={(val) => {
                              console.log("Install Fee on change", { val, field, form });
                              form.setFieldValue(field.name, val)
                            }}
                            hasError={!!errorMessage(errors, touched, "install_fee", hasSubmitted)}
                          />
                        }
                      />
                    )}
                  </TypedField>
                </FieldContainer>
                <FieldContainer>
                  <PrimaryFieldComposite
                    label={
                      <VStack>
                        <Text>Speeds (Mbps)</Text>
                        {/* <BandwidthAlert data={form.values} name="download_kbps" /> */}
                        {/* <BandwidthAlert data={form.values} name="upload_kbps" /> */}
                      </VStack>
                    }
                    errorMessage={
                      errorMessage(errors, touched, "download_kbps", hasSubmitted) ||
                      errorMessage(errors, touched, "upload_kbps", hasSubmitted)
                    }
                    fields={
                      <HStack spacing={8}>
                        <TypedField name="download_kbps">
                          {({ field }) => (
                            <CompositeField
                              // errorMessage={errorMessage(errors, touched, "download_kbps")}
                              label="Download speed (Mbps)"
                              element={
                                <TextInput
                                  value={field.value ?? ""}
                                  autoComplete="off"
                                  placeholder="Download"
                                  onBlur={onBlur(form, "download_kbps")}
                                  id={field.name}
                                  name={field.name}
                                  icon="download"
                                  onChange={(val) =>
                                    form.setFieldValue(field.name, Number(val) || val)
                                  }
                                  hasError={
                                    !!errorMessage(errors, touched, "download_kbps", hasSubmitted)
                                  }
                                />
                              }
                            />
                          )}
                        </TypedField>
                        <TypedField name="upload_kbps">
                          {({ field }) => (
                            <CompositeField
                              label="Upload Speed (Mbps)"
                              // errorMessage={errorMessage(errors, touched, "upload_kbps")}
                              element={
                                <TextInput
                                  value={field.value ?? ""}
                                  autoComplete="off"
                                  onBlur={onBlur(form, "upload_kbps")}
                                  placeholder="Upload"
                                  id={field.name}
                                  name={field.name}
                                  icon="upload"
                                  onChange={(val) =>
                                    form.setFieldValue(field.name, Number(val) || val)
                                  }
                                  hasError={
                                    !!errorMessage(errors, touched, "upload_kbps", hasSubmitted)
                                  }
                                />
                              }
                            />
                          )}
                        </TypedField>
                      </HStack>
                    }
                  />
                </FieldContainer>
                <FieldContainer>
                  <TypedField name="installation_estimates">
                    {({ field }) => (
                      <PrimaryField
                        label="Installation estimate (days)"
                        errorMessage={errorMessage(
                          errors,
                          touched,
                          "installation_estimates",
                          hasSubmitted,
                        )}
                        element={
                          <TextInput
                            value={field.value ?? ""}
                            placeholder="Enter installation estimates"
                            autoComplete="off"
                            onBlur={onBlur(form, "installation_estimates")}
                            id={field.name}
                            name={field.name}
                            icon="clock"
                            onChange={(val) => form.setFieldValue(field.name, Number(val) ?? null)}
                            hasError={
                              !!errorMessage(
                                errors,
                                touched,
                                "installation_estimates",
                                hasSubmitted,
                              )
                            }
                          />
                        }
                      />
                    )}
                  </TypedField>
                </FieldContainer>
                <FieldContainer>
                  <PrimaryField element={null} label="Contract" />
                  <TypedField name="details.contract_status">
                    {({ field }) => (
                      <PrimaryField
                        label="Contract status"
                        errorMessage={errorMessage(
                          errors,
                          touched,
                          "details.contract_status",
                          hasSubmitted,
                        )}
                        element={
                          <Select
                            options={statusSelectGroup}
                            value={field.value}
                            isLoading={statusesAreLoading}
                            onBlur={onBlur(form, "details.contract_status")}
                            onChange={(option: ConnectionStatus) => {
                              console.log(
                                "TypedField::contract_status",
                                "option",
                                option,
                                "field",
                                field,
                                "form",
                                form,
                              );
                              form.setFieldValue("details", {
                                ...form.values.details,
                                ...{ contract_status: option || undefined },
                              });
                            }}
                            hasError={
                              !!errorMessage(
                                errors,
                                touched,
                                "details.contract_status",
                                hasSubmitted,
                              )
                            }
                            styles={customStylesStatus}
                            menuPortalTarget={document.body}
                            ref={statusSelectRef}
                            getOptionLabel={statusOptionLabel}
                            getOptionValue={statusOptionValue}
                            menuPlacement="bottom"
                            noOptionsMessage={() => null}
                            placeholder="Select Status"
                            isClearable
                            components={{ DropdownIndicator, ClearIndicator }}
                          />
                        }
                      />
                    )}
                  </TypedField>
                  <TypedField name="details.type">
                    {({ field }) => (
                      <SecondaryField
                        label="Type"
                        errorMessage={errorMessage(errors, touched, "details.type", hasSubmitted)}
                        element={
                          <BasicSelect
                            id={field.name}
                            name={field.name}
                            value={field.value}
                            onBlur={onBlur(form, "details.type")}
                            onValueChange={(val) => {
                              console.log(
                                "details.type",
                                "onValueChange",
                                val,
                                "form vals",
                                form.values,
                              );
                              form.setFieldValue("details", {
                                ...form.values.details,
                                type: val,
                              });
                            }}
                            hasError={
                              !!errorMessage(errors, touched, "details.type", hasSubmitted)
                            }>
                            <BasicSelectItem value={ISPStatus.IS_UNKNOWN}>Unknown</BasicSelectItem>
                            <BasicSelectItem value={ISPStatus.IS_PRIMARY}>Primary</BasicSelectItem>
                            <BasicSelectItem value={ISPStatus.IS_BACKUP}>Secondary</BasicSelectItem>
                          </BasicSelect>
                        }
                      />
                    )}
                  </TypedField>
                  <TypedField name="contract_length">
                    {({ field }) => (
                      <SecondaryField
                        label="Length (months)"
                        errorMessage={errorMessage(
                          errors,
                          touched,
                          "contract_length",
                          hasSubmitted,
                        )}
                        element={
                          <TextInput
                            value={field.value ?? ""}
                            placeholder=""
                            autoComplete="off"
                            onBlur={onBlur(form, "contract_length")}
                            id={field.name}
                            name={field.name}
                            onChange={(val) =>
                              form.setFieldValue("contract_length", Number(val) || val)
                            }
                            hasError={
                              !!errorMessage(errors, touched, "contract_length", hasSubmitted)
                            }
                          />
                        }
                      />
                    )}
                  </TypedField>
                  <TypedField name="pdf">
                    {({ field }) => (
                      <SecondaryField
                        label="PDF"
                        errorMessage={errorMessage(errors, touched, "pdf", hasSubmitted)}
                        element={
                          <FileField
                            field={field}
                            form={form}
                            isLoading={uploadContractMutation.isLoading}
                            onBlur={onBlur}
                            onUpload={onFileUpload}
                            ref={fieldFieldRef}
                          />
                        }
                      />
                    )}
                  </TypedField>
                </FieldContainer>
                <FieldContainer aria-disabled>
                  <TypedField name="support_contacts.primaryContactEmail">
                    {({ field }) => (
                      <PrimaryField
                        label="Support contact"
                        errorMessage={errorMessage(
                          errors,
                          touched,
                          "support_contacts.primaryContactEmail",
                          hasSubmitted,
                        )}
                        element={
                          <TextInput
                            value={field.value ?? ""}
                            placeholder="Enter email"
                            autoComplete="off"
                            onBlur={onBlur(form, "support_contacts.primaryContactEmail")}
                            id={field.name}
                            name={field.name}
                            icon="email"
                            onChange={(val) =>
                              form.setFieldValue("support_contacts.primaryContactEmail", val)
                            }
                            hasError={
                              !!errorMessage(
                                errors,
                                touched,
                                "support_contacts.primaryContactEmail",
                                hasSubmitted,
                              )
                            }
                          />
                        }
                      />
                    )}
                  </TypedField>
                  <TypedField name="support_contacts.contactPhoneNumber">
                    {({ field }) => (
                      <SecondaryField
                        label="Direct line"
                        errorMessage={errorMessage(
                          errors,
                          touched,
                          "support_contacts.contactPhoneNumber",
                          hasSubmitted,
                        )}
                        element={
                          <TextInput
                            value={field.value ?? ""}
                            placeholder=""
                            autoComplete="off"
                            onBlur={onBlur(form, "support_contacts.contactPhoneNumber")}
                            id={field.name}
                            name={field.name}
                            onChange={(val) =>
                              form.setFieldValue("support_contacts.contactPhoneNumber", val)
                            }
                            hasError={
                              !!errorMessage(
                                errors,
                                touched,
                                "support_contacts.contactPhoneNumber",
                                hasSubmitted,
                              )
                            }
                          />
                        }
                      />
                    )}
                  </TypedField>
                </FieldContainer>
                <Separator />
                <HStack align="end">
                  <HStack spacing={8} align="center">
                    <Button variant="tertiary">
                      <a
                        href={`/dashboard/companies/${companySID}/searches/${sid}/quotes/${prevSID}`}
                        onClick={handleOnClick}>
                        <Icon icon="arrowLeft" size={16} />
                      </a>
                    </Button>
                    <Button variant="tertiary">
                      <a
                        href={`/dashboard/companies/${companySID}/searches/${sid}/quotes/${nextSID}`}
                        onClick={handleOnClick}>
                        <Icon icon="arrowRight" size={16} />
                      </a>
                    </Button>
                  </HStack>
                  <ExpandFlex />
                  <HStack align="end" display="flex" spacing={8} justify="end">
                    <CloseDrawerLink onClose={form.resetForm}>
                      <Button variant="secondary" type="button">
                        Cancel
                      </Button>
                    </CloseDrawerLink>
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                  </HStack>
                </HStack>
              </DrawerContent>
            </form>
          );
        }}
      </Formik>
    </Wrapper>
  );
}

QuoteForm.whyDidYouRender = true;
