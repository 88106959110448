import { CompanyContractResponse, CompanyLocationResponse } from "@meterup/api";

import { formatBandwidth, pbProductToReadable, quoteToTerm } from "../components/Table/formatters";
import { AltRowTypeW, ValueWrapper } from "../components/Table/types";
import { EM_DASH } from "../constants";
import { UseFormattersType } from "./useFormatters";

export function wrapValue<T>(t: T): ValueWrapper<T> {
  return { value: t };
}

const NIL_VALUE = {
  value: EM_DASH,
};

export type ContractForDisplayType = {
  companySID?: string;
  contract: CompanyContractResponse;
  companyLocation: CompanyLocationResponse;
  sumCostCents: number;
};

export function formatContractForDisplay(
  { companyLocation, contract, sumCostCents }: ContractForDisplayType,
  { centsFormatter: formatCents, pbDateFormatter: formatDate }: UseFormattersType,
): AltRowTypeW | null {
  const { location, name, sid } = companyLocation;
  const address = location?.address;
  if (!address) {
    return null;
  }
  const { address1, address2, city, state } = address;
  const { internetServicePlan, latestStatus } = contract;
  if (!internetServicePlan) {
    return { subRows: [] };
  }

  const {
    createdAt,
    monthlyCostCents,
    product,
    provider,
    quote,
    renewalDate,
    sid: ispSid,
  } = internetServicePlan;

  const { quoteRequest } = internetServicePlan;
  const baseResult = {
    address: {
      address1,
      address2,
      city,
      name,
      sid,
      state,
      ...(quoteRequest && { quoteRequestSid: quoteRequest.sid }),
    },
    status: { connectionStatusChange: latestStatus },
  };

  if (quoteRequest) {
    const numQuotes = 0;
    return {
      ...baseResult,
      isp: { type: "quoteRequest" },
      product: wrapValue(undefined),
      bandwidth: {},
      monthlyRate: NIL_VALUE,
      renewalDate: NIL_VALUE,
      contractTerm: NIL_VALUE,
      created: NIL_VALUE,
      total: wrapValue(`${numQuotes} quotes`),
      subRows: [] as AltRowTypeW[],
    };
  }

  return {
    ...baseResult,
    isp: { name: provider, sid: ispSid, type: "normal" },
    product: wrapValue(pbProductToReadable(product)),
    bandwidth: formatBandwidth(internetServicePlan),
    monthlyRate: wrapValue(formatCents(monthlyCostCents)),
    renewalDate: wrapValue(formatDate(renewalDate)),
    contractTerm: wrapValue(quoteToTerm(quote)),
    created: wrapValue(createdAt && formatDate(createdAt)),
    total: wrapValue(formatCents(sumCostCents)),
    subRows: [] as AltRowTypeW[],
  };
}
