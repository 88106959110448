import React, { useMemo } from "react";

import { Invoice, InvoiceCreateRequest } from "@meterup/api";
import { useInvalidateInvoices } from "@meterup/api/src/billing/queryKeys";
import useEditInvoice from "@meterup/api/src/billing/useEditInvoice";
import { DrawerContent } from "@meterup/metric";
import StackedSkeleton from "@meterup/ui/src/components/Loading/StackedSkeleton";
import readDocument from "@meterup/ui/src/files/readDocument";
import { logError } from "@meterup/ui/src/Log.utils";
import { formatDateForAPI, formatDateStringFromAPI } from "@meterup/ui/src/utils/dates";
import { formatCurrencyToNum } from "@meterup/ui/src/utils/formatCurrency";
import { snakeToTitleCase } from "@meterup/ui/src/utils/snakeToTitleCase";
import { SubmitHandler } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";

import InvoiceFormDrawer from "../../../components/invoices/InvoiceFormDrawer";
import { InvoiceSchemaFormData } from "../../../components/invoices/invoiceSchema";
import { InvoicesPageContextType } from "../../../contexts/InvoicesPageContext";
import { InvoicePageParams } from "../../../routerParams";

export default function EditInvoice() {
  const ctx = useOutletContext<InvoicesPageContextType>();
  const { ispContractSID, invoiceSID } = useParams<InvoicePageParams>();

  const invoice = useMemo<Invoice | null>(() => {
    const invoiceResponse = ctx.invoices?.find((i) => i.invoice?.sid === invoiceSID);
    if (!invoiceResponse) {
      return null;
    }
    return invoiceResponse.invoice;
  }, [ctx.invoices, invoiceSID]);
  const mutation = useEditInvoice(ispContractSID, invoiceSID);
  const invalidateInvoices = useInvalidateInvoices();
  const defaultValues = useMemo<InvoiceSchemaFormData | null>(() => {
    if (!invoice) {
      return null;
    }
    return {
      invoice: {
        sid: invoiceSID,
        ispContractSID,
        dueDate: formatDateStringFromAPI(invoice.dueDate),
        amountActual: `${formatCurrencyToNum({ amount: invoice.amountActual })}`,
        amountActualCurrency: invoice.amountActualCurrency,
        invoicedAt: invoice.invoicedAt ? formatDateStringFromAPI(invoice.invoicedAt) : "",
        paidAt: invoice.paidAt ? formatDateStringFromAPI(invoice.paidAt) : "",
      },
      status: {
        value: invoice.status,
        label: snakeToTitleCase(invoice.status),
      },
      billingCycleDates: {
        startDate: formatDateStringFromAPI(invoice.billingCycleStartDate),
        endDate: formatDateStringFromAPI(invoice.billingCycleEndDate),
      },
      invoiceDocument: null,
      fileAttachment: {
        name: invoice.fileAttachment?.name,
        file: null,
        deleteAttachment: false,
      },
    };
  }, [invoice, invoiceSID, ispContractSID]);
  const onSubmit = useMemo<SubmitHandler<InvoiceSchemaFormData>>(
    () => async (innerData) => {
      try {
        const {
          invoice: invoiceUpdates,
          invoiceDocument: file,
          billingCycleDates,
          status,
        } = innerData;
        const { sid: ignoredSID, ispContractSID: ignored, ...invoiceUpdatesRest } = invoiceUpdates;
        const req: InvoiceCreateRequest = {
          invoice: {
            ...invoice,
            ...invoiceUpdatesRest,
            billingCycleStartDate: formatDateForAPI(billingCycleDates.startDate),
            billingCycleEndDate: formatDateForAPI(billingCycleDates.endDate),
            ispContractsSid: ispContractSID,
            status: status.value,
            amountCurrency: invoiceUpdatesRest.amountActualCurrency,
            amount: invoiceUpdatesRest.amountActual,
            invoicedAt: invoiceUpdatesRest.invoicedAt,
            paidAt: invoiceUpdatesRest.paidAt,
          },
        };

        if (file) {
          const documentContents = await readDocument({ file });
          if (documentContents) {
            req.document = documentContents;
          }
        }

        await mutation.mutateAsync(req);
        invalidateInvoices();
        return true;
      } catch (e) {
        logError(e);
        return false;
      }
    },
    [invalidateInvoices, invoice, ispContractSID, mutation],
  );

  if (!invoice) {
    return (
      <DrawerContent>
        <StackedSkeleton count={10} />
      </DrawerContent>
    );
  }

  return (
    <InvoiceFormDrawer
      error={mutation.isError && mutation.error?.response?.data}
      defaultValues={defaultValues}
      ispContractSID={ispContractSID}
      onSubmit={onSubmit}
      purpose="edit"
      successData={mutation.isSuccess && mutation.data}
    />
  );
}
