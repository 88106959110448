import { useContext } from "react";

import { IdentifiedContext, IdentifiedContextType } from "../contexts/IdentifiedContext";

export function useIdentity() {
  return useContext<IdentifiedContextType>(IdentifiedContext);
}

export function useIdentityName() {
  const identity = useIdentity();
  return [identity?.firstName, identity?.lastName].filter(Boolean).join(" ");
}
