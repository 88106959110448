import isNumber from "lodash/isNumber";
import { RefinementCtx, z, ZodType } from "zod";

export function zodBlankable<T>(type: ZodType<T>) {
  return z.union([z.literal(""), type]);
}
type CtxType = Pick<RefinementCtx, "addIssue">;

function transformStringToNullableNumber(value: string | number | null | undefined, ctx: CtxType) {
  let num = value;
  if (!isNumber(value)) {
    if ((value ?? "").length === 0) {
      return null;
    }
    let val = value;
    if (value?.includes(".")) {
      val += "0";
    }
    num = Number(val);
  }
  if (Number.isNaN(num) || !Number.isFinite(num)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Field must be a valid number or an empty string",
    });

    if (num < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Field must be a positive number",
      });
      return z.NEVER;
    }

    return z.NEVER;
  }

  return num;
}

export const numericString = z
  .union([z.string(), z.number(), z.literal("")])
  .nullish()
  .transform(transformStringToNullableNumber);

export const requiredNumericString = z
  .union([z.string().trim(), z.number(), z.literal("")])
  .nullish()
  .transform(transformStringToNullableNumber)
  .transform((value, ctx) => {
    if (!isNumber(value)) {
      if ((value ?? "").length === 0) {
        return value;
      }
    }
    if (value > 0) {
      return value;
    }

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Field must be a positive number",
    });
    return z.NEVER;
  });

const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;

type MkDateStringParams = {
  nullable?: boolean;
};

export function mkDateStringType(params: MkDateStringParams = {}) {
  return z.string().transform((value, ctx) => {
    if (params.nullable && value === "") {
      return null;
    }

    const match = value.match(dateRegex);

    if (!match) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid date format",
      });

      return z.NEVER;
    }

    const [, year, month, day] = match;

    const date = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)));
    if (Number.isNaN(date.getTime())) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid date",
      });

      return z.NEVER;
    }

    return date;
  });
}

export const DateStringType = mkDateStringType();

export const DateRangeType = z
  .object({
    startDate: DateStringType,
    endDate: DateStringType,
  })
  .refine(
    ({ startDate, endDate }) => startDate && endDate && endDate > startDate,
    "End date must come before start date.",
  );

export const DollarsToCentsType = z
  .string()
  .transform((val, ctx) => {
    const num = Number(val.replaceAll(",", ""));
    if (Number.isNaN(num)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Invalid number",
      });

      return z.NEVER;
    }

    return num * 1_000;
  })
  .refine((v) => v > 1_000, "Amount must be at least $1");

export const FileAttachmentType = z
  .object({
    name: z.string().nullish(),
    file: z.instanceof(File).nullish(),
    deleteAttachment: z.boolean().default(false),
  })
  .refine(
    (v) => v.deleteAttachment && !!v.file !== v.deleteAttachment,
    "Cannot provide both a file and delete attachment",
  );

export const AmountWithCurrencyType = z.object({
  amount: DollarsToCentsType,
  currency: z.string().default("USD5"),
});
