import { styled } from "../styled";
import { TabsBarContainer } from "./Tabs/TabsBarContainer";

const LabelRow = styled("div", {
  display: "flex",
  padding: "0 $20 $14",
  boxShadow: "$fenceBottomLight",
  alignItems: "center",

  "& > div, & > h3": {
    marginTop: "$14",
  },

  [`& ${TabsBarContainer}`]: {
    marginTop: "$14",

    "& + div + div": {
      marginTop: 0,
    },
  },
});

export default LabelRow;
