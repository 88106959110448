import React, { useCallback, useMemo } from "react";

import { ContractResponse } from "@meterup/api";
import { Button, HStack, Text } from "@meterup/metric";
import { DataView, Drawer, OpenDrawerLink } from "@meterup/ui";
import { EmDash } from "@meterup/ui/src/constants";
import Header from "@meterup/ui/src/DataView/components/Header";
import { compareDateToRange, formatDateStringFromAPI } from "@meterup/ui/src/utils/dates";
import { formatCurrency } from "@meterup/ui/src/utils/formatCurrency";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useNavigate, useOutlet, useOutletContext, useParams } from "react-router-dom";

import { CompanyContextType } from "../../../contexts/CompanyContext";
import { H3 } from "../../../styles/dashboard";

const columnHelper = createColumnHelper<ContractResponse>();
const columns: ColumnDef<ContractResponse, string>[] = [
  columnHelper.accessor(
    (r) => {
      const { address } = r || {};
      if (!address) {
        return null;
      }
      const { address1, address2, city, state } = address;

      return [address1, address2, city, state].filter(Boolean).join(", ");
    },
    {
      id: "address",
      header: "Address",
      size: 75,
    },
  ),
  columnHelper.accessor(
    (r): string => {
      const { ispContract } = r;
      if (!ispContract) {
        return "";
      }
      const { contractStartDate, contractEndDate } = ispContract;
      const dates = [contractStartDate, contractEndDate].filter(Boolean).map((d) => new Date(d));

      const comparison = compareDateToRange(new Date(), dates);
      if (comparison === 0) {
        return "Current";
      }

      if (comparison === 1) {
        return "Past";
      }

      return "Upcoming";
    },
    {
      id: "status",
      header: "Status",
      size: 75,
    },
  ),
  columnHelper.accessor(
    (r) => {
      const { ispContract } = r;
      if (!ispContract) {
        return "";
      }
      return formatDateStringFromAPI(ispContract.contractStartDate);
    },
    {
      id: "start-date",
      header: "Start Date",
      size: 75,
    },
  ),
  columnHelper.accessor(
    (r) => {
      const { ispContract } = r;
      if (!ispContract) {
        return "";
      }
      return formatDateStringFromAPI(ispContract.contractEndDate);
    },
    {
      id: "end-date",
      header: "End Date",
      size: 75,
    },
  ),
  columnHelper.accessor(
    (r) => {
      const { provider } = r;
      if (!provider) {
        return "";
      }

      return provider.name;
    },
    {
      id: "provider",
      header: "Provider",
      size: 75,
    },
  ),
  columnHelper.accessor(
    (r) => {
      const { ispContract } = r;
      if (!ispContract) {
        return "";
      }
      return formatCurrency({ amount: ispContract.amountDue });
    },
    {
      id: "amount",
      header: "Amount",
      size: 75,
    },
  ),
  columnHelper.display({
    id: "actions",
    header: "Actions",
    size: 125,
    cell: (r) => {
      const contractSID = r.row.original.ispContract?.sid;
      if (!contractSID) {
        return <EmDash />;
      }
      return (
        <HStack spacing={8}>
          <OpenDrawerLink path={`edit/${contractSID}`}>
            <Button type="button" variant="tertiary" icon="pencil" arrangement="leading-icon">
              Edit
            </Button>
          </OpenDrawerLink>
        </HStack>
      );
    },
  }),
];

export default function Contracts() {
  const ctx = useOutletContext<CompanyContextType>();
  const { companySID } = useParams();
  const { contracts: allContracts } = ctx;
  const contracts = useMemo(
    () => allContracts?.contracts || ([] as ContractResponse[]),
    [allContracts?.contracts],
  );
  const children = useOutlet(ctx);
  const buttons = useMemo(
    () => (
      <OpenDrawerLink path="create">
        <Button icon="plusCircle" variant="tertiary" arrangement="leading-label" size="medium">
          Add contract
        </Button>
      </OpenDrawerLink>
    ),
    [],
  );
  const navigate = useNavigate();
  const onClickRow = useCallback(
    ({ record }) => {
      navigate(`/dashboard/companies/${companySID}/billing/${record.ispContract.sid}/invoices`);
    },
    [companySID, navigate],
  );
  return (
    <>
      <DataView
        columns={columns}
        data={contracts}
        buttons={buttons}
        header={
          <Header>
            <Text size={14} lineHeight={20} as={H3} color={{ light: "gray-800" }} weight="medium">
              Contracts
            </Text>
          </Header>
        }
        onClickRow={onClickRow}
      />
      <Drawer>{children}</Drawer>
    </>
  );
}
