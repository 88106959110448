import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Alert, Box, VStack } from "@meterup/metric";
import {
  Column,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import debounce from "lodash/debounce";
import get from "lodash/get";
import isString from "lodash/isString";

import SearchButtonInput from "../components/SearchButtonInput";
import { FirstCellTh } from "../components/Table/FirstCell";
import { logError } from "../Log.utils";
import { styled } from "../styled";
import { AlertWrapper } from "../styles/AlertWrapper";
import { InnerTd } from "../styles/Table/InnerTd";
import {
  StickyFinalRow,
  StickyTableHeader,
  StickyTd,
  StickyTh,
  Table,
  Tr,
} from "../styles/Table/table-styles";
import { TdContentWrapper } from "../styles/Table/TdContentWrapper";
import SortableHeader from "../Table/components/SortableHeader";
import { isFunction } from "../utils";
import { leafMatches } from "../utils/filters";
import BaseDataView from "./BaseDataView";
import DataViewContent from "./DataViewContent";
import DataViewRow, { PartitionCellRows, partitionCellRows } from "./DataViewRow";
import useRecalculateDimensions, {
  HeightProps,
  widthsOfFirstRow,
} from "./hooks/useRecalculateDimensions";
import {
  CellSizingFn,
  DataViewTheme,
  LastColumnVariant,
  OnClickRowProps,
  OnOpenDrawerProps,
  OpenDrawerProps,
  RestDataViewProps,
} from "./types";
import { getParentAnchor } from "./utils";

const ContentWrapper = styled(VStack, {
  paddingBottom: "$2",

  variants: {
    variant: {
      light: {
        [`& ${StickyTd}`]: {
          "&:nth-child(2)": {
            // boxShadow: "$fenceBottomLight, $fenceTopLight",
            boxShadow: "$fenceBottomLight",
          },
        },
        [`& ${StickyTh}`]: {
          "&:nth-child(2)": {
            // boxShadow: "$fenceBottomLight, $fenceTopLight",
            boxShadow: "$fenceBottomLight",
          },
        },
      },
    },
    default: {},
  },
});

function isChildContent<E>(
  element: React.ReactNode,
  childComponent: E,
): element is React.ReactElement<E> {
  return React.isValidElement(element) && element.type === childComponent;
}

type FormatColumnForSearchFn<RecordType> = (row: Row<RecordType>) => any;

type DataViewProps<RecordType> = {
  formatColumnForSearch?: Record<string, FormatColumnForSearchFn<RecordType>>;
  searchValue?: string;
  setSearchValue?: (value: string) => void;

  cellSizing?: Record<string, CellSizingFn>;
  chevronLink?: (row: RecordType) => string;
  children?: React.ReactNode;
  columns: ColumnDef<RecordType, any>[];
  data: RecordType[];
  defaultSorting?: SortingState;
  grouping?: string[];
  ignoreRowClick?: boolean;
  lastColumnVariant?: LastColumnVariant;
  lastColumnIsSticky?: boolean;
  minColumnWidth?: number;
  // noWrapColumns?: string[];
  noWrapColumns?: string[];
  numLeadingStickyColumns?: number;
  sizeWeights?: Record<string, number>;
  staticHeight?: boolean;
  theme?: DataViewTheme;
  finalRow?: (columns?: Column<RecordType>[]) => Row<RecordType>;

  onClickRow?: (props: OnClickRowProps<RecordType>) => void;
  onOpenDrawer?: (props: OnOpenDrawerProps<RecordType>) => React.ReactElement | undefined;
} & RestDataViewProps;

// Current variation works as the plain Companies view
//
// To make companies view work, we need to:
// - Add drawer (as Context-able?)
//
// Need to add the variation that involves nested tabs.
//   I think to do that we make this either take a Heading or Tabs. Then we just pick one or the other
//   than to display nested tabs, we just nest this thing inside a parent component with tabs. Can probably re-use similar Box as VStack then HStack as LabelRow stuff
export default function DataView<RecordType>({
  formatColumnForSearch,
  searchValue: searchValueProp,
  setSearchValue: setSearchValueProp,
  buttons,
  cellSizing,
  chevronLink,
  children: childrenProp,
  columns,
  data,
  defaultSorting,
  grouping,
  finalRow,
  ignoreRowClick,
  lastColumnVariant = "default",
  minColumnWidth = 100,
  noWrapColumns,
  numLeadingStickyColumns = 1,
  sizeWeights,
  staticHeight = false,
  theme = "default",

  onOpenDrawer,
  onClickRow,

  ...props
}: DataViewProps<RecordType>) {
  if (onOpenDrawer && onClickRow) {
    logError(
      "Cannot have both onOpenDrawer and onClickRow. onOpenDrawer will take precedence and onClickRow will be ignored.",
    );
  }
  // If we only support the use case of plumbing search through flat tables, I think it's
  // fine to just use the local state and not bubble it up?
  const [internalSearchValue, setInternalSearchValue] = useState("");
  const [searchValue, setSearchValue] = useMemo(() => {
    if (isString(searchValueProp) && isFunction(setSearchValueProp)) {
      return [searchValueProp, setSearchValueProp];
    }

    return [internalSearchValue, setInternalSearchValue];
  }, [internalSearchValue, searchValueProp, setSearchValueProp]);
  const globalFilterFn = useCallback(
    (row: Row<RecordType>, columnId: string, filterValue: any) => {
      const searchFn = get(formatColumnForSearch, columnId);
      if (isFunction(searchFn)) {
        return leafMatches(searchFn(row), filterValue);
      }

      return leafMatches(row.getValue(columnId), filterValue, columnId);
    },
    [formatColumnForSearch],
  );
  const [sorting, setSorting] = useState<SortingState>(defaultSorting || []);
  const tableOptions = useMemo<TableOptions<RecordType>>(
    () => ({
      columns,
      data,
      enableFilters: true,
      state: {
        globalFilter: searchValue,
        expanded: true,
        sorting,
        ...(grouping ? { grouping } : {}),
      },
      filterFns: {
        fuzzy: () => true,
      },
      enableGrouping: true,
      enableSorting: true,
      enableMultiSorting: false,
      // enableSortingRemoval: true,
      getColumnCanGlobalFilter: () => true,
      enableGlobalFilter: true,
      filterFromLeafRows: true,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getGroupedRowModel: getGroupedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      onSortingChange: setSorting,
      globalFilterFn,
      // debugAll: true,
      // debugTable: true,
      // debugRows: true,
      // debugColumns: true,
      // debugHeaders: true,
    }),
    [columns, data, globalFilterFn, grouping, searchValue, sorting],
  );
  const table = useReactTable<RecordType>(tableOptions);
  const onClickRecord = useCallback(
    (record: RecordType) => (e: React.SyntheticEvent) => {
      if (ignoreRowClick) {
        return;
      }
      const { target } = e;
      if (target instanceof Element) {
        const parent = getParentAnchor(target, 3);
        if (parent.levelsUp !== "exceeds-limit") {
          return;
        }
      }
      e.preventDefault();
      if (!onClickRow) {
        return;
      }
      onClickRow({ record, openDrawer: () => null });
    },
    [ignoreRowClick, onClickRow],
  );
  const enhancedButtons = useCallback(
    (openDrawerProps: OpenDrawerProps) => (
      <>
        {isFunction(buttons) ? buttons(openDrawerProps) : buttons}
        <SearchButtonInput setValue={setSearchValue} value={searchValue} />
      </>
    ),
    [buttons, searchValue, setSearchValue],
  );
  const [colWidths, setColWidths] = useState<Record<string, number>>();
  const rowModel = table.getSortedRowModel();
  const rows = useMemo<Row<RecordType>[]>(() => rowModel.rows, [rowModel]);
  const partitionedCellRows = useMemo(() => {
    if (rows.length === 0) {
      return [] as PartitionCellRows<RecordType>;
    }
    const [firstRow] = rows;
    return partitionCellRows(firstRow, numLeadingStickyColumns);
  }, [numLeadingStickyColumns, rows]);
  const tableRef = useRef<HTMLTableElement>(null);
  const { recalculate: doRecalculate, dimensions } = useRecalculateDimensions({
    cellSizing,
    columns,
    minColumnWidth,
    noWrapColumns,
    sizeWeights,
    tableRef,
  });
  const recalculate = useCallback(() => {
    const dims = doRecalculate();
    table.setColumnSizing(dims);
  }, [doRecalculate, table]);
  const [columnsOrdered, setColumnsOrdered] = useState<Column<RecordType>[]>();
  useEffect(() => {
    recalculate();
    const fn = debounce(recalculate, 250, {});
    window.addEventListener("resize", fn);
    window.addEventListener("focus", fn);
    const tableRefEl = tableRef.current;
    let disconnect = () => null;
    if (tableRefEl) {
      const observer = new ResizeObserver(([observerEntry]) => {
        const tbl = observerEntry.target;

        const calculatedColumnWidths = widthsOfFirstRow(tbl);
        if (!calculatedColumnWidths || partitionedCellRows.length === 0) {
          return;
        }
        const {
          cells: { leading, trailing },
        } = partitionedCellRows[0];
        const leadingIds = leading.map((cell) => cell.column.id);
        const trailingIds = trailing.map((cell) => cell.column.id);
        const allIds = [...leadingIds, ...trailingIds];
        const columnWidthMap = {};
        calculatedColumnWidths.forEach((width, idx) => {
          const id = allIds[idx];
          if (id) {
            columnWidthMap[id] = width;
          }
        });
        const leadingCols = leading.map((cell) => cell.column);
        const trailingCols = trailing.map((cell) => cell.column);
        setColumnsOrdered([...leadingCols, ...trailingCols]);
        setColWidths(columnWidthMap);
      });
      observer.observe(tableRefEl);
      disconnect = () => observer.disconnect();
    }

    // disconnect the ResizeObserver on cleanup
    return () => {
      window.removeEventListener("resize", fn);
      window.removeEventListener("focus", fn);
      disconnect();
    };
  }, [columns, partitionedCellRows, recalculate]);
  const lastColumnIsSticky = ["lastTdSticky", "lastTdSticky-NoArrow"].includes(lastColumnVariant);
  const heightProps = useMemo<HeightProps>(() => {
    const { innerTableHeight, joinedHeight, tableHeight } = dimensions;
    if (!tableHeight) {
      return { table: {}, tableParentDiv: {} };
    }
    const tableParentDiv = { height: joinedHeight };

    const tableOverflowsAvailableSpace = innerTableHeight > tableHeight;
    if (staticHeight && tableOverflowsAvailableSpace) {
      return {
        table: { height: tableHeight },
        tableParentDiv,
      };
    }

    return { table: {}, tableParentDiv };
  }, [dimensions, staticHeight]);

  // const rowModel = table.getGroupedRowModel();
  const children = useMemo(() => {
    if (!childrenProp) {
      return null;
    }
    const childProp = React.Children.only(childrenProp);
    if (isChildContent(childProp, DataViewContent)) {
      const sizing = table.getState().columnSizing;
      return React.cloneElement(childProp, {
        columnSizingState: colWidths || sizing,
      });
    }
    throw new Error("Invalid child provided, must be instance of DataViewContent.");
  }, [childrenProp, colWidths, table]);
  const finalRowData = useMemo(() => {
    if (!finalRow) {
      return null;
    }

    return finalRow(columnsOrdered);
  }, [columnsOrdered, finalRow]);

  return (
    <BaseDataView buttons={enhancedButtons} {...props}>
      <ContentWrapper variant={theme} style={heightProps.tableParentDiv}>
        <Table ref={tableRef}>
          <Box as={StickyTableHeader} width="full">
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id} theme={theme}>
                {headerGroup.headers.map((header, idx, headers) => {
                  const colDef = header.column.columnDef;
                  const size = header.column.getSize();
                  const { header: text } = colDef;
                  const extraProps = size ? { css: { width: size } } : {};
                  if (idx === 0) {
                    return (
                      <FirstCellTh key={header.id} {...extraProps}>
                        <SortableHeader header={header} />
                      </FirstCellTh>
                    );
                  }
                  if (idx === headers.length - 1 && lastColumnIsSticky) {
                    return (
                      <StickyTh className="last" key={header.id} {...extraProps}>
                        <InnerTd type="lastHeader">
                          <TdContentWrapper position="lastHeader">{text}</TdContentWrapper>
                        </InnerTd>
                      </StickyTh>
                    );
                  }
                  return (
                    <StickyTh key={header.id} {...extraProps}>
                      <SortableHeader header={header} />
                    </StickyTh>
                  );
                })}
                {!lastColumnIsSticky && (
                  <StickyTh className="last" key="stickyThLastCol" css={{ width: 20 }}>
                    <InnerTd type="lastHeader">
                      <TdContentWrapper position="lastHeader">&nbsp;</TdContentWrapper>
                    </InnerTd>
                  </StickyTh>
                )}
              </Tr>
            ))}
          </Box>
          <Box as="tbody" width="full">
            {rows.map((row) => (
              <DataViewRow
                chevronLink={chevronLink}
                key={`outer-${row.id}`}
                lastColumnIsSticky={lastColumnIsSticky}
                onClickRecord={onClickRecord}
                numLeadingStickyColumns={numLeadingStickyColumns}
                row={row}
                theme={theme}
                trailingChevron={!lastColumnIsSticky}
              />
            ))}
            {finalRowData ? (
              <DataViewRow
                as={StickyFinalRow}
                key="outer-entry-fields"
                lastColumnIsSticky={lastColumnIsSticky}
                onClickRecord={() => null}
                numLeadingStickyColumns={numLeadingStickyColumns}
                row={finalRowData}
                theme={theme}
                trailingChevron={false}
              />
            ) : null}
          </Box>
        </Table>
        {rows.length === 0 && (
          <AlertWrapper>
            <Alert variant="neutral" copy="No matches found" />
          </AlertWrapper>
        )}
        {children}
      </ContentWrapper>
    </BaseDataView>
  );
}

DataView.whyDidYouRender = true;
