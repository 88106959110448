/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useMemo, useState } from "react";

import { httpDelete, QuoteRequest, useApiResources } from "@meterup/api";
import { Select, Table, useFlashMessage, useModal, useRouter } from "@meterup/ui";
import { Column } from "@tanstack/react-table";
import isEmpty from "lodash/isEmpty";

import { route } from "../../routing";
import { CellTypeBase } from "../QuoteRequest/types";
import StatusChip from "./StatusChip";
import { statusToLabel } from "./statusToFields";

const noop = () => null;

type StatusFilter = "all" | "open" | "active";

const statusFilterItems: { value: StatusFilter; label: string }[] = [
  { value: "all", label: "Show All" },
  { value: "active", label: "Show Active" },
  { value: "open", label: "Manual Quotes Requested" },
];

const truncate = (text: string, numChars: number): string => {
  const truncated = text.substring(0, numChars);
  if (numChars < text.length) {
    return `${truncated}...`;
  }

  return truncated;
};

const DATE_FORMATTER = new Intl.DateTimeFormat();

type CellType = CellTypeBase<QuoteRequest>;

export default function QuoteRequestsTable() {
  const [statusFilter, setStatusFilter] = useState<StatusFilter>("all");
  const { handleOnClick } = useRouter();
  // Pass query param `with-user-info` by default to only fetch quote requests with associated emails
  const quoteRequestsApiQueryString = useMemo(
    () => (statusFilter !== "all" ? `?${statusFilter}` : "?with-user-info=true"),
    [statusFilter],
  );

  const handleOnChange = (value: StatusFilter) => {
    setStatusFilter(value);
  };

  const [loading, quoteRequests, reload] = useApiResources(
    "quoteRequests",
    `admin/quote-requests${quoteRequestsApiQueryString}`,
  );

  const flashMessage = useFlashMessage({
    type: "success",
    message: "Successfully deleted search.",
  });

  const { onShowModal, onHideModal } = useModal({
    type: "warning",
    title: "Are you sure?",
    message: "Are you sure you'd like to delete this search? This action is not reversible!",
    confirmText: "Delete Search",
    onConfirm: async (sid: string) => {
      await httpDelete<QuoteRequest>(`admin/quote-requests/${sid}`);
      await reload();
      flashMessage();
      onHideModal();
    },
    showCancel: true,
  });

  const columns = useMemo(
    (): Column<any>[] => [
      {
        header: "Company",
        accessorKey: "companyName",
        cell: ({
          row: {
            original: { sid, companyName },
          },
        }: CellType) => (
          <a href={route("quoteRequest", { sid })} onClick={handleOnClick}>
            {companyName || <i>None Provided</i>}
          </a>
        ),
      },
      {
        header: "Contact",
        accessorKey: "contactName",
      },
      {
        header: "Email",
        accessorKey: "contactEmail",
      },
      {
        header: "Address",
        accessorFn: (row: QuoteRequest) => {
          const address1 = row.location?.address?.address1;
          if (address1 !== undefined) {
            return truncate(address1, 35);
          }
          return undefined;
        },
      },
      {
        header: "Location",
        accessorFn: (row: QuoteRequest) =>
          `${row.location?.address?.city}, ${row.location?.address?.state}`,
      },
      {
        header: "Status",
        accessorFn: statusToLabel,
        cell: ({
          row: {
            original: { status, isManualResponsePending },
          },
        }: CellType) => (
          <StatusChip status={status} isManualResponsePending={isManualResponsePending} />
        ),
      },
      {
        header: "Created At",
        id: "createdAt",
        accessorFn: (quoteRequest: QuoteRequest) => new Date(quoteRequest.createdAt),
        sortingFn: "datetime",
        enableSorting: true,
        size: 100,
        cell: ({ getValue }: CellType) =>
          getValue() && !Number.isNaN(getValue()) ? DATE_FORMATTER.format(getValue()) : "-",
      },
      {
        header: "",
        id: "delete",
        disableSortBy: true,
        cell: ({
          row: {
            original: { sid },
          },
        }: CellType) => (
          <a
            href="#"
            muted
            onClick={async (e) => {
              e.preventDefault();
              onShowModal(sid);
            }}>
            Delete
          </a>
        ),
      },
    ],
    [handleOnClick, onShowModal],
  );

  return (
    <Table
      loading={loading}
      columns={columns}
      data={quoteRequests}
      showSearch
      defaultSortBy={[{ id: "createdAt", desc: true }]}
      renderFilters={() => (
        <div className="w-1/4 pr-4">
          <Select
            options={statusFilterItems}
            input={{
              name: "statusFilter",
              value: statusFilter,
              onChange: handleOnChange,
              onBlur: noop,
              onFocus: noop,
            }}
            size="extra_large"
            meta={{}}
          />
        </div>
      )}
    />
  );
}
