import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { put } from "../axios";
import { ISPContractCreateRequest_Error } from "../connectadminProtos";
import { APIErrorType } from "../errors";
import { ISPContract, ISPContractCreateRequest } from "../protos";

export type UseCreateContractErrorType = APIErrorType<
  ISPContractCreateRequest_Error,
  "conflicting_contract"
>;

export default function useEditContract(ispSID: string) {
  return useMutation<ISPContract, AxiosError<UseCreateContractErrorType>, ISPContractCreateRequest>(
    (req) =>
      put(
        `/v1/connect/admin/internet-service-plans/${ispSID}/contracts/${req.ispContract.sid}`,
        req,
      ),
  );
}
