import React from "react";

import useActiveStatusTab, {
  QueryParam,
  StatusTabItems,
  StatusTabItemsByVal,
  VisibleTabs,
} from "../../hooks/useActiveStatusTab";
import { TabItem } from "./Tabs";
import { TabsBar } from "./TabsBar";

type StatusTabBarProps = {
  basePath: string;
  tabCount: Record<StatusTabItems, number>;
};
export default function StatusTabBar({ basePath, tabCount }: StatusTabBarProps) {
  const activeTabValue = useActiveStatusTab();

  return (
    <TabsBar>
      {VisibleTabs.map((tab) => {
        const pathParams = new URLSearchParams({
          ...(tab === StatusTabItems.All ? {} : { [QueryParam]: tab }),
        }).toString();
        const path = `${basePath}${pathParams.length > 0 ? `?${pathParams}` : ""}`;
        const isActive = tab === activeTabValue;
        return (
          <TabItem path={path} count={tabCount[tab]} isActive={isActive} key={`status-tab-${tab}`}>
            {StatusTabItemsByVal[tab]}
          </TabItem>
        );
      })}
    </TabsBar>
  );
}
