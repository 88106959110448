import { ISPStatus } from "@meterup/api";
import { createTypedField } from "@meterup/ui/src/utils/stolen-from-frontends/createTypedField";
import { numericString, requiredNumericString, zodBlankable } from "@meterup/ui/src/zod/helpers";
import isEmpty from "lodash/isEmpty";
import { z } from "zod";

// Following schemas derived using https://transform.tools/typescript-to-zod
export const connectionChangeStatusCategorySchema = z.union([
  z.literal("active"),
  z.literal("in-progress"),
  z.literal("needs-attention"),
  z.literal("quotes"),
]);

export const connectionStatusSchema = z.object(
  {
    sid: z.string().optional(),
    status: z.string(),
    display: z.string(),
    category: connectionChangeStatusCategorySchema,
    priority: z.number(),
  },
  {
    required_error: "Connection status is required",
  },
);

export const providerSchema = z.object(
  {
    sid: z.string(),
    name: z.string(),
    path: z.string().optional(),
    portalUrl: z.string().optional(),
  },
  {
    required_error: "Provider is required",
  },
);

export const connectionTypeSchema = z.object(
  {
    sid: z.string(),
    name: z.string(),
  },
  {
    required_error: "Connection type is required",
  },
);

function canBeISP(status: ISPStatus) {
  return [ISPStatus.IS_BACKUP, ISPStatus.IS_PRIMARY].includes(status);
}

export const connectionDetails = z
  .object({
    contract_status: connectionStatusSchema.nullable(),
    type: z
      .enum([ISPStatus.IS_BACKUP, ISPStatus.IS_PRIMARY, ISPStatus.IS_UNKNOWN])
      .default(ISPStatus.IS_UNKNOWN),
  })
  .superRefine((data, ctx) => {
    if (data.contract_status && !canBeISP(data.type)) {
      ctx.addIssue({
        code: "custom",
        path: ["type"],
        message: "Contract status can only be set for primary or backup connections",
      });
    }
  });

export const QuoteZodSchema = z.object({
  availability: z.union([
    z.literal("location_on_net"),
    z.literal("location_near_net"),
    z.literal("location_off_net"),
    z.literal("unknown"),
  ]),
  provider: providerSchema,
  connection_type: connectionTypeSchema.refine(
    (value) => !!value,
    "Connection type must be specified",
  ),
  monthly_fee: requiredNumericString,
  install_fee: numericString,
  download_kbps: z.number().transform((val) => val * 1000),
  upload_kbps: z.number().transform((val) => val * 1000),
  installation_estimates: numericString,
  contract_length: z.number().positive(),
  // contract_status: connectionStatusSchema.optional(),
  // type: z.enum([ISPStatus.IS_BACKUP, ISPStatus.IS_PRIMARY, ISPStatus.IS_UNKNOWN]),
  pdf: z.instanceof(File).optional(),
  // contract_url: zodBlankable(z.string().url().nullable()),
  details: connectionDetails,
  support_contacts: z.object({
    contactPhoneNumber: zodBlankable(
      z.string().min(7, "Phone number should have at least 7 characters").nullable(),
    ).default(null),
    primaryContactEmail: zodBlankable(z.string().email().nullable()).default(null),
  }),
});

export const RefinedQuoteZodSchema = QuoteZodSchema.superRefine((data, ctx) => {
  if (!canBeISP(data.details.type)) {
    if (!isEmpty(data?.support_contacts?.contactPhoneNumber)) {
      ctx.addIssue({
        code: "custom",
        path: ["support_contacts", "contactPhoneNumber"],
        message: "Support details cannot be specified for non-ISP connections",
      });
    }
    if (!isEmpty(data?.support_contacts?.primaryContactEmail)) {
      ctx.addIssue({
        code: "custom",
        path: ["support_contacts", "primaryContactEmail"],
        message: "Support details cannot be specified for non-ISP connections",
      });
    }
  }
});

// Define a Zod object type
const User = z.object({
  name: z.string(),
  age: z.number(),
  address: z.object({
    street: z.string(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
    zip2: z.number().optional(),
  }),
});

export interface QuoteFormData extends z.TypeOf<typeof QuoteZodSchema> {}
export type QuoteSchemaType = z.infer<typeof QuoteZodSchema>;

/*
Write a static type called AllPaths<T> that uses conditional types, type interpolation and recursive type definitions  to create a union of all the string paths to objects in the schema T. Includes even nested paths, so AllPaths<UserType> for example would return a union type of the form "name" | "age" | "address.street" | "address.city" | "address.state" | "address.zip"
 */

export type UserType = z.infer<typeof User>;

export const TypedField = createTypedField<QuoteFormData>();

const newDefault = Object.freeze(
  Object.keys(QuoteZodSchema.shape).reduce(
    (acc, field) => ({
      ...acc,
      [field]: "",
    }),
    {} as Record<keyof typeof QuoteZodSchema["shape"], string>,
  ),
);

export function getBlankQuoteFormData(): Partial<QuoteFormData> {
  return {
    ...newDefault,
    availability: "location_on_net",
    type: ISPStatus.IS_UNKNOWN,
  };
}
