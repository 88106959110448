import { CompanyContractsResponse } from "@meterup/api";
import { StringsOption } from "@meterup/ui/src/components/Select/hooks/Options";

export function companyContractsToISPOptions(
  companyContracts?: CompanyContractsResponse[],
): StringsOption[] {
  const result: StringsOption[] = [];

  if (companyContracts) {
    companyContracts.forEach((contract: CompanyContractsResponse) => {
      const { companyContracts: companyContractsInner, companyLocation } = contract;
      if (!companyContractsInner || !companyLocation) {
        return;
      }
      const { location, name } = companyLocation;

      if (!location) {
        return;
      }

      const { address } = location;
      if (!address) {
        return;
      }

      const { address1 } = address;
      companyContractsInner.forEach((companyContract) => {
        const { internetServicePlan } = companyContract;
        if (!internetServicePlan) {
          return;
        }
        const { providerName, sid } = internetServicePlan;

        result.push({
          value: sid,
          label: `${address1}: ${providerName}`,
        });
      });
    });
  }

  return result;
}
