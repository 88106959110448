import React, { useCallback, useMemo } from "react";

import {
  ConnectionType,
  post,
  Provider,
  put,
  Quote,
  QuotesCreateRequest,
  useApiResources,
} from "@meterup/api";
import { Button } from "@meterup/metric";
import { Select, Textarea, TextInput, validation } from "@meterup/ui";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";

import { route } from "../routing";

interface QuoteFormProps {
  quoteRequestSid: string;
  quote?: Quote;
  initialValues?: QuotesCreateRequest;
}

const riserResponsibilityItems = [
  {
    label: "Provider",
    value: "provider_responsibility",
  },
  {
    label: "Customer",
    value: "customer_responsibility",
  },
];

export default function QuoteForm({
  quoteRequestSid,
  quote,
  initialValues: propsInitialValues,
}: QuoteFormProps) {
  const navigate = useNavigate();

  const [providersLoading, providers] = useApiResources("providers", "providers");

  const [connectionTypesLoading, connectionTypes] = useApiResources(
    "connectionTypes",
    "admin/connection-types",
  );

  const providerItems = useMemo(
    () =>
      (providers as Provider[]).map(({ sid, name }: Provider) => ({
        value: sid,
        label: name,
      })),
    [providers],
  );

  const connectionTypeItems = useMemo(
    () =>
      (connectionTypes as ConnectionType[]).map(({ sid, name }: ConnectionType) => ({
        value: sid,
        label: name,
      })),
    [connectionTypes],
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      const formattedValues = {
        ...values,
        monthlyFeeCents: Math.round(values.monthlyFeeCents * 100),
        installFeeCents: Math.round(values.installFeeCents * 100),
        downloadKbps: Number(values.downloadKbps * 1024).toFixed(0),
        uploadKbps: Number(values.uploadKbps * 1024).toFixed(0),
      };

      if (quote) {
        if (quote.status === "requested") {
          formattedValues.status = "disabled"; // Once the admin updates the quote, it puts it out of the requested state
        }
        await put(`admin/quote-requests/${quoteRequestSid}/quotes/${quote.sid}`, formattedValues);
      } else {
        await post(`admin/quote-requests/${quoteRequestSid}/quotes`, formattedValues);
      }

      navigate(route("quoteRequest", { sid: quoteRequestSid }));
    },
    [navigate, quote, quoteRequestSid],
  );

  const initialValues = useMemo(() => {
    let init: any = quote;
    if (!init) {
      init = propsInitialValues;
    }

    return init
      ? {
          provider: init.provider?.sid || init.provider,
          connectionType: init.connectionType?.sid || init.connectionType,
          monthlyFeeCents: init.monthlyFeeCents / 100,
          installFeeCents: init.installFeeCents / 100,
          contractLengthMonths: init.contractLengthMonths,
          downloadKbps: init.downloadKbps / 1024,
          uploadKbps: init.uploadKbps / 1024,
          installationEstimateDays: init.installationEstimateDays,
          riserResponsibility: init.riserResponsibility,
          notes: init.notes,
        }
      : {};
  }, [quote, propsInitialValues]);

  return (
    <Form<QuotesCreateRequest>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting, valid }) => (
        <form onSubmit={handleSubmit} className="mt-4 px-4">
          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="provider">
              Provider
            </label>

            <Field<string>
              render={(props) => (
                <Select {...props} disabled={providersLoading} options={providerItems} />
              )}
              size="x-large"
              name="provider"
              id="provider"
              placeholder="Select a provider"
              initialValue={initialValues.provider}
            />
          </div>

          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="connectionType">
              Connection Type
            </label>

            <Field<string>
              render={(props) => (
                <Select
                  {...props}
                  disabled={connectionTypesLoading}
                  options={connectionTypeItems}
                />
              )}
              size="x-large"
              name="connectionType"
              id="connectionType"
              placeholder="Select a connection type"
              initialValue={initialValues.connectionType}
            />
          </div>

          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="monthlyFeeCents">
              Monthly Fee ($)
            </label>

            <Field<string>
              component={TextInput}
              name="monthlyFeeCents"
              type="number"
              size="x-large"
              id="monthlyFeeCents"
              validate={validation.required}
            />
          </div>

          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="installFeeCents">
              Install Fee ($)
            </label>

            <Field<string>
              component={TextInput}
              name="installFeeCents"
              type="number"
              size="x-large"
              id="installFeeCents"
              validate={validation.required}
            />
          </div>

          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="contractLengthMonths">
              Contract Length (Months)
            </label>

            <Field<string>
              component={TextInput}
              name="contractLengthMonths"
              type="number"
              size="x-large"
              id="contractLengthMonths"
              validate={validation.required}
            />
          </div>

          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="downloadKbps">
              Download (Mbps)
            </label>

            <Field<string>
              component={TextInput}
              name="downloadKbps"
              type="number"
              size="x-large"
              id="downloadKbps"
              validate={validation.required}
            />
          </div>

          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="uploadKbps">
              Upload (Mbps)
            </label>

            <Field<string>
              component={TextInput}
              name="uploadKbps"
              type="number"
              size="x-large"
              id="uploadKbps"
              validate={validation.required}
            />
          </div>

          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="installationEstimateDays">
              Installation Estimate (Days)
            </label>

            <Field<string>
              component={TextInput}
              name="installationEstimateDays"
              type="number"
              size="x-large"
              id="installationEstimateDays"
              validate={validation.required}
            />
          </div>

          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="riserResponsibility">
              Riser Responsibility
            </label>

            <Field<string>
              render={(props) => <Select {...props} options={riserResponsibilityItems} />}
              name="riserResponsibility"
              id="riserResponsibility"
              size="x-large"
              placeholder="Select Riser Responsibility"
            />
          </div>

          <div className="flex flex-col w-1/3 mb-8">
            <label className="block pr-4 mb-2 text-gray" htmlFor="notes">
              Notes
            </label>

            <Field<string> component={Textarea} name="notes" id="notes" size="x-large" />
          </div>

          <div className="flex flex-col w-1/3 pt-8 mb-8 border-t-1 border-gray-100">
            <p className="w-1/3">
              <Button size="x-large" disabled={!valid || submitting} type="submit">
                {quote ? "Save" : "Create"}
              </Button>
            </p>
          </div>
        </form>
      )}
    />
  );
}
