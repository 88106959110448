import { HStack } from "@meterup/metric";

import { ExpandFlex } from "../../components/ExpandFlex";
import { styled } from "../../styled";
import { DetailsArrow } from "../../styles/DetailsArrow";

export const CellWrapper = styled(HStack, {
  variants: {
    variant: {
      lastTdSticky: {
        boxShadow: "$fenceLeftLight",
      },
      "lastTdSticky-NoArrow": {
        height: "100%",
        boxShadow: "$fenceLeftLight",
        [`& ${DetailsArrow}`]: {
          display: "none",
        },
        [`& ${ExpandFlex}`]: {
          display: "none",
        },
      },
    },
  },
});

export const InnerCellWrapper = styled("div", {
  display: "block",
  // overflow: "hidden",
  "& > div": {
    textOverflow: "ellipsis",
  },
});
