import { ISPProduct, ISPStatus } from "@meterup/api/src/apiProtos";
import { Quote as OuterQuote } from "@meterup/proto/js/connect_pb";

import { BandwidthProps } from "./types";

type Quote = OuterQuote.AsObject;

export function pbProductToReadable(product?: string): string | undefined {
  if (!product) {
    return product;
  }
  let productName;

  // eslint-disable-next-line default-case
  switch (product) {
    case ISPProduct.IR_DEDICATED_FIBER:
      productName = "Dedicated";
      break;
    case ISPProduct.IR_BUSINESS_CABLE:
      productName = "Coax";
      break;
    case ISPProduct.IR_SHARED_FIBER:
      productName = "Shared";
      break;
  }

  return productName;
}

export function pbStatusToReadable(status: ISPStatus): string {
  if (status === ISPStatus.IS_BACKUP) {
    return "Secondary";
  }

  if (status === ISPStatus.IS_PRIMARY) {
    return "Primary";
  }

  return "Unknown";
}

export function roundKbps(kbps: number) {
  return Math.floor(kbps / 5) * 5;
}

export function roundCleanKbps(kbps: number) {
  const num = Math.floor(kbps);
  return Math.floor(kbps / 5) * 5;
}

const numberFormatter = new Intl.NumberFormat();

export function formatKbps(kbps: number) {
  const mbps = kbps / 1_000;
  return `${numberFormatter.format(roundKbps(mbps))} Mbps`;
}

type FormatBandwidthProps = {
  value?: {
    downloadKbps: number;
    uploadKbps: number;
  };
};

export function formatBandwidth(bandwidth?: FormatBandwidthProps): BandwidthProps | undefined {
  const { value } = bandwidth || {};
  if (!value) {
    return value;
  }

  const { downloadKbps, uploadKbps } = value;
  if (!downloadKbps && !uploadKbps) {
    return undefined;
  }

  return {
    downloadKbps: formatKbps(downloadKbps),
    uploadKbps: formatKbps(uploadKbps),
  };
}

export function quoteToTerm(quote?: Quote) {
  if (!quote) {
    return quote;
  }

  const { contractLengthMonths } = quote;

  if (contractLengthMonths === 1) {
    return "Month-to-month";
  }

  return `${contractLengthMonths} Month`;
}
