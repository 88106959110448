import React, { useMemo } from "react";
import Layout from "../components/Layout";
import { Link } from "@meterup/metric";
import ProvidersTable from "../components/Providers/ProvidersTable/ProvidersTable";
import { useRouter } from "@meterup/ui";
import { route } from "../routing";

interface ProvidersProps {}

const Providers: React.FunctionComponent<ProvidersProps> = () => {
  const { handleOnClick } = useRouter();
  // TODO: This format of Title + content will probably be re-used, perhaps move
  // within <Layout> or a separate <Content>
  return (
    <Layout>
      <div className="mt-8">
        <div className="flex px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <h1 className="mb-8 text-2xl font-semibold text-gray-900">
            Providers
            <span className="text-gray-600"> &middot; </span>
            <Link href={route("new_provider")} onClick={handleOnClick}>
              Add a provider
            </Link>
          </h1>
        </div>
        <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <ProvidersTable />
        </div>
      </div>
    </Layout>
  );
};

export default Providers;
