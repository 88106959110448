import React from "react";

import { VStack } from "@meterup/metric";

import { ShowContentContainer } from "./containers";

type ShowContentProps = {
  children: React.ReactNode;
};

export default function ShowContent({ children }: ShowContentProps) {
  return (
    <VStack spacing={8} as={ShowContentContainer}>
      {children}
    </VStack>
  );
}
