import { styled } from "@meterup/ui";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback } from "react";

import { axios, post, QuoteRequest as QuoteRequestApi, useApiResource } from "@meterup/api";
import { Button, HStack, Icon } from "@meterup/metric";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useParams } from "react-router-dom";

import Layout from "../components/Layout";
import ProvidersAvailability from "../components/QuoteRequest/ProvidersAvailability";
import QuoteRequestInfo from "../components/QuoteRequest/QuoteRequestInfo";
import QuotesTable from "../components/QuoteRequest/QuotesTable";
import { route } from "../routing";
import NotFound from "./NotFound";

const Header = styled("div", {
  marginLeft: "$16",
  alignItems: "center",
  // display: flex;
});

function QuotesTableContainer({ quoteRequest }: { quoteRequest: QuoteRequestApi }) {
  const navigate = useNavigate();

  const onGenerateDemoQuotes = useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure?")) {
      await post(`admin/quote-requests/${quoteRequest.sid}/demo-quotes`, {});
      window.location.reload();
    }
  }, [quoteRequest]);

  return (
    <div className="flex flex-col h-full px-8 pt-4 overflow-y-auto">
      <HStack spacing={16} as={Header}>
        <h3 className="text-xl">Quote List</h3>

        <p className="flex items-center ml-auto">
          <Button variant="tertiary" size="large" onClick={onGenerateDemoQuotes}>
            <Icon icon="plus" className="mr-1" /> Demo Quotes
          </Button>
          <span className="ml-2">
            <Button
              size="large"
              onClick={() => navigate(route("newQuote", { sid: quoteRequest.sid }))}>
              <Icon icon="plus" /> New Quote
            </Button>
          </span>
        </p>
      </HStack>
      <div className="mt-4">
        <QuotesTable quoteRequest={quoteRequest} />
      </div>
    </div>
  );
}

export default function QuoteRequest() {
  const { sid } = useParams<{
    sid: string;
  }>();
  const { data: quoteRequest, isLoading: quoteRequestLoading } = useQuery(
    ["admin", "quote-requests", sid],
    () => axios.get(`v1/connect/admin/quote-requests/${sid}`),
  );

  if (quoteRequestLoading) {
    return (
      <div>
        <Skeleton />
      </div>
    );
  }

  if (!quoteRequest) {
    return <NotFound />;
  }

  const locationSid = quoteRequest?.location?.locationSid;

  return (
    <Layout>
      <div className="flex flex-col h-full">
        <div className="px-4 py-6 bg-gray-50 border-b border-gray-100">
          <p className="px-4 mb-4 text-sm">
            <Link to={route("quoteRequests")}>
              <Icon icon="chevronLeft" /> Back
            </Link>
          </p>
          <QuoteRequestInfo quoteRequest={quoteRequest} />
        </div>
        {locationSid && (
          <div className="mt-4 p-8">
            <ProvidersAvailability locationSid={locationSid} />
          </div>
        )}
        <QuotesTableContainer quoteRequest={quoteRequest} />
      </div>
    </Layout>
  );
}
