import camelCaseKeys from "camelcase-keys";
import { useState, useCallback, useEffect } from "react";
import { Entity } from "../protos";
import { get } from "../requests";

export default function useApiResource<T extends Entity>(
  path: string,
  setLoadingOnReload?: boolean,
  findOrFail?: boolean,
): [boolean, T?, (() => Promise<void>)?, boolean?] {
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [entity, setEntity] = useState<T>();

  const reload = useCallback(async () => {
    try {
      const response = (await get(path, setLoadingOnReload ? setLoading : undefined)) as T;

      if (response) {
        setEntity(camelCaseKeys(response, { deep: true }));
      }
    } catch (e) {
      if (e.code === 404 && findOrFail) {
        setFailed(true);
        return;
      }

      throw e;
    }
  }, [path]);

  useEffect(() => {
    (async () => {
      !setLoadingOnReload && setLoading(true);
      await reload();
      !setLoadingOnReload && setLoading(false);
    })();
  }, []);

  return [loading, entity, reload, failed];
}
