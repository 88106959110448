import React, { useCallback, useMemo, useState } from "react";

import type {
  AxiosAPIError,
  CompanyContractResponse,
  CompanyContractsResponse,
  CompanyISPStatusChangeRequest,
  CompanyISPStatusChangeResponse,
  ConnectionChangeStatusCategory,
  ConnectionStatus,
} from "@meterup/api";
import { axios } from "@meterup/api";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  HStack,
  Icon,
  LoadingIcon,
} from "@meterup/metric";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { useAllStatuses, useStatusesByCategory } from "../../../hooks/useAllStatuses";
import { log, logError } from "../../../Log.utils";
import { styled } from "../../../styled";
import { StatusCell } from "../../Table/CommonCells";

const DropdownMenuLabelWrapper = styled("div", {
  "& > div": {
    paddingLeft: "$6",
    "&:first-letter": {
      textTransform: "uppercase",
    },
  },
});

type EditStatusProps = {
  companyContract: CompanyContractResponse;
};

export default function EditStatus({ companyContract }: EditStatusProps) {
  const { latestStatus, internetServicePlan } = companyContract;
  const { companySID, companyLocationSID: sid } = useParams();
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useAllStatuses();
  const { byCategory } = useStatusesByCategory();
  // POST /v1/connect/admin/companies/:company-slug/internet-service-plans/:internet-service-plan-sid/statuses
  const updateStatusMutation = useMutation<
    CompanyISPStatusChangeResponse,
    AxiosAPIError,
    CompanyISPStatusChangeRequest
  >(
    (request) => {
      if (!internetServicePlan) {
        logError("No internet service plan found");
        throw new Error("No internet service plan found");
      }
      return axios.post<CompanyISPStatusChangeResponse>(
        `v1/connect/admin/companies/${companySID}/internet-service-plans/${internetServicePlan.sid}/statuses`,
        request,
      );
    },
    {
      onSuccess: (response) => {
        queryClient.setQueryData(["contracts", { sid }], (oldData?: CompanyContractsResponse) => {
          if (!oldData || !response) {
            return oldData;
          }
          const companyContracts = oldData.companyContracts.map((contract) => {
            if (internetServicePlan?.sid === contract.internetServicePlan?.sid) {
              return {
                ...contract,
                latestStatus: response.statusChange,
              };
            }
            return contract;
          });
          return {
            ...oldData,
            companyContracts,
          };
        });
      },
      onError: (error) => {
        logError(error);
      },
    },
  );

  const [selectedStatus, setSelectedStatus] = useState<ConnectionStatus | null>(null);

  const onDropdownSelect = useCallback(
    (status: ConnectionStatus) => () => {
      log("Selected status", status);
      setSelectedStatus(status);
      updateStatusMutation.mutate({ status });
    },
    [updateStatusMutation],
  );
  const dropdownMenuItems = useMemo(() => {
    if (!byCategory) {
      return null;
    }
    return Object.keys(byCategory).flatMap((category, i) => {
      const items = byCategory[category as ConnectionChangeStatusCategory].map((status) => (
        <DropdownMenuItem onSelect={onDropdownSelect(status)} key={`dmi-${status.status}`}>
          {status.display}
        </DropdownMenuItem>
      ));
      return [
        ...(i === 0 ? [] : [<DropdownMenuSeparator key={`dms-${category}`} />]),
        <DropdownMenuLabelWrapper key={`dml-wrapper-${category}`}>
          <DropdownMenuLabel>{category.replaceAll("-", " ")}</DropdownMenuLabel>
        </DropdownMenuLabelWrapper>,
        ...items,
      ];
    });
  }, [byCategory, onDropdownSelect]);

  if (isLoading) {
    return (
      <div>
        <LoadingIcon size={16} />
      </div>
    );
  }

  return (
    <HStack spacing={4} align="center">
      {updateStatusMutation.isLoading ? (
        <LoadingIcon size={16} />
      ) : (
        <StatusCell connectionStatusChange={latestStatus} />
      )}
      <DropdownMenu>
        <DropdownMenuTrigger asChild disabled={updateStatusMutation.isLoading}>
          <Button variant="tertiary" arrangement="leading-icon" size="small">
            <Icon icon="arrowsVertical" color={{ light: "gray-500" }} size={12} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={0} side="bottom" align="start">
          {/* <Select<ConnectionStatus> */}
          {/*  openMenuOnFocus */}
          {/*  // ref={(r: Select<Option, true, GroupBase<Option>> | null) => console.log(r)} */}
          {/*  closeMenuOnScroll={false} */}
          {/*  getOptionLabel={displayStatus} */}
          {/*  getOptionValue={displayStatus} */}
          {/*  menuPlacement="bottom" */}
          {/*  noOptionsMessage={() => null} */}
          {/*  onChange={optionSelected} */}
          {/*  onKeyDown={onKeyDown} */}
          {/*  options={data.statuses} */}
          {/*  placeholder="Status name or category" */}
          {/*  ref={selectRef} */}
          {/*  styles={customStyles} */}
          {/*  value={null} */}
          {/*  inputValue="" */}
          {/*  isOptionSelected={(status) => selectedStatus?.sid === status.sid} */}
          {/*  onInputChange={(newValue: string, actionMeta: InputActionMeta): void => { */}
          {/*    console.log("onInputChange", newValue, actionMeta); */}
          {/*  }} */}
          {/*  onMenuOpen={(): void => { */}
          {/*    console.log("onMenuOpen"); */}
          {/*  }} */}
          {/*  onMenuClose={(): void => { */}
          {/*    console.log("onMenuClose"); */}
          {/*  }} */}
          {/* /> */}
          {dropdownMenuItems}
        </DropdownMenuContent>
      </DropdownMenu>
    </HStack>
  );
}
