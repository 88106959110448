import isString from "lodash/isString";

type ReadDocument = {
  file: File | null | undefined;
};

export default async function readDocument({ file }: ReadDocument) {
  if (!file) {
    return null;
  }
  const documentContents = await new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const { result } = reader;
      if (isString(result)) {
        resolve(reader.result);
      }
    });
    reader.readAsDataURL(file);
  });
  return {
    name: file.name,
    content: documentContents.split(",", 2)[1],
  };
}
