import React from "react";
import { Address } from "@meterup/api";

interface FormattedAddressProps {
  address: Partial<Address>;
  emphasiseLine1?: boolean;
  showAddress2?: boolean;
  multiline?: boolean;
}

const FormattedAddress: React.FunctionComponent<FormattedAddressProps> = ({
  address,
  emphasiseLine1 = false,
  showAddress2 = false,
  multiline = true,
}) =>
  address ? (
    <span className="font-normal leading-relaxed tracking-wide">
      <span className={emphasiseLine1 ? "text-xl font-medium" : undefined}>{address.address1}</span>
      {showAddress2 && address.address2 ? (
        <span className="block">{address.address2}</span>
      ) : multiline ? (
        <br />
      ) : (
        ", "
      )}
      {address.city}, {address.state} {address.postalCode}
    </span>
  ) : (
    <></>
  );

export default FormattedAddress;
