import React from "react";

import { Spinner } from "@meterup/metric";
import { Row } from "@tanstack/react-table";
import size from "lodash/size";

import RowComponent from "./Row";

type TableBodyContentsProps<RecordType> = {
  loading: boolean;
  maxColumns: number;
  onClickRow: (row: Row<RecordType>) => void;
  rows: Row<RecordType>[];
};

const CLASS_NAMES = "bg-white divide-y divide-gray-50";

export default function TableBody<RecordType>({
  loading,
  maxColumns,
  onClickRow,
  rows,
}: TableBodyContentsProps<RecordType>) {
  if (loading) {
    return (
      <tbody className={CLASS_NAMES}>
        <tr>
          <td className="p-4" colSpan={maxColumns}>
            <Spinner />
          </td>
        </tr>
      </tbody>
    );
  }
  if (size(rows) === 0) {
    return (
      <tbody className={CLASS_NAMES}>
        <tr>
          <td className="px-6 py-2 text-sm opacity-75" colSpan={maxColumns}>
            No rows found
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody className={CLASS_NAMES}>
      {rows.map((row: Row<any>) => (
        <RowComponent key={row.id} row={row} onClick={onClickRow} />
      ))}
    </tbody>
  );
}
