import { QuoteRequest } from "@meterup/api";

export interface StatusChipProps {
  status: QuoteRequest["status"];
  isManualResponsePending: QuoteRequest["isManualResponsePending"];
}

export default function statusToFields({ status, isManualResponsePending }: StatusChipProps) {
  if (status === "created") {
    return {
      label: "Draft",
      text: "text-gray-800",
      bg: "bg-gray-50",
    };
  }
  if (status === "ready_for_review") {
    if (isManualResponsePending) {
      return { label: "Manual Quotes Requested", text: "text-yellow-900", bg: "bg-yellow-100" };
    }
    return {
      label: "In Review",
      text: "text-blue-800",
      bg: "bg-blue-100",
    };
  }
  if (status === "contract_requested") {
    return {
      label: "Contract Requested",
      text: "text-green-800",
      bg: "bg-green-100",
    };
  }

  return {
    label: "Unknown",
  };
}

export function statusToLabel(props: StatusChipProps) {
  return statusToFields(props).label;
}
