import React from "react";
import cx from "classnames";
import ModalBackground from "./ModalBackground";
import { ModalViewProps, StandardModal } from "../old-modal-types";

const SuccessModal: React.FunctionComponent<ModalViewProps<StandardModal>> = ({
  visible,
  direction,
  modal,
  onClose,
}) => (
  <div className="fixed inset-0 z-10 overflow-y-auto">
    <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
      <ModalBackground onClose={onClose} visible={visible} direction={direction} />

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>

      <div
        className={cx(
          "inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6",
          {
            "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95": !visible,
            "opacity-100 translate-y-0 sm:scale-100": visible,
            "ease-out duration-300": direction === "entering",
            "ease-in duration-200": direction === "leaving",
          },
        )}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div>
          <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
            <svg
              className="w-6 h-6 text-green-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-headline">
              {modal.title}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray">{modal.message}</p>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6">
          <button
            onClick={modal.onConfirm}
            type="button"
            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
            {modal.confirmText}
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default SuccessModal;
