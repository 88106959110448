import { styled } from "../styled";

export const SearchForm = styled("form", {
  "& > label": {
    borderRadius: "30px",
    transition: "width 250ms linear 0",
  },

  variants: {
    state: {
      entering: {
        "& > label": {
          // maxWidth: 0,
          // minWidth: 0,
          width: 50,
        },
      },
      entered: {
        "& > label": {
          // maxWidth: 220,
          // minWidth: 220,
          width: 220,
        },
      },
      exiting: {
        "& > label": {
          // maxWidth: 0,
          // minWidth: 220,
          // width: 0,
          width: 50,
        },
      },
      exited: {
        "& > label": {
          // maxWidth: 0,
          // minWidth: 220,
          width: 50,
          // width: 0,
        },
      },
    },
  },
});
