import {
  Currency as CurrencyProto
} from "@meterup/api/src/common";

const usd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export type Currency = {
  amount: number;
  unit?: CurrencyProto;
};

const currencyDivisors = {
  [CurrencyProto.USD]: 1,
  [CurrencyProto.USD3]: 1_000,
  [CurrencyProto.USD5]: 100_000,
};

export function formatCurrencyToNum(currency: Currency) {
  const { amount, unit } = currency;
  // We just assume USD3 everywhere for right now.
  return amount / currencyDivisors[unit || CurrencyProto.USD3];
}

export function formatCurrency(currency: Currency) {
  return usd.format(formatCurrencyToNum(currency));
}
