import React from "react";

// import ErrorBoundary from "./ErrorBoundary";
import { FlashMessages } from "@meterup/ui";

import Sidebar from "./Sidebar";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex h-screen overflow-hidden bg-white">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <div className="pt-1 pl-1 md:hidden sm:pl-3 sm:pt-3">
          <button className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-light hover:text-gray-900 focus:outline-none focus:bg-gray-50 transition ease-in-out duration-150">
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <main className="z-0 flex-1 overflow-y-auto focus:outline-none" tabIndex={0}>
          <FlashMessages />
          {children}
        </main>
      </div>
    </div>
  );
}
