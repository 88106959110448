import * as Sentry from "@sentry/react";
import trim from "lodash/trim";

export const logError = (error: any, ...rest: any[]) => {
  if (import.meta.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    // console.error(error, ...rest);
    console.log(error.stack);
  }

  Sentry.captureException(error);
};

export enum LogLevel {
  DEBUG = "debug",
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

const ignoredInProd = new Set([LogLevel.DEBUG, LogLevel.INFO]);
type RestLogLevels = Exclude<LogLevel, LogLevel.ERROR>;
const logLevels = new Set<LogLevel>([LogLevel.DEBUG, LogLevel.INFO, LogLevel.WARN, LogLevel.ERROR]);

export declare function log(level: LogLevel.ERROR, error: any, ...args: any[]): void;
export declare function log(level: RestLogLevels, ...args: any[]): void;
export declare function log(...args: any[]): void;

function isLogLevel(level: any): level is LogLevel {
  return logLevels.has(level);
}

export function allLoggingEnabled() {
  return !!window.ENABLE_ALL_LOGGING;
}

export function shouldLog(level: LogLevel) {
  return (
    !ignoredInProd.has(level) || import.meta.env.NODE_ENV !== "production" || allLoggingEnabled()
  );
}

function formatTraceLines(str: string[]): string[] {
  return str.flatMap((s) => [trim(s), "\n"]);
}

export function log(level: LogLevel, ...args: any[]) {
  let effectiveLevel: LogLevel = LogLevel.INFO;
  if (isLogLevel(level)) {
    effectiveLevel = level;
  }
  if (!shouldLog(effectiveLevel)) { return;
  }

  if (effectiveLevel === LogLevel.ERROR) {
    const [message, ...rest] = args;
    logError(message, ...rest);
    return;
  }

  // eslint-disable-next-line no-console
  const consoleFn = console[effectiveLevel];

  const err = new Error();
  const stack = err.stack.split("\n");
  const [, , l1, l2] = stack;
  const lines = formatTraceLines([l1, l2]);

  consoleFn(...lines, ...args);
}
