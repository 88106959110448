import React, { useState, useCallback, useEffect } from "react";

interface ProviderLogoProps {
  name: string;
  path?: string;
}

const ProviderLogo: React.FunctionComponent<ProviderLogoProps> = ({ name, path = "" }) => {
  const [showImage, setShowImage] = useState(path.length > 0);
  const ASSET_DOMAIN = "https://public.meter.com"; // TODO: Dynamic based on ENV

  useEffect(() => {
    setShowImage(true);
  }, [path]);

  const onError = useCallback((event) => {
    setShowImage(false);
  }, []);

  if (path && showImage) {
    const assetUrl = ASSET_DOMAIN + path;
    return (
      <img
        src={assetUrl}
        alt={name}
        title={`Logo of ${name}`}
        onError={onError}
        style={{ maxHeight: "28px" }}
      />
    );
  }

  return <>{name}</>;
};

// RRF = React Final Form
export default ProviderLogo;
