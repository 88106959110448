import { logError } from "@meterup/ui/src/Log.utils";
import React, { useCallback, useMemo } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { axios, QuoteRequest, QuoteRequestUpdateRequestBase } from "@meterup/api";
import {
  Button,
  MinList,
  MinListControls,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
} from "@meterup/metric";
import { OpenDrawerLink } from "@meterup/ui";
import { EmDash } from "@meterup/ui/src/constants";

type ContactFormProps = {
  quoteRequest?: QuoteRequest;
};

export default function ContactForm({ quoteRequest }: ContactFormProps) {
  const name = quoteRequest?.contactName;
  const email = quoteRequest?.contactEmail;
  const telephone = quoteRequest?.contactTelephone;

  return (
    <MinList>
      <MinListItemHeader icon="user">
        <MinListTitle>Contact</MinListTitle>
        <MinListControls>
          <OpenDrawerLink path="edit">
            <Button variant="secondary" size="small">
              Edit
            </Button>
          </OpenDrawerLink>
        </MinListControls>
      </MinListItemHeader>
      <MinListItemPair>
        <MinListItemLabel>Name</MinListItemLabel>
        <MinListItemValue>{name || <EmDash />}</MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>Email</MinListItemLabel>
        <MinListItemValue>
          {email ? <a href={`mailto:${email}`}>{email}</a> : <EmDash />}
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>Phone</MinListItemLabel>
        <MinListItemValue>
          {telephone ? <a href={`tel:${telephone}`}>{telephone}</a> : <EmDash />}
        </MinListItemValue>
      </MinListItemPair>
    </MinList>
  );
}
